import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent {

  mailMe(){
    window.open("https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=support@educatorsnowllc.com&tf=cm");
  }
}
