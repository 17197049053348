import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private URL_BASE: string;
  private URL_WEB: string;
  private VERSION: string;
  constructor() {
    /* Configuracion de url del servidor Api Rest */
    // this.URL_BASE = 'http://app.educators.test/api/';
    // this.URL_WEB = 'http://app.educators.test/';
    this.URL_BASE = environment.URL_BASE;
    this.URL_WEB = environment.URL_WEB;
    this.VERSION = '2.6.11';
  }

  /*-----------------------------------------------------------------------------------
  | Funci�n get url base. Retorna la url del servidor configurada en la variable
  -----------------------------------------------------------------------------------*/
  getUrlBase() {
    return this.URL_BASE;
  }
  getUrlWeb() {
    return this.URL_WEB;
  }
  getVersion() {
    return this.VERSION;
  }
}
