<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-100">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                <span class="sr-only">CLOSE</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div class="mt-2" x-data="{create: true, list: false, user: false}">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" name="tabs" class="block w-full focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md" (change)="tabOnChange($event)">
          <option value="create">Create User Profile (Default)</option>
          <option value="list">Edit User Profile</option>
          <option value="user">Create Member Users Profile (Quick)</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a x-on:click="user = false, create = true, list = false" x-bind:class="create ? 'border-orange-500 text-orange-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'" id="tabsList1">
              <svg x-bind:class="create ? 'text-orange-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
              </svg>
              <span>Create User Profile (Default)</span>
            </a>
            <a x-on:click="user = false, create = false, list = true" x-bind:class="list ? 'border-orange-500 text-orange-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'" id="tabsList2">
              <!-- Heroicon name: solid/office-building -->
              <svg x-bind:class="list ? 'text-orange-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span>Edit User Profile</span>
            </a>
            <a x-on:click="user = true, create = false, list = false" x-bind:class="user ? 'border-orange-500 text-orange-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'" id="tabsList3">
              <svg x-bind:class="user ? 'text-orange-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
              </svg>
              <span>Create Member Users Profile (Quick)</span>
            </a>
          </nav>
        </div>
      </div>

      <div x-show="user">
        <div class="mt-4">
          <h2 class="text-lg font-medium leading-6 text-gray-900">Create Member Users Profile (Quick)</h2>
          <p class="mt-1 text-sm text-gray-500">Quick Member User Profile Creation</p>
        </div>

        <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-3">
            <label for="user_type_user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">User Profile Type <span class="text-xss text-red-500"> (Required)</span></label>
            <jqxComboBox #comboboxUserTypeUserProfile
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="type_user_profiles"
              [theme]="'energyblue'"
              (onSelect)="userProfileTypeUserOnSelect($event)"
              [(ngModel)]="user_type_user_profile_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="rol_id" class="block text-sm font-medium text-gray-700 mb-1">Role <span class="text-xss text-red-500"> (Required)</span></label>
            <jqxComboBox #comboboxUserRol
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="roles"
              [theme]="'energyblue'"
              [(ngModel)]="user_rol_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="type_identification_id" class="block text-sm font-medium text-gray-700 mb-1">Identification Type <span class="text-xss text-red-500"> (Required)</span></label>
            <jqxComboBox #comboboxUserTypeIdentification
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="type_identifications"
              [theme]="'energyblue'"
              [(ngModel)]="user_type_identification_id"
              (onSelect)="onChangeUserFormatIdentification($event)"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <div class="group flex relative cursor-pointer">
              <label for="identification" class="block text-sm font-medium text-gray-700">
                Identification Number <span class="text-xss text-red-500"> (Required)</span>
              </label>

              <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                <p class="leading-2 pt-2 pb-2 w-full">Enter the identification number</p>
              </div>
              <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </span>
            </div>

            <input type="text" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_identification" [brmasker]="user_mask_identification">
          </div>

          <div class="col-span-12 sm:col-span-9" *ngIf="user_type_user_profile_id == 2">
            <label for="business_name" class="block text-sm font-medium text-gray-700">School Name <span class="text-xss text-red-500"> (Required)</span></label>
            <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_business_name">
          </div>

          <div class="col-span-12" *ngIf="user_type_user_profile_id == 3">
            <label for="business_name" class="block text-sm font-medium text-gray-700">Organization Name <span class="text-xss text-red-500"> (Required)</span></label>
            <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_business_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="user_type_user_profile_id == 2">
            <label for="organization_id" class="block text-sm font-medium text-gray-700 mb-1">Organization Parent<span class="text-xss text-red-500"> (Required)</span></label>
            <jqxComboBox #comboboxUserOrganization
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="organizations"
              [theme]="'energyblue'"
              [(ngModel)]="user_organization_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="user_type_user_profile_id == 1 || user_type_user_profile_id == 4 || user_type_user_profile_id == 5">
            <label for="first_name" class="block text-sm font-medium text-gray-700">First Name</label>
            <input type="text" autocomplete="first_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_first_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="user_type_user_profile_id == 1 || user_type_user_profile_id == 4 || user_type_user_profile_id == 5">
            <label for="second_name" class="block text-sm font-medium text-gray-700">Middle Name</label>
            <input type="text" autocomplete="second_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_second_name">
          </div>

          <div class="col-span-12 sm:col-span-{{ user_type_user_profile_id == 5 ? '3' : '6' }}" *ngIf="user_type_user_profile_id == 1 || user_type_user_profile_id == 4 || user_type_user_profile_id == 5">
            <label for="last_name" class="block text-sm font-medium text-gray-700">Last Name</label>
            <input type="text" autocomplete="last_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_last_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="user_type_user_profile_id == 5">
            <label for="parent_id" class="block text-sm font-medium text-gray-700 mb-1">Parent<span class="text-xss text-red-500"> (Required)</span></label>
            <jqxComboBox #comboboxUserParent
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="parent_user_profiles"
              [theme]="'energyblue'"
              [(ngModel)]="user_parent_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input type="number" min="0" (input)="numberInput($event)" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="cell_phone" class="block text-sm font-medium text-gray-700">Mobile Phone</label>
            <input type="number" min="0" (input)="numberInput($event)" autocomplete="mobile_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>

          <div class="col-span-12 sm:col-span-{{ user_type_user_profile_id == 4 ? '6' : '3' }}">
            <div class="group flex relative cursor-pointer">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email <span class="text-xss text-red-500"> (Required)</span>
              </label>

              <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                <p class="leading-2 pt-2 pb-2 w-full">Enter the email</p>
              </div>
              <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </span>
            </div>

            <input type="text" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_email">
          </div>

          <div class="col-span-12 sm:col-span-3 {{ user_type_user_profile_id == 4 ? 'hidden' : 'block' }}">
            <div class="group flex relative cursor-pointer">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password <span class="text-xss text-red-500"> (Required)</span>
              </label>

              <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                <p class="leading-2 pt-2 pb-2 w-full">Enter the password</p>
              </div>
              <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </span>
            </div>

            <input type="password" autocomplete="password" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_password">
          </div>

          <div class="col-span-12 sm:col-span-9">
          </div>

          <div class="col-span-12 sm:col-span-3">
            <button type="submit" (click)="registerUserOnClick()" class="w-full mt-1 bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Create User</button>
          </div>
        </div>
      </div>

      <div x-show="create">
        <div class="mt-4">
          <h2 class="text-lg font-medium leading-6 text-gray-900">Create User Profile (Default)</h2>
          <p class="mt-1 text-sm text-gray-500">Full User Creation</p>
        </div>

        <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-3">
            <label for="type_user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
              User Profile Type <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxComboBox #comboboxTypeUserProfile
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="type_user_profiles"
              [theme]="'energyblue'"
              [(ngModel)]="type_user_profile_id"
              (onSelect)="userProfileTypeOnSelect($event)"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="rol_id" class="block text-sm font-medium text-gray-700 mb-1">
              Role <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxComboBox #comboboxRol
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="roles"
              [theme]="'energyblue'"
              [(ngModel)]="rol_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="user_profile_id" class="block text-sm font-medium text-gray-700">User Profile ID</label>
            <input type="text" autocomplete="user_profile_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" disabled="">
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="status" class="block text-sm font-medium text-gray-700">
              Status <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="status" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" disabled="" value="Active">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
            <label for="first_name" class="block text-sm font-medium text-gray-700">
              First Name <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="first_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="first_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
            <label for="second_name" class="block text-sm font-medium text-gray-700">Middle Name</label>
            <input type="text" autocomplete="second_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="second_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
            <label for="last_name" class="block text-sm font-medium text-gray-700">
              Last Name <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="last_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="last_name">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
            <label for="nick_name" class="block text-sm font-medium text-gray-700">Nickname</label>
            <input type="text" autocomplete="nick_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="nickname">
          </div>

          <div class="col-span-12 sm:col-span-9" *ngIf="type_user_profile_id == 2">
            <label for="business_name" class="block text-sm font-medium text-gray-700">
              School Name <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="business_name">
          </div>

          <div class="col-span-12 sm:col-span-12" *ngIf="type_user_profile_id == 3">
            <label for="business_name" class="block text-sm font-medium text-gray-700">
              Organization Name <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="business_name">
          </div>

          <div class="col-span-3" *ngIf="type_user_profile_id == 2">
            <label for="organization_id" class="block text-sm font-medium text-gray-700 mb-1">
              Organization Parent <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxComboBox #comboboxOrganization
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="organizations"
              [theme]="'energyblue'"
              [(ngModel)]="organization_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="type_identification_id" class="block text-sm font-medium text-gray-700 mb-1">
              Type Identification <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxComboBox #comboboxTypeIdentification
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="type_identifications"
              [theme]="'energyblue'"
              [(ngModel)]="type_identification_id"
              (onSelect)="onChangeFormatIdentification($event)"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="identification" class="block text-sm font-medium text-gray-700">
              Identification Number <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="text" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="identification" [brmasker]="mask_identification">
          </div>

          <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4">
            <label for="birth_date" class="block text-sm font-medium text-gray-700 mb-1">
              Birth Date <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxDateTimeInput
              [width]="'100%'"
              [height]="38"
              [formatString]="'MM-dd-yyyy'"
              [(ngModel)]="birth_date"
              [theme]="'energyblue'"
            ></jqxDateTimeInput>
          </div>

          <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 2 || type_user_profile_id == 3 ? 'hidden' : 'block' }}">
            <label for="gender_id" class="block text-sm font-medium text-gray-700 mb-1">Gender</label>
            <jqxComboBox #comboboxGender
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="genders"
              [theme]="'energyblue'"
              [(ngModel)]="gender_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-12 bg-[#e8f0fe] px-1 py-5">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 sm:col-span-12">
                <h2 class="text-lg font-medium leading-6 text-gray-900">Current Address Information</h2>
                <p class="mt-1 text-sm text-gray-500">Current Address Information will be use for your GPS location.</p>
              </div>

              <div class="col-span-12 sm:col-span-3 hidden">
                <label for="country_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Current Country <span class="text-xss text-red-500">(Required)</span>
                </label>
                <jqxComboBox #comboboxCountry
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'common_name'"
                  [valueMember]="'id'"
                  [source]="countries"
                  [theme]="'energyblue'"
                  [(ngModel)]="country_id"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="state_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Current State <span class="text-xss text-red-500">(Required)</span>
                </label>
                <input type="text" name="state_id" id="state_id" autocomplete="state_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" value="GEORGIA" readonly>
                <!-- <jqxComboBox #comboboxState
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [disabled]="true"
                  [source]="states"
                  [theme]="'energyblue'"
                  [(ngModel)]="state_id"
                  (onSelect)="statesOnSelect($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox> -->
              </div>

              <div class="col-span-12 sm:col-span-3 hidden">
                <label for="county_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Current County <span class="text-xss text-red-500">(Required)</span>
                </label>
                <jqxComboBox
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [source]="counties"
                  [theme]="'energyblue'"
                  [(ngModel)]="county_id"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="address" class="block text-sm font-medium text-gray-700">
                  Current Address <span class="text-xss text-red-500">(Required)</span>
                </label>
                <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="address">
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="city_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Current City <span class="text-xss text-red-500">(Required)</span>
                </label>
                <jqxComboBox #comboboxCity
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [source]="cities"
                  [theme]="'energyblue'"
                  (onSelect)="cityOnSelect($event)"
                  (focusout)="cityFocusout($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                  Current ZIP Code <span class="text-xss text-red-500">(Required)</span>
                </label>
                <jqxComboBox #comboboxZipCode
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'code'"
                  [valueMember]="'code'"
                  [source]="zip_codes"
                  [theme]="'energyblue'"
                  (focusout)="zipCodeFocusout($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>
            </div>
          </div>

          <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 sm:col-span-3">
                <label for="permanent_state_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Permanent State
                </label>
                <jqxComboBox #comboboxPermanentState
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [source]="permanent_states"
                  [theme]="'energyblue'"
                  [(ngModel)]="permanent_state_id"
                  (onSelect)="permanentStateOnSelect($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="permanent_address" class="block text-sm font-medium text-gray-700">
                  Permanent Address
                </label>
                <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="permanent_address">
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="permanent_city_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Permanent City
                </label>
                <jqxComboBox #comboboxPermanentCity
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [source]="permanent_cities"
                  [theme]="'energyblue'"
                  (onSelect)="permanentCityOnSelect($event)"
                  (focusout)="permanentCityFocusout($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
              </div>

              <div class="col-span-12 sm:col-span-3">
                <label for="permanent_zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                  Permanent ZIP Code
                </label>
                <jqxComboBox #comboboxPermanentZipCode
                  [width]="'100%'"
                  [height]="38"
                  [displayMember]="'code'"
                  [valueMember]="'code'"
                  [source]="permanent_zip_codes"
                  [theme]="'energyblue'"
                  (focusout)="permanentZipCodeFocusout($event)"
                  [autoComplete]="true"
                  [searchMode]="'containsignorecase'"
                ></jqxComboBox>
                <!-- <input type="text" autocomplete="zip_code" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="permanent_zip_code"> -->
              </div>
            </div>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input type="number" min="0" (input)="numberInput($event)" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="cell_phone" class="block text-sm font-medium text-gray-700">
              Mobile Phone <span class="text-xss text-red-500">(Required)</span>
            </label>
            <input type="number" min="0" (input)="numberInput($event)" autocomplete="cell_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>

          <div class="col-span-12 sm:col-span-{{ type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5 ? '3' : '6' }}">
            <div class="group flex relative cursor-pointer">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email <span class="text-xss text-red-500">(Required)</span>
              </label>

              <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                <p class="leading-2 pt-2 pb-2 w-full">Enter the email</p>
              </div>
              <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </span>
            </div>

            <input type="text" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="email">
          </div>

          <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 2 || type_user_profile_id == 3 ? 'hidden' : 'block' }}">
            <label for="hire_date" class="block text-sm font-medium text-gray-700 mb-1">
              Hire Date <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxDateTimeInput
              [width]="'100%'"
              [height]="38"
              [formatString]="'MM-dd-yyyy'"
              [(ngModel)]="hire_date"
              [theme]="'energyblue'"
            ></jqxDateTimeInput>
          </div>

          <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 5 ? 'block' : 'hidden' }}">
            <label for="parent_id" class="block text-sm font-medium text-gray-700 mb-1">
              Parent <span class="text-xss text-red-500">(Required)</span>
            </label>
            <jqxComboBox #comboboxParent
              [width]="'100%'"
              [height]="38"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="parent_user_profiles"
              [theme]="'energyblue'"
              [(ngModel)]="parent_id"
              [autoComplete]="true"
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-{{ type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5 ? '12' : '6' }}">
            <label for="observation" class="block text-sm font-medium text-gray-700">Comments</label>
            <input type="text" autocomplete="observation" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation">
          </div>

          <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
            <div id="start_user_profile">
              <h2 class="text-lg font-medium leading-6 text-gray-900">
                Teacher About / Bio <span class="text-xss text-red-500">(Required)</span>
              </h2>
              <p class="mt-1 text-sm text-gray-500">Teacher Profile Description</p>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm">
            </div>
          </div>
          <div class="col-span-12 sm:col-span-8">&nbsp;</div>
          <div class="col-span-12 sm:col-span-2">
            <button type="submit" (click)="cleanUpOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Clear</button>
          </div>
          <div class="col-span-12 sm:col-span-2">
            <button type="submit" (click)="registerOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Create User</button>
          </div>
        </div>
      </div>

      <div x-show="list">
        <div class="mt-4">
          <h2 class="text-lg font-medium leading-6 text-gray-900">Edit User Profile</h2>
          <p class="mt-1 text-sm text-gray-500">Edit, Update and Add up to 3 Profile Pictures</p>
        </div>

        <div class="mt-6 grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-12">
            <jqxTooltip #tooltipReference [position]="'mouse'" [name]="'name'"></jqxTooltip>
            <jqxGrid #gridUserProfile
              [width]="'100%'"
              [cellhover]='cellHoverUserProfile'
              [source]="dataAdapterUserProfile"
              [columns]="columnsUserProfile"
              [pageable]="true"
              [autoheight]="true"
              [showfilterrow]="true"
              [columnsresize]="true"
              [filterable]="true"
              [sortable]="true"
              [editable]="false"
              [editmode]="'dblclick'"
              [selectionmode]="'singlerow'"
              (onCellclick)="onCellClick($event)"
              [localization]="localization"
              [theme]="'energyblue'"
            ></jqxGrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div *ngIf="modalImages && images_user_profile" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-1 text-center sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase" id="modal-title">Change images user profile {{ images_user_profile.first_name }} {{ images_user_profile.last_name }}</h3>
              <input type="hidden" class="input-energyblue" [(ngModel)]="user_id_change_images">
            </div>
          </div>
          <div class="mt-2 grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input type="file" class="hidden" name="photo" #imgInput1 accept=".png, .jpg, .jpeg" (change)="uploadImageOnChange($event, 1)"/>
              <input type="file" class="hidden" name="photo" #imgInput2 accept=".png, .jpg, .jpeg" (change)="uploadImageOnChange($event, 2)"/>
              <input type="file" class="hidden" name="photo" #imgInput3 accept=".png, .jpg, .jpeg" (change)="uploadImageOnChange($event, 3)"/>
            </div>
            <div class="col-span-12 sm:col-span-8">
              <img src="{{ img_1 }}" alt="img_1" class="w-full h-auto bg-gray-200 cursor-pointer" (click)="imgInput1.click()">
            </div>
            <div class="col-span-12 sm:col-span-4">
              <img src="{{ img_2 }}" alt="img_2" class="w-full h-auto bg-gray-200 cursor-pointer" (click)="imgInput2.click()">
              <img src="{{ img_3 }}" alt="img_3" class="w-full h-auto bg-gray-200 mt-3 cursor-pointer" (click)="imgInput3.click()">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modalImages = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalPDF" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-48 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-44">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Document</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalPDF = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2 grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <pdf-viewer
                [src]="pdfSource"
                [original-size]="false"
                [zoom]="0.9"
                [zoom-scale]="'page-width'"
                style="width: 100%; height: 500px"
              ></pdf-viewer>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modalPDF = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative z-30 {{ slideUserProfile && edit_user_profile_id ? 'block' : 'hidden' }}" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <div class="pointer-events-auto w-screen max-w-4xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">Edit User Profile</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button (click)="cleanUpEditOnClick(); slideUserProfile = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <!-- Main -->
            <div class="divide-y divide-gray-200">
              <div class="pb-6">
                <div class="h-24 bg-orange-600 sm:h-20 lg:h-28"></div>
                <div class="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                  <div>
                    <div class="-m-1 flex items-end">
                      <div class="inline-flex overflow-hidden rounded-full border-4 border-white bg-gray-200">
                        <input type="file" class="hidden" name="photo" #imgPhoto accept=".png, .jpg, .jpeg" (change)="uploadPhotoOnChange($event)"/>
                        <img *ngIf="edit_photo" src="{{ edit_photo }}" class="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 cursor-pointer" (click)="imgPhoto.click()">
                        <svg *ngIf="!edit_photo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 cursor-pointer" (click)="imgPhoto.click()">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      </div>
                      <div class="rounded-full w-9 h-9 flex justify-center items-center bg-orange-600 border-2 text-white text-xl font-bold cursor-pointer ml-1 hover:bg-orange-500" (click)="deletePhotoOnClick()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5  m-auto">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 mb-5 sm:ml-6 sm:flex-1">
                    <div class="flex items-center">
                      <h3 class="text-xl font-bold text-gray-900 sm:text-2xl" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        {{ edit_first_name }} {{ edit_second_name }} {{ edit_last_name }}
                      </h3>
                      <h3 class="text-xl font-bold text-gray-900 sm:text-2xl" *ngIf="edit_type_user_profile_id == 2 || edit_type_user_profile_id == 3">
                        {{ edit_business_name }}
                      </h3>
                      <span class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                        <span class="sr-only">Activo</span>
                      </span>
                      <h3 class="text-xl font-bold text-gray-900 sm:text-2xl ml-6" *ngIf="edit_type_user_profile_id == 4">
                        LEVEL {{ edit_level }}
                      </h3>
                      <div class="flex border-solid rounded-md pl-3 pr-3 pt-2 pb-2" >
                       <!--  <h4 class="text-lg font-bold text-gray-900 sm:text-xl" >
                          LEGACY
                        </h4>
                        <jqxSwitchButton
                        class="ml-3"
                        (onChecked)="onChecked(1)"
                        (onUnchecked)="onUnchecked(1)"
                        [width]='50'
                        [height]='27'
                        [checked]='false'
                      ></jqxSwitchButton> -->
                          <div role="status" *ngIf="!showLegacy && edit_type_user_profile_id == 4">
                            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <label class="inline-flex items-center cursor-pointer" *ngIf="showLegacy && edit_type_user_profile_id == 4">
                          <input type="checkbox" [(ngModel)]="toggleLegacy" class="sr-only peer" (change)="changeLegacy($event)">
                          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600"></div>
                          <span class="ml-2 text-md font-medium text-gray-900">Legacy</span>
                        </label>
                      </div>
                    </div>
                    <p class="text-sm text-gray-500">{{ edit_email }}</p>
                  </div>
                </div>
              </div>

              <div class="px-5 py-5">
                <div class="mt-6 mb-6" x-data="{acc_general: true, acc_address: false, acc_contacts: false, acc_map: false}">
                  <h2>
                    <button type="button" x-bind:class="acc_general ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = !acc_general, acc_address = false, acc_contacts = false, acc_map = false">
                      <span>General</span>
                      <svg x-bind:class="acc_general ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                    </button>
                  </h2>
                  <div x-show="acc_general">
                    <div class="grid grid-cols-12 gap-4 p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                      <div class="col-span-12 sm:col-span-3">
                        <label for="edit_type_user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
                          User Profile Type <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <jqxComboBox #comboboxTypeUserProfileEdit
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="type_user_profiles"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_type_user_profile_id"
                          (onSelect)="userProfileTypeEditOnSelect($event)"
                          [disabled]="true"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <div class="group flex relative cursor-pointer">
                          <label for="edit_rol_id" class="block text-sm font-medium text-gray-700 mb-1">
                            Role <span class="text-xss text-red-500">(Required)</span>
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">Enter the role</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <jqxComboBox #comboboxRolEdit
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="edit_roles"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_rol_id"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <div class="group flex relative cursor-pointer">
                          <label for="user_profile_id" class="block text-sm font-medium text-gray-700">
                            User Profile ID
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">User Profile ID</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <input type="text" name="user_profile_id" id="user_profile_id" autocomplete="user_profile_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_user_profile_id" disabled>
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <label for="status" class="block text-sm font-medium text-gray-700">
                          Status
                        </label>
                        <input type="text" autocomplete="status" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_status" disabled>
                      </div>

                      <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        <label for="edit_first_name" class="block text-sm font-medium text-gray-700">
                          First Name <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <input type="text" name="first_name" id="first_name" autocomplete="first_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_first_name">
                      </div>

                      <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        <label for="edit_second_name" class="block text-sm font-medium text-gray-700">Middle Name</label>
                        <input type="text" name="second_name" id="second_name" autocomplete="second_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_second_name">
                      </div>

                      <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        <label for="edit_last_name" class="block text-sm font-medium text-gray-700">
                          Last Name <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <input type="text" name="last_name" id="last_name" autocomplete="last_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_last_name">
                      </div>

                      <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        <label for="nickname" class="block text-sm font-medium text-gray-700">Nickname</label>
                        <input type="text" name="nickname" id="nickname" autocomplete="nickname" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_nickname">
                      </div>

                      <div class="col-span-12 sm:col-span-9" *ngIf="edit_type_user_profile_id == 2">
                        <label for="edit_business_name" class="block text-sm font-medium text-gray-700">
                          School Name <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <input type="text" name="business_name" id="business_name" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_business_name">
                      </div>

                      <div class="col-span-12 sm:col-span-12" *ngIf="edit_type_user_profile_id == 3">
                        <label for="edit_business_name" class="block text-sm font-medium text-gray-700">
                          Organization Name <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <input type="text" name="business_name" id="business_name" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_business_name">
                      </div>

                      <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <label for="edit_organization_id" class="block text-sm font-medium text-gray-700 mb-1">
                          Organization Parent <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <jqxComboBox #comboboxEditOrganization
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="organizations"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_organization_id"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <label for="edit_type_identification_id" class="block text-sm font-medium text-gray-700 mb-1">
                          Type Identification <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <jqxComboBox #comboboxEditTypeIdentification
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="type_identifications"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_type_identification_id"
                          (onSelect)="onChangeFormatEditIdentification($event)"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <div class="group flex relative cursor-pointer">
                          <label for="edit_identification" class="block text-sm font-medium text-gray-700">
                            Identification Number
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">Required, enter ID number</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <input type="text" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_identification" [brmasker]="edit_mask_identification">
                      </div>

                      <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5">
                        <label for="edit_birth_date" class="block text-sm font-medium text-gray-700 mb-1">
                          Birth Date <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <jqxDateTimeInput
                          [width]="'100%'"
                          [height]="38"
                          [formatString]="'MM-dd-yyyy'"
                          [(ngModel)]="edit_birth_date"
                          [theme]="'energyblue'"
                        ></jqxDateTimeInput>
                      </div>

                      <div class="col-span-12 sm:col-span-3  {{ edit_type_user_profile_id == 2 || edit_type_user_profile_id == 3 ? 'hidden' : 'block' }}">
                        <label for="edit_gender_id" class="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                        <jqxComboBox #comboboxEditGender
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="genders"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_gender_id"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12">
                        <hr>
                      </div>

                      <div class="col-span-12 sm:col-span-6">
                        <div class="group flex relative cursor-pointer">
                          <label for="edit_email" class="block text-sm font-medium text-gray-700">
                            Email <span class="text-xss text-red-500">(Required)</span>
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">Enter the email</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <input type="text" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_email">
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <div class="group flex relative cursor-pointer">
                          <label for="edit_cell_phone" class="block text-sm font-medium text-gray-700">
                            Mobile Phone <span class="text-xss text-red-500">(Required)</span>
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">Enter the mobile phone</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <input type="number" min="0" (input)="numberInput($event)" name="cell_phone" id="cell_phone" autocomplete="cell_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
                      </div>

                      <div class="col-span-12 sm:col-span-3">
                        <label for="edit_phone" class="block text-sm font-medium text-gray-700">Phone</label>
                        <input type="number" min="0" (input)="numberInput($event)" name="phone" id="phone" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
                      </div>

                      <div class="col-span-12 sm:col-span-12 bg-[#e8f0fe] px-1 py-5">
                        <div class="grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Current Address Information</h2>
                            <p class="mt-1 text-sm text-gray-500">Current Address Information will be use for your GPS location.</p>
                          </div>

                          <div class="col-span-12 sm:col-span-3 hidden">
                            <label for="edit_country_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Current Country <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditCountry
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'common_name'"
                              [valueMember]="'id'"
                              [source]="countries"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_country_id"
                              (onSelect)="countriesOnSelect($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_state_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Current State <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <input type="text" name="edit_state_id" id="edit_state_id" autocomplete="edit_state_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" value="GEORGIA" readonly>
                            <!-- <jqxComboBox #comboboxEditState
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [disabled]="true"
                              [source]="states"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_state_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox> -->
                          </div>

                          <div class="col-span-12 sm:col-span-3 hidden">
                            <label for="edit_county_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Current County <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditCounty
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="counties"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_county_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_address" class="block text-sm font-medium text-gray-700">
                              Current Address <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_address" (blur)="changeAddress()">
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_city_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Current City <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditCity
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="cities"
                              [theme]="'energyblue'"
                              (onSelect)="cityEditOnSelect($event)"
                              (focusout)="cityEditFocusout($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                              Current ZIP Code <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditZipCode
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'code'"
                              [valueMember]="'code'"
                              [source]="edit_zip_codes"
                              [theme]="'energyblue'"
                              (focusout)="zipCodeEditFocusout($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <div class="grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-8"></div>
                          <div class="col-span-12 sm:col-span-4">
                            <button type="submit" (click)="duplicateCurrentOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Duplicate Current Address</button>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12">
                        <hr>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <div class="grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Permanent Address</h2>
                            <p class="mt-1 text-sm text-gray-500">Permanent Address will be used as a secondary mailing address.</p>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_permanent_state_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Permanent State <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditPermanentState
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="permanent_states"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_permanent_state_id"
                              (onSelect)="editPermanentStateOnSelect($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_permanent_address" class="block text-sm font-medium text-gray-700">
                              Permanent Address <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_permanent_address">
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_permanent_city_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Permanent City <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditPermanentCity
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="edit_permanent_cities"
                              [theme]="'energyblue'"
                              (onSelect)="permanentCityEditOnSelect($event)"
                              (focusout)="permanentCityEditFocusout($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="edit_permanent_zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                              Permanent ZIP Code <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditPermanentZipCode
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'code'"
                              [valueMember]="'code'"
                              [source]="edit_permanent_zip_codes"
                              [theme]="'energyblue'"
                              (focusout)="permanentZipCodeEditFocusout($event)"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-{{ edit_type_user_profile_id == 1 || edit_type_user_profile_id == 4 || edit_type_user_profile_id == 5 ? '6' : '3' }} {{ edit_type_user_profile_id == 2 || edit_type_user_profile_id == 3 ? 'hidden' : 'block' }}">
                        <div class="group flex relative cursor-pointer">
                          <label for="edit_hire_date" class="block text-sm font-medium text-gray-700 mb-1">
                            Hire Date <span class="text-xss text-red-500">(Required)</span>
                          </label>

                          <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">
                            <p class="leading-2 pt-2 pb-2 w-full">Enter the hire date</p>
                          </div>
                          <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                          </span>
                        </div>

                        <jqxDateTimeInput
                          [width]="'100%'"
                          [height]="38"
                          [formatString]="'MM-dd-yyyy'"
                          [(ngModel)]="edit_hire_date"
                          [theme]="'energyblue'"
                        ></jqxDateTimeInput>
                      </div>

                      <div class="col-span-12 sm:col-span-6 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <label for="edit_pay_rate_id" class="block text-sm font-medium text-gray-700 mb-1">
                          Educator Pay Rate <!-- <span class="text-xss text-red-500">(Required)</span> -->
                        </label>
                        <jqxComboBox #comboboxEditPayRate
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="educator_pay_rates_comb"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_pay_rate_id"
                          [autoComplete]="true"
                          [disabled]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-6 {{ edit_type_user_profile_id == 5 ? 'block' : 'hidden' }}">
                        <label for="edit_parent_id" class="block text-sm font-medium text-gray-700 mb-1">
                          Parent <span class="text-xss text-red-500">(Required)</span>
                        </label>
                        <jqxComboBox #comboboxEditParent
                          [width]="'100%'"
                          [height]="38"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [source]="parent_user_profiles"
                          [theme]="'energyblue'"
                          [(ngModel)]="edit_parent_id"
                          [autoComplete]="true"
                          [searchMode]="'containsignorecase'"
                        ></jqxComboBox>
                      </div>

                      <div class="col-span-12 sm:col-span-{{ edit_type_user_profile_id == 3 || edit_type_user_profile_id == 5 ? '12' : '6' }}">
                        <label for="edit_observation" class="block text-sm font-medium text-gray-700">Comments</label>
                        <input type="text" autocomplete="observation" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_observation">
                      </div>

                      <div class="col-span-12 sm:col-span-6 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <label for="edit_direction_radius" class="block text-sm font-medium text-gray-700 mb-1">
                          Educator Coverage Radius (5 miles default)
                        </label>
                        <input type="text" autocomplete="direction_radius" (input)="numberInputRadius($event)" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_direction_radius">
                      </div>

                      <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <label class="block text-sm font-medium text-gray-700">Saturday Work</label>
                        <jqxCheckBox #checkSaturdayWork
                          [width]="'100%'"
                          [height]="38"
                          [theme]="'energyblue'"
                        ></jqxCheckBox>
                      </div>

                      <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <label class="block text-sm font-medium text-gray-700">Sunday Work</label>
                        <jqxCheckBox #checkSundayWork
                          [width]="'100%'"
                          [height]="38"
                          [theme]="'energyblue'"
                        ></jqxCheckBox>
                      </div>
                    </div>
                  </div>

                  <!-- <h2>
                    <button type="button" x-bind:class="acc_address ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = false, acc_address = !acc_address, acc_contacts = false, acc_map = false">
                      <span>Current Address</span>
                      <svg x-bind:class="acc_address ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                    </button>
                  </h2>
                  <div x-show="acc_address">
                    <div class="grid grid-cols-12 gap-4 p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                    </div>
                  </div> -->

                  <h2>
                    <button type="button" x-bind:class="acc_contacts ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = false, acc_address = false, acc_contacts = !acc_contacts, acc_map = false">
                      <span>Additional Details</span>
                      <svg x-bind:class="acc_contacts ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                    </button>
                  </h2>
                  <div x-show="acc_contacts">
                    <div class="grid grid-cols-12 gap-4 p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                      <div class="col-span-12 sm:col-span-12">
                        <div class="grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Personal Contacts</h2>
                            <p class="mt-1 text-sm text-gray-500">Add another Contact in case of Emergency or additional reference</p>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="contact_type_contact_id" class="block text-sm font-medium text-gray-700">Type Contact</label>
                            <jqxComboBox
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="type_contacts"
                              [theme]="'energyblue'"
                              [(ngModel)]="contact_type_contact_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="contact_name" class="block text-sm font-medium text-gray-700">Contact Name</label>
                            <input type="text" name="contact_name" id="contact_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_name">
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label for="contact_email" class="block text-sm font-medium text-gray-700">Email</label>
                            <input type="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_email">
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label for="contact_mobile_phone" class="block text-sm font-medium text-gray-700">Mobile Phone</label>
                            <input type="number" min="0" (input)="numberInput($event)" name="contact_mobile_phone" id="contact_mobile_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_mobile_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
                          </div>
                          <div class="col-span-12 sm:col-span-9"></div>
                          <div class="col-span-12 sm:col-span-3">
                            <!-- <label class="block text-sm font-medium text-gray-700">&nbsp;</label> -->
                            <button type="submit" (click)="addContactOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Add Contact</button>
                          </div>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridContacts
                              [width]="'100%'"
                              [cellhover]='cellHoverContacts'
                              [source]="dataAdapterContacts"
                              [columns]="columnsContacts"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="true"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickContacts($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">
                              Teacher About / Bio <span class="text-xss text-red-500">(Required)</span>
                            </h2>
                            <p class="mt-1 text-sm text-gray-500">Teacher profile description</p>
                            <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="edit_teacher_about">
                          </div>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-4">
                            <label for="document_type_id" class="block text-sm font-medium text-gray-700 mb-1">Document type</label>
                            <jqxComboBox
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="type_documents"
                              [theme]="'energyblue'"
                              [(ngModel)]="document_type_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label for="document" class="block text-sm font-medium text-gray-700 mb-1">Document (PDF only)</label>
                            <input type="file" id="documentInput" class="hidden" accept="application/pdf" (change)="uploadDocumentOnChange($event)" [(ngModel)]="document">
                            <button class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick="document.getElementById('documentInput').click();">Select file</button>
                            <small>{{ document_name }}</small>
                          </div>
                          <div class="col-span-12 sm:col-span-2">
                            <label for="document_validity_date" class="block text-sm font-medium text-gray-700 mb-1">Expiration Date</label>
                            <jqxDateTimeInput
                              [width]="'100%'"
                              [height]="38"
                              [formatString]="'MM-dd-yyyy'"
                              [(ngModel)]="document_validity_date"
                              [theme]="'energyblue'"
                            ></jqxDateTimeInput>
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                            <button type="submit" (click)="addDocumentOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Document</button>
                          </div>
                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridDocuments
                              [width]="'100%'"
                              [cellhover]='cellHoverDocuments'
                              [source]="dataAdapterDocuments"
                              [columns]="columnsDocuments"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="true"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickDocuments($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Education & Work Experience</h2>
                            <!-- <p class="mt-1 text-sm text-gray-500">Add not available dates list</p> -->
                          </div>

                          <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <label for="edit_level_temp_id" class="block text-sm font-medium text-gray-700 mb-1">Temporary Level</label>
                            <jqxComboBox #comboboxEditLevelTemp
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="levels"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_level_temp_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <label for="edit_experience_temp_id" class="block text-sm font-medium text-gray-700 mb-1">Temporary Experience</label>
                            <jqxComboBox #comboboxEditExperienceTemp
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="experiences"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_experience_temp_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <label for="edit_level_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Level <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditLevel
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="levels"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_level_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <label for="edit_experience_id" class="block text-sm font-medium text-gray-700 mb-1">
                              Experience <span class="text-xss text-red-500">(Required)</span>
                            </label>
                            <jqxComboBox #comboboxEditExperience
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="experiences"
                              [theme]="'energyblue'"
                              [(ngModel)]="edit_experience_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                            <div class="col-span-12 sm:col-span-12">
                              <jqxGrid #gridEducatorPayRates
                                [width]="'100%'"
                                [source]="dataAdapterEducatorPayRates"
                                [columns]="columnsEducatorPayRates"
                                [pageable]="false"
                                [pagesize]="20"
                                [autoheight]="true"
                                [showfilterrow]="true"
                                [columnsresize]="true"
                                [filterable]="true"
                                [sortable]="true"
                                [editable]="toggleLegacy"
                                [selectionmode]="'singlerow'"
                                [localization]="localization"
                                [theme]="'energyblue'"
                              ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 2 || edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">School Preferred List</h2>
                            <p class="mt-1 text-sm text-gray-500">Add Educators from the list below</p>
                          </div>

                          <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Allow List of Schools for Educators</h2>
                            <p class="mt-1 text-sm text-gray-500">Agency can only select a Educator associated to a School without radius restriction</p>
                          </div>

                          <div class="col-span-12 sm:col-span-9  {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                            <label for="allow_educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
                            <jqxComboBox
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'user_profile_id'"
                              [source]="educators"
                              [theme]="'energyblue'"
                              [(ngModel)]="allow_educator_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-9 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                            <label for="allow_school_id" class="block text-sm font-medium text-gray-700">School</label>
                            <jqxComboBox
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'business_name'"
                              [valueMember]="'id'"
                              [source]="schools"
                              [theme]="'energyblue'"
                              [(ngModel)]="allow_school_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                            <button type="submit" (click)="addAllowListOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add to Lists</button>
                          </div>

                          <div class="col-span-12 sm:col-span-12" *ngIf="edit_type_user_profile_id == 2 && ranking_educators && ranking_educators.length > 0">
                            <div class="grid grid-cols-10 gap-4 mt-2 mb-2">
                              <div class="col-span-10 sm:col-span-10">
                                <div class="bg-gray-100 py-2 px-4">
                                  <h2 class="text-xl font-semibold text-gray-800">Preferred Educator</h2>
                                </div>
                              </div>
                              <div class="col-span-10 sm:col-span-2 flex" *ngFor="let rank_educator of ranking_educators">
                                <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                  <div class="flex flex-col items-center mx-2 pt-3">
                                    <div class="relative inline-block text-center">
                                      <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="{{ rank_educator.photo }}" alt="Educator image" *ngIf="rank_educator.photo"/>
                                      <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Educator image" *ngIf="!rank_educator.photo"/>
                                      <div class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-full w-6 bg-orange-600 text-white rounded-full text-sm">
                                        {{ rank_educator.order }}
                                      </div>
                                    </div>
                                    <div class="mb-1 text-base text-center text-orange-600 dark:text-white" style="min-height: 50px;">
                                      {{ rank_educator.first_name }} {{ rank_educator.second_name }} {{ rank_educator.last_name }}
                                    </div>
                                    <p class="text-sm text-gray-600 text-base" *ngIf="user_type == 1">{{ rank_educator.score }}%</p>
                                    <button type="submit" (click)="addAllowListOnClick(rank_educator.user_profile_id)" class="my-2 w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Add to List</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridAllowList
                              [width]="'100%'"
                              [cellhover]='cellHoverAllowList'
                              [source]="dataAdapterAllowList"
                              [columns]="columnsAllowList"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="false"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickAllowList($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ user_type == 1 && edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Educators Without Radius Restriction List</h2>
                            <p class="mt-1 text-sm text-gray-500">Add educator to school bypassing radius restriction</p>
                          </div>

                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridAllowListEducators
                              [width]="'100%'"
                              [cellhover]='cellHoverAllowListEducators'
                              [source]="dataAdapterAllowListEducators"
                              [columns]="columnsAllowListEducators"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="false"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickAllowListEducators($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Blocked Dates</h2>
                            <p class="mt-1 text-sm text-gray-500">Add dates when the Educator is not available to work</p>
                          </div>
                          <div class="col-span-12 sm:col-span-4">
                            <label for="not_working_start_date" class="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                            <jqxDateTimeInput
                              [width]="'100%'"
                              [height]="38"
                              [formatString]="'MM-dd-yyyy'"
                              [(ngModel)]="not_working_start_date"
                              [theme]="'energyblue'"
                            ></jqxDateTimeInput>
                          </div>
                          <div class="col-span-12 sm:col-span-4">
                            <label for="not_working_end_date" class="block text-sm font-medium text-gray-700 mb-1">End Date</label>
                            <jqxDateTimeInput
                              [width]="'100%'"
                              [height]="38"
                              [formatString]="'MM-dd-yyyy'"
                              [(ngModel)]="not_working_end_date"
                              [theme]="'energyblue'"
                            ></jqxDateTimeInput>
                          </div>
                          <div class="col-span-12 sm:col-span-4">
                            <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                            <button type="submit" (click)="addNotWorkingDatesOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Blocked Dates</button>
                          </div>
                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridNotWorkingDates
                              [width]="'100%'"
                              [cellhover]='cellHoverNotWorkingDates'
                              [source]="dataAdapterNotWorkingDates"
                              [columns]="columnsNotWorkingDates"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="true"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickNotWorkingDates($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Educators Restricted List</h2>
                            <p class="mt-1 text-sm text-gray-500">Add educators to restricted list</p>
                          </div>
                          <div class="col-span-12 sm:col-span-6">
                            <label for="wb_educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
                            <jqxComboBox
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="all_educators"
                              [theme]="'energyblue'"
                              [(ngModel)]="wb_educator_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label for="wb_type_id" class="block text-sm font-medium text-gray-700">Type</label>
                            <jqxComboBox #comboboxWBType
                              [width]="'100%'"
                              [height]="38"
                              [displayMember]="'name'"
                              [valueMember]="'id'"
                              [source]="wb_types"
                              [theme]="'energyblue'"
                              [(ngModel)]="wb_type_id"
                              [autoComplete]="true"
                              [searchMode]="'containsignorecase'"
                            ></jqxComboBox>
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                            <button type="submit" (click)="addWBListOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Lists</button>
                          </div>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridWBLists
                              [width]="'100%'"
                              [cellhover]='cellHoverWBLists'
                              [source]="dataAdapterWBLists"
                              [columns]="columnsWBLists"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="true"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickWBLists($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 sm:col-span-12 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <h2 class="text-lg font-medium leading-6 text-gray-900">Specific Holidays</h2>
                            <p class="mt-1 text-sm text-gray-500">Add specific holidays</p>
                          </div>
                          <div class="col-span-12 sm:col-span-9">
                            <label for="holiday_date" class="block text-sm font-medium text-gray-700">Date</label>
                            <jqxDateTimeInput
                              [width]="'100%'"
                              [height]="38"
                              [formatString]="'MM-dd-yyyy'"
                              [(ngModel)]="holiday_date"
                              [theme]="'energyblue'"
                            ></jqxDateTimeInput>
                          </div>
                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                            <button type="submit" (click)="addHolidayOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Holiday</button>
                          </div>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-12">
                            <jqxGrid #gridSpecificHolidays
                              [width]="'100%'"
                              [cellhover]='cellHoverSpecificHolidays'
                              [source]="dataAdapterSpecificHolidays"
                              [columns]="columnsSpecificHolidays"
                              [pageable]="true"
                              [autoheight]="true"
                              [showfilterrow]="true"
                              [columnsresize]="true"
                              [filterable]="true"
                              [sortable]="true"
                              [editable]="true"
                              [editmode]="'dblclick'"
                              [selectionmode]="'singlerow'"
                              (onCellclick)="onCellClickSpecificHolidays($event)"
                              [localization]="localization"
                              [theme]="'energyblue'"
                            ></jqxGrid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h2>
                    <button type="button" x-bind:class="acc_map ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = false, acc_address = false, acc_contacts = false, acc_map = !acc_map">
                      <span>Map Location</span>
                      <svg x-bind:class="acc_map ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                    </button>
                  </h2>
                  <div x-show="acc_map">
                    <div class="grid grid-cols-12 gap-4 p-4 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                      <div class="col-span-12 sm:col-span-12">
                        <h2 class="text-lg font-medium leading-6 text-gray-900">Map Location</h2>
                        <p class="mt-1 mb-2 text-sm text-gray-500"><b>Important!</b> The user's Current Address Information for this profile is used for your GPS location. However, you can adjust the location by interacting with the map below using the map's Pin.</p>
                        <!-- <label for="markerPositions" class="block text-sm font-medium text-gray-700 mb-1">Map</label> -->
                        <google-map
                          width="100%"
                          height="350px"
                          [center]="center"
                          [zoom]="mapZoom"
                          [options]="mapOptions"
                          (mapClick)="addMarker($event)">
                          <map-marker #markerElem="mapMarker" *ngFor="let markerPosition of markerPositions"
                            [title]="markerPosition.title"
                            [position]="markerPosition.position"
                            [options]="markerPosition.options"
                            (mapClick)="openInfoWindow(markerElem, markerPosition.info)"
                          ></map-marker>
                          <map-info-window>{{ markerInfo.title }}</map-info-window>
                        </google-map>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 2">&nbsp;</div>
                  <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 3">&nbsp;</div>
                  <div class="col-span-12 sm:col-span-3" *ngIf="edit_type_user_profile_id == 4">&nbsp;</div>
                  <div class="col-span-12 sm:col-span-9" *ngIf="edit_type_user_profile_id != 2 && edit_type_user_profile_id != 3 && edit_type_user_profile_id != 4">&nbsp;</div>

                  <div class="col-span-12 sm:col-span-3">
                    <button type="submit" (click)="updateOnClick()" class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Update & Save</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="revisionsRequiredOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Revision is Required</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="updateAprovalEducatorOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Approve Application</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="updateAprovalSchoolOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Approve Application</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 3 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="updateAprovalOrganizationOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Approve Application</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 && edit_status_id != 3 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="cancellationSchoolOnClick()" class="w-full bg-red-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">School Cancellation</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 3 && edit_status_id != 3 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="cancellationOrganizationOnClick()" class="w-full bg-red-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Cancellation</button>
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ edit_type_user_profile_id == 2 && edit_status_id == 3 ? 'block' : 'hidden' }}">
                    <button type="submit" (click)="activationSchoolOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">School Activation</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmDeleteDocument" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-64 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Delete Document</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmDeleteDocument = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Are you sure you want to remove this document?</h2>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="deleteDocument(delete_document_id); modalConfirmDeleteDocument = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Delete</button>
          <button type="button" (click)="modalConfirmDeleteDocument = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmCancellationSchool" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-72 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 pr-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">School Cancellation</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmCancellationSchool = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Would you like to proceed with canceling the school?</h2>
            </div>
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700">Reason for cancellation</label>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation_cancel_activate">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="cancellationActivationSchool(edit_user_profile_id, false, true); modalConfirmCancellationSchool = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel School</button>
          <button type="button" (click)="modalConfirmCancellationSchool = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmCancellationOrganization" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-84 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 pr-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Organization Cancellation</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmCancellationOrganization = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Would you like to proceed with canceling the organization?</h2>
            </div>
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700">Reason for cancellation</label>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation_cancel_activate">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="cancellationActivationOrganization(edit_user_profile_id, false, true); modalConfirmCancellationOrganization = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel Organization</button>
          <button type="button" (click)="modalConfirmCancellationOrganization = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmActivationSchool" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-72 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 pr-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">School Activation</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmActivationSchool = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Would you like to proceed with activating the school?</h2>
            </div>
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700">Reason for activation</label>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation_cancel_activate">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="cancellationActivationSchool(edit_user_profile_id, true, true); modalConfirmActivationSchool = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm">Active School</button>
          <button type="button" (click)="modalConfirmActivationSchool = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmApproveSchool" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-72 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 pr-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">School Approval</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmApproveSchool = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Would you like to proceed with school approval?</h2>
            </div>
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700">Reason for approval</label>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation_cancel_activate">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="updateAprovalSchool(edit_user_profile_id, true); modalConfirmApproveSchool = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm">Approval School</button>
          <button type="button" (click)="modalConfirmApproveSchool = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmApproveOrganization" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-72 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 pr-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Organization Approval</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmApproveOrganization = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Would you like to proceed with organization approval?</h2>
            </div>
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700">Reason for approval</label>
              <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation_cancel_activate">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="updateAprovalOrganization(edit_user_profile_id, true); modalConfirmApproveOrganization = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm">Approval Organization</button>
          <button type="button" (click)="modalConfirmApproveOrganization = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalTooltip" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">
            <svg class="h-7 w-7 text-orange-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <!-- <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Payment successful</h3> -->
            <div class="mt-2">
              <p class="text-sm text-gray-500">{{ message_tooltip }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button type="button" class="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600" (click)="modalTooltip = !modalTooltip;">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
