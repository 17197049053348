<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'orange'" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'orange'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Assignment Postings</h2>
      <p class="mt-1 text-sm text-gray-500">Create and Post your New Assignment!</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <!-- <div class="col-span-12 sm:col-span-6 hidden">
        <label for="site_name" class="block text-sm font-medium text-gray-700">Site name</label>
        <input type="text" name="site_name" id="site_name" autocomplete="site_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="site_name">
      </div> -->
      <div class="col-span-12 sm:col-span-6">
        <label for="user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
          School <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxComboBox #comboboxUserProfile
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="user_profiles" 
          [theme]="'energyblue'"
          [(ngModel)]="user_profile_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="status_id" class="block text-sm font-medium text-gray-700 mb-1">
          Status <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxComboBox #comboboxAssignmentStatuses
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="assignment_posting_statuses" 
          [theme]="'energyblue'"
          [(ngModel)]="status_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="true"
        ></jqxComboBox>
      </div>
      <div class="col-span-12 sm:col-span-3">
        <label for="start_date" class="block text-sm font-medium text-gray-700 mb-1">
          Start Date <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxDateTimeInput #startDateDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="start_date" 
          [min]="min_start_date"
          [theme]="'energyblue'"
          (onChange)="startDateOnChange($event)"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-3">
        <label for="end_date" class="block text-sm font-medium text-gray-700 mb-1">
          End Date <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxDateTimeInput #endDateDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="end_date" 
          [min]="min_end_date"
          [theme]="'energyblue'"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="assignament_type_id" class="block text-sm font-medium text-gray-700 mb-1">
          Assignment Type <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxComboBox #comboboxAssignmentType
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="assignament_types" 
          [theme]="'energyblue'"
          [(ngModel)]="assignament_type_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="start_time" class="block text-sm font-medium text-gray-700 mb-1">
          Start Time <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxDateTimeInput 
          [width]="'100%'"  
          [height]="38" 
          [formatString]="'hh:mm tt'" 
          [showTimeButton]="true" 
          [showCalendarButton]="false"
          [(ngModel)]="start_time"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="end_time" class="block text-sm font-medium text-gray-700 mb-1">
          End Time <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxDateTimeInput 
          [width]="'100%'"  
          [height]="38" 
          [formatString]="'hh:mm tt'" 
          [showTimeButton]="true" 
          [showCalendarButton]="false"
          [(ngModel)]="end_time"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="lunch_hours" class="block text-sm font-medium text-gray-700">Lunch Hour(s)</label>
        <input type="text" min="0" (input)="numberInput($event)" name="lunch_hours" id="lunch_hours" autocomplete="lunch_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="lunch_hours">
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="break_hours" class="block text-sm font-medium text-gray-700">Break Hours</label>
        <input type="text" min="0" (input)="numberInput($event)" name="break_hours" id="break_hours" autocomplete="break_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="break_hours">
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="check_in_location" class="block text-sm font-medium text-gray-700">Check-in Location</label>
        <input type="text" name="check_in_location" id="check_in_location" autocomplete="check_in_location" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="check_in_location">
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="check_in_contact" class="block text-sm font-medium text-gray-700">Check-in Contact</label>
        <input type="text" name="check_in_contact" id="check_in_contact" autocomplete="check_in_contact" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="check_in_contact">
      </div>
    </div>

    <hr>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <h2 class="text-lg font-medium leading-6 text-gray-900">Assignment Skills</h2>
        <p class="mt-1 text-sm text-gray-500">Add Assignment Skills Requirements</p>
      </div>

      <div class="col-span-12 sm:col-span-6">
        <label for="skill_id" class="block text-sm font-medium text-gray-700 mb-1">
          Skills <span class="text-xss text-red-500">(Required)</span>
        </label>
        <jqxComboBox #comboboxSkill
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="skills" 
          [theme]="'energyblue'"
          [(ngModel)]="skill_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="skill_other" class="block text-sm font-medium text-gray-700">Skill Other</label>
        <input type="text" name="skill_other" id="skill_other" autocomplete="skill_other" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="skill_other">
      </div>
    </div>

    <hr>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <h2 class="text-lg font-medium leading-6 text-gray-900">Assignment Documentation</h2>
        <!-- <p class="mt-1 text-sm text-gray-500"></p> -->
      </div>

      <div class="col-span-12 sm:col-span-6">
        <label for="substitute_teacher_plan" class="block text-sm font-medium text-gray-700">Substitute Teacher Plan</label>
        <input type="file" id="documentInput" class="hidden" accept="application/pdf" (change)="uploadDocumentoOnChange($event)" [(ngModel)]="document_teacher">
        <button class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick="document.getElementById('documentInput').click();">Select file</button>
      </div>

      <div class="col-span-12 sm:col-span-6">
        <label class="block text-sm font-medium text-gray-700 mt-2">&nbsp;</label>
        <small>{{ document_teacher_name }}</small>
      </div>
    </div>

    <hr>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <h2 class="text-lg font-medium leading-6 text-gray-900">Additional Details</h2>
        <!-- <p class="mt-1 text-sm text-gray-500"></p> -->
      </div>

      <div class="col-span-12 sm:col-span-12">
        <label for="note_section" class="block text-sm font-medium text-gray-700">Comments</label>
        <textarea id="note_section" name="note_section" rows="4" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="note_section"></textarea>
      </div>
      <!-- <div class="col-span-12 sm:col-span-1">
        <p class="text-sm font-medium text-gray-700" aria-hidden="true">Image</p>

        <div class="relative hidden overflow-hidden rounded-full lg:block">
          <img class="relative h-30 w-30 rounded-full bg-gray-100" src="{{ image }}">
          <label for="desktop-user-photo" class="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100">
            <span>Change</span>
            <span class="sr-only">Image</span>
            <input type="file" accept=".jpg, .png, .jpeg" id="image" name="image" class="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" (change)="uploadImageOnChange($event)">
          </label>
        </div>
      </div> -->
    </div>

    <hr>

    <div class="mt-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-8"></div>
      
      <div class="col-span-12 sm:col-span-2">
        <button type="submit" (click)="cleanOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus-visible:ring-sky-500">Clean Up</button>
      </div>

      <div class="col-span-12 sm:col-span-2">
        <button type="submit" (click)="registerOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus-visible:ring-sky-500">Update & Save</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div *ngIf="modalConfirmAdd" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true" style="Overflow: hidden">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" (click)="modalConfirmAdd = false">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="mx-auto mb-4 text-orange-600 w-12 h-12 dark:text-orange-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
          </svg>
          <p class="mb-4 text-gray-600 dark:text-gray-300">Please Confirm To Add Assignment</p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button" class="py-2 px-8 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" (click)="modalConfirmAdd = false;">
              No, cancel
            </button>
            <button type="submit" class="py-2 px-8 text-sm font-medium text-center text-white bg-orange-600 rounded-lg hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-900" (click)="createAssignmentPostings(); modalConfirmAdd = false;">
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>