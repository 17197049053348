<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'orange'" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'orange'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex antialiased text-gray-800">
    <div class="flex flex-row h-full w-full overflow-x-hidden">
      <div class="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
        <div class="flex flex-row items-center h-12 w-full">
          <div class="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
            </svg>
          </div>
          <div class="ml-2 font-bold text-1xl">Assignment Chats</div>
        </div>

        <div class="mt-1 flex flex-row items-center w-full text-left">
          <p class="text-sm text-gray-500">Communicate with Educators regarding specific Assignments</p>
        </div>

        <div class="flex flex-col mt-8">
          <div class="flex flex-row items-center justify-between text-xs">
            <span class="font-bold">Active Conversations</span>
            <span class="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">{{ search_chats.length }}</span>
          </div>

          <hr class="mt-3 mb-3">

          <div class="text-xs">
            <b>Search by</b> <br>
            - Assignment ID<br>
            - School name <br>
            - Educator name <br>
            - Assignment start date (MM/DD/YYYY)
          </div>

          <div class="max-w-md mt-4">   
            <div class="relative">
              <button class="absolute inset-y-0 start-0 flex items-center ps-3 text-gray-500 hover:text-orange-500" (click)="clearSearchChatOnClick()">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"  stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
              </button>

              <input type="search" id="default-search" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus-visible:border-orange-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500" placeholder="Search Chats"  [(ngModel)]="search_chat"/>

              <button class="text-white absolute end-1 bottom-1 bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800" (click)="searchChatOnClick()">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
                </svg>
              </button>
            </div>
          </div>

          <div class="flex flex-col space-y-1 mt-2 -mx-2 overflow-y-auto h-80" *ngIf="search_chats && search_chats.length > 0">
            <button class="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 {{ chat_active && chat_active.id == chat.id ? 'bg-orange-600 text-white hover:bg-orange-600 cursor-auto' : 'bg-white text-black cursor-pointer'}}" *ngFor="let chat of search_chats" (click)="chatOnClick(chat.id, 1)">
              <div class="flex items-center justify-center h-8 w-8 bg-gray-200 rounded-full {{ chat_active && chat_active.id == chat.id ? 'text-orange-600' : 'text-black'}}">
                {{ chat.icon }}
              </div>
              <div class="ml-2 text-sm font-semibold text-left" *ngIf="chat.assignment_posting_id">
                {{ chat.assignment_posting_id }} <br> 
                {{ chat.school_business_name }} <br>
                {{ chat.educator }} <br>
                {{ chat.start_date  | date : 'MM/dd/yyy' }} 
              </div>
              <div class="ml-2 text-sm font-semibold text-left" *ngIf="!chat.assignment_posting_id">
                {{ chat.first_name }} {{ chat.last_name }}
              </div>
              <!-- <div class="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                2
              </div> -->
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col flex-auto p-6" style="height: 700px; margin-bottom: 10px;">
        <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-600 p-4" style="height: 650px">
          <div class="flex flex-col overflow-x-auto mb-4" style="height: 700px">
            <div class="flex flex-col h-full" *ngIf="conversations && conversations.length > 0">
              <div class="grid grid-cols-12 gap-y-2" *ngFor="let conversation of conversations; let index = index">
                <div class="col-span-12 sm:col-span-12 p-3" *ngIf="index == 0 || conversations[index-1].date != conversation.date">
                  <div class="w-28 text-sm text-center bg-white py-2 px-4 shadow rounded-xl m-auto">
                    {{ conversation.date }}
                  </div>
                </div>

                <div class="col-start-1 col-end-8 p-3 rounded-lg" *ngIf="conversation.user_id != user_id">
                  <div class="flex flex-row items-center">
                    <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      {{ conversation.icon }}
                    </div>
                    <div class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                      <div id="chatMessageId{{ conversation.id }}">{{ conversation.message }}</div>
                      <div class="text-xss bottom-0 text-right mt-3">{{ conversation.user_name }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-start-6 col-end-13 p-3 rounded-lg" *ngIf="conversation.user_id == user_id">
                  <div class="flex items-center justify-start flex-row-reverse">
                    <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      {{ conversation.icon }}
                    </div>
                    <div class="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                      <div id="chatMessageId{{ conversation.id }}">{{ conversation.message }}</div>
                      <div class="text-xss bottom-0 text-left mt-3">{{ conversation.user_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center h-10 rounded-xl bg-white w-full px-2 py-2 mb-1" *ngIf="chat_active && showSearchMessage">
            <div>
              <button class="flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-white" (click)="clearSearchOnClick()">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"></path>
                </svg>
              </button>
            </div>
            <div class="flex-grow ml-4">
              <input type="text" class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8" [(ngModel)]="search_message"/>
            </div>
            <div class="ml-4">
              <button class="flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-white" (click)="searchUpMessageOnClick()">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4.5 15.75 7.5-7.5 7.5 7.5"></path>
                </svg>
              </button>
            </div>
            <div class="ml-1">
              <div class="flex items-center justify-center text-gray-400">
                <small *ngIf="search_messages && search_messages.length > 0">{{ (search_index_message + 1) }}/{{ search_messages.length }}</small>
                <small *ngIf="!search_messages || search_messages.length == 0">0/0</small>
              </div>
            </div>
            <div class="ml-1">
              <button class="flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-white" (click)="searchDownMessageOnClick()">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19.5 8.25-7.5 7.5-7.5-7.5"></path>
                </svg>
              </button>
            </div>
            <div class="ml-4">
              <button class="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 h-8" (click)="searchMessageOnClick()">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
                </svg>
              </button>
            </div>
          </div>

          <div class="flex flex-row items-center h-10 rounded-xl bg-white w-full px-2 py-2" *ngIf="chat_active">
            <div>
              <button class="flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-white" (click)="showSearchMessage = !showSearchMessage;">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
                </svg>
              </button>
            </div>
            <div class="flex-grow ml-4">
              <input type="text" class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8" [(ngModel)]="message"/>
            </div>
            <div class="ml-4">
              <button class="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 h-8" (click)="sendMessage()">
                <svg class="w-5 h-5 transform rotate-90 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>