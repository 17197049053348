import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';      

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  //GRIDS
  @ViewChild('gridUsers', { static: false }) gridUsers: jqxGridComponent;
  @ViewChild('gridFatherPermissions', { static: false }) gridFatherPermissions: jqxGridComponent;
  @ViewChild('gridChildPermissions', { static: false }) gridChildPermissions: jqxGridComponent;  

  //TOOLTIP
  @ViewChild('tooltipReference') myTooltip: jqxTooltipComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  private user_type: any;
  private school_profile_id: any;

  public user: any;
  public modal_permissions: any;

  // CHANGE PASSWORD
  public new_password: string = "";
  public new_password_confirm: string = "";
  public user_id_new_password: string = "";

  // MODALS
  public modalPassword: boolean = false;

  public users: any;
  public sourceUsers: any;
  public dataAdapterUsers: any = [];
  public cellHoverUsers: any = [];
  public columnsUsers: any;

  public user_father_permissions: any;
  public sourceFatherPermissions: any;
  public dataAdapterFatherPermissions: any = [];
  public cellHoverFatherPermissions: any = [];
  public columnsFatherPermissions: any;

  public user_child_permissions: any;
  public sourceChildPermissions: any;
  public dataAdapterChildPermissions: any = [];
  public cellHoverChildPermissions: any = [];
  public columnsChildPermissions: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('users');
    this.user_id = this.tokenStorage.getUser();
    this.school_profile_id = this.tokenStorage.getUserProfile();
    this.user_type = this.tokenStorage.getUserType();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataGrid();
      this.createGridUsers();
      this.creategridFatherPermissions();
      this.creategridChildPermissions();

      this.modal_permissions = document.getElementById('modalPermissions') as HTMLImageElement;
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataGrid() {
    var route = "users";
    if(this.user_type == 2) {
      route = "users/filter-parent/" + this.school_profile_id;
    }

    this.http.get(this.URL_BASE + route).subscribe(res => {
      this.users = res;

      this.sourceUsers.localdata = this.users;
      this.gridUsers.updatebounddata('cells');
    });
  }

  loadDatagridFatherPermissions(user_id: any) {
    this.http.get(this.URL_BASE + 'father-permissions/user/' + user_id).subscribe(res => {
      this.user_child_permissions = res;
      this.sourceFatherPermissions.localdata = this.user_child_permissions;
      this.gridFatherPermissions.updatebounddata('cells');
    });
  }

  loadDatagridChildPermissions(father_id: any, user_id: any) {
    this.http.get(this.URL_BASE + 'child-permissions/user/' + father_id + '/' + user_id).subscribe(res => {
      this.user_father_permissions = res;
      this.sourceChildPermissions.localdata = this.user_father_permissions;
      this.gridChildPermissions.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  changePasswordUserOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      if(this.new_password == this.new_password_confirm) {
        this.changePasswordUser();
      } else {
        let error = {
          message: "Passwords do not match",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS INPUT                                   */
  /* ------------------------------------------------------------------------------------- */
  

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  editUser(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'user/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        email: RowEdit.email, 
        active: RowEdit.active, 
        rol_id: RowEdit.rol_id, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGrid();
        } else {
          this.gridUsers.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteUser(id: number) {
    this.http
    .put(
      this.URL_BASE + 'user/delete/' + id, 
      {
        user_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGrid();
        } else {
          this.gridUsers.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editPermission(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'permission/' + RowEdit.id, 
      { 
        visualize: RowEdit.visualize, 
        store: RowEdit.store, 
        update: RowEdit.update,
        delete: RowEdit.delete,
        print: RowEdit.print, 
        supervise: RowEdit.supervise, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDatagridFatherPermissions(res.user_id);
          if(res.father_id > 0) {
            this.loadDatagridChildPermissions(res.father_id, res.user_id);
          }
        } else {
          this.gridFatherPermissions.updatebounddata('cells');
          this.gridChildPermissions.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();

        this.gridFatherPermissions.updatebounddata('cells');
        this.gridChildPermissions.updatebounddata('cells');
      }
    );
  }

  changePasswordUser() {
    this.http
    .put(
      this.URL_BASE + 'user/change-password/' + this.user_id_new_password, 
      { 
        password: this.new_password,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGrid();
          this.modalPassword = false;
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridUsers() {
    this.sourceUsers =
    {
      localdata: this.users,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_profile_id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'active', type: 'boolean' },
        { name: 'rol_id', type: 'number' },
        { name: 'parent_id', type: 'number' },
        { name: 'organization_id', type: 'number' },
        { name: 'type_user_profile_id', type: 'number' },
        { name: 'rol', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editUser(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterUsers = new jqx.dataAdapter(this.sourceUsers, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverUsers = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridUsers.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridUsers.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del" || datafield == "Per" || datafield == "Con" || datafield == "Edit") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete User</b>';
          }
          
          if(datafield == "Per") {
            tooltipContent = '<b>Open Permissions User</b>';
          }
          
          if(datafield == "Con") {
            tooltipContent = '<b>Change Password User</b>';
          }
          
          if(datafield == "Edit") {
            tooltipContent = '<b>Edit User Profile</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsUsers =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        hidden: true,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: '',
        datafield: 'Per',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"/></svg>'
        },
      },
      {
        text: '',
        datafield: 'Con',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'Edit',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" /></svg>'
        },
      },
      { 
        text: 'User ID',  
        datafield: 'user_profile_id',
        displayfield: 'user_profile_id',
        editable: false,
        cellsalign: 'center',
        width: '7%' 
      },
      { 
        text: 'Active',  
        datafield: 'active',
        displayfield: 'active',
        columntype: 'checkbox',
        editable: true,
        width: '6%' 
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Email',  
        datafield: 'email',
        displayfield: 'email',
        editable: true,
        width: '28%' 
      },
      { 
        text: 'Rol',  
        datafield: 'rol_id',
        displayfield: 'rol',
        editable: true,
        width: '17%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          let roles: any = new Array();

          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: roles,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        },
        initeditor: (row: number, cellValue: any, editor: any, cellText: any, width: any, height: any): any => {
          var rowdata = this.gridUsers.getrowdata(row);
          var type_user_profile_id = 0;

          if(rowdata && rowdata.id == 1) {
            type_user_profile_id = 1;
          } else {
            type_user_profile_id = this.users.find((element: any) => element.id == rowdata.id).type_user_profile_id;
          }
          let roles: any = new Array();

          this.http.get(this.URL_BASE + 'roles/filter-type/' + type_user_profile_id).subscribe(res => {
            roles = res;
            editor.jqxDropDownList('source', roles);
          });
        },
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClick(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteUser(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    } else if (args.datafield == "Per") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.update == 1) {
          this.user = rowdata;

          if(this.gridFatherPermissions) {
            this.gridFatherPermissions.clearselection();
          }

          if(this.gridChildPermissions) {
            this.user_father_permissions = new Array();
            this.sourceChildPermissions.localdata = this.user_father_permissions;
            this.gridChildPermissions.updatebounddata('cells');
          }

          this.loadDatagridFatherPermissions(rowdata.id);

          this.modal_permissions.attributes[8].value = "display: block;";
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    } else if(args.datafield == "Con") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.update == 1) {
          this.user = rowdata;
          this.new_password = "";
          this.new_password_confirm = "";
          this.user_id_new_password = rowdata.id;

          this.modalPassword = true;
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    } else if(args.datafield == "Edit") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.update == 1) {
          this.router.navigateByUrl("/submenus/user/user-profiles/2/" + rowdata.user_profile_id);
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  creategridFatherPermissions() {
    this.sourceFatherPermissions =
    {
      localdata: this.user_father_permissions,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'functionality_id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'functionality', type: 'string' },
        { name: 'visualize', type: 'boolean' },
        { name: 'store', type: 'boolean' },
        { name: 'update', type: 'boolean' },
        { name: 'delete', type: 'boolean' },
        { name: 'print', type: 'boolean' },
        { name: 'supervise', type: 'boolean' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editPermission(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterFatherPermissions = new jqx.dataAdapter(this.sourceFatherPermissions, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.columnsFatherPermissions =
    [
      { 
        text: 'Permission',  
        datafield: 'functionality',
        displayfield: 'functionality',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Visualize',  
        datafield: 'visualize',
        displayfield: 'visualize',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Store',  
        datafield: 'store',
        displayfield: 'store',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Update',  
        datafield: 'update',
        displayfield: 'update',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Delete',  
        datafield: 'delete',
        displayfield: 'delete',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Print',  
        datafield: 'print',
        displayfield: 'print',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Supervise',  
        datafield: 'supervise',
        displayfield: 'supervise',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '14%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  fatherPermissionsGridOnRowSelect(event: any): void {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row;
    this.loadDatagridChildPermissions(rowdata.functionality_id, rowdata.user_id);
  };

  creategridChildPermissions() {
    this.sourceChildPermissions =
    {
      localdata: this.user_child_permissions,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'functionality_id', type: 'int' },
        { name: 'functionality', type: 'string' },
        { name: 'visualize', type: 'boolean' },
        { name: 'store', type: 'boolean' },
        { name: 'update', type: 'boolean' },
        { name: 'delete', type: 'boolean' },
        { name: 'print', type: 'boolean' },
        { name: 'supervise', type: 'boolean' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editPermission(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterChildPermissions = new jqx.dataAdapter(this.sourceChildPermissions, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.columnsChildPermissions =
    [
      { 
        text: 'Permission',  
        datafield: 'functionality',
        displayfield: 'functionality',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Visualize',  
        datafield: 'visualize',
        displayfield: 'visualize',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Store',  
        datafield: 'store',
        displayfield: 'store',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Update',  
        datafield: 'update',
        displayfield: 'update',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Delete',  
        datafield: 'delete',
        displayfield: 'delete',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Print',  
        datafield: 'print',
        displayfield: 'print',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Supervise',  
        datafield: 'supervise',
        displayfield: 'supervise',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '14%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
