import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent {
  //GRIDS
  @ViewChild('gridReports', { static: false }) gridReports: jqxGridComponent;
  @ViewChild('gridFatherPermissions', { static: false }) gridFatherPermissions: jqxGridComponent;
  @ViewChild('gridChildPermissions', { static: false }) gridChildPermissions: jqxGridComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  //FORM DATA
  public type_user_profiles: any;
  public roles: any;

  //FORM 
  public name: string = "";
  public type_user_profile_id: number = 0;

  public rol_id: number = 0;
  public functionalities: any = new Array();

  //BOTTONS
  public enableAdd = true;
  public enableUpdate = false;

  //GRIDS
  public father_permissions: any;
  public sourceFatherPermissions: any;
  public dataAdapterFatherPermissions: any = [];
  public columnsFatherPermissions: any;

  public child_functionalities: any;
  public sourceChildPermissions: any;
  public dataAdapterChildPermissions: any = [];
  public columnsChildPermissions: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('roles');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();
      this.loadRoles();
      this.creategridFatherPermissions();
      this.creategridChildPermissions();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    await this.http.get(this.URL_BASE + 'type_user_profiles').subscribe(res => {
      this.type_user_profiles = res; 
    });
  }

  async loadRoles() {
    await this.http.get(this.URL_BASE + 'roles/listing-corto').subscribe(res => {
      this.roles = res; 
    });
  }

  async loadDatagridFatherPermissions(rol_id: any = 0, father_id: any = 0) {
    await this.http.get(this.URL_BASE + 'role/' + rol_id).subscribe((rol: any) => {
      this.functionalities = new Array();
      let father_functionalities = new Array();

      if(rol && rol.functionalities) {
        this.name = rol.name;
        this.type_user_profile_id = rol.type_user_profile_id;
        this.functionalities = rol.functionalities;

        father_functionalities = this.functionalities.filter((element: any) => {
          return element.father_id == null; 
        });
      }
      // console.log(father_functionalities);

      this.father_permissions = father_functionalities;
      this.sourceFatherPermissions.localdata = this.father_permissions;
      this.gridFatherPermissions.updatebounddata('cells');

      setTimeout(() => {
        if(father_id > 0) {
          this.loadDatagridChildPermissions(father_id);
        }
      }, 300);
    });
  }

  loadDatagridChildPermissions(father_id: any = 0) {
    let child_functionalities = new Array();

    if(this.functionalities && this.functionalities) {
      child_functionalities = this.functionalities.filter((element: any) => {
        return element.father_id == father_id; 
      });
    }
    // console.log(functionalities);

    this.child_functionalities = child_functionalities;
    this.sourceChildPermissions.localdata = this.child_functionalities;
    this.gridChildPermissions.updatebounddata('cells');
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  registerOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createRol();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  updateOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.updateRol();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  rolOnChange(event: any): void {
    if (!event.args) {
      this.rol_id = 0;
      this.enableAdd = true;
      this.enableUpdate = false;

      this.loadDatagridFatherPermissions(0);
      this.loadDatagridChildPermissions(0);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  rolOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDatagridFatherPermissions(item);
        this.gridFatherPermissions.clearselection();
        this.enableAdd = false;
        this.enableUpdate = true;

        this.child_functionalities = new Array();
        this.sourceChildPermissions.localdata = this.child_functionalities;
        this.gridChildPermissions.updatebounddata('cells');
      }
    }
  };


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createRol() {
    this.http
    .post(
      this.URL_BASE + 'role', 
      { 
        name: this.name, 
        type_user_profile_id: this.type_user_profile_id, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  updateRol() {
    if(!this.rol_id || this.rol_id == 0) {
      let error = {
        message: "Select a role to continue",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'role/'+ this.rol_id, 
      { 
        name: this.name, 
        type_user_profile_id: this.type_user_profile_id, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editPermission(RowEdit: any) {
    if(!this.rol_id || this.rol_id == 0) {
      let error = {
        message: "Select a role to continue",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'role/update-functionalities/' + this.rol_id + '/' + RowEdit.id, 
      { 
        visualize: RowEdit.visualize,
        store: RowEdit.store,
        update: RowEdit.update,
        supervise: RowEdit.supervise,
        delete: RowEdit.delete,
        print: RowEdit.print,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        // this.alerts.push(res);

        if(res.type == "green") {
          this.loadDatagridFatherPermissions(res.rol_id, res.father_id);
        } else {
          this.gridFatherPermissions.updatebounddata('cells');
          this.gridChildPermissions.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.name = "";
    this.type_user_profile_id = 0;
    this.rol_id = 0;
    this.enableAdd = true;
    this.enableUpdate = false;
    this.functionalities = new Array();

    this.father_permissions = new Array();
    this.sourceFatherPermissions.localdata = this.father_permissions;
    this.gridFatherPermissions.updatebounddata('cells');

    this.child_functionalities = new Array();
    this.sourceChildPermissions.localdata = this.child_functionalities;
    this.gridChildPermissions.updatebounddata('cells');

    this.loadRoles();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  creategridFatherPermissions() {
    this.sourceFatherPermissions =
    {
      localdata: this.father_permissions,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'functionality_id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'visualize', type: 'boolean' },
        { name: 'store', type: 'boolean' },
        { name: 'update', type: 'boolean' },
        { name: 'delete', type: 'boolean' },
        { name: 'print', type: 'boolean' },
        { name: 'supervise', type: 'boolean' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editPermission(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterFatherPermissions = new jqx.dataAdapter(this.sourceFatherPermissions, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.columnsFatherPermissions =
    [
      { 
        text: 'Permission',  
        datafield: 'functionality_id',
        displayfield: 'name',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Visualize',  
        datafield: 'visualize',
        displayfield: 'visualize',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Store',  
        datafield: 'store',
        displayfield: 'store',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Update',  
        datafield: 'update',
        displayfield: 'update',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Delete',  
        datafield: 'delete',
        displayfield: 'delete',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Print',  
        datafield: 'print',
        displayfield: 'print',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Supervise',  
        datafield: 'supervise',
        displayfield: 'supervise',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '14%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  fatherPermissionsGridOnRowSelect(event: any): void {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row;
    if(this.rol_id > 0) {
      this.loadDatagridChildPermissions(rowdata.functionality_id);
    }
  };

  creategridChildPermissions() {
    this.sourceChildPermissions =
    {
      localdata: this.child_functionalities,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'functionality_id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'visualize', type: 'boolean' },
        { name: 'store', type: 'boolean' },
        { name: 'update', type: 'boolean' },
        { name: 'delete', type: 'boolean' },
        { name: 'print', type: 'boolean' },
        { name: 'supervise', type: 'boolean' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editPermission(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterChildPermissions = new jqx.dataAdapter(this.sourceChildPermissions, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.columnsChildPermissions =
    [
      { 
        text: 'Permission',  
        datafield: 'functionality_id',
        displayfield: 'name',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Visualize',  
        datafield: 'visualize',
        displayfield: 'visualize',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Store',  
        datafield: 'store',
        displayfield: 'store',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Update',  
        datafield: 'update',
        displayfield: 'update',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Delete',  
        datafield: 'delete',
        displayfield: 'delete',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Print',  
        datafield: 'print',
        displayfield: 'print',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '11%' 
      },
      { 
        text: 'Supervise',  
        datafield: 'supervise',
        displayfield: 'supervise',
        columntype: 'checkbox',
        filterable: false,
        editable: true,
        width: '14%' 
      }
    ];
    // this.localization = getLocalization('es');
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
