<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'orange'" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'orange'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Assignments Filters</h2>
      <p class="mt-1 text-sm text-gray-500">Filter by Assignments Status</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-3">
        <label for="start_date" class="block text-sm font-medium text-gray-700 mb-1">
          Start Date
        </label>
        <jqxDateTimeInput #startDateDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="start_date" 
          [theme]="'energyblue'"
          (onChange)="startDateOnChange($event);"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-3">
        <label for="end_date" class="block text-sm font-medium text-gray-700 mb-1">
          End Date
        </label>
        <jqxDateTimeInput #endDateDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="end_date" 
          [min]="min_end_date"
          [theme]="'energyblue'"
        ></jqxDateTimeInput>
      </div>
      <div class="col-span-12 sm:col-span-3">
        <label for="user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
          Status
        </label>
        <jqxComboBox #comboboxStatus
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="statuses" 
          [theme]="'energyblue'"
          [(ngModel)]="status_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>
      <div class="col-span-12 sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="filterOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus-visible:ring-sky-500">Filter</button>
      </div>
    </div>

    <hr>

    <div id="start_assignment_posting" class="mt-2 mb-2">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Assigments Map</h2>
      <p class="mt-1 text-sm text-gray-500">Select Additional Filters</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-8">
        <div class="flex items-center justify-between">
          <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
          <button type="button" class="{{ show_educators == true ? 'bg-indigo-600' : 'bg-gray-200' }} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 mr-3" role="switch" aria-checked="false" (click)="show_educators = !show_educators; educatorOnChange(show_educators)">
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span class="{{ show_educators == true ? 'translate-x-5' : 'translate-x-0' }} pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
              <!-- Enabled: "opacity-0 duration-100 ease-out", Not Enabled: "opacity-100 duration-200 ease-in" -->
              <span class="{{ show_educators == true ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in' }} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <!-- Enabled: "opacity-100 duration-200 ease-in", Not Enabled: "opacity-0 duration-100 ease-out" -->
              <span class="{{ show_educators == true ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out' }} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </button>
          <span class="flex flex-grow flex-col">
            <span class="text-sm font-medium leading-6 text-gray-900" id="availability-label">Show Educators</span>
            <span class="text-sm text-gray-500" id="availability-description">Select to Show Educators LIVE Location by Assignments Status (Filled or In progress)</span>
          </span>
        </div>
      </div>

      <div class="col-span-12 sm:col-span-4">
        <div class="flex items-center justify-between">
          <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
          <button type="button" class="{{ refresh_map == true ? 'bg-indigo-600' : 'bg-gray-200' }} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 mr-3" role="switch" aria-checked="false" (click)="refresh_map = !refresh_map; refreshMapOnChange(refresh_map)">
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span class="{{ refresh_map == true ? 'translate-x-5' : 'translate-x-0' }} pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
              <!-- Enabled: "opacity-0 duration-100 ease-out", Not Enabled: "opacity-100 duration-200 ease-in" -->
              <span class="{{ refresh_map == true ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in' }} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <!-- Enabled: "opacity-100 duration-200 ease-in", Not Enabled: "opacity-0 duration-100 ease-out" -->
              <span class="{{ refresh_map == true ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out' }} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </button>
          <span class="flex flex-grow flex-col">
            <span class="text-sm font-medium leading-6 text-gray-900" id="availability-label">Refresh Map</span>
            <span class="text-sm text-gray-500" id="availability-description">Continuous refresh</span>
          </span>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-12">
        <div class="map" id="map" #map></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>