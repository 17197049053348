import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { LoadingService } from '../shared/service/loading.service';
import { Router } from '@angular/router';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

@Component({
  selector: 'app-reconciliate',
  templateUrl: './reconciliate.component.html',
  styleUrls: ['./reconciliate.component.css']
})
export class ReconciliateComponent {
  //GRIDS
  @ViewChild('gridActualAssignmentPost', { static: false }) gridActualAssignmentPost: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxZoneManager', { static: false }) comboboxZoneManager: jqxComboBoxComponent;
  @ViewChild('comboboxZone', { static: false }) comboboxZone: jqxComboBoxComponent;
  @ViewChild('comboboxSchool', { static: false }) comboboxSchool: jqxComboBoxComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  private user_profile_id: any;
  private user_type: any;
  private user_rol_id: any;
  private school_parent_id: any;

  public organizations: any;
  public zone_managers: any;
  public zones: any;
  public schools: any;
  public comments: any = [
    {
      "id": 1,
      "description": "No Call No Show"
    },
    {
      "id": 2,
      "description": "Late Arrival"
    },
    {
      "id": 3,
      "description": "Left Early"
    },
    {
      "id": 4,
      "description": "Did Not Clock Out for Lunch"
    },
    {
      "id": 5,
      "description": "Late Clock-Cut"
    },
    {
      "id": 6,
      "description": "Extended Break Time"
    },
    {
      "id": 7,
      "description": "Other (add comments)"
    },
  ];
  public date_from: string = "";
  public date_to: string = "";
  public organization_id: number = 0;
  public zone_manager_id: number = 0;
  public zone_id: number = 0;
  public school_id: number = 0;

  //ACTUAL ASSIGNMENT HOURS 
  public actual_assignment_posting_id: number = 0;
  public actual_assignment_stat_id: number = 0;
  public school_hours: number = 0;
  public school_overtime: number = 0;
  public comment_id: number = 0;
  public school_comments: string = "";
  public approval_hours: number = 0;
  public approval_overtime: number = 0;

  // ACTUAL ASSIGNMENTS
  public actual_assignment_posts: any;
  public sourceActualAssignmentPost: any;
  public dataAdapterActualAssignmentPost: any = [];
  public columnsActualAssignmentPost: any;

  public localization: any;

  //MODAL
  public modalOpenAssignment: boolean = false;
  public modalSchoolHours: boolean = false;
  public modalApprovalHours: boolean = false;

  //DISABLED
  public disComboboxUserProfile: boolean = false;
  modalTip: boolean = false;
  amountTip: number = 0;
  
  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private router: Router
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('reconciliate');
    this.user_id = this.tokenStorage.getUser();
    this.user_profile_id = this.tokenStorage.getUserProfile();
    this.user_type = this.tokenStorage.getUserType();
    this.user_rol_id = this.tokenStorage.getUserRol();
    this.school_parent_id = this.tokenStorage.getUserParent();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataOrganizations();
      await this.loadDataZoneManagers();
      await this.loadOrganizationSchools(0);
      await this.loadZoneManagerZonesSchools(0);
      await this.createGridActualAssignmentPosts();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.date_from = moment(new Date()).format("MM-DD-yyyy");
    this.date_to = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadDataOrganizations() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter/organizations-schools').subscribe(res => {
      var sourceUserProfiles =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    });
  }

  async loadDataZoneManagers() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-zone-managers').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.zone_managers = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZoneManagers();
      }
    });
  }

  async loadOrganizationSchools(organization_id = 0) {
    if(organization_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 

        setTimeout(() => {
          if(this.user_type == 2) {
            this.comboboxSchool.val(this.user_profile_id);
          } else if(this.user_type == 5) {
            this.comboboxSchool.val(this.school_parent_id);
          }

          if(this.permissions && this.permissions.supervise == 1) {
            this.disComboboxUserProfile = false;
          } else {
            this.disComboboxUserProfile = true;
          }
        }, 500);
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-organization/' + organization_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    }
  }

  async loadZoneManagerZonesSchools(zone_manager_id = 0) {
    if(zone_manager_id == 0) {
      await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
        var sourceZones =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'description', type: 'string' }
          ]
        };

        this.zones = new jqx.dataAdapter(sourceZones, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              fila.name = fila.name + " - " + fila.description;

              data.push(fila);
            }
          },
        });
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerZonesSchools(zone_manager_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'zones/filter-zone-manager/' + zone_manager_id).subscribe(res => {
        var sourceZones =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'description', type: 'string' }
          ]
        };

        this.zones = new jqx.dataAdapter(sourceZones, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              fila.name = fila.name + " - " + fila.description;

              data.push(fila);
            }
          },
        });
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerZonesSchools(zone_manager_id);
        }
      });
    }
  }

  async loadZoneSchools(zone_id = 0) {
    if(zone_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-zone/' + zone_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    }
  }

  loadData(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_manager_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }
    
    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_manager_id || zone_manager_id == 0) {
      zone_manager_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }
    
    this.http.get<any[]>(this.URL_BASE + 'actual_assignment_post_stats/filter-user-date/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_manager_id + '/' + zone_id + '/' + school_id).subscribe(res => {
      this.actual_assignment_posts = res;

      this.sourceActualAssignmentPost.localdata = this.actual_assignment_posts;
      this.gridActualAssignmentPost.updatebounddata('cells');

      setTimeout(() => {
        this.createGridActualAssignmentPosts();
      }, 500);
    });
  }

  reportExcelData(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_manager_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }
    
    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_manager_id || zone_manager_id == 0) {
      zone_manager_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }
    
    window.open(this.URL_BASE + 'hours-reconciliation/report-excel/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_manager_id + '/' + zone_id + '/' + school_id + '/' + this.user_id, "_blank");
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  downloadOnClick() {
    this.reportExcelData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  organizationOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.zone_manager_id = 0;
        this.comboboxZoneManager.clearSelection();
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, item, this.zone_manager_id, this.zone_id, this.school_id);
        this.loadZoneManagerZonesSchools(0);
        this.loadOrganizationSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_manager_id = 0;
      this.comboboxZoneManager.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadOrganizationSchools(0);
    }
  }

  zoneManagerOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, this.organization_id, item, this.zone_id, this.school_id);
        this.loadZoneManagerZonesSchools(item);
        this.loadZoneSchools(this.school_id);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_manager_id = 0;
      this.comboboxZoneManager.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadZoneManagerZonesSchools(0);
      this.loadZoneSchools(0);
    }
  }

  zoneOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, item, this.school_id);
        this.loadZoneSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadZoneSchools(0);
    }
  }

  userOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, item);
      }
    } else {
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(date, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(this.date_from, date, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  editActualAssignmentPosts(rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + rowdata.id, 
      {
        update_type: "approval", 
        approval: rowdata.approval, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editSchoolHours() {
    if(this.school_hours == 0 || this.school_hours == null) {
      let error = {
        message: "To save you must enter the hours",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.comment_id == 0 || this.comment_id == null) {
      let error = {
        message: "To save you must enter the hours",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    } else if(this.comment_id == 7) {
      if(this.school_comments == "" || this.school_comments == null) {
        let error = {
          message: "To save you must enter the comments",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      this.school_comments = this.comments.find((element: any) => element.id == this.comment_id).description;
    }  

    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + this.actual_assignment_stat_id, 
      {
        update_type: "school_hours",
        comments: this.school_comments, 
        school_hours: this.school_hours,
        school_overtime: this.school_overtime,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);

          this.clearFormSchoolHours();
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editApprovalHours() {
    if(this.approval_hours == 0 || this.approval_hours == null) {
      let error = {
        message: "To save you must enter the hours",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + this.actual_assignment_stat_id, 
      {
        update_type: "approval_hours",
        approval_hours: this.approval_hours,
        approval_overtime: this.approval_overtime,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
          
          this.clearFormApprovalHours();
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearFormSchoolHours() {
    this.school_hours = 0;
    this.school_overtime = 0;
    this.comment_id = 0;
    this.school_comments = "";

    this.modalSchoolHours = false;
  }

  clearFormApprovalHours() {
    this.approval_hours = 0;
    this.approval_overtime = 0;

    this.modalApprovalHours = false;
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridActualAssignmentPosts() {
    this.sourceActualAssignmentPost = {
      localdata: this.actual_assignment_posts,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'tips', type: 'float' },
        { name: 'deductions', type: 'float' },
        { name: 'assignament_user_profile_id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'date', type: 'string' },
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'end_time_real', type: 'string' },
        { name: 'long_term', type: 'boolean' },
        { name: 'overtime_hours', type: 'number' },
        { name: 'educator_hours', type: 'number' },
        { name: 'lunch_hours', type: 'number' },
        { name: 'break_hours', type: 'number' },
        { name: 'school_hours', type: 'number' },
        { name: 'school_overtime', type: 'number' },
        { name: 'approval_hours', type: 'number' },
        { name: 'approval_overtime', type: 'number' },
        { name: 'comments', type: 'string' },
        { name: 'status_id', type: 'int' },
        { name: 'assignment_post', type: 'string' },
        { name: 'school_business_name', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'approval', type: 'boolean' },
        { name: 'status', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zone_manager', type: 'string' },
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editActualAssignmentPosts(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterActualAssignmentPost = new jqx.dataAdapter(this.sourceActualAssignmentPost, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }          

          if(fila.start_time) {
            fila.start_time = moment(fila.start_time, "HH:mm:ss").format("LT");
          }

          if(fila.end_time) {
            fila.end_time = moment(fila.end_time, "HH:mm:ss").format("LT");
          }

          data.push(fila);
        }
      },
    });

    this.columnsActualAssignmentPost =
    [
      {
        text: 'Assignment ID',
        datafield: 'assignment_posting_id',
        displayfield: 'assignment_posting_id',
        filtertype: 'int',
        editable: false,
        columntype: 'button',
        width: '8%',
        buttonclick: (row: any) => this.openRightReconciliate(row),
      },
      /* {
        text: 'School ID',
        datafield: 'assignament_user_profile_id',
        displayfield: 'assignament_user_profile_id',
        filtertype: 'int',
        editable: false,
        columntype: 'button',
        width: '8%'
      }, */
      { 
        text: 'Approved',  
        datafield: 'approval',
        displayfield: 'approval',
        editable: true,
        filtertype: 'boolean',
        columntype: 'checkbox',
        width: '8%' 
      },
      { 
        text: 'Longterm',  
        datafield: 'long_term',
        displayfield: 'long_term',
        editable: false,
        filtertype: 'boolean',
        columntype: 'checkbox',
        width: '8%' 
      },
      { 
        text: 'Status',  
        datafield: 'status_id',
        displayfield: 'status',
        filtertype: 'checkedlist',
        editable: false,
        width: '8%' 
      },
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Start Time',  
        datafield: 'start_time',
        displayfield: 'start_time',
        editable: false,
        width: '9%' 
      },
      { 
        text: 'End Time',  
        datafield: 'end_time',
        displayfield: 'end_time',
        editable: false,
        width: '9%' 
      },
      { 
        text: 'Real End',  
        datafield: 'end_time_real',
        displayfield: 'end_time_real',
        hidden: true,
        editable: false,
        width: '9%' 
      },
      { 
        text: 'APP Worked Hrs.',
        datafield: 'educator_hours',
        displayfield: 'educator_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Lunch Hrs.',  
        datafield: 'lunch_hours',
        displayfield: 'lunch_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Break Hrs.',  
        datafield: 'break_hours',
        displayfield: 'break_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Overtime Hrs.',
        datafield: 'overtime_hours',
        displayfield: 'overtime_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      {
        text: `TIP's`,
        datafield: 'tips',
        displayfield: 'tips',
        editable: false,
        filterable: false,
        columntype: 'button',
        width: '8%',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          if(value == 0) {
            return '0.00'
          }
          return value
        },
        buttonclick: (row: any) => this.openModalTip(row),
      },
      {
        text: 'Deductions',
        datafield: 'deductions',
        displayfield: 'deductions',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'School',
        datafield: 'school_hours',
        displayfield: 'school_hours',
        editable: false,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          if(value == 0 || value == null) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            return value;
          }
        }
      },
      { 
        text: 'Approved',
        datafield: 'approval_hours',
        displayfield: 'approval_hours',
        editable: true,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          if(value == 0 || value == null) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            return value;
          }
        }
      },
      { 
        text: 'Ap. Overtime',  
        datafield: 'approval_overtime',
        displayfield: 'approval_overtime',
        editable: true,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any, value2: any, value3: any, data: any): any => {
          if((value == null || value == 0) && (data.status_id == 1 || data.status_id == 3)) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            if(value == 0) {
              return 0;
            } else {
              return value;
            }
          }
        }
      },
      { 
        text: 'School',  
        datafield: 'school_business_name',
        displayfield: 'school_business_name',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Comments',  
        datafield: 'comments',
        displayfield: 'comments',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Zone',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '20%' 
      }
    ];
  }

  openRightReconciliate(id: number) {
    if (
      this.sourceActualAssignmentPost.localdata &&
      this.sourceActualAssignmentPost.localdata.length > 0
    ) {
      const reconciliate = this.sourceActualAssignmentPost.localdata[id];
      this.loadingService.show();
      console.log(reconciliate);
      this.router.navigate([`submenus/assignments/assignment-lists`], {
        state: {
          data: {
            args: {
              rowindex: 1,
              datafield: 'Edit',
              row: {
                bounddata: {
                  status_id: 1,
                  user_profile_id: reconciliate.assignament_user_profile_id,
                  id: reconciliate.assignment_posting_id,
                },
              },
            },
          },
        },
      });
    }
  }

  openModalTip(id: number) {
    if (
      this.sourceActualAssignmentPost.localdata &&
      this.sourceActualAssignmentPost.localdata.length > 0
    ) {
      const tipData = this.sourceActualAssignmentPost.localdata[id];
      this.actual_assignment_posting_id = tipData.assignment_posting_id;
      this.actual_assignment_stat_id = tipData.user_profile_id;
      this.amountTip = Number(tipData.tips);
      this.modalTip = true;
    }
  }

  onCellClickActualAssignmentPost(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if(this.permissions && this.permissions.update == 1) {
        if (args.datafield == "school_hours") {
          if(rowdata.educator_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.school_hours = rowdata.educator_hours;
            this.school_overtime = rowdata.overtime_hours;

            this.modalSchoolHours = true;
          }
        } else if (args.datafield == "approval_hours") {
          if(rowdata.school_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.approval_hours = rowdata.school_hours;
            this.approval_overtime = rowdata.school_overtime;

            this.modalApprovalHours = true;
          }
        } else if (args.datafield == "approval_overtime") {
          if(rowdata.school_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.approval_hours = rowdata.school_hours;
            this.approval_overtime = rowdata.school_overtime;

            this.modalApprovalHours = true;
          }
        }
      } else {
        let error = {
          message: "You do not have permissions to update in this module",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

  clearFormAmount(){
    this.amountTip = 0;
    this.modalTip = false;
  }
  
  editAmount(){
    if(this.amountTip == 0 || this.amountTip == null) {
      let error = {
        message: "To save you must enter the amount",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
    this.http
    .post( //TODO CHANGE URL TIP EDITION
      this.URL_BASE + 'actual_assignment_post_stats/update_tips', 
      {
        value: Number(this.amountTip),
        assignment_id: this.actual_assignment_posting_id,
        user_profile_id:  this.actual_assignment_stat_id,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);

          this.clearFormAmount();
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

}
