import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private URL_BASE: string;
  public alerts: any;
  public user_id: any;
  public user_profile_id: any;

  content?: string;
  isLoggedIn = false;
  user: any;

  public tasks: any = new Array();
  public homeDashBoard: any = new Array();

  public photo: string = "";

  constructor(
    private router: Router,
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) {
    this.URL_BASE = this.configuration.getUrlBase();
    this.user_id = this.tokenStorage.getUser();
    this.user_profile_id = this.tokenStorage.getUserProfile();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      this.user = this.tokenStorage.getUserData();
      if(this.user && this.user.user_profile) {
        this.photo = this.user.user_profile.photo;
      }
    }

    this.loadTasks();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */
  loadTasks() {
    this.http.get(this.URL_BASE + 'tasks/filter-assigned-status/' + this.user_id + '/0').subscribe(res => {
      this.tasks = res;
    });

    this.http.get(this.URL_BASE + 'home/dashboard/' + this.user_profile_id).subscribe(res => {
      this.homeDashBoard = res;
      // console.log(this.homeDashBoard);
    });
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  goAssignmets() {
    this.router.navigateByUrl("/submenus/assignments/assignment-lists");
  }

  goUserProfiles() {
    this.router.navigateByUrl("/submenus/user/user-profiles/2");
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }
}
