import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-agency-profile',
  templateUrl: './agency-profile.component.html',
  styleUrls: ['./agency-profile.component.css']
})
export class AgencyProfileComponent implements OnInit {

  //COMBOBOX
  @ViewChild('comboboxCountry', { static: false }) comboboxCountry: jqxComboBoxComponent;
  @ViewChild('comboboxState', { static: false }) comboboxState: jqxComboBoxComponent;
  @ViewChild('comboboxCounty', { static: false }) comboboxCounty: jqxComboBoxComponent;
  @ViewChild('comboboxCity', { static: false }) comboboxCity: jqxComboBoxComponent;
  @ViewChild('comboboxZipCode', { static: false }) comboboxZipCode: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public company_profile: any;

  public assignment_codes: any;
  public countries: any;
  public states: any;
  public counties: any;
  public cities: any;
  public zip_codes: any;

  public company_profile_id: number = 0;
  public name: string = "";
  public agency_id: string = "A1001";
  public description: string = "";
  public initials: string = "";
  public identification: number = 0;
  public assignment_code_id: number = 0;
  public country_id: number = 0;
  public state_id: number = 0;
  public county_id: number = 0;
  public city_id: number = 0;
  public zip_code: string = "";
  public address: string = "";
  public phone: string = "";
  public phone_alt: string = "";
  public cell_phone: string = "";
  public cell_phone_alt: string = "";
  public email: string = "";
  public email_alt: string = "";
  public logo: any;
  public dark_logo: any;

  //MODAL
  public modalConfirmAdd: boolean = false;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('agency-profile');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();
      this.uploadDataCompanyProfile();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataForm() {
    this.http.get(this.URL_BASE + 'assignment_codes').subscribe(res => {
      var sourceAssignmentCodes =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'code', type: 'string' }
        ]
      };

      this.assignment_codes = new jqx.dataAdapter(sourceAssignmentCodes, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " (" + fila.code + ")";

            data.push(fila);
          }
        },
      }); 
    });

    this.http.get(this.URL_BASE + 'countries').subscribe(res => {
      this.countries = res;
    });

    this.http.get(this.URL_BASE + 'cities').subscribe(res => {
      this.cities = res;
    });
  }

  loadZipCodes(city_id: number = 0) {
    this.http.get(this.URL_BASE + 'zip_codes/filter-city/' + city_id).subscribe(res => {
      this.zip_codes = res;
    });
  }

  uploadDataCompanyProfile() {
    this.http.get(this.URL_BASE + 'company-profile/' + 1001).subscribe(res => {
      if(res) {
        this.company_profile = res;
        // console.log(this.company_profile);
        this.company_profile_id = this.company_profile.id;
        this.name = this.company_profile.name;
        this.description = this.company_profile.description;
        this.initials = this.company_profile.initials;
        this.identification = this.company_profile.identification;
        this.comboboxCountry.val(this.company_profile.country_id);
        setTimeout(() =>{this.comboboxState.val(this.company_profile.state_id)}, 1400);
        setTimeout(() =>{this.comboboxCounty.val(this.company_profile.county_id)}, 2000);
        setTimeout(() =>{this.comboboxCity.val(this.company_profile.city_id)}, 2500);
        setTimeout(() =>{this.comboboxZipCode.val(this.company_profile.zip_code)}, 3500);
        this.zip_code = this.company_profile.zip_code;
        this.address = this.company_profile.address;
        this.phone = this.company_profile.phone;
        this.phone_alt = this.company_profile.phone_alt;
        this.cell_phone = this.company_profile.cell_phone;
        this.cell_phone_alt = this.company_profile.cell_phone_alt;
        this.email = this.company_profile.email;
        this.email_alt = this.company_profile.email_alt;
        this.logo = this.company_profile.logo;
        this.dark_logo = this.company_profile.dark_logo;
      }
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  editProfileOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.modalConfirmAdd = true;
      // this.editCompanyProfile();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        let arr_val = value.split("-");
        var value_return = "";

        if(arr_val.length > 0) {
          arr_val.forEach((element: any) => {
            if(element != "-") {
              value_return = value_return + "" + element;
            }
          })
        }

        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  uploadLogoOnChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.logo = reader.result;
      this.editCompanyProfileLogo(this.logo, this.dark_logo, "light");
    };
  }

  uploadDarkLogoOnChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.dark_logo = reader.result;
      this.editCompanyProfileLogo(this.logo, this.dark_logo, "dark");
    };
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  countriesOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.http.get(this.URL_BASE + 'states/filter-country/' + item).subscribe(res => {
          this.states = res;
        });
      }
    }
  };

  statesOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.http.get(this.URL_BASE + 'counties/filter-state/' + item).subscribe(res => {
          this.counties = res;
        });
      }
    }
  };

  cityOnSelect(event: any): void {
    // console.log(event);
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadZipCodes(item);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                     EVENTOS FOCUSOUT                                  */
  /* ------------------------------------------------------------------------------------- */

  cityFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let city = this.cities.find((element: any) => element.name == item);

          if(city) {
            this.city_id = city.id;
          } else {
            this.city_id = 0;
            this.comboboxCity.val("");
            this.comboboxCity.close();
            this.zip_codes = new Array();
            this.zip_code = "";

            let error = {
              message: "You must select a valid city",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }

  zipCodeFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let zip_code = this.zip_codes.find((element: any) => element.code == item);

          if(zip_code) {
            this.zip_code = zip_code.code;
          } else {
            this.zip_code = "";
            this.comboboxZipCode.val("");
            this.comboboxZipCode.close();

            let error = {
              message: "You must select a valid zip code",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */


  // Agency profile
  editCompanyProfile() {
    this.http
    .put(
      this.URL_BASE + 'company-profile/' + this.company_profile_id, 
      {
        name: this.name,
        description: this.description,
        initials: this.initials,
        identification: this.identification,
        city_id: this.city_id,
        zip_code: this.zip_code,
        address: this.address,
        phone: this.phone,
        phone_alt: this.phone_alt,
        cell_phone: this.cell_phone,
        cell_phone_alt: this.cell_phone_alt,
        email: this.email,
        email_alt: this.email_alt,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editCompanyProfileLogo(logo: any, dark_logo: any, type: string) {
    this.http
    .put(
      this.URL_BASE + 'company-profile/update-logo/' + this.company_profile_id, 
      {
        logo: logo,
        dark_logo: dark_logo,
        type: type,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          setTimeout(() => {
            // window.location.reload();
          }, 900);
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}