import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-school-invoice',
  templateUrl: './school-invoice.component.html',
  styleUrls: ['./school-invoice.component.css']
})
export class SchoolInvoiceComponent {
  //GRIDS
  @ViewChild('gridInvoices', { static: false }) gridInvoices: jqxGridComponent;

  //DATETIME
  @ViewChild('dateTimeInput', { static: false }) dateTimeInput: jqxDateTimeInputComponent;

  //COMBOBOX
  @ViewChild('comboboxZipCode', { static: false }) comboboxZipCode: jqxComboBoxComponent;
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxZone', { static: false }) comboboxZone: jqxComboBoxComponent;
  @ViewChild('comboboxSchool', { static: false }) comboboxSchool: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public zip_codes: any;
  public organizations: any;
  public zones: any;
  public schools: any;
  public weeks: any;
  public dataAdapterWeeks: any;

  // FORM
  public date_from: string = "";
  public date_to: string = "";
  public zip_code_id: number = 0;
  public organization_id: number = 0;
  public zone_id: number = 0;
  public school_id: number = 0;
  public school: any;

  public week_id: number = 0;

  // GRIDS
  public invoices: any;
  public sourceInvoices: any;
  public dataAdapterInvoices: any = [];
  public columnsInvoices: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('invoice');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataWeeks();
      await this.loadDataZipCodes();
      await this.loadDataOrganizations();
      await this.loadDataZones();
      await this.loadOrganizationSchools(0);

      this.createGridInvoices();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataWeeks() {
    await this.http.get(this.URL_BASE + 'weeks').subscribe(res => {
      this.weeks = res;

      var sourceWeeks =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'initial_date', type: 'string' }
        ]
      };

      this.dataAdapterWeeks = new jqx.dataAdapter(sourceWeeks, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            // Week of Sunday-mm-dd-yy
            fila.name = fila.name + " of " + moment(fila.initial_date).format("ddd-MM-DD-yyyy");

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataWeeks();
      }
    });
  }

  async loadDataZipCodes() {
    await this.http.get(this.URL_BASE + 'zip_codes').subscribe(res => {
      this.zip_codes = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZipCodes();
      }
    });
  }

  async loadDataOrganizations() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter/organizations-schools').subscribe(res => {
      var sourceUserProfiles =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    });
  }

  async loadDataZones() {
    await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
      var sourceZones =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'description', type: 'string' }
        ]
      };

      this.zones = new jqx.dataAdapter(sourceZones, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " - " + fila.description;

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZones();
      }
    });
  }

  async loadOrganizationSchools(organization_id = 0) {
    if(organization_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-organization/' + organization_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    }
  }

  async loadZoneSchools(zone_id = 0) {
    if(zone_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      }); 
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-zone/' + zone_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    }
  }

  loadData(date_from: string = "", date_to: string = "", zip_code_id: number = 0, organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zip_code_id || zip_code_id == 0) {
      zip_code_id = 0;
    }    

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    this.http.get(this.URL_BASE + 'assignment_postings/filter-user-profile-liquidations/' + date_from + '/' + date_to + '/' + zip_code_id + '/' + organization_id + '/' + zone_id + '/' + school_id).subscribe(res => {
      this.invoices = res;

      this.sourceInvoices.localdata = this.invoices;
      this.gridInvoices.updatebounddata('cells');
    });
  }

  reportActualAssignments(date_from: string = "", date_to: string = "", zip_code_id: number = 0, organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(!this.week_id || this.week_id == 0) {
      let error = {
        message: "Select a week to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zip_code_id || zip_code_id == 0) {
      zip_code_id = 0;
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    window.open(this.URL_BASE + 'assignment_postings/report-user-profile-liquidations/' + date_from + '/' + date_to + '/' + zip_code_id + '/' + organization_id + '/' + zone_id + '/' + school_id + '/' + this.user_id + '/3' , "_blank");
  }

  reportExcelData(date_from: string = "", date_to: string = "", zip_code_id: number = 0, organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(!this.week_id || this.week_id == 0) {
      let error = {
        message: "Select a week to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zip_code_id || zip_code_id == 0) {
      zip_code_id = 0;
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    window.open(this.URL_BASE + 'assignment_postings/report-excel-user-profile-liquidations/' + date_from + '/' + date_to + '/' + zip_code_id + '/' + organization_id + '/' + zone_id + '/' + school_id + '/' + this.user_id + '/3' , "_blank");
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  reportOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportActualAssignments(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  liquidateOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      if(!this.week_id || this.week_id == 0) {
        let error = {
          message: "Select a week to continue",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }

      if(!this.school_id || this.school_id == 0) {
        let error = {
          message: "Select a school to continue",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }

      this.liquidateActualAssignments(this.school_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  printOnClick() {
    const html = document.getElementById('pdfPrint')!;

    html2canvas(html, {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function(canvas: any) {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF();
      doc.addImage(img,'PNG',7, 20, 195, 105);
      doc.save('report_actual_assignments.pdf');
    });
  }

  downloadOnClick() {
    this.reportExcelData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  zipCodeOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadData(this.date_from, this.date_to, item, this.organization_id, this.zone_id, this.school_id);
      }
    } else {
      this.zip_code_id = 0;
      this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
    }
  }

  organizationOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, this.zip_code_id, item, this.zone_id, this.school_id);
        this.loadOrganizationSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
      this.loadOrganizationSchools(0);
    }
  }

  zoneOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, item, this.school_id);
        this.loadZoneSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
      this.loadZoneSchools(0);
    }
  }

  userOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, item);
      }
    } else {
      this.school_id = 0;
      this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  weekOnChange(event: any): void {
    if (event.args) {
      let item = event.args.item.value;

      if (item) {
        let week = this.weeks.find((element: any) => element.id == item);

        this.date_from = moment(week.initial_date).format("MM-DD-yyyy");
        this.date_to = moment(week.final_date).format("MM-DD-yyyy");

        setTimeout(() => {
          // this.dateOnChange();
        }, 500);
      }
    }
  }

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(date, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(this.date_from, date, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  liquidateActualAssignments(user_profile_id: number) {
    var date_from = "0";
    var date_to = "0";

    if(date_from) {
      date_from = moment(this.date_from).format("yyyy-MM-DD");
    }
    
    if(date_to) {
      date_to = moment(this.date_to).format("yyyy-MM-DD");
    }

    this.http
    .post(
      this.URL_BASE + 'assignment_postings/liquidate/' + user_profile_id, 
      {
        date_from: date_from, 
        date_to: date_to, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadData(this.date_from, this.date_to, this.zip_code_id, this.organization_id, this.zone_id, this.school_id);
        } else {
          this.gridInvoices.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridInvoices() {
    this.sourceInvoices =
    {
      localdata: this.invoices, 
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'assignament_type_id', type: 'int' },
        { name: 'date', type: 'string' },
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'lunch_hours', type: 'number' },
        { name: 'break_hours', type: 'number' },
        { name: 'approval_hours', type: 'number' },
        { name: 'approval_overtime', type: 'number' },
        { name: 'assignment_id', type: 'int' },
        { name: 'rate_id', type: 'int' },
        { name: 'value_hour', type: 'number' },
        { name: 'value_over', type: 'number' },
        { name: 'subtotal_hours', type: 'number' },
        { name: 'subtotal_overt', type: 'number' },
        { name: 'total', type: 'number' },
        { name: 'billed', type: 'boolean' },
        { name: 'assignament_type', type: 'string' },
        { name: 'school_business_name', type: 'string' },
        { name: 'organization_name', type: 'string' },
        { name: 'zip_code', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zone_manager', type: 'string' }
      ]
    };

    this.dataAdapterInvoices = new jqx.dataAdapter(this.sourceInvoices, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }

          if(fila.start_time) {
            fila.start_time = moment(fila.start_time, "HH:mm:ss").format("LT");
          }

          if(fila.end_time) {
            fila.end_time = moment(fila.end_time, "HH:mm:ss").format("LT");
          }

          data.push(fila);
        }
      },
    });

    this.columnsInvoices =
    [
      { 
        text: 'Finalized',  
        datafield: 'billed',
        displayfield: 'billed',
        filtertype: 'boolean',
        columntype: 'checkbox',
        editable: false,
        width: '8%'
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Organization',  
        datafield: 'organization_name',
        displayfield: 'organization_name',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'School',  
        datafield: 'school_business_name',
        displayfield: 'school_business_name',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Zone Number',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Zip Code',  
        datafield: 'zip_code',
        displayfield: 'zip_code',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Assignment Number',  
        datafield: 'assignment_posting_id',
        displayfield: 'assignment_posting_id',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Assignment Type',  
        datafield: 'assignament_type_id',
        displayfield: 'assignament_type',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Actual Start Time',  
        datafield: 'start_time',
        displayfield: 'start_time',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Actual End Time',  
        datafield: 'end_time',
        displayfield: 'end_time',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Lunch Hrs.',  
        datafield: 'lunch_hours',
        displayfield: 'lunch_hours',
        editable: false,
        width: '10%',
        cellsformat: 'd2'
      },
      { 
        text: 'Break Hrs.',  
        datafield: 'break_hours',
        displayfield: 'break_hours',
        editable: false,
        width: '10%',
        cellsformat: 'd2'
      },
      { 
        text: 'Straight Hrs.',  
        datafield: 'approval_hours',
        displayfield: 'approval_hours',
        editable: false,
        width: '10%',
        cellsformat: 'd2'
      },
      { 
        text: 'Overtime Hrs.',  
        datafield: 'approval_overtime',
        displayfield: 'approval_overtime',
        editable: false,
        width: '10%',
        cellsformat: 'd2'
      },
      // { 
      //   text: 'Rate Type',  
      //   datafield: 'rate_id',
      //   displayfield: 'rate',
      //   editable: false,
      //   width: '8%' 
      // },
      { 
        text: 'Pay Rate',  
        datafield: 'value_hour',
        displayfield: 'value_hour',
        cellsalign: 'right',
        editable: false,
        width: '10%',
        cellsformat: 'c2'
      },
      { 
        text: 'OT Rate',  
        datafield: 'value_over',
        displayfield: 'value_over',
        cellsalign: 'right',
        editable: false,
        width: '10%',
        cellsformat: 'c2'
      },
      { 
        text: 'Straight Time Pay',  
        datafield: 'subtotal_hours',
        displayfield: 'subtotal_hours',
        cellsalign: 'right',
        editable: false,
        width: '10%',
        cellsformat: 'c2'
      },
      { 
        text: 'Total OT Pay',  
        datafield: 'subtotal_overt',
        displayfield: 'subtotal_overt',
        cellsalign: 'right',
        editable: false,
        width: '10%',
        cellsformat: 'c2'
      },
      { 
        text: 'Total Pay',  
        datafield: 'total',
        displayfield: 'total',
        cellsalign: 'right',
        editable: false,
        width: '10%',
        cellsformat: 'c2'
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
 