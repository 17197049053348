import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-late-check-out-report',
  templateUrl: './late-check-out-report.component.html',
  styleUrls: ['./late-check-out-report.component.css']
})
export class LateCheckOutReportComponent {
  //GRIDS
  @ViewChild('gridReports', { static: false }) gridReports: jqxGridComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  //COMBOBOX
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxZone', { static: false }) comboboxZone: jqxComboBoxComponent;
  @ViewChild('comboboxSchool', { static: false }) comboboxSchool: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;

  // DATA
  public organizations: any;
  public zones: any;
  public schools: any;

  // FORM
  public date_from: string = "";
  public date_to: string = "";
  public organization_id: number = 0;
  public zone_id: number = 0;
  public school_id: number = 0;

  // GRIDS
  public reports: any;
  public sourceReports: any;
  public dataAdapterReports: any = [];
  public columnsReports: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('late-check-out-report');
    this.user_id = this.tokenStorage.getUser();
    this.user_name = this.tokenStorage.getUserName();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.date_from = moment(new Date()).format("MM-DD-yyyy");
      this.date_to = moment(new Date()).format("MM-DD-yyyy");
      
      this.loadDataOrganizations();
      this.loadDataZones();
      this.loadOrganizationSchools(0);
      this.createGridReports();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataOrganizations() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter/organizations-schools').subscribe(res => {
      var sourceUserProfiles =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    });
  }

  async loadDataZones() {
    await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
      var sourceZones =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'description', type: 'string' }
        ]
      };

      this.zones = new jqx.dataAdapter(sourceZones, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " - " + fila.description;

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZones();
      }
    });
  }

  async loadOrganizationSchools(organization_id = 0) {
    if(organization_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-organization/' + organization_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    }
  }

  async loadZoneSchools(zone_id = 0) {
    if(zone_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-zone/' + zone_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    }
  }

  loadData(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    this.http.get(this.URL_BASE + 'report/filter-late-check-out/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_id + '/' + school_id).subscribe(res => {
      this.reports = res;

      this.sourceReports.localdata = this.reports;
      this.gridReports.updatebounddata('cells');
    });
  }

  reportPrint(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from == "" && date_to == "") {
      let error = {
        message: "Select a date range to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }
    
    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    window.open(this.URL_BASE + 'late-check-out/report-pdf/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_id + '/' + school_id + '/' + this.user_id, "_blank");
  }

  reportExcelData(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    if(date_from == "" && date_to == "") {
      let error = {
        message: "Select a date range to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }
    
    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }

    window.open(this.URL_BASE + 'late-check-out/report-excel/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_id + '/' + school_id + '/' + this.user_id, "_blank");
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  reportOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportPrint(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  downloadOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportExcelData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  organizationOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, item, this.zone_id, this.school_id);
        this.loadOrganizationSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
      this.loadOrganizationSchools(0);
    }
  }

  zoneOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.loadData(this.date_from, this.date_to, this.organization_id, item, this.school_id);
        this.loadZoneSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
      this.loadZoneSchools(0);
    }
  }

  userOnSelect(event: any): void {
    // console.log(event);
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_id, item);
      }
    } else {
      this.school_id = 0;
      this.loadData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(date, this.date_to, this.organization_id, this.zone_id, this.school_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(this.date_from, date, this.organization_id, this.zone_id, this.school_id);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridReports() {
    this.sourceReports =
    {
      localdata: this.reports, 
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'assignament_type_id', type: 'int' },
        { name: 'date', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'assignment_id', type: 'int' },
        { name: 'assignament_type', type: 'string' },
        { name: 'assignament_start_time', type: 'string' },
        { name: 'start_time_real', type: 'string' },
        { name: 'assignament_end_time', type: 'string' },
        { name: 'end_time_real', type: 'string' },
        { name: 'school_business_name', type: 'string' },
        { name: 'organization_name', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zip_code', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'zone_manager', type: 'string' }
      ]
    };

    this.dataAdapterReports = new jqx.dataAdapter(this.sourceReports, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          fila.date = moment(fila.date).format("MM-DD-yyyy");

          if(fila.assignament_start_time) {
            fila.assignament_start_time = moment(fila.assignament_start_time, "HH:mm:ss").format("LT");
          }

          if(fila.start_time_real) {
            fila.start_time_real = moment(fila.start_time_real, "HH:mm:ss").format("LT");
          }

          if(fila.assignament_end_time) {
            fila.assignament_end_time = moment(fila.assignament_end_time, "HH:mm:ss").format("LT");
          }

          if(fila.end_time_real) {
            fila.end_time_real = moment(fila.end_time_real, "HH:mm:ss").format("LT");
          }

          data.push(fila);
        }
      },
    });

    this.columnsReports =
    [
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Organization',  
        datafield: 'organization_name',
        displayfield: 'organization_name',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'School',  
        datafield: 'school_business_name',
        displayfield: 'school_business_name',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'Zone Number',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zip Code',  
        datafield: 'zip_code',
        displayfield: 'zip_code',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'Assignment Number',  
        datafield: 'assignment_posting_id',
        displayfield: 'assignment_posting_id',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Assignment Type',  
        datafield: 'assignament_type_id',
        displayfield: 'assignament_type',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Ass. Start Time',  
        datafield: 'assignament_start_time',
        displayfield: 'assignament_start_time',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Actual Start Time',  
        datafield: 'start_time_real',
        displayfield: 'start_time_real',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Ass. End Time',  
        datafield: 'assignament_end_time',
        displayfield: 'assignament_end_time',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Actual End Time',  
        datafield: 'end_time_real',
        displayfield: 'end_time_real',
        editable: false,
        width: '12%' 
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
 