import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';          

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent {
  //GRIDS
  @ViewChild('gridTasks', { static: false }) gridTasks: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxUser', { static: false }) comboboxUser: jqxComboBoxComponent;
  @ViewChild('comboboxAssigned', { static: false }) comboboxAssigned: jqxComboBoxComponent;

  //TOOLTIP
  @ViewChild('tooltipReference') myTooltip: jqxTooltipComponent;

  
  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public users: any;

  public user_task_id: number = 0;
  public assigned_id: number = 0;
  public title: string = "";
  public description: string = "";
  public creation_date: string = "";
  public min_creation_date: any;
  public expiration_date: string = "";
  public min_expiration_date: any;

  public tasks: any;
  public sourceTasks: any;
  public dataAdapterTasks: any = [];
  public cellHoverTasks: any = [];
  public columnsTasks: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('tasks');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();
      this.loadDataTask(0, 0);
      this.createGridTasks();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataForm() {
    this.creation_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_creation_date = moment(new Date()).format("MM-DD-yyyy");
    
    this.expiration_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_expiration_date = moment(new Date()).format("MM-DD-yyyy");

    this.http.get(this.URL_BASE + 'users').subscribe(res => {
      var sourceUser =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' }
        ]
      };

      this.users = new jqx.dataAdapter(sourceUser, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            data.push(fila);
          }
        },
      }); 

      setTimeout(() => {
        this.user_task_id = this.user_id;
      })
    });
  }

  loadDataTask(user_id: number = 0, assigned_id: number = 0) {
    if(this.user_id == 1) {
      user_id = 0;
    } else {
      if(!user_id || user_id == 0) {
        user_id = 0;
      }
    }
    
    if(!assigned_id || assigned_id == 0) {
      assigned_id = 0;
    }

    this.http.get(this.URL_BASE + 'tasks/filter-user-assigned/' + user_id + '/' + assigned_id).subscribe(res => {

      this.sourceTasks.localdata = res;
      this.gridTasks.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  registerOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createTask();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  cleanOnClick() {
    this.clearForm();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  userOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDataTask(item, this.assigned_id);
      }
    } else {
      this.user_task_id = 0;
      this.comboboxUser.clearSelection();
      this.loadDataTask(this.user_task_id, this.assigned_id);
    }
  }

  assignedOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDataTask(this.user_task_id, item);
      }
    } else {
      this.assigned_id = 0;
      this.comboboxAssigned.clearSelection();
      this.loadDataTask(this.user_task_id, this.assigned_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createTask() {
    this.http
    .post(
      this.URL_BASE + 'task', 
      { 
        user_id: this.user_task_id, 
        assigned_id: this.assigned_id, 
        title: this.title,
        description: this.description,
        creation_date: moment(this.creation_date).format("yyyy-MM-D"),
        expiration_date: moment(this.expiration_date).format("yyyy-MM-D"),
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          this.loadDataTask(0, 0);
        } else {
          this.gridTasks.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editTask(Rowdata: any) {
    var creation_date = "";
    var expiration_date = "";

    if(Rowdata.creation_date) {
      creation_date = moment(Rowdata.creation_date).format("yyyy-MM-DD");
    }

    if(Rowdata.expiration_date) {
      expiration_date = moment(Rowdata.expiration_date).format("yyyy-MM-DD");
    }

    this.http
    .put(
      this.URL_BASE + 'task/' + Rowdata.id, 
      {
        user_id: Rowdata.user_id, 
        assigned_id: Rowdata.assigned_id, 
        title: Rowdata.title,
        description: Rowdata.description,
        creation_date: creation_date,
        expiration_date: expiration_date,
        status: Rowdata.status,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataTask(this.user_task_id, this.assigned_id);
        } else {
          this.gridTasks.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteTask(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'task/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "orange") {
          this.loadDataTask(this.user_task_id, this.assigned_id);
        } else {
          this.gridTasks.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.user_task_id = this.user_id;
    this.comboboxUser.clearSelection();
    this.assigned_id = 0;
    this.comboboxAssigned.clearSelection();
    this.title = "";
    this.description = "";
    this.creation_date = moment(new Date()).format("MM-DD-yyyy");
    this.expiration_date = moment(new Date()).format("MM-DD-yyyy");

    this.sourceTasks.localdata = this.tasks;
    this.gridTasks.updatebounddata('cells');
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridTasks() {
    this.sourceTasks =
    {
      localdata: this.tasks,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'assigned_id', type: 'int' },
        { name: 'title', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'creation_date', type: 'string' },
        { name: 'expiration_date', type: 'string' },
        { name: 'status', type: 'boolean' },
        { name: 'user', type: 'string' },
        { name: 'assigned', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editTask(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterTasks = new jqx.dataAdapter(this.sourceTasks, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.creation_date) {
            fila.creation_date = moment(fila.creation_date).format("MM-DD-yyyy");
          }

          if(fila.expiration_date) {
            fila.expiration_date = moment(fila.expiration_date).format("MM-DD-yyyy");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverTasks = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridTasks.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridTasks.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Task</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsTasks =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Task ID',  
        datafield: 'id',
        displayfield: 'id',
        editable: false,
        cellsalign: 'center',
        width: '7%' 
      },
      { 
        text: 'Completed',  
        datafield: 'status',
        displayfield: 'status',
        editable: true,
        columntype: 'checkbox',
        filtertype: 'boolean',
        width: '9%' 
      },
      { 
        text: 'User',  
        datafield: 'user_id',
        displayfield: 'user',
        editable: false,
        width: '20%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.users,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      },
      { 
        text: 'Assigned',  
        datafield: 'assigned_id',
        displayfield: 'assigned',
        editable: false,
        width: '20%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.users,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      },
      { 
        text: 'Title',  
        datafield: 'title',
        displayfield: 'title',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Description',  
        datafield: 'description',
        displayfield: 'description',
        editable: false,
        width: '40%',
        cellsrenderer: function (row: number, column: any, value: any) {
          return "<p class='text-justify p-1'>" + value + "</p>";
        }
      },
      { 
        text: 'Creation',  
        datafield: 'creation_date',
        displayfield: 'creation_date',
        editable: false,
        width: '12%' 
      },
      // { 
      //   text: 'Expiration',  
      //   datafield: 'expiration_date',
      //   displayfield: 'expiration_date',
      //   editable: false,
      //   width: '10%' 
      // },
    ];
    // this.localization = getLocalization('es');
  }

  onCellClick(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteTask(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
