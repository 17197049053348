import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-postings',
  templateUrl: './postings.component.html',
  styleUrls: ['./postings.component.css']
})
export class PostingsComponent {

  private URL_BASE: string;
  public alerts: any;

  public type_posting: string;
  public name_posting: string = "";
  public description_posting: string = "";

  public postings: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    
    this.alerts = new Array();

    this.type_posting = this.router.url.split("/")[3];
    if(this.type_posting) {
      this.type_posting = this.type_posting.split("?")[0];
    }
    // console.log(this.type_posting);
  }

  ngOnInit(): void {
    this.loadDataForm();
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataForm() {
    if(this.type_posting == "jobs") {
      this.name_posting = "Job postings";
      this.description_posting = "Here you can find the best job offers";

      this.http.get(this.URL_BASE + 'job_postings/data/unauthenticated').subscribe(res => {
        this.postings = res;
      });
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  goLanding() {
    this.router.navigateByUrl("/landing");
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  limpiarForm() {
    // this.cotizacion_id = 0;
    // this.token = "";
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                               */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}