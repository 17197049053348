import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-educator-blocked-dates-report',
  templateUrl: './educator-blocked-dates-report.component.html',
  styleUrls: ['./educator-blocked-dates-report.component.css']
})
export class EducatorBlockedDatesReportComponent {
  //GRIDS
  @ViewChild('gridReport', { static: false }) gridReport: jqxGridComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  //COMBOBOX
  @ViewChild('comboboxUserProfile', { static: false }) comboboxUserProfile: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public user_profiles: any;

  // FORM
  public start_date: string = "";
  public end_date: string = "";
  public min_end_date: any = "";
  public user_profile_id: number = 0;

  // GRIDS
  public report_data: any;
  public sourceReport: any;
  public dataAdapterReport: any = [];
  public columnsGridReport: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('educator-blocked-dates-report');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataEducators();

      this.createGridReport();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.start_date = moment(new Date()).format("MM-DD-yyyy");
    this.end_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_end_date = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadDataEducators() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-type/4').subscribe(res => {
      var sourceUser =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' }
        ]
      };

      this.user_profiles = new jqx.dataAdapter(sourceUser, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            }

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataEducators();
      }
    });
  }

  loadData(start_date: string = "", end_date: string = "", user_profile_id: number = 0) {
    if(start_date) {
      start_date = moment(start_date).format("yyyy-MM-DD");
    } else {
      start_date = "0";
    }
    
    if(end_date) {
      end_date = moment(end_date).format("yyyy-MM-DD");
    } else {
      end_date = "0";
    }

    if(!user_profile_id || user_profile_id == 0) {
      user_profile_id = 0;
    }

    this.http.get(this.URL_BASE + 'user_profiles/user-profile-blocked-dates/' + start_date + '/' + end_date + '/' + user_profile_id).subscribe(res => {
      this.report_data = res;

      this.sourceReport.localdata = this.report_data;
      this.gridReport.updatebounddata('cells');
    });
  }

  reportActualAssignments(start_date: string = "", end_date: string = "", user_profile_id: number = 0) {
    if(start_date) {
      start_date = moment(start_date).format("yyyy-MM-DD");
    } else {
      start_date = "0";
    }
    
    if(end_date) {
      end_date = moment(end_date).format("yyyy-MM-DD");
    } else {
      end_date = "0";
    }

    if(!user_profile_id || user_profile_id == 0) {
      user_profile_id = 0;
    }

    window.open(this.URL_BASE + 'user-profile-blocked-dates/report-pdf/' + start_date + '/' + end_date + '/' + user_profile_id + '/' + this.user_id , "_blank");
  }

  reportExcelData(start_date: string = "", end_date: string = "", user_profile_id: number = 0) {
    if(start_date) {
      start_date = moment(start_date).format("yyyy-MM-DD");
    } else {
      start_date = "0";
    }
    
    if(end_date) {
      end_date = moment(end_date).format("yyyy-MM-DD");
    } else {
      end_date = "0";
    }

    if(!user_profile_id || user_profile_id == 0) {
      user_profile_id = 0;
    }

    window.open(this.URL_BASE + 'user-profile-blocked-dates/report-excel/' + start_date + '/' + end_date + '/' + user_profile_id + '/' + this.user_id , "_blank");
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  reportOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportActualAssignments(this.start_date, this.end_date, this.user_profile_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  printOnClick() {
    const html = document.getElementById('pdfPrint')!;

    html2canvas(html, {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function(canvas: any) {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF();
      doc.addImage(img,'PNG',7, 20, 195, 105);
      doc.save('report_actual_assignments.pdf');
    });
  }

  downloadOnClick() {
    this.reportExcelData(this.start_date, this.end_date, this.user_profile_id);
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  userProfileOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadData(this.start_date, this.end_date, item);
      }
    } else {
      this.user_profile_id = 0;
      this.comboboxUserProfile.clearSelection();
      this.loadData(this.start_date, this.end_date, this.user_profile_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.min_end_date = date;
        this.loadData(date, this.end_date, this.user_profile_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadData(this.start_date, date, this.user_profile_id);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridReport() {
    this.sourceReport =
    {
      localdata: this.report_data, 
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'zip_code', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'identification', type: 'string' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zone_manager', type: 'string' }
      ]
    };

    this.dataAdapterReport = new jqx.dataAdapter(this.sourceReport, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];
          
          fila.start_date = moment(fila.start_date).format("MM-DD-yyyy");

          fila.end_date = moment(fila.end_date).format("MM-DD-yyyy");

          data.push(fila);
        }
      },
    });

    this.columnsGridReport =
    [
      { 
        text: 'Zip Code',  
        datafield: 'zip_code',
        displayfield: 'zip_code',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Identification',  
        datafield: 'identification',
        displayfield: 'identification',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '35%' 
      },
      { 
        text: 'Start Date',  
        datafield: 'start_date',
        displayfield: 'start_date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'End Date',  
        datafield: 'end_date',
        displayfield: 'end_date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Zone Number',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '30%' 
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
 