import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  selector: 'app-assignment-postings',
  templateUrl: './assignment-postings.component.html',
  styleUrls: ['./assignment-postings.component.css']
})
export class AssignmentPostingsComponent {
  //COMBOBOX
  @ViewChild('comboboxUserProfile', { static: false }) comboboxUserProfile: jqxComboBoxComponent;
  @ViewChild('comboboxAssignmentType', { static: false }) comboboxAssignmentType: jqxComboBoxComponent;
  @ViewChild('comboboxSkill', { static: false }) comboboxSkill: jqxComboBoxComponent;
  @ViewChild('comboboxAssignmentStatuses', { static: false }) comboboxAssignmentStatuses: jqxComboBoxComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;
  private school_profile_id: any;
  private user_type: any;
  private user_rol_id: any;
  private school_parent_id: any;

  public user_profiles: any;
  public assignament_types: any;
  public skills: any;
  public assignment_posting_statuses: any;
  public document_teacher: any;

  public assignment_posting_id: number = 0;
  public user_profile_id: number = 0;
  public start_date: string = "";
  public min_start_date: any;
  public end_date: string = "";
  public min_end_date: any = "";
  public assignament_type_id: number = 0;
  public start_time: string = "";
  public end_time: string = "";
  public lunch_hours: number = 1;
  public break_hours: number = 0;
  public check_in_location: string = "";
  public check_in_contact: string = "";
  public skill_id: number = 0;
  public skill_other: string = "";
  public note_section: string = "";
  public substitute_teacher_plan: any;
  public document_teacher_name: string = "";
  public status_id: number = 0;
  public image: any = "";

  public localization: any;

  //DISABLED
  public disComboboxUserProfile: boolean = false;

  //MODAL
  public modalConfirmAdd: boolean = false;


  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('assignment-postings');
    this.user_id = this.tokenStorage.getUser();
    this.school_profile_id = this.tokenStorage.getUserProfile();
    this.user_type = this.tokenStorage.getUserType();
    this.user_rol_id = this.tokenStorage.getUserRol();
    this.school_parent_id = this.tokenStorage.getUserParent();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataSchools();
      await this.loadDataAssignmentTypes();
      await this.loadDataSkills();
      await this.loadDataAssignmentStatuses();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.start_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_start_date = moment(new Date()).format("MM-DD-yyyy");
    // this.max_start_date = moment(new Date()).format("MM-DD-yyyy");

    // var end_date = new Date();
    // end_date.setMonth(end_date.getMonth() + 1);
    this.end_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_end_date = moment(new Date()).format("MM-DD-yyyy");

    this.start_time = "08:00 AM";
    this.end_time = "04:00 PM";
  }

  async loadDataSchools() {
    this.http.get(this.URL_BASE + 'user_profiles/filter-type-active/2/1').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.user_profiles = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];
            
            fila.name = fila.business_name;

            data.push(fila);
          }
        },
      }); 

      setTimeout(() => {
        if(this.user_type == 2) {
          this.comboboxUserProfile.val(this.school_profile_id);
        } else if(this.user_type == 5) {
          this.comboboxUserProfile.val(this.school_parent_id);
        }

        if(this.permissions && this.permissions.supervise == 1) {
          this.disComboboxUserProfile = false;
        } else {
          this.disComboboxUserProfile = true;
        }
      }, 500);
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataSchools();
      }
    });
  }

  async loadDataAssignmentTypes() {
    this.http.get(this.URL_BASE + 'assignament_types').subscribe(res => {
      this.assignament_types = res; 

      setTimeout(() => {
        this.comboboxAssignmentType.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataAssignmentTypes();
      }
    });
  }

  async loadDataSkills() {
    this.http.get(this.URL_BASE + 'skills').subscribe(res => {
      this.skills = res; 

      setTimeout(() => {
        this.comboboxSkill.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataSkills();
      }
    });
  }
    
  async loadDataAssignmentStatuses() {
    this.http.get(this.URL_BASE + 'assignment_posting_statuses').subscribe(res => {
      this.assignment_posting_statuses = res; 

      setTimeout(() => {
        this.comboboxAssignmentStatuses.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataAssignmentStatuses();
      }
    });
  }

  convertFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  registerOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      setTimeout(() => {
        this.modalConfirmAdd = true;
        // this.createAssignmentPostings();
      }, 500);
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  cleanOnClick() {
    this.clearForm();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */

  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }
  

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  uploadDocumentoOnChange(event: any): void {
    if(event.target) {
      var document_file = event.target.files[0];
      this.convertFile(document_file).then(data => {
        this.substitute_teacher_plan = data;
        this.document_teacher_name = document_file.name;
      }); 
    }
  }

  uploadImageOnChange(event: any): void {
    if(event.target) {
      var document_file = event.target.files[0];
      this.convertFile(document_file).then(data => {
        this.image = data;
      }); 
    }
  }

  startDateOnChange(event: any): void {
    if(event.target) {
      var end_date = event.target.value;

      if(end_date) {
        this.min_end_date = end_date;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  async createAssignmentPostings() {
    await this.http
    .post(
      this.URL_BASE + 'assignment_posting', 
      { 
        user_profile_id: this.user_profile_id, 
        start_date: moment(this.startDateDateTime.val()).format("yyyy-MM-D"),
        end_date: moment(this.endDateDateTime.val()).format("yyyy-MM-D"), 
        assignament_type_id: this.assignament_type_id, 
        start_time: moment(this.start_time, "LT").format("HH:mm:ss"), 
        end_time: moment(this.end_time, "LT").format("HH:mm:ss"), 
        lunch_hours: this.lunch_hours, 
        break_hours: this.break_hours, 
        check_in_location: this.check_in_location, 
        check_in_contact: this.check_in_contact, 
        skill_id: this.skill_id, 
        skill_other: this.skill_other,
        note_section: this.note_section,
        substitute_teacher_plan: this.substitute_teacher_plan,
        image: this.image,
        status_id: this.status_id,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          setTimeout(() => {
            window.open("/submenus/assignments/assignment-lists", "_self");
          }, 2000);
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }
  

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.assignment_posting_id = 0;
    if(this.user_type == 2) {
      this.comboboxUserProfile.val(this.school_profile_id);
    } else {
      this.user_profile_id = 0;
      this.comboboxUserProfile.clearSelection();
    }
    this.start_date = moment(new Date()).format("MM-D-yyyy");
    this.end_date = moment(new Date()).format("MM-D-yyyy");
    this.comboboxAssignmentType.selectIndex(0);
    this.start_time = "08:00 AM";
    this.end_time = "04:00 PM";
    this.lunch_hours = 1;
    this.break_hours = 0;
    this.check_in_location = "";
    this.check_in_contact = "";
    this.comboboxSkill.selectIndex(0);
    this.skill_other = "";
    this.document_teacher = "";
    this.document_teacher_name = "";
    this.substitute_teacher_plan = "";
    this.note_section = "";
    this.comboboxAssignmentStatuses.selectIndex(0);
    this.image = "";
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    const timeoutId = setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
