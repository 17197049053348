<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'orange'" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'orange'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Assignments</h2>
      <p class="mt-1 text-sm text-gray-500">Filter, Select, Delete, Edit, Chat, Track Hours, Rate, Cancel and Track Educators via Google Maps</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-3">
        <label for="date_from" class="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
        <jqxDateTimeInput #dateFromDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_from" 
          [theme]="'energyblue'"
          (onChange)="dateFromOnChange($event)"
        ></jqxDateTimeInput>
      </div>
      
      <div class="col-span-12 sm:col-span-3">
        <label for="date_to" class="block text-sm font-medium text-gray-700 mb-1">End Date</label>
        <jqxDateTimeInput #dateToDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_to" 
          [theme]="'energyblue'"
          (onChange)="dateToOnChange($event)"
        ></jqxDateTimeInput>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="organization_id" class="block text-sm font-medium text-gray-700">Organization</label>
        <jqxComboBox #comboboxOrganization
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="organizations" 
          [theme]="'energyblue'"
          [(ngModel)]="organization_id"
          (onSelect)="organizationOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="zone_manager_id" class="block text-sm font-medium text-gray-700">Zone Manager</label>
        <jqxComboBox #comboboxZoneManager
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zone_managers" 
          [theme]="'energyblue'"
          [(ngModel)]="zone_manager_id"
          (onSelect)="zoneManagerOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="zone_id" class="block text-sm font-medium text-gray-700">Zone</label>
        <jqxComboBox #comboboxZone
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zones" 
          [theme]="'energyblue'"
          [(ngModel)]="zone_id"
          (onSelect)="zoneOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="school_id" class="block text-sm font-medium text-gray-700">School</label>
        <jqxComboBox #comboboxSchool
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="schools" 
          [theme]="'energyblue'"
          [(ngModel)]="school_id"
          (onSelect)="userOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>
    </div>

    <hr>

    <div class="mt-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxTooltip #tooltipReference [position]="'mouse'" [name]="'name'"></jqxTooltip>
        <jqxGrid #gridAssignmentPostings
          [width]="'100%'" 
          [cellhover]='cellHoverAssignmentPostings'
          [source]="dataAdapterAssignmentPostings" 
          [columns]="columnsAssignmentPostings"
          [pageable]="true" 
          [autoheight]="true" 
          [showfilterrow]="true"
          [columnsresize]="true"
          [filterable]="true"
          [sortable]="true"  
          [editable]="false" 
          [editmode]="'dblclick'"
          [selectionmode]="'singlerow'"
          (onCellclick)="onCellClick($event)"
          [localization]="localization"
          [theme]="'energyblue'"
        ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div class="relative z-30 {{ slideAssignment && assignment_posting_id ? 'block' : 'hidden' }}" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <!-- <div class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true"> -->
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <div class="pointer-events-auto w-screen max-w-4xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="px-4 pt-4 pb-4 sm:px-6 bg-orange-600">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-white" id="slide-over-title">Edit Assignment ID {{ assignment_posting_id }}</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button (click)="slideAssignment = false" type="button" class="rounded-md bg-white text-orange-400 hover:text-orange-500 focus:ring-2 focus:ring-orange-500">
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="divide-y divide-gray-200">              
              <div class="px-5 pt-3 pb-5 mb-4">
                <div class="grid grid-cols-12 gap-4 mb-5">
                  <div class="col-span-12 sm:col-span-12">
                    <label for="user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
                      School <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxComboBox #comboboxUserProfile
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'id'" 
                      [source]="combobox_schools" 
                      [theme]="'energyblue'"
                      [(ngModel)]="user_profile_id"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                      [disabled]="true"
                    ></jqxComboBox>
                  </div>

                  <div class="col-span-12 sm:col-span-3">
                    <label for="start_date" class="block text-sm font-medium text-gray-700 mb-1">
                      Start Date <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxDateTimeInput #startDateDateTime
                      [width]="'100%'" 
                      [height]="38" 
                      [formatString]="'MM-dd-yyyy'"
                      [(ngModel)]="start_date" 
                      [theme]="'energyblue'"
                      (onChange)="startDateOnChange($event)"
                      [disabled]="true"
                    ></jqxDateTimeInput>
                  </div>

                  <div class="col-span-12 sm:col-span-3">
                    <label for="end_date" class="block text-sm font-medium text-gray-700 mb-1">
                      End Date <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxDateTimeInput #endDateDateTime
                      [width]="'100%'" 
                      [height]="38" 
                      [formatString]="'MM-dd-yyyy'"
                      [(ngModel)]="end_date" 
                      [min]="min_end_date"
                      [theme]="'energyblue'"
                    ></jqxDateTimeInput>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="assignament_type_id" class="block text-sm font-medium text-gray-700 mb-1">
                      Assignment Type <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxComboBox #comboboxAssignmentType
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'id'" 
                      [source]="assignament_types" 
                      [theme]="'energyblue'"
                      [(ngModel)]="assignament_type_id"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="start_time" class="block text-sm font-medium text-gray-700">
                      Start Time <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxDateTimeInput 
                      [width]="'100%'"  
                      [height]="38" 
                      [formatString]="'hh:mm tt'" 
                      [showTimeButton]="true" 
                      [showCalendarButton]="false"
                      [(ngModel)]="start_time"
                    ></jqxDateTimeInput>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="end_time" class="block text-sm font-medium text-gray-700">
                      End Time <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxDateTimeInput 
                      [width]="'100%'"  
                      [height]="38" 
                      [formatString]="'hh:mm tt'" 
                      [showTimeButton]="true" 
                      [showCalendarButton]="false"
                      [(ngModel)]="end_time"
                    ></jqxDateTimeInput>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="lunch_hours" class="block text-sm font-medium text-gray-700">Lunch Hour(s)</label>
                    <input type="text" min="0" (input)="numberInput($event)" name="lunch_hours" id="lunch_hours" autocomplete="lunch_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="lunch_hours">
                  </div>

                  <div class="col-span-12 sm:col-span-3">
                    <label for="break_hours" class="block text-sm font-medium text-gray-700">Break Hours</label>
                    <input type="text" min="0" (input)="numberInput($event)" name="break_hours" id="break_hours" autocomplete="break_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="break_hours">
                  </div>

                  <div class="col-span-12 sm:col-span-3">
                    <label for="force_long_term" class="block text-sm font-medium text-gray-700">Force Long Term</label>
                    <!-- <input type="checkbox" name="force_long_term" id="force_long_term" autocomplete="force_long_term" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="force_long_term"> -->
                    <jqxCheckBox #checkboxForceLongTerm
                      [width]="'100%'"  
                      [height]="38" 
                      [theme]="'energyblue'"
                    ></jqxCheckBox>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="check_in_location" class="block text-sm font-medium text-gray-700">Check in Location</label>
                    <input type="text" name="check_in_location" id="check_in_location" autocomplete="check_in_location" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="check_in_location">
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="check_in_contact" class="block text-sm font-medium text-gray-700">Check in Contact</label>
                    <input type="text" name="check_in_contact" id="check_in_contact" autocomplete="check_in_contact" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="check_in_contact">
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="skill_id" class="block text-sm font-medium text-gray-700 mb-1">
                      Skills <span class="text-xss text-red-500">(Required)</span>
                    </label>
                    <jqxComboBox #comboboxSkill
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'id'" 
                      [source]="skills" 
                      [theme]="'energyblue'"
                      [(ngModel)]="skill_id"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label for="skill_other" class="block text-sm font-medium text-gray-700">Skill Other</label>
                    <input type="text" name="skill_other" id="skill_other" autocomplete="skill_other" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="skill_other">
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <h2 class="text-lg font-medium leading-6 text-gray-900">Substitute Teacher Plan</h2>
                    <p class="mt-1 text-sm text-gray-500">Add Document Attachment in PDF Format</p>
                    <input type="file" id="documentInput" class="hidden" accept="application/pdf" (change)="uploadDocumentoOnChange($event)" [(ngModel)]="document_teacher">
                    <button class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick="document.getElementById('documentInput').click();">Select Document and press Update</button>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <div class="mb-3"><small>&nbsp;</small></div>
                    <button type="submit" (click)="viewPDFOnClick()" class="w-full bg-sky-600 hover:bg-sky-700 focus:ring-sky-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2" *ngIf="pdfSource">View PDF</button>
                    <button type="submit" (click)="viewPDFOnClick()" class="w-full bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2" *ngIf="!pdfSource" disabled>View PDF</button>
                    <div class="text-right"><small>{{ document_teacher_name }}</small></div>
                  </div>
                </div>

                <hr>

                <div class="grid grid-cols-12 gap-4 mb-4">
                  <div class="col-span-12 sm:col-span-9"></div>
                  <div class="col-span-12 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="updateOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Update & Save</button>
                  </div>
                </div>

                <hr class="{{ user_type == 2 ? 'hidden' : 'block' }}">

                <div class="grid grid-cols-12 gap-4 mt-4 mb-4 {{ user_type == 2 ? 'hidden' : 'block' }}">
                  <div class="col-span-12 sm:col-span-12">
                    <h2 class="text-lg font-medium leading-6 text-gray-900">Suggested Educators for this Assignment</h2>
                    <p class="mt-1 text-sm text-gray-500">Select any or all of the three options below to invite Educator(s) to apply for this Assignment:</p>
                  </div>
                  <div class="col-span-12 sm:col-span-12">
                    <div class="bg-gray-100 py-2 px-4">
                      <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 sm:col-span-10">
                          <h2 class="text-xl font-semibold text-gray-800">
                            1. Individual Educator
                          </h2>
                        </div>

                        <div class="col-span-12 sm:col-span-2">
                          <button type="submit" (click)="requestEducatorOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <label for="skill_other" class="block text-sm font-medium text-gray-700">
                      Use the drop-down menu to select the Educator for this Assignment ID {{ assignment_posting_id }}
                    </label>
                    <jqxComboBox #comboboxAssignmentUser
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'user_id'" 
                      [source]="combobox_educators" 
                      [theme]="'energyblue'"
                      [(ngModel)]="user_ass_id"
                      [autoComplete]="true" 
                      [dropDownVerticalAlignment]="'top'"
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>
                </div>

                <hr>

                <div class="grid grid-cols-10 gap-4 mt-4 mb-4" *ngIf="preferred_educators && preferred_educators.length > 0">
                  <div class="col-span-12 sm:col-span-12">
                    <div class="bg-gray-100 py-2 px-4">
                      <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 sm:col-span-10">
                          <h2 class="text-xl font-semibold text-gray-800">
                            2. School Preferred Educators
                          </h2>
                        </div>

                        <div class="col-span-12 sm:col-span-2">
                          <button type="submit" (click)="requestEducatorsOnClick(assignment_posting_id, preferred_educators, 'preferred')" class="w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 {{ send_request_pre == true ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500'}}">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-10 sm:col-span-2 flex" *ngFor="let preferred_educator of preferred_educators; let index = index">
                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div class="flex flex-col items-center mx-2 pt-3 pb-3">
                        <div class="relative inline-block text-center">
                          <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="{{ preferred_educator.educator_photo }}" alt="Educator image" *ngIf="preferred_educator.educator_photo"/>
                          <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Educator image" *ngIf="!preferred_educator.educator_photo"/>
                          <div class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-full w-6 bg-orange-600 text-white rounded-full text-sm">
                            {{ (index + 1) }}
                          </div>
                        </div>
                        <div class="mb-1 text-base text-center text-orange-600 dark:text-white" style="min-height: 50px;">
                          {{ preferred_educator.educator_first_name }} {{ preferred_educator.educator_second_name }} {{ preferred_educator.educator_last_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr>

                <div class="grid grid-cols-10 gap-4 mt-4 mb-4" *ngIf="ranking_educators && ranking_educators.length > 0">
                  <div class="col-span-12 sm:col-span-12">
                    <div class="bg-gray-100 py-2 px-4">
                      <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 sm:col-span-10">
                          <h2 class="text-xl font-semibold text-gray-800">
                            3. Top Educators Suggested based on ranking
                          </h2>
                        </div>

                        <div class="col-span-12 sm:col-span-2">
                          <button type="submit" (click)="requestEducatorsOnClick(assignment_posting_id, ranking_educators, 'top')" class="w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 {{ send_request_top == true ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500'}}">Send Request</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-10 sm:col-span-2 flex" *ngFor="let rank_educator of ranking_educators">
                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div class="flex flex-col items-center mx-2 pt-3 pb-3">
                        <div class="relative inline-block text-center">
                          <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="{{ rank_educator.photo }}" alt="Educator image" *ngIf="rank_educator.photo"/>
                          <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Educator image" *ngIf="!rank_educator.photo"/>
                          <div class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-full w-6 bg-orange-600 text-white rounded-full text-sm">
                            {{ rank_educator.order }}
                          </div>
                        </div>
                        <div class="mb-1 text-base text-center text-orange-600 dark:text-white" style="min-height: 50px;">
                          {{ rank_educator.first_name }} {{ rank_educator.second_name }} {{ rank_educator.last_name }}
                        </div>
                        <p class="text-sm text-gray-600 text-base" *ngIf="user_type == 1">{{ rank_educator.score }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalChat" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Chats {{ chat_name }}</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalChat = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2" x-data="{chatEducator: true, chatSchool: false}">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                <option>My Account</option>

                <option>Company</option>

                <option selected>Team Members</option>

                <option>Billing</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                  <a x-on:click="chatEducator = true, chatSchool = false" x-bind:class="chatEducator ? 'border-indigo-500 text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'">
                    <svg x-bind:class="chatEducator ? 'text-indigo-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                    </svg>
                    <span>Chat Educator</span>
                  </a>
                  <a x-on:click="chatEducator = false, chatSchool = true" x-bind:class="chatSchool ? 'border-indigo-500 text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'">
                    <!-- Heroicon name: solid/office-building -->
                    <svg x-bind:class="chatSchool ? 'text-indigo-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                    </svg>
                    <span>Chat School</span>
                  </a>
                </nav>
              </div>
            </div>
            <div x-show="chatEducator">
              <div class="flex h-screen antialiased text-gray-800">
                <div class="flex flex-row h-full w-full overflow-x-hidden">
                  <div class="flex flex-col flex-auto h-full p-6">
                    <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 p-4">
                      <div class="flex flex-col h-full overflow-x-auto mb-4">
                        <div class="flex flex-col h-full" *ngIf="conversation_educator">
                          <div class="grid grid-cols-12 gap-y-2" *ngFor="let message of conversation_educator; let index = index">
                            <div class="col-span-12 sm:col-span-12 p-3" *ngIf="index == 0 || conversation_educator[index-1].date != message.date">
                              <div class="w-28 text-sm text-center bg-white py-2 px-4 shadow rounded-xl m-auto">
                                {{ message.date | date : 'MM/dd/yyy' }} 
                              </div>
                            </div>

                            <div class="col-start-1 col-end-8 p-3 rounded-lg mt-1" *ngIf="message.person == 2">
                              <div class="flex flex-row items-center">
                                <div class="items-center justify-center text-center h-10 w-12 rounded-full flex-shrink-0">
                                  <img src="././assets/ava2-bg.webp" alt="avatar 2" style="width: 100%; height: auto;">
                                  <div class="p-0 m-0 text-xss" style="font-size:10px;">{{ message.date_time | date: 'hh:mm a' }}</div>
                                </div>
                                <div class="relative ml-3 text-sm bg-red-200 py-2 px-4 shadow rounded-xl min-w-[25%]">
                                  <div>{{ message.message }}</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-start-6 col-end-13 p-3 rounded-lg mt-1" *ngIf="message.person == 1">
                              <div class="flex items-center justify-start flex-row-reverse">
                                <div class="items-center justify-center text-center h-10 w-12 rounded-full flex-shrink-0">
                                  <img src="././assets/ava1-bg.webp" alt="avatar 1" style="width: 100%; height: auto;">
                                  <div class="p-0 m-0 text-xss" style="font-size:10px;">{{ message.date_time | date: 'hh:mm a' }}</div>
                                </div>
                                <div class="relative mr-3 text-sm bg-white py-2 px-4 shadow rounded-xl min-w-[25%]">
                                  <div>{{ message.message }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                        <div class="items-center justify-center text-center h-10 w-10 rounded-full flex-shrink-0">
                          <img src="././assets/ava3-bg.webp" alt="avatar 3" style="width: 100%; height: auto;">
                        </div>
                        <div class="flex-grow ml-4 mt-1">
                          <div class="relative w-full">
                            <input type="text" class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10" placeholder="Type message" [(ngModel)]="message_educator"/>
                          </div>
                        </div>
                        <div class="ml-4 py-2">
                          <button class="flex items-center justify-center bg-sky-600 hover:bg-indigo-600 rounded-xl text-white px-4 py-2 flex-shrink-0" (click)="sendMessage(1)">
                            <span>Send</span>
                            <span class="ml-2">
                              <svg class="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div x-show="chatSchool">
              <div class="flex h-screen antialiased text-gray-800">
                <div class="flex flex-row h-full w-full overflow-x-hidden">
                  <div class="flex flex-col flex-auto h-full p-6">
                    <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 p-4">
                      <div class="flex flex-col h-full overflow-x-auto mb-4">
                        <div class="flex flex-col h-full" *ngIf="conversation_school">
                          <div class="grid grid-cols-12 gap-y-2" *ngFor="let message of conversation_school; let index = index">
                            <div class="col-span-12 sm:col-span-12 p-3" *ngIf="index == 0 || conversation_school[index-1].date != message.date">
                              <div class="w-28 text-sm text-center bg-white py-2 px-4 shadow rounded-xl m-auto">
                                {{ message.date | date : 'MM/dd/yyy' }} 
                              </div>
                            </div>

                            <div class="col-start-1 col-end-8 p-3 rounded-lg mt-1" *ngIf="message.user_id != user_id">
                              <div class="flex flex-row items-center">
                                <div class="items-center justify-center text-center h-10 w-12 rounded-full flex-shrink-0">
                                  <img src="././assets/ava2-bg.webp" alt="avatar 2" style="width: 100%; height: auto;">
                                  <div class="p-0 m-0 text-xss" style="font-size:10px;">{{ message.date_time | date: 'hh:mm a' }}</div>
                                </div>
                                <div class="relative ml-3 text-sm bg-red-200 py-2 px-4 shadow rounded-xl">
                                  <div>{{ message.message }}</div>
                                  <small class="text-xss bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                    {{ message.user_name }}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-start-6 col-end-13 p-3 rounded-lg mt-1" *ngIf="message.user_id == user_id">
                              <div class="flex items-center justify-start flex-row-reverse">
                                <div class="items-center justify-center text-center h-10 w-12 rounded-full flex-shrink-0">
                                  <img src="././assets/ava1-bg.webp" alt="avatar 1" style="width: 100%; height: auto;">
                                  <div class="p-0 m-0 text-xss" style="font-size:10px;">{{ message.date_time | date: 'hh:mm a' }}</div>
                                </div>
                                <div class="relative mr-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                                  <div>{{ message.message }}</div>
                                  <small class="text-xss bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                    {{ message.user_name }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                        <div class="items-center justify-center text-center h-10 w-10 rounded-full flex-shrink-0">
                          <img src="././assets/ava3-bg.webp" alt="avatar 3" style="width: 100%; height: auto;">
                        </div>
                        <div class="flex-grow ml-4 mt-1">
                          <div class="relative w-full">
                            <input type="text" class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10" placeholder="Type message" [(ngModel)]="message_school"/>
                          </div>
                        </div>
                        <div class="ml-4 py-2">
                          <button class="flex items-center justify-center bg-sky-600 hover:bg-indigo-600 rounded-xl text-white px-4 py-2 flex-shrink-0" (click)="sendMessage(2)">
                            <span>Send</span>
                            <span class="ml-2">
                              <svg class="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalCancelAssignment" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Cancel or Close Assignment Post</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalCancelAssignment = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">  
              <label for="status_id" class="block text-sm font-medium text-gray-700 mb-1">Status</label>
              <jqxComboBox #comboboxStatusCancelClosed
                [width]="'100%'"  
                [height]="38"
                [dropDownHeight]="100"
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="assignment_closed_cancel_statuses" 
                [theme]="'energyblue'"
                [(ngModel)]="closed_cancel_status_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>
            <div class="col-span-12 sm:col-span-12">  
              <label for="cancel_comment" class="block text-sm font-medium text-gray-700">Comment</label>
              <textarea id="cancel_comment" name="cancel_comment" rows="5" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cancel_comment"></textarea>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="cancelAssignment()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel / Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative z-40 {{ modalOpenAssignment ? 'block' : 'hidden' }}" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-11/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Actual Assignment Post</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalOpenAssignment = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">
              <jqxGrid #gridActualAssignmentPost
                [width]="'100%'"  
                [source]="dataAdapterActualAssignmentPost" 
                [columns]="columnsActualAssignmentPost"
                [pageable]="true" 
                [autoheight]="true" 
                [showfilterrow]="true"
                [columnsresize]="true"
                [filterable]="true"
                [sortable]="true"  
                [editable]="true" 
                [editmode]="'dblclick'"
                [selectionmode]="'singlerow'"
                (onCellclick)="onCellClickActualAssignmentPost($event)"
                [localization]="localization"
                [theme]="'energyblue'"
              ></jqxGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalSchoolHours" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">School Hours</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalSchoolHours = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-2">  
              <label for="school_hours" class="block text-sm font-medium text-gray-700">Hours</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="school_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_hours">
            </div>
            <div class="col-span-12 sm:col-span-2">  
              <label for="school_overtime" class="block text-sm font-medium text-gray-700">Overtime</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="school_overtime" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_overtime">
            </div>
            <div class="col-span-12 sm:col-span-8">  
              <label for="school_comments" class="block text-sm font-medium text-gray-700">Comments</label>
              <textarea rows="3" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_comments"></textarea>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editSchoolHours()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalApprovalHours" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Approval Hours</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalApprovalHours = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">  
              <label for="approval_hours" class="block text-sm font-medium text-gray-700">Hours</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="approval_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="approval_hours">
            </div>
            <div class="col-span-12 sm:col-span-6">  
              <label for="approval_overtime" class="block text-sm font-medium text-gray-700">Overtime</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="approval_overtime" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="approval_overtime">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editApprovalHours()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalRatesAssignment" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-9/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Assignment Rating</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalRatesAssignment = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">  
              <div class="relative overflow-x-auto" *ngIf="assignment_ratings && assignment_ratings.length > 0">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" *ngFor="let assignment_rating of assignment_ratings">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-center" style="width:30%;">
                        Educator
                      </th>
                      <th scope="col" class="px-6 py-3 text-center" style="width:10%;"></th>
                      <th scope="col" class="px-6 py-3 text-center" style="width:50%;">
                        Rate
                      </th>
                      <th scope="col" class="px-6 py-3 text-center" style="width:10%;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" colspan="2" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ assignment_rating.user }}
                      </th>
                      <td class="px-6 py-4">
                        <div class="grid grid-cols-10 gap-4">
                          <div class="col-span-2 sm:col-span-2">  
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" (click)="rateEducatorOnClick(assignment_rating.user_id, 1)">
                              <path class="{{ assignment_rating.rating > 0 ? 'fill-yellow-400 text-yellow-400' : '' }}" stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                          </div>
                          <div class="col-span-2 sm:col-span-2">  
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" (click)="rateEducatorOnClick(assignment_rating.user_id, 2)">
                              <path class="{{ assignment_rating.rating > 1 ? 'fill-yellow-400 text-yellow-400' : '' }}" stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                          </div>
                          <div class="col-span-2 sm:col-span-2">  
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" (click)="rateEducatorOnClick(assignment_rating.user_id, 3)">
                              <path class="{{ assignment_rating.rating > 2 ? 'fill-yellow-400 text-yellow-400' : '' }}" stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                          </div>
                          <div class="col-span-2 sm:col-span-2">  
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" (click)="rateEducatorOnClick(assignment_rating.user_id, 4)">
                              <path class="{{ assignment_rating.rating > 3 ? 'fill-yellow-400 text-yellow-400' : '' }}" stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                          </div>
                          <div class="col-span-2 sm:col-span-2">  
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" (click)="rateEducatorOnClick(assignment_rating.user_id, 5)">
                              <path class="{{ assignment_rating.rating > 4 ? 'fill-yellow-400 text-yellow-400' : '' }}" stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <button type="submit" (click)="registerRateOnClick(assignment_rating)" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus-visible:ring-sky-500" [disabled]="assignment_rating.disabled">SEND</button>
                      </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" *ngFor="let assignment_question of assignment_rating.questions">
                      <td class="px-6 py-4">
                        {{ assignment_question.question }}
                      </td> 
                      <td class="px-6 py-4">
                        <select (change)="rateEducatorOnFocus($event, assignment_rating.user_id, assignment_question.id)" [(ngModel)]="assignment_question.answer">
                          <option></option>
                          <option *ngFor="let answer of answers" [value]="answer.option">{{ answer.option }}</option>
                        </select>
                      </td>
                      <td colspan="2" class="px-6 py-4">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalMapGps" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-9/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">GPS Live</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="clearIntervalMap()" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">  
              <google-map 
                width="100%"
                height="350px"
                [center]="center"
                [zoom]="mapZoom"
                [options]="mapOptions">
                <map-marker #markerElem="mapMarker" *ngFor="let markerPosition of markerPositions"
                  [title]="markerPosition.title"
                  [position]="markerPosition.position"
                  [options]="markerPosition.options"
                  (mapClick)="openInfoWindow(markerElem, markerPosition.info)"
                ></map-marker>
                <map-info-window>{{ markerInfo.title }}</map-info-window>
              </google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalPDF" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-48 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-44">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Document</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalPDF = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2 grid grid-cols-12 gap-4">
            <div class="col-span-12">  
              <pdf-viewer 
                [src]="pdfSource"
                [original-size]="false"
                [zoom]="0.9"
                [zoom-scale]="'page-width'"
                style="width: 100%; height: 500px"
              ></pdf-viewer>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modalPDF = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative z-40 {{ modalHistory ? 'block' : 'hidden' }}" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-11/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Assignment History</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalHistory = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">
              <jqxGrid #gridAssignmentHistories
                [width]="'100%'" 
                [source]="dataAdapterAssignmentHistories" 
                [columns]="columnsAssignmentHistories"
                [pageable]="true" 
                [autoheight]="true" 
                [showfilterrow]="true"
                [columnsresize]="true"
                [filterable]="true"
                [sortable]="true"  
                [editable]="false" 
                [editmode]="'dblclick'"
                [selectionmode]="'singlerow'"
                [localization]="localization"
                [theme]="'energyblue'"
              ></jqxGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmDel" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true" style="Overflow: hidden">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" (click)="modalConfirmDel = false">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="mx-auto mb-4 text-orange-600 w-12 h-12 dark:text-orange-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"/>
          </svg>
          <p class="mb-4 text-gray-600 dark:text-gray-300">Please Confirm To Delete Assigment ID {{ delete_assignment_id }}</p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button" class="py-2 px-8 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" (click)="modalConfirmDel = false;">
              No, cancel
            </button>
            <button type="submit" class="py-2 px-8 text-sm font-medium text-center text-white bg-orange-600 rounded-lg hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-900" (click)="deleteAssignmentPostings(delete_assignment_id); modalConfirmDel = false;">
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmEdi" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true" style="Overflow: hidden">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" (click)="modalConfirmEdi = false">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="mx-auto mb-4 text-orange-600 w-12 h-12 dark:text-orange-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
          </svg>
          <p class="mb-4 text-gray-600 dark:text-gray-300">Please Confirm to Update & Save Assigment</p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button" class="py-2 px-8 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" (click)="modalConfirmEdi = false;">
              No, cancel
            </button>
            <button type="submit" class="py-2 px-8 text-sm font-medium text-center text-white bg-orange-600 rounded-lg hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-900" (click)="editAssignmentPostings(); modalConfirmEdi = false;">
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
