import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';    

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent {
  //ACCORDIONS 
  @ViewChild('accordionGeneral', { static: false }) accordionGeneral: ElementRef;
  @ViewChild('accordionAssignmentTypes', { static: false }) accordionAssignmentTypes: ElementRef;
  @ViewChild('accordionAgencyProfile', { static: false }) accordionAgencyProfile: ElementRef;

  //GRIDS
  //GENERALS
  @ViewChild('gridIdTypes', { static: false }) gridIdTypes: jqxGridComponent;
  @ViewChild('gridGenders', { static: false }) gridGenders: jqxGridComponent;
  @ViewChild('gridContactTypes', { static: false }) gridContactTypes: jqxGridComponent;
  @ViewChild('gridDocumentTypes', { static: false }) gridDocumentTypes: jqxGridComponent;
  //ASSIGNMENTS
  @ViewChild('gridAssignmentTypes', { static: false }) gridAssignmentTypes: jqxGridComponent;
  @ViewChild('gridSkills', { static: false }) gridSkills: jqxGridComponent;
  @ViewChild('gridHolidays', { static: false }) gridHolidays: jqxGridComponent;
  //AGENCY PROFILE
  @ViewChild('gridContacts', { static: false }) gridContacts: jqxGridComponent;
  @ViewChild('gridApplicationMessages', { static: false }) gridApplicationMessages: jqxGridComponent;
  @ViewChild('gridWBLists', { static: false }) gridWBLists: jqxGridComponent;

  //TOOLTIP
  @ViewChild('tooltipReference') myTooltip: jqxTooltipComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  // ID TYPES
  public id_type_name: string = "";

  // GENDERS
  public gender_name: string = "";

  // CONTACT TYPES
  public contact_type_name: string = "";

  // DOCUMENT TYPES
  public document_type_name: string = "";

  // ASSIGNMENT TYPES
  public assignment_type_name: string = "";

  // SKILLS
  public skill_name: string = "";

  // HOLIDAYS
  public holiday_name: string = "";
  public holiday_date: string = "";

  // AGENCY PROFILE
  public company_profile_id: number = 1001;
  public time_zone: string = "";
  public platform_color: string = "";
  public gps_range: number = 0;
  public entry_time_tolerance: number = 0;
  public cancel_educator_tolerance: number = 0;
  public cancel_school_tolerance: number = 0;
  public country_code: string = "";

  // CONTACTS
  public contact_name: string = "";
  public contact_email: string = "";
  public contact_mobile_phone: string = "";

  // APPLICATION MESSAGES
  public message_types: any;
  public appl_mess_type: string = "";
  public appl_mess_text: string = "";
  public appl_mess_link: string = "";

  // WB LISTS
  public educators: any;
  public wb_types: any;
  public wb_educator_id: number = 0;
  public wb_type_id: number = 0;

  
  // ID TYPES
  public id_types: any;
  public sourceIdTypes: any;
  public dataAdapterIdTypes: any = [];
  public cellHoverIdTypes: any = [];
  public columnsIdTypes: any;

  // GENDERS
  public genders: any;
  public sourceGenders: any;
  public dataAdapterGenders: any = [];
  public cellHoverGenders: any = [];
  public columnsGenders: any;

  // CONTACT TYPES
  public contact_types: any;
  public sourceContactTypes: any;
  public dataAdapterContactTypes: any = [];
  public cellHoverContactTypes: any = [];
  public columnsContactTypes: any;

  // DOCUMENT TYPES
  public document_types: any;
  public sourceDocumentTypes: any;
  public dataAdapterDocumentTypes: any = [];
  public cellHoverDocumentTypes: any = [];
  public columnsDocumentTypes: any;

  // ASSIGNMENT TYPES
  public assignment_types: any;
  public sourceAssignmentTypes: any;
  public dataAdapterAssignmentTypes: any = [];
  public cellHoverAssignmentTypes: any = [];
  public columnsAssignmentTypes: any;

  // SKILLS
  public skills: any;
  public sourceSkills: any;
  public dataAdapterSkills: any = [];
  public cellHoverSkills: any = [];
  public columnsSkills: any;

  // HOLIDAYS
  public holidays: any;
  public sourceHolidays: any;
  public dataAdapterHolidays: any = [];
  public cellHoverHolidays: any = [];
  public columnsHolidays: any;

  // CONTACTS
  public contacts: any;
  public sourceContacts: any;
  public dataAdapterContacts: any = [];
  public cellHoverContacts: any = [];
  public columnsContacts: any;

  // APPLICATION MESSAGES
  public application_messages: any;
  public sourceApplicationMessages: any;
  public dataAdapterApplicationMessages: any = [];
  public cellHoverApplicationMessages: any = [];
  public columnsApplicationMessages: any;

  // WB LISTS
  public wb_lists: any;
  public sourceWBLists: any;
  public dataAdapterWBLists: any = [];
  public cellHoverWBLists: any = [];
  public columnsWBLists: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('parameters');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
    
    this.contacts = new Array();
    this.application_messages = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataIdTypes();
      await this.loadDataGenders();
      await this.loadDataContactTypes();
      await this.loadDataDocumentTypes();
      await this.loadDataAssignmentTypes();
      await this.loadDataSkills();
      await this.loadDataHolidays();
      await this.uploadDataCompanyProfile();

      this.createGridIdTypes();
      this.createGridGenders();
      this.createGridContactTypes();
      this.createGridDocumentTypes();
      this.createGridAssignmentTypes();
      this.createGridSkills();
      this.createGridHolidays();
      // this.createGridContacts();
      this.createGridApplicationMessages();
      this.createGridWBLists();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataIdTypes() {
    await this.http.get(this.URL_BASE + 'type_identifications').subscribe(res => {
      this.id_types = res;

      this.sourceIdTypes.localdata = this.id_types;
      this.gridIdTypes.updatebounddata('cells');
    });
  }

  async loadDataGenders() {
    await this.http.get(this.URL_BASE + 'genders').subscribe(res => {
      this.genders = res;

      this.sourceGenders.localdata = this.genders;
      this.gridGenders.updatebounddata('cells');
    });
  }

  async loadDataContactTypes() {
    await this.http.get(this.URL_BASE + 'type_contacts').subscribe(res => {
      this.contact_types = res;

      this.sourceContactTypes.localdata = this.contact_types;
      this.gridContactTypes.updatebounddata('cells');
    });
  }

  async loadDataDocumentTypes() {
    await this.http.get(this.URL_BASE + 'type_documents').subscribe(res => {
      this.document_types = res;

      this.sourceDocumentTypes.localdata = this.document_types;
      this.gridDocumentTypes.updatebounddata('cells');
    });
  }

  async loadDataAssignmentTypes() {
    await this.http.get(this.URL_BASE + 'assignament_types').subscribe(res => {
      this.assignment_types = res;

      this.sourceAssignmentTypes.localdata = this.assignment_types;
      this.gridAssignmentTypes.updatebounddata('cells');
    });
  }

  async loadDataSkills() {
    await this.http.get(this.URL_BASE + 'skills').subscribe(res => {
      this.skills = res;

      this.sourceSkills.localdata = this.skills;
      this.gridSkills.updatebounddata('cells');
    });
  }

  async loadDataHolidays() {
    this.holiday_date = moment(new Date()).format("MM-DD-yyyy");

    await this.http.get(this.URL_BASE + 'holidays').subscribe(res => {
      this.holidays = res;

      this.sourceHolidays.localdata = this.holidays;
      this.gridHolidays.updatebounddata('cells');
    });
  }

  async uploadDataCompanyProfile() {
    this.message_types = new Array(
      { "id": 1, "name": "Text", "alias": "text" },
      { "id": 2, "name": "Link", "alias": "link" },
      { "id": 2, "name": "Link-Skills", "alias": "link-skill" }
    );

    await this.http.get(this.URL_BASE + 'company-profile/' + this.company_profile_id).subscribe((res: any) => {
      if(res) {
        this.time_zone = res.time_zone;
        this.platform_color = res.platform_color;
        this.gps_range = res.gps_range;
        this.entry_time_tolerance = res.entry_time_tolerance;
        this.cancel_educator_tolerance = res.cancel_educator_tolerance;
        this.cancel_school_tolerance = res.cancel_school_tolerance;
        this.country_code = res.country_code;

        let contacts = res.contacts;
        if(contacts != null) {
          this.contacts = contacts;
        }

        // this.sourceContacts.localdata = this.contacts;
        // this.gridContacts.updatebounddata('cells');

        let application_messages = res.application_messages;
        if(application_messages != null) {
          this.application_messages = application_messages;
        }

        this.sourceApplicationMessages.localdata = this.application_messages;
        this.gridApplicationMessages.updatebounddata('cells');
      }
    });

    await this.http.get(this.URL_BASE + 'user_profiles/filter-type/4').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.educators = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      }); 
    });

    this.wb_types = new Array(
      { "id": 1, "name": "Preferential" },
      { "id": 2, "name": "Restricted" }
    );

    this.http.get(this.URL_BASE + 'wb_lists/filter-user-profile/' + this.company_profile_id).subscribe(res => {
      this.wb_lists = res;

      this.sourceWBLists.localdata = this.wb_lists;
      this.gridWBLists.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  createIdTypeOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createIdType();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createGenderOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createGender();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createContactTypeOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createContactType();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createDocumentTypeOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createDocumentType();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createAssignmentTypeOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createAssignmentType();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createSkillOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createSkill();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createHolidayOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createHoliday();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createContactOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createContact();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  createApplicationMessageOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createApplicationMessage();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  editProfileOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.editCompanyProfile();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addWBListOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createWBList();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  tabOnClick(tab: number) {
    // setTimeout(() => {
    //   switch(tab) {
    //     case(1): {
    //       this.accordionGeneral.nativeElement.open = true;
    //       break;
    //     }
    //     case(2): {
    //       this.accordionAssignmentTypes.nativeElement.open = true;
    //       break;
    //     }
    //     case(3): {
    //       this.accordionAgencyProfile.nativeElement.open = true;
    //       break;
    //     }
    //   }
    // }, 300);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createIdType() {
    this.http
    .post(
      this.URL_BASE + 'type_identification', 
      { 
        name: this.id_type_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormIdType();
          this.loadDataIdTypes();
        } else {
          this.gridIdTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editIdType(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'type_identification/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataSkills();
        } else {
          this.gridIdTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createGender() {
    this.http
    .post(
      this.URL_BASE + 'gender', 
      { 
        name: this.gender_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormGender();
          this.loadDataGenders();
        } else {
          this.gridGenders.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editGender(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'gender/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGenders();
        } else {
          this.gridGenders.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createContactType() {
    this.http
    .post(
      this.URL_BASE + 'type_contact', 
      { 
        name: this.contact_type_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormContactType();
          this.loadDataContactTypes();
        } else {
          this.gridContactTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editContactType(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'type_contact/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataContactTypes();
        } else {
          this.gridContactTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createDocumentType() {
    this.http
    .post(
      this.URL_BASE + 'type_document', 
      { 
        name: this.document_type_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormDocumentType();
          this.loadDataDocumentTypes();
        } else {
          this.gridDocumentTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editDocumentType(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'type_document/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        amount: RowEdit.amount, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataDocumentTypes();
        } else {
          this.gridDocumentTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createAssignmentType() {
    this.http
    .post(
      this.URL_BASE + 'assignament_type', 
      { 
        name: this.assignment_type_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormAssignmentType();
          this.loadDataAssignmentTypes();
        } else {
          this.gridAssignmentTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editAssignmentType(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'assignament_type/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        lunch_time: RowEdit.lunch_time, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataAssignmentTypes();
        } else {
          this.gridAssignmentTypes.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createSkill() {
    this.http
    .post(
      this.URL_BASE + 'skill', 
      { 
        name: this.skill_name, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormSkill();
          this.loadDataSkills();
        } else {
          this.gridSkills.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editSkill(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'skill/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        link: RowEdit.link, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataSkills();
        } else {
          this.gridSkills.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createHoliday() {
    this.http
    .post(
      this.URL_BASE + 'holiday', 
      { 
        name: this.holiday_name, 
        date: moment(this.holiday_date).format("yyyy-MM-D"), 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormHoliday();
          this.loadDataHolidays();
        } else {
          this.gridHolidays.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editHoliday(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'holiday/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        date: moment(RowEdit.date).format("yyyy-MM-D"), 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataHolidays();
        } else {
          this.gridHolidays.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createContact() {
    if(this.contact_name === '') {
      let error = {
        message: "To register a contact you must enter the name",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.contact_email === '') {
      let error = {
        message: "To register a contact you must enter the email",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.contact_mobile_phone === '') {
      let error = {
        message: "To register a contact you must enter the mobile phone",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    var id = 1;
    if(this.contacts.length > 0) {
      id = (this.contacts[this.contacts.length-1].id + 1);
    }
    new_array = {
      id: id,
      name: this.contact_name,
      email: this.contact_email,
      mobile_phone: this.contact_mobile_phone
    };
    this.contacts.push(new_array);
    // this.sourceContacts.localdata = this.contacts;
    // this.gridContacts.updatebounddata('cells');

    this.editCompanyProfileContacts(this.dataAdapterContacts.records);
  }

  updateContact(rowdata: any) {
    this.contacts.forEach(function (item: any) {
      if(item.id == rowdata.id) {
        item.name = rowdata.name;
        item.email = rowdata.email;
        item.mobile_phone = rowdata.mobile_phone;
      }
    });

    // this.sourceContacts.localdata = this.contacts;
    // this.gridContacts.updatebounddata('cells');

    this.editCompanyProfileContacts(this.dataAdapterContacts.records);
  }

  deleteContact(contact_id: number) {
    var newArrayGrid = this.contacts.filter((item: any) => item.id !== contact_id);
    this.contacts = newArrayGrid;
    // this.sourceContacts.localdata = this.contacts;
    // this.gridContacts.updatebounddata('cells');

    this.editCompanyProfileContacts(this.dataAdapterContacts.records);
  }

  createApplicationMessage() {
    if(this.appl_mess_type === '') {
      let error = {
        message: "To register a application message you must enter the type",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.appl_mess_text === '') {
      let error = {
        message: "To register a application message you must enter the text",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.appl_mess_type && this.appl_mess_type == 'link' && this.appl_mess_link === '') {
      let error = {
        message: "To register a application message you must enter the link",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    var id = 1;
    if(this.application_messages.length > 0) {
      id = (this.application_messages[this.application_messages.length-1].id + 1);
    }
    new_array = {
      id: id,
      type: this.appl_mess_type,
      text: this.appl_mess_text,
      link: this.appl_mess_link
    };
    this.application_messages.push(new_array);
    this.sourceApplicationMessages.localdata = this.application_messages;
    this.gridApplicationMessages.updatebounddata('cells');

    this.editCompanyProfileApplicationMessage(this.dataAdapterApplicationMessages.records);
  }

  updateApplicationMessage(rowdata: any) {
    this.application_messages.forEach(function (item: any) {
      if(item.id == rowdata.id) {
        item.type = rowdata.type;
        item.text = rowdata.text;
        item.link = rowdata.link;
      }
    });

    this.sourceApplicationMessages.localdata = this.application_messages;
    this.gridApplicationMessages.updatebounddata('cells');

    this.editCompanyProfileApplicationMessage(this.dataAdapterApplicationMessages.records);
  }

  deleteApplicationMessage(application_message_id: number) {
    var newArrayGrid = this.application_messages.filter((item: any) => item.id !== application_message_id);
    this.application_messages = newArrayGrid;
    this.sourceApplicationMessages.localdata = this.application_messages;
    this.gridApplicationMessages.updatebounddata('cells');

    this.editCompanyProfileApplicationMessage(this.dataAdapterApplicationMessages.records);
  }

  editCompanyProfileContacts(contacts: any) {
    this.http
    .put(
      this.URL_BASE + 'company-profile/update-contacts/' + this.company_profile_id, 
      {
        contacts: contacts,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormContacts();
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editCompanyProfileApplicationMessage(application_messages: any) {
    this.http
    .put(
      this.URL_BASE + 'company-profile/update-application-messages/' + this.company_profile_id, 
      {
        application_messages: application_messages,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormApplicationMessages();
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editCompanyProfile() {
    this.http
    .put(
      this.URL_BASE + 'company-profile/update-parameters/' + this.company_profile_id, 
      {
        time_zone: this.time_zone,
        platform_color: this.platform_color,
        gps_range: this.gps_range,
        entry_time_tolerance: this.entry_time_tolerance,
        cancel_educator_tolerance: this.cancel_educator_tolerance,
        cancel_school_tolerance: this.cancel_school_tolerance,
        country_code: this.country_code,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  createWBList() {
    if(this.wb_educator_id == 0) {
      let error = {
        message: "Select an educator",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.wb_type_id == 0) {
      let error = {
        message: "Select type",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'wb_list', 
      { 
        school_id: this.company_profile_id, 
        educator_id: this.wb_educator_id, 
        type: this.wb_type_id, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormWBLists();
          this.uploadDataCompanyProfile();
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  updateWBList(rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'wb_list/' + rowdata.id, 
      {
        school_id: rowdata.school_id, 
        educator_id: rowdata.educator_id, 
        type: rowdata.type, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);

        if(res.type == "green") {
          this.uploadDataCompanyProfile();
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteWBList(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'wb_list/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "orange") {
          this.uploadDataCompanyProfile();
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearFormIdType() {
    this.id_type_name = "";
  }

  clearFormGender() {
    this.gender_name = "";
  }

  clearFormContactType() {
    this.contact_type_name = "";
  }

  clearFormDocumentType() {
    this.document_type_name = "";
  }

  clearFormAssignmentType() {
    this.assignment_type_name = "";
  }

  clearFormSkill() {
    this.skill_name = "";
  }

  clearFormHoliday() {
    this.holiday_name = "";
    this.holiday_date = moment(new Date()).format("MM-DD-yyyy");
  }

  clearFormContacts() {
    this.contact_name = "";
    this.contact_email = "";
    this.contact_mobile_phone = "";
  }

  clearFormApplicationMessages() {
    this.appl_mess_type = "";
    this.appl_mess_text = "";
    this.appl_mess_link = "";
  }

  clearFormWBLists() {
    this.wb_educator_id = 0;
    this.wb_type_id = 0;
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridIdTypes() {
    this.sourceIdTypes =
    {
      localdata: this.id_types,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editIdType(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterIdTypes = new jqx.dataAdapter(this.sourceIdTypes, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverIdTypes = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridIdTypes.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridIdTypes.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Type</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsIdTypes =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '100%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickIdTypes(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridGenders() {
    this.sourceGenders =
    {
      localdata: this.genders,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editGender(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterGenders = new jqx.dataAdapter(this.sourceGenders, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverGenders = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridGenders.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridGenders.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Gender</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsGenders =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '100%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickGenders(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridContactTypes() {
    this.sourceContactTypes =
    {
      localdata: this.contact_types,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editContactType(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterContactTypes = new jqx.dataAdapter(this.sourceContactTypes, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverContactTypes = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridContactTypes.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridContactTypes.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Contact Type</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsContactTypes =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '100%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickContactTypes(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridDocumentTypes() {
    this.sourceDocumentTypes =
    {
      localdata: this.document_types,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'amount', type: 'integer' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editDocumentType(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterDocumentTypes = new jqx.dataAdapter(this.sourceDocumentTypes, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverDocumentTypes = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridDocumentTypes.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridDocumentTypes.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Document Type</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsDocumentTypes =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '90%' 
      },
      { 
        text: 'Amount',  
        datafield: 'amount',
        displayfield: 'amount',
        editable: true,
        width: '10%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickDocumentTypes(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridAssignmentTypes() {
    this.sourceAssignmentTypes =
    {
      localdata: this.assignment_types,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'lunch_time', type: 'boolean' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editAssignmentType(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterAssignmentTypes = new jqx.dataAdapter(this.sourceAssignmentTypes, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverAssignmentTypes = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridAssignmentTypes.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridAssignmentTypes.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Assignment Type</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsAssignmentTypes =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '90%' 
      },
      { 
        text: 'Lunch Time',  
        datafield: 'lunch_time',
        displayfield: 'lunch_time',
        columntype: 'checkbox',
        filtertype: 'boolean',
        editable: true,
        width: '10%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickAssignmentTypes(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridSkills() {
    this.sourceSkills =
    {
      localdata: this.skills,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'link', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editSkill(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterSkills = new jqx.dataAdapter(this.sourceSkills, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverSkills = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridSkills.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridSkills.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Skill</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsSkills =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '80%' 
      },
      { 
        text: 'link ',  
        datafield: 'link',
        displayfield: 'link',
        editable: true,
        width: '20%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickSkills(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridHolidays() {
    this.sourceHolidays =
    {
      localdata: this.holidays,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'date', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editHoliday(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterHolidays = new jqx.dataAdapter(this.sourceHolidays, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverHolidays = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridHolidays.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridHolidays.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Holiday</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsHolidays =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '80%' 
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: true,
        width: '20%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickHolidays(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridContacts() {
    this.sourceContacts =
    {
      localdata: this.contacts,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'mobile_phone', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateContact(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterContacts = new jqx.dataAdapter(this.sourceContacts, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverContacts = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridContacts.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridContacts.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Contact</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsContacts =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '36%' 
      },
      {
        text: 'Email',  
        datafield: 'email',
        displayfield: 'email',
        editable: true,
        width: '35%' 
      },
      {
        text: 'Mobile Phone',  
        datafield: 'mobile_phone',
        displayfield: 'mobile_phone',
        editable: true,
        width: '25%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickContacts(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteContact(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridApplicationMessages() {
    this.sourceApplicationMessages =
    {
      localdata: this.application_messages,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'type', type: 'string' },
        { name: 'text', type: 'string' },
        { name: 'link', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateApplicationMessage(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterApplicationMessages = new jqx.dataAdapter(this.sourceApplicationMessages, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverApplicationMessages = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridApplicationMessages.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridApplicationMessages.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Assignment Chat Communication</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsApplicationMessages =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Type',  
        datafield: 'type',
        displayfield: 'type',
        editable: false,
        width: '20%' 
      },
      {
        text: 'Text',  
        datafield: 'text',
        displayfield: 'text',
        editable: true,
        width: '40%' 
      },
      {
        text: 'Link',  
        datafield: 'link',
        displayfield: 'link',
        editable: true,
        width: '36%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickApplicationMessages(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteApplicationMessage(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridWBLists() {
    this.sourceWBLists =
    {
      localdata: this.contacts,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'school_id', type: 'int' },
        { name: 'educator_id', type: 'int' },
        { name: 'type', type: 'int' },
        { name: 'school', type: 'string' },
        { name: 'school_first_name', type: 'string' },
        { name: 'school_second_name', type: 'string' },
        { name: 'school_last_name', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' },
        { name: 'type_name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateWBList(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterWBLists = new jqx.dataAdapter(this.sourceWBLists, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.school_first_name && fila.school_second_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_second_name + " " + fila.school_last_name;
          }
          if(fila.school_first_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_last_name;
          }

          if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
          }
          if(fila.educator_first_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_last_name;
          }

          let wb_types = new Array(
            { "id": 1, "name": "Preferential" },
            { "id": 2, "name": "Restricted" }
          );

          if(fila.type) {
            if(wb_types) {
              let type = wb_types.find((element: any) => element.id == fila.type);
              if(type) {
                fila.type_name = type.name;
              }
            }
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverWBLists = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridWBLists.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridWBLists.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Agency Preferential or Restricted Educator</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsWBLists =
    [
      {
        text: '',
        datafield: 'Del',
        width: '6%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Educator',  
        datafield: 'educator_id',
        displayfield: 'educator',
        editable: false,
        width: '74%' 
      },
      {
        text: 'Type',  
        datafield: 'type',
        displayfield: 'type_name',
        editable: true,
        width: '20%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.wb_types,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickWBLists(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteWBList(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
