<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: outline/check-circle -->
            <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
            <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: mini/x-mark -->
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>   

<div class="py-6 px-4 sm:p-6 lg:pb-8">
  <div>
    <h2 class="text-lg font-medium leading-6 text-gray-900">{{ name_posting }}</h2>
    <p class="mt-1 text-sm text-gray-500">{{ description_posting }}</p>
  </div>

  <div class="mt-6" *ngIf="postings && postings.length > 0">
    <div class="grid grid-cols-12 mb-5 border-solid border-2 border-orange-100 py-6 px-6" *ngFor="let posting of postings">
    	<div class="col-span-12 sm:col-span-4">
        <div class="relative w-full px-3">
          <img src="{{ posting.image }}" alt="Img-posting" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]">
        </div>
      </div> 
    	<div class="col-span-12 sm:col-span-8">
        <div>
          <div class="group relative mb-3">
            <h3 class="mt-2 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <span class="absolute inset-0"></span>
              <div class="uppercase">
              	<b>{{ posting.first_name }} {{ posting.second_name }} {{ posting.first_last_name }} {{ posting.second_last_name }}: </b> {{ posting.assignament_type }}
	              <a class="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100">
			            {{ posting.status }}
			          </a>
              </div>
            </h3>
            <p class="mt-3 text-sm leading-6 text-gray-600 line-clamp-3 text-justify">
            	{{ posting.note_section }}
            </p>
            <div class="mt-3 flex gap-x-4 text-xs leading-6 text-gray-500">
            	<p><b>SKILL: </b>{{ posting.skill }}</p>
            	<p><b>SKILL OTHER: </b>{{ posting.skill_other }}</p>
            	<p><b>CHECK IN LOCATION: </b>{{ posting.check_in_location }}</p>
            	<p><b>CHECK IN CONTACT: </b>{{ posting.check_in_contact }}</p>
            </div>
          </div>
          <div class="flex items-center gap-x-4 text-xs">
            <time datetime="2020-03-16" class="text-gray-500"><b>START DATE: </b>{{ posting.start_date }}</time>
            <time datetime="2020-03-16" class="text-gray-500"><b>END DATE: </b>{{ posting.end_date }}</time>
            <time datetime="2020-03-16" class="text-gray-500"><b>START TIME: </b>{{ posting.job_start_time }}</time>
            <time datetime="2020-03-16" class="text-gray-500"><b>END TIME: </b>{{ posting.job_end_time }}</time>
          </div>
          <div class="items-center gap-x-4 text-xs text-right">
            <a href="{{ posting.site_name }}">{{ posting.site_name }}</a>
          </div>
        </div>
    	</div>
    </div>
  </div>
</div>