import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { GmapsService } from '../services/gmaps.service';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

@Component({
  selector: 'app-assignment-map',
  templateUrl: './assignment-map.component.html',
  styleUrls: ['./assignment-map.component.css']
})
export class AssignmentMapComponent {
  //COMBOBOX
  @ViewChild('comboboxStatus', { static: false }) comboboxStatus: jqxComboBoxComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public statuses: any;
  public assignments: any;

  public start_date: string = "";
  public end_date: string = "";
  public min_end_date: any = "";
  public status_id: number = 0;

  // Configuración de Google Maps 
  public googleMaps: any;
  public map: any;
  public locationMap = {lat: 33.793529328327665, lng: -84.36022593369294};
  public mapZoom = 10;
  public markers = new Array();
  public markerClustererImagePath = '/assets/wpsl-cluster-markers/m';
  public setIntervalID: number = 0;

  public show_educators: boolean = false;
  public refresh_map: boolean = false;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private gmaps: GmapsService,
    private renderer: Renderer2
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('assignment-map');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataAssignmentStatuses();
      await this.loadMap();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.start_date = moment(new Date()).format("MM-DD-yyyy");
    var end_date = new Date();
    end_date.setDate(end_date.getDate());
    this.end_date = moment(end_date).format("MM-DD-yyyy");
    this.min_end_date = moment(new Date()).format("MM-DD-yyyy");
  }
    
  async loadDataAssignmentStatuses() {
    this.http.get(this.URL_BASE + 'assignment_posting_statuses').subscribe(res => {
      this.statuses = res; 

      setTimeout(() => {
        this.comboboxStatus.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataAssignmentStatuses();
      }
    });
  }
    
  async loadDataAssignmentFilter() {
    var date_from = moment(this.start_date).format("yyyy-MM-DD");
    var date_to = moment(this.end_date).format("yyyy-MM-DD");
    var status_id = 0;

    if(this.status_id) {
      status_id = this.status_id;
    }

    this.http.get(this.URL_BASE + 'assignment_posting/filter-map/' + date_from + '/' + date_to + '/' + status_id).subscribe(res => {
      this.assignments = res; 
      
      this.loadMap(); 

      setTimeout(() => {
        this.addMarkers(this.assignments, this.show_educators);
      }, 800);
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataAssignmentStatuses();
      }
    });
  }

  // MAPS
  async loadMap() {
    try {
      this.googleMaps = await this.gmaps.loadGoogleMaps();

      const mapEl = document.getElementById("map") as HTMLElement;
      this.map = new this.googleMaps.Map(mapEl, {
        center: this.locationMap,
        zoom: this.mapZoom,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        maxZoom: 16,
        minZoom: 3,
      });

      this.renderer.addClass(mapEl, 'visible');
    } catch(e) {
      console.log(e);
    }
  }

  async addMarkers(elements: any, educators: boolean = false) {
    const map = this.map;
    const infoWindow = new this.googleMaps.InfoWindow();
    this.markers = new Array();

    if(elements && elements.length > 0) {
      elements.forEach((element: any) =>  {
        var lat = "";
        var lng = "";
        var educator = "";
        const assignment_markers: any = new Array();

        if(educators == true) {
          if(element.locate) {
            lat = element.locate.latitude;
            lng = element.locate.longitude;
          }
          assignment_markers.push({
            marker_educator: 1,
            lat: lat,
            lng: lng
          });
        } 

        if(element.maps) {
          assignment_markers.push({
            marker_educator: 0,
            lat: element.maps.position.lat,
            lng: element.maps.position.lng
          });
        }

        if(element.user_id > 0) {
          if(element.edu_first_name && element.edu_second_name && element.edu_last_name) {
            educator = element.edu_first_name + " " + element.edu_second_name + " " + element.edu_last_name;
          } else if(element.edu_first_name && element.edu_last_name) {
            educator = element.edu_first_name + " " + element.edu_last_name;
          } else {
            educator = element.edu_first_name;
          }
        }

        if(assignment_markers && assignment_markers.length > 0) {
          assignment_markers.forEach((assignment_marker: any) => {
            if(assignment_marker.lat && assignment_marker.lng) {
              var title_marker = 'Marker School ' + element.business_name;
              var url_img = '/assets/img/markers/school.png';
              if(assignment_marker.marker_educator == 1) {
                title_marker = 'Marker Educator ' + educator;
                url_img = '/assets/img/markers/person.png';
              }

              const imageMarker = {
                url: url_img,
                // This marker is 20 pixels wide by 32 pixels high.
                // size: new this.googleMaps.Size(100, 120),
                // The origin for this image is (0, 0).
                origin: new this.googleMaps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new this.googleMaps.Point(0, 32),
              };

              const marker = new this.googleMaps.Marker({
                map: map,
                position: {
                  lat: assignment_marker.lat,
                  lng: assignment_marker.lng,
                },
                icon: imageMarker,
                title: title_marker,
                info: {
                  title: title_marker,
                  url_img: url_img,
                  assignment_id: element.id,
                  school: element.business_name,
                  educator: educator,
                  start_date: element.start_date,
                  end_date: element.end_date,
                  status: element.status
                },
                draggable: false,
                animation: this.googleMaps.Animation.DROBOUNCEP,
                optimized: true
              });


              marker.addListener("click", () => {
                var content = '<div class="relative overflow-x-auto">'
                            + '<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">'
                            + '<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">'
                            + '<tr>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;"></th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Assignment</th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:25%;">School</th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:25%;">Educator</th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Start Date</th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">End Date</th>'
                            + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Status</th>'
                            + '</tr>'
                            + '</thead>'
                            + '<tbody>'
                            + '<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center">'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><img src="' + marker.info.url_img + '" alt="Icono"></td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + marker.info.assignment_id + '</td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + marker.info.school + '</td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + marker.info.educator + '</td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + moment(marker.info.start_date).format("MM-DD-yyyy") + '</td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + moment(marker.info.end_date).format("MM-DD-yyyy") + '</td>'
                            + '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + marker.info.status + '</td>'
                            + '</tr>'
                            + '</tbody>'
                            + '</table>'
                            + '</div>';
                // if (marker.getAnimation() !== null) {
                //   marker.setAnimation(null);
                // } else {
                //   marker.setAnimation(this.googleMaps.Animation.BOUNCE);
                // }
                infoWindow.close();
                infoWindow.setContent(content);
                infoWindow.open(marker.getMap(), marker);
              });

              this.markers.push(marker);
            }
          });
        }
      });
    }

    setTimeout(() => {
      const markers = this.markers;

      let renderer = {
        render: ({ count, position }: any) => 
          new this.googleMaps.Marker({
            label: {
              text: String(count),
              color: "white",
            },
            position,
            icon: {
              url: '/assets/img/cluster-markers/m1.png'            
            },
            zIndex: Number(this.googleMaps.Marker.MAX_ZINDEX) + count
          })
      };

      const markerCluster = new MarkerClusterer({ map, markers });

      markerCluster.addListener("click", (cluster: any) => {
        const markers = cluster.markers;
        var content = '<div class="relative overflow-x-auto">'
                    + '<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">'
                    + '<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">'
                    + '<tr>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;"></th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Assignment</th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:25%;">School</th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:25%;">Educator</th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Start Date</th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">End Date</th>'
                    + '<th scope="col" class="px-6 py-3 text-center" style="width:10%;">Status</th>'
                    + '</tr>'
                    + '</thead>'
                    + '<tbody>';
        markers.forEach((element: any) => {
          content += '<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center">'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><img src="' + element.info.url_img + '" alt="Icono"></td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + element.info.assignment_id + '</td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + element.info.school + '</td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + element.info.educator + '</td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + moment(element.info.start_date).format("MM-DD-yyyy") + '</td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + moment(element.info.end_date).format("MM-DD-yyyy") + '</td>'
                  +  '<td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">' + element.info.status + '</td>'
                  +  '</tr>';
        })
        content += '</tbody>'
                +  '</table>'
                +  '</div>';

        setTimeout(() => {
          infoWindow.close();
          infoWindow.setContent(content);
          infoWindow.open(cluster.marker.getMap(), cluster.marker);
        }, 500);
      }); 
    }, 1000)
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  filterOnClick() {
    this.loadDataAssignmentFilter();
  }

  cleanOnClick() {
    this.clearForm();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  startDateOnChange(event: any): void {
    if(event.target) {
      var end_date = event.target.value;

      if(end_date) {
        this.min_end_date = end_date;
      }
    }
  }

  educatorOnChange(value: boolean): void {
    if(this.assignments && this.assignments.length > 0) {
      this.loadMap(); 
    }

    setTimeout(() => {
      this.addMarkers(this.assignments, value);
    }, 800);
  }

  refreshMapOnChange(value: boolean): void {
    if(value == true) {
      this.setIntervalID = setInterval(() => {
        this.loadDataAssignmentFilter();
      }, 120000);
    } else {
      clearInterval(this.setIntervalID);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.start_date = moment(new Date()).format("MM-D-yyyy");
    this.end_date = moment(new Date()).format("MM-D-yyyy");
    this.comboboxStatus.selectIndex(0);
    clearInterval(this.setIntervalID);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    const timeoutId = setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
