import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../_services/auth.service';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private URL_BASE: string;
  form: any = {
    email: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';

  public company_profile: any;
  public logo: string = "";

  constructor(
    private configuration: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, 
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/home");
    }
    this.uploadDataProfileCompany();
  }

  onSubmit(): void {
    const { email, password } = this.form;

    this.authService.login(email, password).subscribe({
      next: (data: any) => {
        if(data.change_password) {
          this.router.navigateByUrl("/change-password/" + data.user_id);
        } else {
          this.tokenStorage.saveToken(data.access_token);

          if(data.menus && data.menus.length > 0) {
            data.menus.forEach((element: any) => {
              if(element.submenus && element.submenus.length > 0) {
                element.submenus.sort(function (a: any, b: any) {
                  if (a.order < b.order)
                    return -1;
                  else if (a.order > b.order)
                    return 1;
                  else 
                    return 0;
                });
              }
            });
          }

          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;

          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          console.log(returnUrl);
          if(returnUrl != "/") {
            window.open(returnUrl, "_self");
          } else {
            window.location.reload();
          }
        }
      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  uploadDataProfileCompany(): void {
    this.http.get(this.URL_BASE + 'company-profile/data/' + 1001).subscribe(res => {
      if(res) {
        this.company_profile = res;
        this.logo = this.company_profile.logo;
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }
}
