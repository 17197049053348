import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';           
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  //GRIDS
  @ViewChild('gridContacts', { static: false }) gridContacts: jqxGridComponent;
  @ViewChild('gridWBLists', { static: false }) gridWBLists: jqxGridComponent;
  @ViewChild('gridDocuments', { static: false }) gridDocuments: jqxGridComponent;
  @ViewChild('gridUserProfile', { static: false }) gridUserProfile: jqxGridComponent;
  @ViewChild('gridSpecificHolidays', { static: false }) gridSpecificHolidays: jqxGridComponent;
  @ViewChild('gridNotWorkingDates', { static: false }) gridNotWorkingDates: jqxGridComponent;
  @ViewChild('gridAllowList', { static: false }) gridAllowList: jqxGridComponent;
  @ViewChild('gridAllowListEducators', { static: false }) gridAllowListEducators: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxTypeUserProfile', { static: false }) comboboxTypeUserProfile: jqxComboBoxComponent;
  @ViewChild('comboboxRol', { static: false }) comboboxRol: jqxComboBoxComponent;
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxTypeIdentification', { static: false }) comboboxTypeIdentification: jqxComboBoxComponent;
  @ViewChild('comboboxGender', { static: false }) comboboxGender: jqxComboBoxComponent;
  @ViewChild('comboboxCountry', { static: false }) comboboxCountry: jqxComboBoxComponent;
  // @ViewChild('comboboxState', { static: false }) comboboxState: jqxComboBoxComponent;
  @ViewChild('comboboxCounty', { static: false }) comboboxCounty: jqxComboBoxComponent;
  @ViewChild('comboboxCity', { static: false }) comboboxCity: jqxComboBoxComponent;
  @ViewChild('comboboxZipCode', { static: false }) comboboxZipCode: jqxComboBoxComponent;
  @ViewChild('comboboxPayRate', { static: false }) comboboxPayRate: jqxComboBoxComponent;
  @ViewChild('comboboxParent', { static: false }) comboboxParent: jqxComboBoxComponent;
  @ViewChild('comboboxLevelTemp', { static: false }) comboboxLevelTemp: jqxComboBoxComponent;
  @ViewChild('comboboxExperienceTemp', { static: false }) comboboxExperienceTemp: jqxComboBoxComponent;
  @ViewChild('comboboxLevel', { static: false }) comboboxLevel: jqxComboBoxComponent;
  @ViewChild('comboboxExperience', { static: false }) comboboxExperience: jqxComboBoxComponent;
  @ViewChild('comboboxPermanentState', { static: false }) comboboxPermanentState: jqxComboBoxComponent;
  @ViewChild('comboboxPermanentCity', { static: false }) comboboxPermanentCity: jqxComboBoxComponent;
  @ViewChild('comboboxPermanentZipCode', { static: false }) comboboxPermanentZipCode: jqxComboBoxComponent;
  @ViewChild('comboboxWBType', { static: false }) comboboxWBType: jqxComboBoxComponent;

  //CHECKBOX
  @ViewChild('checkSaturdayWork') checkSaturdayWork: jqxCheckBoxComponent; 
  @ViewChild('checkSundayWork') checkSundayWork: jqxCheckBoxComponent; 

  //TOOLTIP
  @ViewChild('tooltipReference') myTooltip: jqxTooltipComponent;

  //GOOGLE MAPS
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow

  private URL_BASE: string;
  public alerts: any;
  public arr_time_outs: any;
  public permissions: any;
  public user_id: any;
  public user_type: any;
  public user_profile_id: any;

  public type_user_profiles: any;
  public type_identifications: any;
  public roles: any;
  public countries: any;
  public states: any;
  public counties: any;
  public cities: any;
  public zip_codes: any;
  public permanent_states: any;
  public permanent_cities: any;
  public permanent_zip_codes: any;
  public genders: any;
  public user_profile: any;
  public images_user_profile: any;
  public type_documents: any;
  public educator_pay_rates: any;
  public levels: any;
  public experiences: any;
  public statuses: any;
  public type_contacts: any;
  public all_educators: any;
  public educators: any;
  public schools: any;
  public wb_types: any;
  public parent_user_profiles: any;
  public organizations: any;
  public ranking_educators: any;

  // User
  public type_user_profile_id: number = 0;
  public photo: any;
  public status_id: number = 0;
  public status: string = "";
  public level: string = "";
  public first_name: string = "";
  public second_name: string = "";
  public last_name: string = "";
  public nickname: string = "";
  public type_identification_id: number = 0;
  public identification: any = 0;
  public mask_identification: any = {mask:'00-0000000', type:'num', len:10, userCaracters: true};
  public email: string = "";
  public hire_date: string = "";
  public birth_date: string = "";
  public country_id: number = 0;
  public state_id: number = 0;
  public county_id: number = 0;
  public city_id: any;
  public zip_code: string = "";
  public gender_id: number = 0;
  public address: string = "";
  public permanent_state_id: number = 0;
  public permanent_city_id: any;
  public permanent_address: string = "";
  public permanent_zip_code: string = "";
  public phone: string = "";
  public cell_phone: string = "";
  public business_name: string = "";
  public observation: string = "";
  public direction_radius: number = 0;
  public rol_id: number = 0;
  public level_temp_id: number = 0;
  public experience_temp_id: number = 0;
  public level_id: number = 0;
  public experience_id: number = 0;
  public teacher_about: string = "";
  public pay_rate_id: number = 0;
  public parent_id: number = 0;
  public organization_id: number = 0;
  // Images 
  public user_id_change_images: number = 0;
  public img_1: any;
  public img_2: any;
  public img_3: any;
  //Contacts
  public contact_name: string = "";
  public contact_email: string = "";
  public contact_mobile_phone: string = "";
  public contact_type_contact_id: number = 0;
  //Documents
  public delete_document_id: number = 0;
  public document_type_id: number = 0;
  public document_validity_date: any;
  public document_status_id: number = 0;
  public document_data: any;
  public document: any;
  public document_name: any = "";
  public pdfSource: string =  "";
  //WB Lists
  public wb_educator_id: number = 0;
  public wb_type_id: number = 0;
  //Specific Holidays
  public holiday_date: string =  "";
  //Not Working Dates
  public not_working_start_date: string =  "";
  public not_working_end_date: string =  "";
  //Allow List
  public allow_school_id: number = 0;
  public allow_educator_id: number = 0;
  public allow_type: string = "";
  //School Cancellation
  public observation_cancel_activate: string =  "";
  //Tooltip
  public message_tooltip: string =  "";

  // CHANGE PASSWORD
  public new_password: string = "";
  public new_password_confirm: string = "";

  //MODALS 
  public modalImages: boolean = false;
  public modalPDF: boolean = false;
  public modalConfirmDeleteDocument: boolean = false;
  public modalPassword: boolean = false;
  public modalTooltip: boolean = false;

  // GRIDS
  //Contacts
  public contacts: any;
  public sourceContacts: any;
  public dataAdapterContacts: any = [];
  public cellHoverContacts: any = [];
  public columnsContacts: any;
  //WB Lists
  public wb_lists: any;
  public sourceWBLists: any;
  public dataAdapterWBLists: any = [];
  public cellHoverWBLists: any = [];
  public columnsWBLists: any;
  //Documents
  public documents: any;
  public sourceDocuments: any;
  public dataAdapterDocuments: any = [];
  public cellHoverDocuments: any = [];
  public columnsDocuments: any;
  //Specific Holidays
  public specific_holidays: any;
  public sourceSpecificHolidays: any;
  public dataAdapterSpecificHolidays: any = [];
  public cellHoverSpecificHolidays: any = [];
  public columnsSpecificHolidays: any;
  //Not Working Dates
  public not_working_dates: any;
  public sourceNotWorkingDates: any;
  public dataAdapterNotWorkingDates: any = [];
  public cellHoverNotWorkingDates: any = [];
  public columnsNotWorkingDates: any;
  //Allow List
  public allow_list: any;
  public sourceAllowList: any;
  public dataAdapterAllowList: any = [];
  public cellHoverAllowList: any = [];
  public columnsAllowList: any;
  //Allow List Educators to School
  public allow_list_educators: any;
  public sourceAllowListEducators: any;
  public dataAdapterAllowListEducators: any = [];
  public cellHoverAllowListEducators: any = [];
  public columnsAllowListEducators: any;

  public localization: any;
 
  // Configuración de Google Maps 
  public center = {lat: 32.770625, lng: -83.315218};
  public mapZoom = 10;
  public display?: google.maps.LatLngLiteral;
  public mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 1,
  };
  public geocoder: google.maps.Geocoder;
  public markerPositions: any = [];
  public markerInfo: any = [];
  public markerMap: any = [];

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private routeActive: ActivatedRoute
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('user-profile');
    this.user_id = this.tokenStorage.getUser();
    this.user_profile_id = this.tokenStorage.getUserProfile();
    this.user_type = this.tokenStorage.getUserType();

    this.alerts = new Array();
    this.arr_time_outs = new Array();
    
    this.contacts = new Array();
    this.specific_holidays = new Array();
    this.not_working_dates = new Array();
    this.allow_list = new Array();
    this.allow_list_educators = new Array();
    this.documents = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadTypeIdentifications();
      await this.loadStates();
      await this.loadCities();
      await this.loadGenders();
      await this.loadTypeDocuments();
      await this.loadEducatorLevels();
      await this.loadLevels();
      await this.loadExperiences();
      await this.loadStatuses();
      await this.loadTypeContacts();
      await this.loadWbTypes();
      await this.loadTypeUserProfiles();
      await this.loadDataUserProfiles();
      await this.loadDataOrganizations();
      await this.loadDataUserProfiles();

      this.createGridContacts();
      this.createGridWBLists();
      this.createGridDocuments();
      this.createGridSpecificHolidays();
      this.createGridNotWorkingDates();
      this.createGridAllowList();
      this.createGridAllowListEducators();

      setTimeout(() => {
        this.loadDataUserProfile(this.user_profile_id);
      }, 1000);
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  geocode(request: google.maps.GeocoderRequest): void {
    // clear();

    this.geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        const latLng = JSON.stringify(results[0].geometry, null, 2);
        const location = JSON.parse(latLng).location;

        // console.log(results[0].geometry);
        // console.log(location);

        this.markerMap = {
          position: {
            lat: location.lat,
            lng: location.lng,
          },
          label: {
            color: 'red',
            text: 'New Marker School',
          },
          title: 'New Marker School',
          info: {
            title: 'New Marker School',
          },
          options: { 
            draggable: false,
            animation: google.maps.Animation.DROP,
          },
        }

        console.log(this.markerMap);

        setTimeout(() => {
          this.markerPositions.push(this.markerMap);
        }, 800)

        console.log(this.markerPositions);        

        // map.setCenter(results[0].geometry.location);
        // marker.setPosition(results[0].geometry.location);
        // marker.setMap(map);
        // responseDiv.style.display = "block";
        // response.innerText = JSON.stringify(result, null, 2);
        // return this.markerMap;
      })
      .catch((e) => {
        alert("Geocode was not successful for the following reason: " + e);
      });
  }

  async loadDataForm() {
    this.birth_date = moment(new Date()).format("MM-DD-yyyy");
    this.hire_date = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadTypeIdentifications() {
    await this.http.get(this.URL_BASE + 'type_identifications').subscribe((res: any) => {
      this.type_identifications = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadTypeIdentifications();
      }
    });
  }

  async loadCountries() {
    // await this.http.get(this.URL_BASE + 'countries').subscribe((res: any) => {
    //   this.countries = res;

    //   setTimeout(() => {
    //     this.comboboxUserCountry.selectIndex(64);
    //     this.comboboxCountry.selectIndex(64);
    //   }, 200);  
    // }, (error: any) => {
    //   if(error && error.statusText && error.statusText == "Unknown Error") {
    //     this.loadCountries();
    //   }
    // });
  }

  async loadStates() {
    await this.http.get(this.URL_BASE + 'states').subscribe((res: any) => {
      this.permanent_states = res;

      setTimeout(() => {
        this.comboboxPermanentState.selectIndex(11);
      }, 500);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadStates();
      }
    });
  }

  async loadCities() {
    await this.http.get(this.URL_BASE + 'cities/filter-state/10').subscribe((res: any) => {
      this.cities = res;

      // setTimeout(() => {
      //   this.comboboxCity.selectIndex(0);
      // }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadCities();
      }
    });
  }

  async loadGenders() {
    await this.http.get(this.URL_BASE + 'genders').subscribe((res: any) => {
      this.genders = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadGenders();
      }
    });
  }

  async loadTypeDocuments() {
    await this.http.get(this.URL_BASE + 'type_documents').subscribe((res: any) => {
      this.type_documents = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadTypeDocuments();
      }
    });
  }

  async loadEducatorLevels() {
    await this.http.get(this.URL_BASE + 'educator_levels').subscribe((res: any) => {
      var sourceEducatorLevels =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'level', type: 'string' },
          { name: 'experience', type: 'string' }
        ]
      };

      this.educator_pay_rates = new jqx.dataAdapter(sourceEducatorLevels, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " (" + fila.level + " " + fila.experience + ")";

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadEducatorLevels();
      }
    });
  }

  async loadLevels() {
    await this.http.get(this.URL_BASE + 'levels').subscribe((res: any) => {
      this.levels = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadLevels();
      }
    });
  }

  async loadExperiences() {
    await this.http.get(this.URL_BASE + 'experiences').subscribe((res: any) => {
      this.experiences = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadExperiences();
      }
    });
  }

  async loadStatuses() {
    await this.http.get(this.URL_BASE + 'user_profile_statuses').subscribe((res: any) => {
      this.statuses = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadStatuses();
      }
    });
  }

  async loadTypeContacts() {
    await this.http.get(this.URL_BASE + 'type_contacts').subscribe((res: any) => {
      this.type_contacts = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadTypeContacts();
      }
    });
  }

  async loadWbTypes() {
    this.wb_types = new Array(
      { "id": 1, "name": "Preferential" },
      { "id": 2, "name": "Restricted" }
    );

    setTimeout(() => {
      this.comboboxWBType.val('2');
      this.wb_type_id = 2;
      this.comboboxWBType.disabled(true);
    }, 800);
  }

  async loadTypeUserProfiles() {
    await this.http.get(this.URL_BASE + 'type_user_profiles').subscribe((res) => {
      this.type_user_profiles = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadTypeUserProfiles();
      }
    });
  }

  async loadDataRol(type: any, rol_id: number = 0) {
    await this.http.get(this.URL_BASE + 'roles/filter-type/' + type).subscribe(res => {
      this.roles = res; 

      if(rol_id > 0) {
        setTimeout(() =>{
          this.comboboxRol.val(this.user_profile.rol_id)
          this.comboboxRol.disabled(true)
        }, 1000);
      }
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataRol(type);
      }
    });
  }

  async loadZipCodes(city_id: number = 0) {
    await this.http.get(this.URL_BASE + 'zip_codes/filter-city/' + city_id).subscribe(res => {
      this.zip_codes = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadZipCodes(city_id);
      }
    });
  }

  async loadPermanentZipCodes(city_id: number = 0) {
    await this.http.get(this.URL_BASE + 'zip_codes/filter-city/' + city_id).subscribe(res => {
      this.permanent_zip_codes = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadPermanentZipCodes(city_id);
      }
    });
  }

  async loadDataUserProfiles() {
    await this.http.get(this.URL_BASE + 'user_profiles').subscribe((res: any) => {
      if(res) {
        let schools = res.filter((element: any) => element.type_user_profile_id == 2);
        schools.sort(function(a: any, b: any) {
          if(a.business_name.toLowerCase() < b.business_name.toLowerCase()) { return -1; }
          if(a.business_name.toLowerCase() > b.business_name.toLowerCase()) { return 1; }
          return 0;
        });

        setTimeout(() => {
          this.schools = schools;
        }, 800);

        var sourceEducators = {
          localdata: res.filter((element: any) => element.type_user_profile_id == 4),
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'name', type: 'string' }
          ]
        };

        this.all_educators = new jqx.dataAdapter(sourceEducators, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              }

              data.push(fila);
            }
          },
        });

        var sourceUserProfiles = {
          localdata: res.filter((element: any) => element.type_user_profile_id == 2),
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' },
            { name: 'name', type: 'string' }
          ]
        };

        this.parent_user_profiles = new jqx.dataAdapter(sourceUserProfiles, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        });
      }
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataUserProfiles();
      }
    });
  }

  async loadDataOrganizations() {
    this.http.get(this.URL_BASE + 'user_profiles/filter/organizations').subscribe(res => {
      var sourceUserProfiles = {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    });
  }

  async loadDataRankingEducators(user_profile_id: number = 0) {
    await this.http.get(this.URL_BASE + 'user-profile-ranking-quantity/0/' + user_profile_id + '/999').subscribe((res: any) => {
      var sourceEducator = {
        localdata: res.sort(function(a: any, b: any) {
          if(a.first_name && a.second_name && a.last_name) {
            a.name = a.first_name + " " + a.second_name + " " + a.last_name;
          } else if(a.first_name && a.last_name) {
            a.name = a.first_name + " " + a.last_name;
          }
          if(b.first_name && b.second_name && b.last_name) {
            b.name = b.first_name + " " + b.second_name + " " + b.last_name;
          } else if(b.first_name && b.last_name) {
            b.name = b.first_name + " " + b.last_name;
          }

          if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
          if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
          return 0;
        }),
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.educators = new jqx.dataAdapter(sourceEducator, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            }

            data.push(fila);
          }
        },
      });
      
      if(res && res.length > 0) {
        this.ranking_educators = res;

        if(this.ranking_educators && this.ranking_educators.length > 0) {
          setTimeout(() => {
            this.ranking_educators.sort(function (a: any, b: any) {
              if (a.order < b.order)
                return -1;
              else if (a.order > b.order)
                return 1;
              else 
                return 0;
            });

            if(res.length > 5) {
              this.ranking_educators = res.slice(0, 5);
            }
            console.log(this.ranking_educators);
          }, 800);
        }
      }
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataRankingEducators(user_profile_id);
      }
    });
  }

  changeAddress () {
    this.geocode({ address: this.address + ", GA " + this.zip_code });
  }

  loadDataGridWBLists(user_profile_id: number) {
    this.http.get(this.URL_BASE + 'wb_lists/filter-user-profile/' + user_profile_id).subscribe(res => {
      this.wb_lists = res;

      this.sourceWBLists.localdata = this.wb_lists;
      this.gridWBLists.updatebounddata('cells');
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataGridWBLists(user_profile_id);
      }
    });
  }

  loadDataGridAllowList(user_profile_id: number, type: string) {
    this.http.get(this.URL_BASE + 'allow_lists/filter-user-profile/' + user_profile_id + '/' + type + '/0').subscribe(res => {
      this.allow_list = res;

      this.sourceAllowList.localdata = this.allow_list;
      this.gridAllowList.updatebounddata('cells');
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataGridAllowList(user_profile_id, type);
      }
    });
  }

  loadDataGridAllowListEducators(user_profile_id: number) {
    this.http.get(this.URL_BASE + 'allow_lists/filter-user-profile/' + user_profile_id + '/School/1').subscribe(res => {
      this.allow_list_educators = res;

      this.sourceAllowListEducators.localdata = this.allow_list_educators;
      this.gridAllowListEducators.updatebounddata('cells');
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataGridAllowListEducators(user_profile_id);
      }
    });
  }

  async loadDataUserProfile(user_profile_id: number) {
    await this.http.get(this.URL_BASE + 'user_profile/' + user_profile_id).subscribe(res => {
      this.user_profile = res;

      if(this.user_profile) {
        if(this.user_profile.type_user_profile_id == 2) {
          this.loadDataRankingEducators(user_profile_id);
        }

        this.user_profile_id = this.user_profile.id;
        this.photo = this.user_profile.photo;

        this.comboboxTypeUserProfile.val(this.user_profile.type_user_profile_id);
        this.userProfileTypeOnSelect([], this.user_profile.type_user_profile_id, this.user_profile.rol_id);

        this.status = this.user_profile.status;
        this.status_id = this.user_profile.status_id;
        
        this.level = this.user_profile.level;
        this.first_name = this.user_profile.first_name;
        this.second_name = this.user_profile.second_name;
        this.last_name = this.user_profile.last_name;
        this.nickname = this.user_profile.nickname;

        if(this.user_profile.type_identification_id) {
          this.type_identification_id = this.user_profile.type_identification_id;
          this.comboboxTypeIdentification.val(this.user_profile.type_identification_id);
        }

        this.identification = this.user_profile.identification;

        if(this.user_profile.gender_id) {
          this.gender_id = this.user_profile.gender_id;
          this.comboboxGender.val(this.user_profile.gender_id);
        }

        this.email = this.user_profile.email;
        this.hire_date = this.user_profile.hire_date;

        if(this.user_profile.country_id) {
          this.comboboxCountry.val(this.user_profile.country_id);
        }

        // setTimeout(() =>{this.comboboxState.val(this.user_profile.state_id)}, 1000);
        
        if(this.user_profile.county_id) {
          setTimeout(() =>{
            this.comboboxCounty.val(this.user_profile.county_id)
          }, 2000);
        }

        if(this.user_profile.city_id) {
          setTimeout(() =>{
            this.city_id = this.user_profile.city_id;
            this.comboboxCity.val(this.user_profile.city_id);
          }, 2500);
        }

        if(this.user_profile.zip_code) {
          setTimeout(() =>{
            this.zip_code = this.user_profile.zip_code;
            this.comboboxZipCode.val(this.user_profile.zip_code);
          }, 3500);
        }

        if(this.user_profile.hire_date) {
          this.hire_date = moment(this.user_profile.hire_date).format("MM-DD-yyyy");
        }

        if(this.user_profile.birth_date) {
          this.birth_date = moment(this.user_profile.birth_date).format("MM-DD-yyyy");
        }

        this.address = this.user_profile.address;

        if(this.user_profile.permanent_state_id) {
          setTimeout(() =>{
            this.permanent_state_id = this.user_profile.permanent_state_id;
            this.comboboxPermanentState.val(this.user_profile.permanent_state_id);
          }, 1000);
        }
        if(this.user_profile.permanent_city_id) {
          setTimeout(() =>{
            this.permanent_city_id = this.user_profile.permanent_city_id;
            this.comboboxPermanentCity.val(this.user_profile.permanent_city_id);
          }, 2500);
        }
        if(this.user_profile.permanent_zip_code) {
          setTimeout(() =>{
            this.permanent_zip_code = this.user_profile.permanent_zip_code;
            this.comboboxPermanentZipCode.val(this.user_profile.permanent_zip_code);
          }, 3500);
        }
        this.permanent_address = this.user_profile.permanent_address;

        this.phone = this.user_profile.phone;
        this.cell_phone = this.user_profile.cell_phone;
        this.business_name = this.user_profile.business_name;
        this.observation = this.user_profile.observation;
        this.direction_radius = this.user_profile.direction_radius;

        if(this.user_profile.level_temp_id) {
          this.level_temp_id = this.user_profile.level_temp_id;
          this.comboboxLevelTemp.val(this.user_profile.level_temp_id);
        }

        if(this.user_profile.experience_temp_id) {
          this.experience_temp_id = this.user_profile.experience_temp_id;
          this.comboboxExperienceTemp.val(this.user_profile.experience_temp_id);
        }

        if(this.user_profile.level_id) {
          this.level_id = this.user_profile.level_id;
          this.comboboxLevel.val(this.user_profile.level_id);
        }

        if(this.user_profile.experience_id) {
          this.experience_id = this.user_profile.experience_id;
          this.comboboxExperience.val(this.user_profile.experience_id);
        }

        this.teacher_about = this.user_profile.teacher_about;
        this.checkSaturdayWork.val(this.user_profile.saturday_work);
        this.checkSundayWork.val(this.user_profile.sunday_work);

        if(this.user_profile.pay_rate_id) {
          this.pay_rate_id = this.user_profile.pay_rate_id;
          this.comboboxPayRate.val(this.user_profile.pay_rate_id);
        }

        if(this.user_profile.parent_id) {
          this.parent_id = this.user_profile.parent_id;
          this.comboboxParent.val(this.user_profile.parent_id);
        }

        if(this.user_profile.organization_id) {
          this.organization_id = this.user_profile.organization_id;
          this.comboboxOrganization.val(this.user_profile.organization_id);
        }

        let contact = this.user_profile.contacts;
        if(contact != null) {
          this.contacts = this.user_profile.contacts;
        }
        this.sourceContacts.localdata = this.contacts;
        this.gridContacts.updatebounddata('cells');

        let specific_holidays = this.user_profile.specific_holidays;
        if(specific_holidays != null) {
          this.specific_holidays = this.user_profile.specific_holidays;
        }
        this.sourceSpecificHolidays.localdata = this.specific_holidays;
        this.gridSpecificHolidays.updatebounddata('cells');

        this.documents = this.user_profile.documents;
        this.sourceDocuments.localdata = this.documents;
        this.gridDocuments.updatebounddata('cells');

        let not_working_dates = this.user_profile.not_working_dates;
        if(not_working_dates != null) {
          this.not_working_dates = this.user_profile.not_working_dates;
        }
        this.sourceNotWorkingDates.localdata = this.not_working_dates;
        this.gridNotWorkingDates.updatebounddata('cells');

        // this.geocode({ address: this.user_profile.address + ", GA " + this.user_profile.zip_code });

        this.markerPositions = new Array();
        if(this.user_profile.maps) {
          this.markerPositions.push(this.user_profile.maps);
          this.center = {lat: this.user_profile.maps.position.lat, lng: this.user_profile.maps.position.lng};
        }

        this.loadDataGridWBLists(this.user_profile_id);

        if(this.user_profile.type_user_profile_id == 2 || this.user_profile.type_user_profile_id == 4) {
          if(this.user_profile.type_user_profile_id == 2) {
            this.allow_type = "Educator";
            this.loadDataGridAllowListEducators(this.user_profile_id)
          }
          if(this.user_profile.type_user_profile_id == 4) {
            this.allow_type = "School";
          }

          setTimeout(() => {
            this.loadDataGridAllowList(this.user_profile_id, this.allow_type);
          }, 500);
        }
      }
    });
  }

  async loadImagesUserProfile(user_profile_id: number) {
    await this.http.get(this.URL_BASE + 'user_profile/images/' + user_profile_id).subscribe(res => {
      this.images_user_profile = res;

      this.user_id_change_images = this.images_user_profile.id;
      this.img_1 = this.images_user_profile.images[0].path;
      this.img_2 = this.images_user_profile.images[1].path;
      this.img_3 = this.images_user_profile.images[2].path;

      setTimeout(() => {
        this.modalImages = true;
      }, 200)
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  convertFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  // MAPS
  addMarker(event: google.maps.MapMouseEvent) {
    if(event) {
      this.markerPositions = [];
      setTimeout(() => {
        if(event.latLng) {
          // console.log(event);
          let markerMap = {
            position: {
              lat: event.latLng.toJSON().lat,
              lng: event.latLng.toJSON().lng,
            },
            label: {
              color: 'red',
              text: 'Marker School',
            },
            title: 'Marker School',
            info: {
              title: 'Marker School',
            },
            options: { 
              draggable: false,
              animation: google.maps.Animation.DROP,
            },
          }

          this.markerPositions.push(markerMap);
          // console.log(this.markerPositions);
        }
      }, 200);
    }
  }

  openInfoWindow(marker: MapMarker, info: any) {
    this.markerInfo = info;
    this.infoWindow.open(marker);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  updateOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.editUserProfile();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addContactOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createContact();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addWBListOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createWBList();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addDocumentOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createDocument();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addHolidayOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createHoliday();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addNotWorkingDatesOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      this.createNotWorkingDates();
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  addAllowListOnClick(educator_id: number = 0) {
    if(this.permissions && this.permissions.update == 1) {
      this.createAllowList(educator_id);
    } else {
      let error = {
        message: "You do not have permissions to edit in this module",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  changePasswordUserOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      if(this.new_password == this.new_password_confirm) {
        this.changePasswordUser();
      } else {
        let error = {
          message: "Passwords do not match",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  deletePhotoOnClick() {
    if(this.permissions && this.permissions.delete == 1) {
      this.deletePhotoUserProfile();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  tooltipOnClick(message: string) {
    this.message_tooltip = message;
    this.modalTooltip = true;
  }

  duplicateCurrentOnClick() {
    this.comboboxPermanentState.val("10");
    this.permanent_state_id = 10;

    if(this.address) {
      this.permanent_address = this.address;
    }

    setTimeout(() => {
      if(this.comboboxCity.val()) {
        this.comboboxPermanentCity.val(this.comboboxCity.val());
        this.permanent_city_id = this.city_id;
      }
    }, 800);

    setTimeout(() => {
      if(this.comboboxZipCode.val()) {
        this.comboboxPermanentZipCode.val(this.comboboxZipCode.val());
        this.permanent_zip_code = this.zip_code;
      }
    }, 1600);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  uploadPhotoOnChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.photo = reader.result;
      this.editPhotoUserProfile(this.user_profile_id);
    };
  }

  uploadImageOnChange(event: any, id: number): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if(id == 1) {
        this.img_1 = reader.result;
        this.editImagesUserProfile(this.user_id_change_images, id, this.img_1);
      }
      if(id == 2) {
        this.img_2 = reader.result;
        this.editImagesUserProfile(this.user_id_change_images, id, this.img_2);
      }
      if(id == 3) {
        this.img_3 = reader.result;
        this.editImagesUserProfile(this.user_id_change_images, id, this.img_3);
      }
    };
  }

  uploadDocumentOnChange(event: any): void {
    if(event.target) {
      var document_file = event.target.files[0];
      var size = (document_file.size / 1048576).toFixed(2); //En MB
      // console.log(parseFloat(size));
      if(parseFloat(size) <= 2) {
        this.convertFile(document_file).then(data => {
          this.document_data = data;
          this.document_name = document_file.name;
        }); 
      } else {
        this.document = "";
        this.document_data = "";
        this.document_name = "";

        let error = {
          message: "The file must be less than 2 MB",
          type: "red"
        };
        this.alerts.push(error);
        return this.closeAlertTime();
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS INPUT                                   */
  /* ------------------------------------------------------------------------------------- */

  onChangeFormatIdentification(event: any): void {
    var type_identification_id = 0;
    if(event && event.args) {
      type_identification_id = event.args.item.value;
    } else {
      type_identification_id = this.type_identification_id;
    }

    if(type_identification_id > 0) {
      if(type_identification_id == 1) {
        // SSN 123-45-6789
        this.mask_identification = {mask:'000-00-0000', type:'num', len:11, userCaracters: true};
      }
      if(type_identification_id == 2) {
        // EIN 12-3456789
        this.mask_identification = {mask:'00-0000000', type:'num', len:10, userCaracters: true};
      }

      setTimeout(()  => {
        this.identification = this.identification;
      }, 300);
    }
  }
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        let arr_val = value.split("-");
        var value_return = "";

        if(arr_val.length > 0) {
          arr_val.forEach((element: any) => {
            if(element != "-") {
              value_return = value_return + "" + element;
            }
          })
        }

        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }
  
  numberInputRadius(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  userProfileTypeOnSelect(event: any, type_user_profile_id: number = 0, rol_id: number = 0): void {
    // console.log(event);
    var item = 0;

    if (event.args) {
      item = event.args.item.value;
    } else if(type_user_profile_id > 0) {
      item = type_user_profile_id;
    }

    if (item) {
      this.loadDataRol(item, rol_id);
      if(item == 2 || item == 3) {
        this.first_name = "";
        this.second_name = "";
        this.last_name = "";
        this.nickname = "";
      } else {
        this.business_name = "";
      }
    }
  }

  countriesOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.http.get(this.URL_BASE + 'states/filter-country/' + item).subscribe(res => {
          this.states = res;

          setTimeout(() => {
            // this.comboboxState.selectIndex(0);
          }, 500);  
        });
      }
    }
  };

  statesOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.http.get(this.URL_BASE + 'counties/filter-state/' + item).subscribe(res => {
          this.counties = res;
        });
      }
    }
  };

  cityOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadZipCodes(item);
      }
    }
  }

  permanentCityOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadPermanentZipCodes(item);
      }
    }
  }

  permanentStateOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.http.get(this.URL_BASE + 'cities/filter-state/' + item).subscribe(res => {
          this.permanent_cities = res;
        });
      }
    }
  };


  /* ------------------------------------------------------------------------------------- */
  /*                                     EVENTOS FOCUSOUT                                  */
  /* ------------------------------------------------------------------------------------- */

  cityFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let city = this.cities.find((element: any) => element.name == item);

          if(city) {
            this.city_id = city.id;
          } else {
            this.city_id = null;
            this.comboboxCity.val("");
            this.comboboxCity.close();
            this.zip_codes = new Array();
            this.zip_code = "";

            let error = {
              message: "You must select a valid city",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }

  zipCodeFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let zip_code = this.zip_codes.find((element: any) => element.code == item);

          if(zip_code) {
            this.zip_code = zip_code.code;
          } else {
            this.zip_code = "";
            this.comboboxZipCode.val("");
            this.comboboxZipCode.close();

            let error = {
              message: "You must select a valid zip code",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }
  
  permanentCityFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let city = this.permanent_cities.find((element: any) => element.name == item);

          if(city) {
            this.permanent_city_id = city.id;
          } else {
            this.permanent_city_id = null;
            this.comboboxPermanentCity.val("");
            this.comboboxPermanentCity.close();
            this.permanent_zip_codes = new Array();
            this.permanent_zip_codes = "";

            let error = {
              message: "You must select a valid city",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }

  permanentZipCodeFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let zip_code = this.permanent_zip_codes.find((element: any) => element.code == item);

          if(zip_code) {
            this.permanent_zip_code = zip_code.code;
          } else {
            this.permanent_zip_code = "";
            this.comboboxPermanentZipCode.val("");
            this.comboboxPermanentZipCode.close();

            let error = {
              message: "You must select a valid zip code",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  editUserProfile() {
    let arr_maps: any = null;
    if(this.markerPositions.length > 0) {
      arr_maps = this.markerPositions[this.markerPositions.length-1];
    }

    var identification = "";
    let arr_identification = Array.from(this.identification);

    if(arr_identification && arr_identification.length > 0) {
      arr_identification.forEach((element: any) => {
        if(!isNaN(element)) {
          identification = identification + "" + element;
        }
      });
    }

    var hire_date = "";
    if(this.hire_date) {
      hire_date = moment(this.hire_date).format("yyyy-MM-DD");
    }

    var birth_date = "";
    if(this.birth_date) {
      birth_date = moment(this.birth_date).format("yyyy-MM-DD");
    }

    setTimeout(() => {
      this.http
      .put(
        this.URL_BASE + 'user_profile/' + this.user_profile_id, 
        {
          type_user_profile_id: this.type_user_profile_id, 
          first_name: this.first_name, 
          second_name: this.second_name, 
          last_name: this.last_name, 
          nickname: this.nickname, 
          type_identification_id: this.type_identification_id, 
          identification: identification, 
          email: this.email, 
          hire_date: hire_date, 
          birth_date: birth_date, 
          city_id: this.city_id, 
          zip_code: this.zip_code, 
          gender_id: this.gender_id, 
          address: this.address, 
          permanent_city_id: this.permanent_city_id, 
          permanent_address: this.permanent_address, 
          permanent_zip_code: this.permanent_zip_code, 
          phone: this.phone, 
          cell_phone: this.cell_phone, 
          business_name: this.business_name, 
          observation: this.observation, 
          direction_radius: this.direction_radius, 
          saturday_work: this.checkSaturdayWork.val(), 
          sunday_work: this.checkSundayWork.val(), 
          rol_id: this.rol_id, 
          level_temp_id: this.level_temp_id, 
          experience_temp_id: this.experience_temp_id, 
          level_id: this.level_id, 
          experience_id: this.experience_id, 
          teacher_about: this.teacher_about, 
          maps: arr_maps, 
          pay_rate_id: this.pay_rate_id, 
          parent_id: this.parent_id, 
          organization_id: this.organization_id, 
          update_id: this.user_id
        }
      )
      .subscribe(
        (res:any) => {
          this.alerts.push(res);
          this.closeAlertTime();

          if(res.type == "green") {
            this.loadDataUserProfiles();
            this.loadDataOrganizations();
          }
        },
        (err:any) => {
          if(typeof err.error !== 'undefined') {
            if(err.error.errors)  {
              let message = "";
              Object.keys(err.error.errors).map((key) => {
                message += "- " + err.error.errors[key][0] + " <br>";
              });
                
              let error = {
                message: message,
                type: "red"
              };
              this.alerts.push(error);
            } else if(err.error.message)  {
              let error = {
                tittle: "Error",
                message: err.error.message,
                type: "red"
              };

              this.alerts.push(error);
            }
          } else {
            let error = {
              tittle: "Error",
              message: err,
              type: "red"
            };

            this.alerts.push(error);
          }
          this.closeAlertTime();
        }
      );
    }, 200);
  }

  editPhotoUserProfile(user_profile_id: number) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-photo/' + user_profile_id, 
      {
        photo: this.photo,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.tokenStorageSavePhotoUser(this.photo);
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deletePhotoUserProfile() {
    this.http
    .put(
      this.URL_BASE + 'user_profile/delete-photo/' + this.user_profile_id, 
      {
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.photo = "";
          this.tokenStorageSavePhotoUser(this.photo);
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editImagesUserProfile(user_profile_id: number, id_img: number, img: string) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-images/' + user_profile_id, 
      {
        id_img: id_img, 
        img: img, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          // this.modalImages = false;
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editUserProfileContacts(user_profile_id: number, contacts: any) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-contacts/' + user_profile_id, 
      {
        contacts: contacts,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormContacts();
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editUserProfileDocuments(user_profile_id: number, data: any) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-documents/' + user_profile_id, 
      data
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormDocuments();
          this.loadDataUserProfile(this.user_profile_id);
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editUserProfileSpecificHolidays(user_profile_id: number, specific_holidays: any) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-specific-holidays/' + user_profile_id, 
      {
        specific_holidays: specific_holidays,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormHoliday();
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editUserProfileNotWorkingDates(user_profile_id: number, not_working_dates: any) {
    this.http
    .put(
      this.URL_BASE + 'user_profile/update-not-working-dates/' + user_profile_id, 
      {
        not_working_dates: not_working_dates,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();
        
        if(res.type == "green") {
          this.clearFormNotWorkingDates();
        } 
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  // Contacts
  createContact() {
    if(this.contact_name == "") {
      let error = {
        message: "To register a contact you must enter the name",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.contact_email === '') {
      let error = {
        message: "To register a contact you must enter the email",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.contact_mobile_phone === '') {
      let error = {
        message: "To register a contact you must enter the mobile phone",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.contact_type_contact_id == 0) {
      let error = {
        message: "To register a contact you must enter the type contact",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    let type_contact = this.type_contacts.find((item: any) => item.id == this.contact_type_contact_id);
    var id = 1;
    if(this.contacts.length > 0) {
      id = (this.contacts[this.contacts.length-1].id + 1);
    } else {
      this.contacts = new Array();
    }

    new_array = {
      id: id,
      name: this.contact_name,
      email: this.contact_email,
      mobile_phone: this.contact_mobile_phone,
      type_contact_id: this.contact_type_contact_id,
      type_contact: type_contact.name
    };

    this.contacts.push(new_array);
    this.sourceContacts.localdata = this.contacts;
    this.gridContacts.updatebounddata('cells');

    this.editUserProfileContacts(this.user_profile_id, this.dataAdapterContacts.records);
  }

  updateContact(rowdata: any) {
    let type_contact = this.type_contacts.find((item: any) => item.id == rowdata.type_contact_id);

    this.contacts.forEach(function (item: any) {
      if(item.id == rowdata.id) {
        item.name = rowdata.name;
        item.email = rowdata.email;
        item.mobile_phone = rowdata.mobile_phone;
        item.type_contact_id = rowdata.type_contact_id;
        item.type_contact = type_contact.name;
      }
    });

    this.sourceContacts.localdata = this.contacts;
    this.gridContacts.updatebounddata('cells');

    this.editUserProfileContacts(this.user_profile_id, this.dataAdapterContacts.records);
  }

  deleteContact(contact_id: number) {
    var newArrayGrid = this.contacts.filter((item: any) => item.id !== contact_id);
    this.contacts = newArrayGrid;
    this.sourceContacts.localdata = this.contacts;
    this.gridContacts.updatebounddata('cells');

    this.editUserProfileContacts(this.user_profile_id, this.dataAdapterContacts.records);
  }

  // WB List
  createWBList() {
    if(this.wb_educator_id == 0) {
      let error = {
        message: "Select an educator",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.wb_type_id == 0) {
      let error = {
        message: "Select type",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'wb_list', 
      { 
        school_id: this.user_profile_id, 
        educator_id: this.wb_educator_id, 
        type: this.wb_type_id, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormWBLists();
          this.loadDataGridWBLists(this.user_profile_id);
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  updateWBList(rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'wb_list/' + rowdata.id, 
      {
        school_id: rowdata.school_id, 
        educator_id: rowdata.educator_id, 
        type: rowdata.type, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGridWBLists(this.user_profile_id);
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteWBList(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'wb_list/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGridWBLists(this.user_profile_id);
        } else {
          this.gridWBLists.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  // Documents
  createDocument() {
    if(this.document_type_id == 0) {
      let error = {
        message: "To register a document you must enter the document type",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }
    if(this.document_data === '' || this.document_data == '') {
      let error = {
        message: "To register a document you must enter the document",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    var validity_date = "";
    if(this.document_type_id == 1) {
      if(this.document_validity_date == "" || this.document_validity_date == null) {
        let error = {
          message: "Please enter you Background Check expiration date",
          type: "red"
        };
        this.alerts.push(error);
        return this.closeAlertTime();
      } 
    }

    if(this.document_validity_date) {
      validity_date = moment(this.document_validity_date).format("yyyy-MM-DD");
      var date_now = moment(new Date()).format("yyyy-MM-DD");
      if(validity_date < date_now) {
        let error = {
          message: "The expiration date is invalid",
          type: "red"
        };
        this.alerts.push(error);
        return this.closeAlertTime();
      }
    }

    let data: any = new Array();
    let document_type = this.type_documents.find((item: any) => item.id == this.document_type_id);
    var id = 1;

    if(this.documents) {
      var exist_doc = this.documents.filter((item: any) => item.document_type_id == this.document_type_id);
      // console.log(exist_doc);
      // console.log(document_type);

      if(exist_doc.length > 0 && exist_doc.length >= document_type.amount) {
        let error = {
          message: "The document has already been registered the number of times allowed",
          type: "red"
        };
        this.alerts.push(error);
        return this.closeAlertTime();
      }

      if(this.documents != null && this.documents.length > 0) {
        id = (this.documents[this.documents.length-1].id + 1);
      }
    } else {
      this.documents = new Array();
    }

    data = {
      id: id,
      type: "create",
      document_type_id: this.document_type_id,
      document_type: document_type.name,
      path: this.document_data,
      expires: 0,
      validity_date: validity_date,
      status_id: 1,
      status: "Pending",
      review_date: "",
      observation: "",
      update_id: this.user_id
    };

    this.editUserProfileDocuments(this.user_profile_id, data);
  }

  updateDocument(rowdata: any) {
    var document_type = this.type_documents.find((element: any) => {
      return element.id == rowdata.document_type_id;
    }).name;

    var status = this.statuses.find((element: any) => {
      return element.id == rowdata.status_id;
    }).name;

    var review_date = moment(new Date()).format("yyyy-MM-DD");

    let data: any = {
      id: rowdata.id,
      type: "update",
      document_type_id: rowdata.document_type_id,
      document_type: document_type,
      path: rowdata.path,
      expires: rowdata.expires,
      validity_date: moment(rowdata.validity_date).format("yyyy-MM-DD"),
      status_id: rowdata.status_id,
      status: status,
      review_date: review_date,
      observation: rowdata.observation,
      update_id: this.user_id
    }; 

    this.editUserProfileDocuments(this.user_profile_id, data);
  }

  deleteDocument(document_id: number) {
    let doc = this.documents.find((item: any) => item.id == document_id);

    let data: any = {
      id: doc.id,
      type: "delete",
      document_type_id: doc.document_type_id,
      path: doc.path,
      update_id: this.user_id
    }; 

    this.editUserProfileDocuments(this.user_profile_id, data);
  }

  // Specific Holidays
  createHoliday() {
    if(this.holiday_date == "") {
      let error = {
        message: "To register a specific holiday you must enter the date",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    var id = 1;
    if(this.specific_holidays.length > 0) {
      id = (this.specific_holidays[this.specific_holidays.length-1].id + 1);
    } else {
      this.specific_holidays = new Array();
    }

    new_array = {
      id: id,
      date: moment(this.holiday_date).format("yyyy-MM-DD")
    };

    this.specific_holidays.push(new_array);
    this.sourceSpecificHolidays.localdata = this.specific_holidays;
    this.gridSpecificHolidays.updatebounddata('cells');

    this.editUserProfileSpecificHolidays(this.user_profile_id, this.specific_holidays);
  }

  updateHoliday(rowdata: any) {
    this.specific_holidays.forEach(function (item: any) {
      if(item.id == rowdata.id) {
        item.date = moment(rowdata.date).format("yyyy-MM-DD");
      }
    });

    this.sourceSpecificHolidays.localdata = this.specific_holidays;
    this.gridSpecificHolidays.updatebounddata('cells');

    this.editUserProfileSpecificHolidays(this.user_profile_id, this.specific_holidays);
  }

  deleteHoliday(contact_id: number) {
    var newArrayGrid = this.specific_holidays.filter((item: any) => item.id !== contact_id);
    this.specific_holidays = newArrayGrid;
    this.sourceSpecificHolidays.localdata = this.specific_holidays;
    this.gridSpecificHolidays.updatebounddata('cells');

    this.editUserProfileSpecificHolidays(this.user_profile_id, this.specific_holidays);
  }

  // Not Working Dates
  createNotWorkingDates() {
    if(this.not_working_start_date == "") {
      let error = {
        message: "To register a not working dates you must enter the start date",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.not_working_end_date == "") {
      let error = {
        message: "To register a not working dates you must enter the end date",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    var id = 1;
    if(this.not_working_dates.length > 0) {
      id = (this.not_working_dates[this.not_working_dates.length-1].id + 1);
    } else {
      this.not_working_dates = new Array();
    }

    new_array = {
      id: id,
      start_date: moment(this.not_working_start_date).format("yyyy-MM-DD"),
      end_date: moment(this.not_working_end_date).format("yyyy-MM-DD")
    };

    this.not_working_dates.push(new_array);
    this.sourceNotWorkingDates.localdata = this.not_working_dates;
    this.gridNotWorkingDates.updatebounddata('cells');

    this.editUserProfileNotWorkingDates(this.user_profile_id, this.not_working_dates);
  }

  updateNotWorkingDates(rowdata: any) {
    this.not_working_dates.forEach(function (item: any) {
      if(item.id == rowdata.id) {
        item.start_date = moment(rowdata.start_date).format("yyyy-MM-DD");
        item.end_date = moment(rowdata.end_date).format("yyyy-MM-DD");
      }
    });

    this.sourceNotWorkingDates.localdata = this.not_working_dates;
    this.gridNotWorkingDates.updatebounddata('cells');

    this.editUserProfileNotWorkingDates(this.user_profile_id, this.not_working_dates);
  }

  deleteNotWorkingDates(id: number) {
    var newArrayGrid = this.not_working_dates.filter((item: any) => item.id !== id);
    this.not_working_dates = newArrayGrid;
    this.sourceNotWorkingDates.localdata = this.not_working_dates;
    this.gridNotWorkingDates.updatebounddata('cells');

    this.editUserProfileNotWorkingDates(this.user_profile_id, this.not_working_dates);
  }

  // Allow List
  createAllowList(educator_rank_id: number = 0) {
    var school_id = 0;
    var educator_id = 0;

    if(this.allow_type == "School") {
      school_id = this.allow_school_id;
      educator_id = this.user_profile_id;
    }

    if(this.allow_type == "Educator") {
      school_id = this.user_profile_id;
      if(educator_rank_id > 0) {
        educator_id = educator_rank_id;
      } else {
        educator_id = this.allow_educator_id;
      }
    }

    if(educator_id == 0) {
      let error = {
        message: "Select an educator",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(school_id == 0) {
      let error = {
        message: "Select an school",
        type: "red"
      };
      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'allow_list', 
      { 
        school_id: school_id, 
        educator_id: educator_id, 
        type: this.allow_type, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormAllowList();
          this.loadDataGridAllowList(this.user_profile_id, this.allow_type);
        } else {
          this.gridAllowList.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  updateAllowList(rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'allow_list/' + rowdata.id, 
      {
        school_id: rowdata.school_id, 
        educator_id: rowdata.educator_id, 
        type: rowdata.type, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGridAllowList(this.user_profile_id, this.allow_type);
        } else {
          this.gridAllowList.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteAllowList(id: number, yourself: number = 0) {
    this.http
    .delete(
      this.URL_BASE + 'allow_list/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGridAllowList(this.user_profile_id, this.allow_type);
          if(yourself) {
            this.loadDataGridAllowListEducators(this.user_profile_id);
          }
        } else {
          this.gridAllowList.updatebounddata('cells');
          if(yourself) {
            this.gridAllowListEducators.updatebounddata('cells');
          }
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  //Change Password
  changePasswordUser() {
    this.http
    .put(
      this.URL_BASE + 'user/change-password/' + this.user_id, 
      { 
        password: this.new_password,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormChangePass();
          this.modalPassword = false;
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  //Save User Local Storage
  tokenStorageSavePhotoUser(photo: any) {
    let data = this.tokenStorage.getDataAll();
    if(data && data.user && data.user.user_profile) {
      data.user.user_profile.photo = photo;
      // console.log(data);
      this.tokenStorage.saveUser(data);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearFormContacts() {
    this.contact_name = "";
    this.contact_email = "";
    this.contact_mobile_phone = "";
    this.contact_type_contact_id = 0;
  }

  clearFormWBLists() {
    this.wb_educator_id = 0;
    // this.wb_type_id = 0;
  }

  clearFormDocuments() {
    this.document_type_id = 0;
    this.document_validity_date = "";
    this.document = "";
    this.document_name = "";
  }

  clearFormHoliday() {
    this.holiday_date = "";
  }

  clearFormNotWorkingDates() {
    this.not_working_start_date = "";
    this.not_working_end_date = "";
  }

  clearFormAllowList() {
    this.allow_educator_id = 0;
    this.allow_school_id = 0;
  }

  clearFormChangePass() {
    this.new_password = "";
    this.new_password_confirm = "";
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridContacts() {
    this.sourceContacts =
    {
      localdata: this.contacts,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'mobile_phone', type: 'string' },
        { name: 'type_contact_id', type: 'int' },
        { name: 'type_contact', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateContact(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterContacts = new jqx.dataAdapter(this.sourceContacts, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.cellHoverContacts = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridContacts.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridContacts.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Contact</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsContacts =
    [
      {
        text: '',
        datafield: 'Del',
        width: '6%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: true,
        width: '34%' 
      },
      {
        text: 'Email',  
        datafield: 'email',
        displayfield: 'email',
        editable: true,
        width: '25%' 
      },
      {
        text: 'Mobile Phone',  
        datafield: 'mobile_phone',
        displayfield: 'mobile_phone',
        editable: true,
        width: '20%' 
      },
      {
        text: 'Type Contact',  
        datafield: 'type_contact_id',
        displayfield: 'type_contact',
        editable: false,
        width: '15%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickContacts(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteContact(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridWBLists() {
    this.sourceWBLists =
    {
      localdata: this.wb_lists,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'school_id', type: 'int' },
        { name: 'educator_id', type: 'int' },
        { name: 'type', type: 'int' },
        { name: 'school', type: 'string' },
        { name: 'school_first_name', type: 'string' },
        { name: 'school_second_name', type: 'string' },
        { name: 'school_last_name', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' },
        { name: 'type_name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateWBList(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterWBLists = new jqx.dataAdapter(this.sourceWBLists, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.school_first_name && fila.school_second_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_second_name + " " + fila.school_last_name;
          }
          if(fila.school_first_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_last_name;
          }

          if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
          }
          if(fila.educator_first_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_last_name;
          }

          let wb_types = new Array(
            { "id": 1, "name": "Preferential" },
            { "id": 2, "name": "Restricted" }
          );

          if(fila.type) {
            if(wb_types) {
              let type = wb_types.find((element: any) => element.id == fila.type);
              if(type) {
                fila.type_name = type.name;
              }
            }
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverWBLists = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridWBLists.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridWBLists.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Preferential or Restricted Educator</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsWBLists =
    [
      {
        text: '',
        datafield: 'Del',
        width: '6%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Educator',  
        datafield: 'educator_id',
        displayfield: 'educator',
        editable: false,
        width: '74%' 
      },
      {
        text: 'Type',  
        datafield: 'type',
        displayfield: 'type_name',
        editable: false,
        width: '20%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.wb_types,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickWBLists(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteWBList(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridDocuments() {
    this.sourceDocuments =
    {
      localdata: this.documents,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'document_type_id', type: 'int' },
        { name: 'document_type', type: 'string' },
        { name: 'path', type: 'string' },
        { name: 'path_base', type: 'string' },
        { name: 'expires', type: 'boolean' },
        { name: 'validity_date', type: 'string' },
        { name: 'status_id', type: 'int' },
        { name: 'status', type: 'string' },
        { name: 'review_date', type: 'string' },
        { name: 'observation', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateDocument(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterDocuments = new jqx.dataAdapter(this.sourceDocuments, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.validity_date) {
            fila.validity_date = moment(fila.validity_date).format("MM-DD-yyyy");
          }

          if(fila.review_date) {
            fila.review_date = moment(fila.review_date).format("MM-DD-yyyy");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverDocuments = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridDocuments.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridDocuments.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del" || datafield == "Pdf") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Document</b>';
          }

          if(datafield == "Pdf") {
            tooltipContent = '<b>Open Document</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsDocuments =
    [
      {
        text: '',
        datafield: 'Del',
        width: '5%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: '',
        datafield: 'Pdf',
        width: '5%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>'
        },
      },
      {
        text: 'Document type',  
        datafield: 'document_type_id',
        displayfield: 'document_type',
        editable: false,
        width: '20%' 
      },
      {
        text: 'Expires',  
        datafield: 'expires',
        displayfield: 'expires',
        columntype: 'checkbox',
        editable: true,
        width: '10%' 
      },
      {
        text: 'Expiration Date',  
        datafield: 'validity_date',
        displayfield: 'validity_date',
        editable: true,
        width: '12%' 
      },
      {
        text: 'Updated Date',  
        datafield: 'review_date',
        displayfield: 'review_date',
        editable: false,
        width: '12%' 
      },
      {
        text: 'Status',  
        datafield: 'status_id',
        displayfield: 'status',
        editable: true,
        width: '10%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.statuses,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      },
      {
        text: 'Comment',  
        datafield: 'observation',
        displayfield: 'observation',
        editable: true,
        width: '26%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickDocuments(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.modalConfirmDeleteDocument = true;
          this.delete_document_id = rowdata.id;
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
    if (args.datafield == "Pdf") {
      if (selectedrowindex >= 0) {
        if(rowdata.path_base && rowdata.path_base != "") {
          this.pdfSource = rowdata.path_base;
          this.modalPDF = true;
          // window.open(this.URL_BASE + 'user_profile/view-documents/' + this.user_profile.id + '/' + rowdata.id, "_blank");
        } else {
          let error = {
            message: "The PDF was not found on the server",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridSpecificHolidays() {
    this.sourceSpecificHolidays =
    {
      localdata: this.specific_holidays,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'date', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateHoliday(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterSpecificHolidays = new jqx.dataAdapter(this.sourceSpecificHolidays, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverSpecificHolidays = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridSpecificHolidays.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridSpecificHolidays.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Specific Holiday</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsSpecificHolidays =
    [
      {
        text: '',
        datafield: 'Del',
        width: '6%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: true,
        width: '94%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickSpecificHolidays(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteHoliday(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridNotWorkingDates() {
    this.sourceNotWorkingDates =
    {
      localdata: this.not_working_dates,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateNotWorkingDates(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterNotWorkingDates = new jqx.dataAdapter(this.sourceNotWorkingDates, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.start_date) {
            fila.start_date = moment(fila.start_date).format("MM-DD-yyyy");
          }

          if(fila.end_date) {
            fila.end_date = moment(fila.end_date).format("MM-DD-yyyy");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverNotWorkingDates = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridNotWorkingDates.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridNotWorkingDates.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Not Working Dates</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsNotWorkingDates =
    [
      {
        text: '',
        datafield: 'Del',
        width: '6%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'Start Date',  
        datafield: 'start_date',
        displayfield: 'start_date',
        editable: true,
        width: '47%' 
      },
      {
        text: 'End Date',  
        datafield: 'end_date',
        displayfield: 'end_date',
        editable: true,
        width: '47%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickNotWorkingDates(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteNotWorkingDates(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridAllowList() {
    this.sourceAllowList =
    {
      localdata: this.allow_list,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'school_id', type: 'int' },
        { name: 'educator_id', type: 'int' },
        { name: 'type', type: 'string' },
        { name: 'school', type: 'string' },
        { name: 'school_status', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' },
        { name: 'educator_status', type: 'string' },
        { name: 'user_id', type: 'int' },
        { name: 'status', type: 'string' },
        { name: 'name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.updateWBList(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterAllowList = new jqx.dataAdapter(this.sourceAllowList, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.type == "School") {
            fila.user_id = fila.school_id
            fila.status = fila.school_status
            fila.name = fila.school
          } else if(fila.type == "Educator") {
            fila.user_id = fila.educator_id
            fila.status = fila.educator_status
            if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
              fila.name = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
            } else if(fila.educator_first_name && fila.educator_last_name) {
              fila.name = fila.educator_first_name + " " + fila.educator_last_name;
            }
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverAllowList = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridAllowList.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridAllowList.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Allow School for Educator</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsAllowList =
    [
      {
        text: '',
        datafield: 'Del',
        width: '5%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'User ID',  
        datafield: 'user_id',
        displayfield: 'user_id',
        editable: false,
        width: '10%' 
      },
      {
        text: 'Status',  
        datafield: 'status',
        displayfield: 'status',
        editable: false,
        width: '15%' 
      },
      {
        text: 'Type User',  
        datafield: 'type',
        displayfield: 'type',
        editable: false,
        width: '15%' 
      },
      {
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: false,
        width: '55%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickAllowList(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteAllowList(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridAllowListEducators() {
    this.sourceAllowListEducators =
    {
      localdata: this.allow_list_educators,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'school_id', type: 'int' },
        { name: 'educator_id', type: 'int' },
        { name: 'school', type: 'string' },
        { name: 'school_status', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' },
        { name: 'educator_status', type: 'string' },
        { name: 'user_id', type: 'int' },
        { name: 'status', type: 'string' },
        { name: 'name', type: 'string' }
      ]
    };

    this.dataAdapterAllowListEducators = new jqx.dataAdapter(this.sourceAllowListEducators, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];


          fila.user_id = fila.educator_id
          fila.status = fila.educator_status
          if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
            fila.name = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
          } else if(fila.educator_first_name && fila.educator_last_name) {
            fila.name = fila.educator_first_name + " " + fila.educator_last_name;
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverAllowListEducators = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridAllowListEducators.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridAllowListEducators.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Allow Educator to School</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsAllowListEducators =
    [
      {
        text: '',
        datafield: 'Del',
        width: '5%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: 'User ID',  
        datafield: 'user_id',
        displayfield: 'user_id',
        editable: false,
        width: '10%' 
      },
      {
        text: 'Status',  
        datafield: 'status',
        displayfield: 'status',
        editable: false,
        width: '15%' 
      },
      {
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: false,
        width: '70%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickAllowListEducators(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteAllowList(rowdata.id, 1);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();

    if(this.arr_time_outs && this.arr_time_outs.length > 0) {
      this.arr_time_outs.forEach((element: any) => {
        clearTimeout(element);
      })

      this.arr_time_outs = new Array();
    }
  }

  closeAlertTime() {
    const timeoutId = setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
      this.arr_time_outs = this.arr_time_outs.filter((element: any, index: number) => index != 0);
    }, 20000);

    this.arr_time_outs.push(timeoutId);
  }
}
