import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../_services/auth.service';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, Event as NavigationEvent, NavigationStart, NavigationEnd, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  private URL_BASE: string;
  public alerts: any;
  public user_id: number = 0;
  public password: string = "";
  public password_confirmation: string = "";

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: any = '';

  constructor(
    private configuration: ConfigurationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService, 
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();

    this.alerts = new Array();
  }

  ngAfterContentInit(): void {
    if(this.router.url) {
      var url = this.router.url;
      this.user_id = parseInt(url.split("/")[2]);

      if (!this.tokenStorage.getToken()) {
        setTimeout(() => {
          this.http.get(this.URL_BASE + 'validate-change-password/' + this.user_id).subscribe(res => {
            if(!res) {
              this.router.navigateByUrl("/login");
            }
          });
        }, 100);
      }
    }
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/home");
    }
  }

  onSubmit(): void {
    this.authService.changePassword(this.user_id, this.password, this.password_confirmation).subscribe({
      next: res => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          setTimeout(() => {
            this.router.navigateByUrl("login");
          }, 5000);
        }
      },
      error: err => {
        console.log(err);
        if(err.error) {
          this.errorMessage = err.error;
        } else {
          this.errorMessage = err;
        }
        this.errorMessage.type = "red";
        this.alerts.push(this.errorMessage);
        return this.closeAlertTime();
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }
}
