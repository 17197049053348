<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-100">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                <span class="sr-only">CLOSE</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting" class="mb-2">
      <h2 class="text-lg font-medium leading-6 text-gray-900">My Profile</h2>
      <p class="mt-1 text-sm text-gray-500">Delete, Edit, Update and Change Password</p>
    </div>

    <div class="pb-6">
      <div class="h-24 bg-orange-600 sm:h-8 lg:h-16"></div>
      <div class="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
        <div>
          <div class="-m-1 flex items-end">
            <div class="inline-flex overflow-hidden rounded-full border-4 border-white bg-gray-200">
              <input type="file" class="hidden" name="photo" #imgPhoto accept=".png, .jpg, .jpeg" (change)="uploadPhotoOnChange($event)"/>
              <img *ngIf="photo" src="{{ photo }}" class="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 cursor-pointer" (click)="imgPhoto.click()">
              <svg *ngIf="!photo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 cursor-pointer" (click)="imgPhoto.click()">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </div>
            <div class="rounded-full w-9 h-9 flex justify-center items-center bg-orange-600 border-2 text-white text-xl font-bold cursor-pointer ml-1 hover:bg-orange-500" (click)="deletePhotoOnClick()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5  m-auto">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </div>
          </div>
        </div>
        <div class="mt-5 mb-5 sm:ml-6 sm:flex-1">
          <div class="flex items-center">
            <h3 class="text-xl font-bold text-gray-900 sm:text-2xl" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4">
              {{ first_name }} {{ second_name }} {{ last_name }}
            </h3>
            <h3 class="text-xl font-bold text-gray-900 sm:text-2xl" *ngIf="type_user_profile_id == 2 || type_user_profile_id == 3">
              {{ business_name }}
            </h3>
            <span class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
              <span class="sr-only">Activo</span>
            </span>
          </div>
          <p class="text-sm text-gray-500">{{ email }}</p>
        </div>
      </div>
    </div>
    
    <div class="py-5">
      <div class="mt-6 mb-6" x-data="{acc_general: true, acc_address: false, acc_contacts: false, acc_map: false}">
        <h2>
          <button type="button" x-bind:class="acc_general ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = !acc_general, acc_address = false, acc_contacts = false, acc_map = false">
            <span>General</span>
            <svg x-bind:class="acc_general ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div x-show="acc_general">
          <div class="grid grid-cols-12 gap-4 p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <div class="col-span-12 sm:col-span-3">
              <label for="type_user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">
                User Profile Type <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxComboBox #comboboxTypeUserProfile
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="type_user_profiles" 
                [theme]="'energyblue'"
                [(ngModel)]="type_user_profile_id"
                (onSelect)="userProfileTypeOnSelect($event)"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
                [disabled]="true"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-3">
              <div class="group flex relative cursor-pointer">
                <label for="rol_id" class="block text-sm font-medium text-gray-700 mb-1">
                  Role <span class="text-xss text-red-500">(Required)</span>
                </label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">Enter the role</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>
              <jqxComboBox #comboboxRol
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="roles" 
                [theme]="'energyblue'"
                [(ngModel)]="rol_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-3">
              <div class="group flex relative cursor-pointer">
                <label for="user_profile_id" class="block text-sm font-medium text-gray-700">User Profile ID</label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">User Profile ID</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>

              <input type="text" autocomplete="user_profile_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="user_profile_id" disabled>
            </div>

            <div class="col-span-12 sm:col-span-3">
              <label for="status" class="block text-sm font-medium text-gray-700">
                Status <span class="text-xss text-red-500">(Required)</span>
              </label>
              <input type="text" autocomplete="status" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" disabled="" value="Active">
            </div>

            <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
              <label for="first_name" class="block text-sm font-medium text-gray-700">
                First Name <span class="text-xss text-red-500">(Required)</span>
              </label>
              <input type="text" autocomplete="first_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="first_name">
            </div>

            <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
              <label for="second_name" class="block text-sm font-medium text-gray-700">Middle Name</label>
              <input type="text" autocomplete="second_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="second_name">
            </div>

            <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
              <label for="last_name" class="block text-sm font-medium text-gray-700">
                Last Name <span class="text-xss text-red-500">(Required)</span>
              </label>
              <input type="text" autocomplete="last_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="last_name">
            </div>

            <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
              <label for="nick_name" class="block text-sm font-medium text-gray-700">Nickname</label>
              <input type="text" autocomplete="nick_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="nickname">
            </div>

            <div class="col-span-12 sm:col-span-9" *ngIf="type_user_profile_id == 2">
              <label for="business_name" class="block text-sm font-medium text-gray-700">
                School Name <span class="text-xss text-red-500">(Required)</span>
              </label>
              <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="business_name">
            </div>

            <div class="col-span-12 sm:col-span-12" *ngIf="type_user_profile_id == 3">
              <label for="business_name" class="block text-sm font-medium text-gray-700">
                Organization Name <span class="text-xss text-red-500">(Required)</span>
              </label>
              <input type="text" autocomplete="business_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="business_name">
            </div>

            <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <label for="organization_id" class="block text-sm font-medium text-gray-700 mb-1">
                Organization Parent <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxComboBox #comboboxOrganization
                [width]="'100%'"
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="organizations" 
                [theme]="'energyblue'"
                [(ngModel)]="organization_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-3">
              <label for="type_identification_id" class="block text-sm font-medium text-gray-700 mb-1">
                Type Identification <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxComboBox #comboboxTypeIdentification
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="type_identifications" 
                [theme]="'energyblue'"
                [(ngModel)]="type_identification_id"
                (onSelect)="onChangeFormatIdentification($event)"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-3">
              <div class="group flex relative cursor-pointer">
                <label for="identification" class="block text-sm font-medium text-gray-700">
                  Identification Number <span class="text-xss text-red-500">(Required)</span>
                </label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">Enter the identification number</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>

              <input type="text" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="identification" [brmasker]="mask_identification">
            </div>

            <div class="col-span-12 sm:col-span-3" *ngIf="type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5">
              <label for="birth_date" class="block text-sm font-medium text-gray-700 mb-1">
                Birth Date <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxDateTimeInput 
                [width]="'100%'" 
                [height]="38"
                [formatString]="'MM-dd-yyyy'"
                [(ngModel)]="birth_date" 
                [theme]="'energyblue'"
              ></jqxDateTimeInput>
            </div>

            <div class="col-span-12 sm:col-span-3  {{ type_user_profile_id == 2 || type_user_profile_id == 3 ? 'hidden' : 'block' }}">
              <label for="gender_id" class="block text-sm font-medium text-gray-700 mb-1">Gender</label>
              <jqxComboBox #comboboxGender
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="genders" 
                [theme]="'energyblue'"
                [(ngModel)]="gender_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12">
              <hr>
            </div>

            <div class="col-span-12 sm:col-span-6">
              <div class="group flex relative cursor-pointer">
                <label for="email" class="block text-sm font-medium text-gray-700">
                  Email <span class="text-xss text-red-500">(Required)</span>
                </label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">Enter the email</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>

              <input type="text" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="email">
            </div>

            <div class="col-span-12 sm:col-span-3">
              <div class="group flex relative cursor-pointer">
                <label for="cell_phone" class="block text-sm font-medium text-gray-700">
                  Mobile Phone <span class="text-xss text-red-500">(Required)</span>
                </label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">Enter the mobile phone</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>

              <input type="number" min="0" (input)="numberInput($event)" autocomplete="cell_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
            </div>

            <div class="col-span-12 sm:col-span-3">
              <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
              <input type="number" min="0" (input)="numberInput($event)" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
            </div>

            <div class="col-span-12 sm:col-span-12 bg-[#e8f0fe] px-1 py-5">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Current Address Information</h2>
                  <p class="mt-1 text-sm text-gray-500">Current Address Information will be use for your GPS location.</p>
                </div>

                <div class="col-span-12 sm:col-span-3 hidden">
                  <label for="country_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Current Country <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxCountry
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'common_name'" 
                    [valueMember]="'id'" 
                    [source]="countries" 
                    [theme]="'energyblue'"
                    [(ngModel)]="country_id"
                    (onSelect)="countriesOnSelect($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="state_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Current State <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <input type="text" autocomplete="state_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" value="GEORGIA" disabled>
                  <!-- <jqxComboBox #comboboxState
                    [width]="'100%'" 
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'"
                    [disabled]="true"
                    [source]="states" 
                    [theme]="'energyblue'"
                    [(ngModel)]="state_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox> -->
                </div>

                <div class="col-span-12 sm:col-span-3 hidden">
                  <label for="county_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Current County <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxCounty
                    [width]="'100%'" 
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="counties" 
                    [theme]="'energyblue'"
                    [(ngModel)]="county_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="address" class="block text-sm font-medium text-gray-700">
                    Current Address <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="address" (blur)="changeAddress()">
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="city_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Current City <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxCity
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="cities" 
                    [theme]="'energyblue'"
                    (onSelect)="cityOnSelect($event)"
                    (focusout)="cityFocusout($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                    Current ZIP Code <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxZipCode
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'code'" 
                    [valueMember]="'code'" 
                    [source]="zip_codes" 
                    [theme]="'energyblue'"
                    (focusout)="zipCodeFocusout($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-9"></div>
                <div class="col-span-12 sm:col-span-3">
                  <button type="submit" (click)="duplicateCurrentOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Duplicate Current Address</button>
                </div>
              </div>
            </div>

            <div class="col-span-12">
              <hr>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Permanent Address</h2>
                  <p class="mt-1 text-sm text-gray-500">Permanent Address will be used as a secondary mailing address.</p>
                </div>
                          
                <div class="col-span-12 sm:col-span-3">
                  <label for="permanent_state_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Permanent State <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxPermanentState
                    [width]="'100%'" 
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'"
                    [source]="permanent_states" 
                    [theme]="'energyblue'"
                    [(ngModel)]="permanent_state_id"
                    (onSelect)="permanentStateOnSelect($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="permanent_address" class="block text-sm font-medium text-gray-700">
                    Permanent Address <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <input type="text" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="permanent_address">
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="permanent_city_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Permanent City <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxPermanentCity
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="permanent_cities" 
                    [theme]="'energyblue'"
                    (onSelect)="permanentCityOnSelect($event)"
                    (focusout)="permanentCityFocusout($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="permanent_zip_code" class="block text-sm font-medium text-gray-700 mb-1">
                    Permanent ZIP Code <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxPermanentZipCode
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'code'" 
                    [valueMember]="'code'" 
                    [source]="permanent_zip_codes" 
                    [theme]="'energyblue'"
                    (focusout)="permanentZipCodeFocusout($event)"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-{{ type_user_profile_id == 1 || type_user_profile_id == 4 || type_user_profile_id == 5 ? '6' : '3' }} {{ type_user_profile_id == 2 || type_user_profile_id == 3 ? 'hidden' : 'block' }}">
              <div class="group flex relative cursor-pointer">
                <label for="hire_date" class="block text-sm font-medium text-gray-700 mb-1">
                  Hire Date <span class="text-xss text-red-500">(Required)</span>
                </label>

                <div class="absolute invisible bottom-7 w-full group-hover:visible bg-[#e8f0fe] text-gray-500 px-2 py-1 text-sm rounded-md border border-gray-300">  
                  <p class="leading-2 pt-2 pb-2 w-full">Enter the hire date</p>
                </div>
                <span class="underline hover:cursor-pointer ml-2 text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </span>
              </div>

              <jqxDateTimeInput 
                [width]="'100%'" 
                [height]="38"
                [formatString]="'MM-dd-yyyy'"
                [(ngModel)]="hire_date" 
                [theme]="'energyblue'"
              ></jqxDateTimeInput>
            </div>

            <div class="col-span-12 sm:col-span-6 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <label for="pay_rate_id" class="block text-sm font-medium text-gray-700 mb-1">
                Educator Pay Rate <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxComboBox #comboboxEducatorLevels
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="educator_pay_rates" 
                [theme]="'energyblue'"
                [(ngModel)]="pay_rate_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-6 {{ type_user_profile_id == 5 ? 'block' : 'hidden' }}">
              <label for="parent_id" class="block text-sm font-medium text-gray-700 mb-1">
                Parent <span class="text-xss text-red-500">(Required)</span>
              </label>
              <jqxComboBox #comboboxParent
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'name'" 
                [valueMember]="'id'" 
                [source]="parent_user_profiles" 
                [theme]="'energyblue'"
                [(ngModel)]="parent_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
            </div>

            <div class="col-span-12 sm:col-span-{{ type_user_profile_id == 3 || type_user_profile_id == 5 ? '12' : '6' }}">
              <label for="observation" class="block text-sm font-medium text-gray-700">Comments</label>
              <input type="text" autocomplete="observation" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="observation">
            </div>

            <div class="col-span-12 sm:col-span-6 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <label for="direction_radius" class="block text-sm font-medium text-gray-700 mb-1">
                Educator Coverage Radius (miles)
              </label>
              <input type="text" autocomplete="direction_radius" (input)="numberInputRadius($event)" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="direction_radius">
            </div>

            <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
              <jqxCheckBox #checkSaturdayWork
                [width]="'100%'" 
                [height]="38"
                [theme]="'energyblue'"
              >Saturday Work
              </jqxCheckBox>
            </div>

            <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
              <jqxCheckBox #checkSundayWork
                [width]="'100%'" 
                [height]="38"
                [theme]="'energyblue'"
              >Sunday Work
              </jqxCheckBox>
            </div>
          </div>
        </div>

        <h2>
          <button type="button" x-bind:class="acc_contacts ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = false, acc_address = false, acc_contacts = !acc_contacts, acc_map = false">
            <span>Additional Details</span>
            <svg x-bind:class="acc_contacts ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div x-show="acc_contacts">
          <div class="grid grid-cols-12 gap-4 p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <div class="col-span-12 sm:col-span-12">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Personal Contacts</h2>
                  <p class="mt-1 text-sm text-gray-500">Add another Contact in case of Emergency or additional reference</p>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="contact_type_contact_id" class="block text-sm font-medium text-gray-700">Type Contact</label>
                  <jqxComboBox
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="type_contacts" 
                    [theme]="'energyblue'"
                    [(ngModel)]="contact_type_contact_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="contact_name" class="block text-sm font-medium text-gray-700">Contact Name</label>
                  <input type="text" name="contact_name" id="contact_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_name">
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label for="contact_email" class="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_email">
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label for="contact_mobile_phone" class="block text-sm font-medium text-gray-700">Mobile Phone</label>
                  <input type="number" min="0" (input)="numberInput($event)" name="contact_mobile_phone" id="contact_mobile_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_mobile_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
                </div>
                <div class="col-span-12 sm:col-span-9"></div>
                <div class="col-span-12 sm:col-span-3">
                  <!-- <label class="block text-sm font-medium text-gray-700">&nbsp;</label> -->
                  <button type="submit" (click)="addContactOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Add Contact</button>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <jqxTooltip #tooltipReference [position]="'mouse'" [name]="'name'"></jqxTooltip>
                  <jqxGrid #gridContacts
                    [width]="'100%'" 
                    [cellhover]='cellHoverContacts'
                    [source]="dataAdapterContacts" 
                    [columns]="columnsContacts"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="true" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickContacts($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">
                    Teacher About / Bio <span class="text-xss text-red-500">(Required)</span>
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">Teacher profile description</p>
                  <input type="text" autocomplete="about-bio" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="teacher_about">
                </div>
              </div>

              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-4">
                  <label for="document_type_id" class="block text-sm font-medium text-gray-700 mb-1">Document type</label>
                  <jqxComboBox
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="type_documents" 
                    [theme]="'energyblue'"
                    [(ngModel)]="document_type_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label for="document" class="block text-sm font-medium text-gray-700 mb-1">Document (PDF only)</label>
                  <input type="file" id="documentInput" class="hidden" accept="application/pdf" (change)="uploadDocumentOnChange($event)" [(ngModel)]="document">
                  <button class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick="document.getElementById('documentInput').click();">Select file</button>
                  <small>{{ document_name }}</small>
                </div>
                <div class="col-span-12 sm:col-span-2">
                  <label for="document_validity_date" class="block text-sm font-medium text-gray-700 mb-1">Expiration Date</label>
                  <jqxDateTimeInput 
                    [width]="'100%'" 
                    [height]="38"
                    [formatString]="'MM-dd-yyyy'"
                    [(ngModel)]="document_validity_date" 
                    [theme]="'energyblue'"
                  ></jqxDateTimeInput>
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <button type="submit" (click)="addDocumentOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Document</button>
                </div>
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridDocuments
                    [width]="'100%'" 
                    [cellhover]='cellHoverDocuments'
                    [source]="dataAdapterDocuments" 
                    [columns]="columnsDocuments"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="true" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickDocuments($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Education & Work Experience</h2>
                  <!-- <p class="mt-1 text-sm text-gray-500">Add not available dates list</p> -->
                </div>

                <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <label for="level_temp_id" class="block text-sm font-medium text-gray-700 mb-1">Temporary Level</label>
                  <jqxComboBox #comboboxLevelTemp
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="levels" 
                    [theme]="'energyblue'"
                    [(ngModel)]="level_temp_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <label for="experience_temp_id" class="block text-sm font-medium text-gray-700 mb-1">Temporary Experience</label>
                  <jqxComboBox #comboboxExperienceTemp
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="experiences" 
                    [theme]="'energyblue'"
                    [(ngModel)]="experience_temp_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <label for="level_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Level <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxLevel
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="levels" 
                    [theme]="'energyblue'"
                    [(ngModel)]="level_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <label for="experience_id" class="block text-sm font-medium text-gray-700 mb-1">
                    Experience <span class="text-xss text-red-500">(Required)</span>
                  </label>
                  <jqxComboBox #comboboxExperience
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="experiences" 
                    [theme]="'energyblue'"
                    [(ngModel)]="experience_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
              </div>                    
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 2 || type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">School Preferred List</h2>
                  <p class="mt-1 text-sm text-gray-500">Add Educators from the list below</p>
                </div>

                <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Allow List of Schools for Educators</h2>
                  <p class="mt-1 text-sm text-gray-500">Agency can only select a Educator associated to a School without radius restriction</p>
                </div>

                <div class="col-span-12 sm:col-span-9  {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
                  <label for="allow_educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
                  <jqxComboBox
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="educators" 
                    [theme]="'energyblue'"
                    [(ngModel)]="allow_educator_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-9 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
                  <label for="allow_school_id" class="block text-sm font-medium text-gray-700">School</label>
                  <jqxComboBox
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'business_name'" 
                    [valueMember]="'id'" 
                    [source]="schools" 
                    [theme]="'energyblue'"
                    [(ngModel)]="allow_school_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>

                <div class="col-span-12 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <button type="submit" (click)="addAllowListOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add to Lists</button>
                </div>

                <div class="col-span-12 sm:col-span-12" *ngIf="type_user_profile_id == 2 && ranking_educators && ranking_educators.length > 0">
                  <div class="grid grid-cols-10 gap-4 mt-2 mb-2">
                    <div class="col-span-10 sm:col-span-10">
                      <div class="bg-gray-100 py-2 px-4">
                        <h2 class="text-xl font-semibold text-gray-800">Preferred Educator</h2>
                      </div>
                    </div>
                    <div class="col-span-10 sm:col-span-2 flex" *ngFor="let rank_educator of ranking_educators">
                      <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div class="flex flex-col items-center mx-2 pt-3">
                          <div class="relative inline-block text-center">
                            <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="{{ rank_educator.photo }}" alt="Educator image" *ngIf="rank_educator.photo"/>
                            <img class="w-24 h-24 mb-1 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Educator image" *ngIf="!rank_educator.photo"/>
                            <div class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-full w-6 bg-orange-600 text-white rounded-full text-sm">
                              {{ rank_educator.order }}
                            </div>
                          </div>
                          <div class="mb-1 text-base text-center text-orange-600 dark:text-white" style="min-height: 50px;">
                            {{ rank_educator.first_name }} {{ rank_educator.second_name }} {{ rank_educator.last_name }}
                          </div>
                          <p class="text-sm text-gray-600 text-base" *ngIf="user_type == 1">{{ rank_educator.score }}%</p>
                          <button type="submit" (click)="addAllowListOnClick(rank_educator.user_profile_id)" class="my-2 w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Add to List</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridAllowList
                    [width]="'100%'" 
                    [cellhover]='cellHoverAllowList'
                    [source]="dataAdapterAllowList" 
                    [columns]="columnsAllowList"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="false" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickAllowList($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ user_type == 1 && type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">School Allow List Summary</h2>
                  <p class="mt-1 text-sm text-gray-500">Educator to school without radius restrictions</p>
                </div>
              
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridAllowListEducators
                    [width]="'100%'" 
                    [cellhover]='cellHoverAllowListEducators'
                    [source]="dataAdapterAllowListEducators" 
                    [columns]="columnsAllowListEducators"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="false" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickAllowListEducators($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 4 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Blocked Dates</h2>
                  <p class="mt-1 text-sm text-gray-500">Add dates when the Educator is not available to work</p>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <label for="not_working_start_date" class="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                  <jqxDateTimeInput 
                    [width]="'100%'" 
                    [height]="38"
                    [formatString]="'MM-dd-yyyy'"
                    [(ngModel)]="not_working_start_date" 
                    [theme]="'energyblue'"
                  ></jqxDateTimeInput>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <label for="not_working_end_date" class="block text-sm font-medium text-gray-700 mb-1">End Date</label>
                  <jqxDateTimeInput 
                    [width]="'100%'" 
                    [height]="38"
                    [formatString]="'MM-dd-yyyy'"
                    [(ngModel)]="not_working_end_date" 
                    [theme]="'energyblue'"
                  ></jqxDateTimeInput>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <button type="submit" (click)="addNotWorkingDatesOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Blocked Dates</button>
                </div>
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridNotWorkingDates
                    [width]="'100%'" 
                    [cellhover]='cellHoverNotWorkingDates'
                    [source]="dataAdapterNotWorkingDates" 
                    [columns]="columnsNotWorkingDates"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="true" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickNotWorkingDates($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Restricted List of Educators</h2>
                  <p class="mt-1 text-sm text-gray-500">Add educator restricted list</p>
                </div>
                <div class="col-span-12 sm:col-span-6">
                  <label for="wb_educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
                  <jqxComboBox
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="all_educators" 
                    [theme]="'energyblue'"
                    [(ngModel)]="wb_educator_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label for="wb_type_id" class="block text-sm font-medium text-gray-700">Type</label>
                  <jqxComboBox #comboboxWBType
                    [width]="'100%'"  
                    [height]="38" 
                    [displayMember]="'name'" 
                    [valueMember]="'id'" 
                    [source]="wb_types" 
                    [theme]="'energyblue'"
                    [(ngModel)]="wb_type_id"
                    [autoComplete]="true" 
                    [searchMode]="'containsignorecase'"
                  ></jqxComboBox>
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <button type="submit" (click)="addWBListOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Lists</button>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridWBLists
                    [width]="'100%'" 
                    [cellhover]='cellHoverWBLists'
                    [source]="dataAdapterWBLists" 
                    [columns]="columnsWBLists"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="true" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickWBLists($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 {{ type_user_profile_id == 2 ? 'block' : 'hidden' }}">
              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <h2 class="text-lg font-medium leading-6 text-gray-900">Specific Holidays</h2>
                  <p class="mt-1 text-sm text-gray-500">Add specific holidays</p>
                </div>
                <div class="col-span-12 sm:col-span-9">
                  <label for="holiday_date" class="block text-sm font-medium text-gray-700">Date</label>
                  <jqxDateTimeInput 
                    [width]="'100%'" 
                    [height]="38"
                    [formatString]="'MM-dd-yyyy'"
                    [(ngModel)]="holiday_date" 
                    [theme]="'energyblue'"
                  ></jqxDateTimeInput>
                </div>
                <div class="col-span-12 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <button type="submit" (click)="addHolidayOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Holiday</button>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-12">
                  <jqxGrid #gridSpecificHolidays
                    [width]="'100%'" 
                    [cellhover]='cellHoverSpecificHolidays'
                    [source]="dataAdapterSpecificHolidays" 
                    [columns]="columnsSpecificHolidays"
                    [pageable]="true" 
                    [autoheight]="true" 
                    [showfilterrow]="true"
                    [columnsresize]="true"
                    [filterable]="true"
                    [sortable]="true"  
                    [editable]="true" 
                    [editmode]="'dblclick'"
                    [selectionmode]="'singlerow'"
                    (onCellclick)="onCellClickSpecificHolidays($event)"
                    [localization]="localization"
                    [theme]="'energyblue'"
                  ></jqxGrid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2>
          <button type="button" x-bind:class="acc_map ? 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 bg-orange-600 text-white gap-3' : 'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'" x-on:click="acc_general = false, acc_address = false, acc_contacts = false, acc_map = !acc_map">
            <span>Map Location</span>
            <svg x-bind:class="acc_map ? 'w-3 h-3 shrink-0' : 'w-3 h-3 rotate-180 shrink-0'" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div x-show="acc_map">
          <div class="grid grid-cols-12 gap-4 p-4 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <div class="col-span-12 sm:col-span-12">
              <h2 class="text-lg font-medium leading-6 text-gray-900">Map Location</h2>
              <p class="mt-1 mb-2 text-sm text-gray-500"><b>Important!</b> The user's Current Address Information for this profile is used for your GPS location. However, you can adjust the location by interacting with the map below using the map's Pin.</p>
              <!-- <label for="markerPositions" class="block text-sm font-medium text-gray-700 mb-1">Map</label> -->
              <google-map 
                width="100%"
                height="350px"
                [center]="center"
                [zoom]="mapZoom"
                [options]="mapOptions"
                (mapClick)="addMarker($event)">
                <map-marker #markerElem="mapMarker" *ngFor="let markerPosition of markerPositions"
                  [title]="markerPosition.title"
                  [position]="markerPosition.position"
                  [options]="markerPosition.options"
                  (mapClick)="openInfoWindow(markerElem, markerPosition.info)"
                ></map-marker>
                <map-info-window>{{ markerInfo.title }}</map-info-window>
              </google-map>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-2">
          <button type="submit" (click)="updateOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Update</button>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <button type="submit" (click)="modalPassword = true" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Change Password</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div *ngIf="modalPassword" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-1 text-center sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase" id="modal-title">PASSWORD CHANGE FOR {{ first_name }} {{ last_name }}</h3>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">  
              <label for="new_password" class="block text-sm font-medium text-gray-700">New Password</label>
              <input type="text" name="new_password" id="new_password" autocomplete="new_password" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="new_password">
            </div>
            <div class="col-span-12 sm:col-span-6">    
              <label for="new_password_confirm" class="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input type="text" name="new_password_confirm" id="new_password_confirm" autocomplete="new_password_confirm" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="new_password_confirm">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modalPassword = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
          <button type="button" (click)="changePasswordUserOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalConfirmDeleteDocument" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-7/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-64 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-58">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Delete Document</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalConfirmDeleteDocument = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 text-center">
              <h2 class="text-lg font-medium">Are you sure you want to remove this document?</h2>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="deleteDocument(delete_document_id); modalConfirmDeleteDocument = false;" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Delete</button>
          <button type="button" (click)="modalConfirmDeleteDocument = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>