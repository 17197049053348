import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-educator-pay-rates',
  templateUrl: './educator-pay-rates.component.html',
  styleUrls: ['./educator-pay-rates.component.css']
})
export class EducatorPayRatesComponent {
  //GRIDS
  @ViewChild('gridEducatorPayRates', { static: false }) gridEducatorPayRates: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxEducatorLevels', { static: false }) comboboxEducatorLevels: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public educator_levels: any;

  public level_id: number = 0;

  public educator_pay_rates: any;
  public sourceEducatorPayRates: any;
  public dataAdapterEducatorPayRates: any = [];
  public columnsEducatorPayRates: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('users');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();
      this.loadDataGrid();
      this.createGridEducatorPayRates();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataForm() {
    this.http.get(this.URL_BASE + 'educator_levels').subscribe(res => {
      var sourceEducatorLevels =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'level', type: 'string' },
          { name: 'experience', type: 'string' }
        ]
      };

      this.educator_levels = new jqx.dataAdapter(sourceEducatorLevels, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " (" + fila.level + " " + fila.experience + ")";

            data.push(fila);
          }
        },
      }); 
    });
  }

  loadDataGrid() {
    this.http.get(this.URL_BASE + 'educator_pay_rates').subscribe(res => {
      this.educator_pay_rates = res;

      this.sourceEducatorPayRates.localdata = this.educator_pay_rates;
      this.gridEducatorPayRates.updatebounddata('cells');
    });
  }

  loadDataSchoolGrid(educator_level_id: number) {
    this.http.get(this.URL_BASE + 'educator_pay_rates/filter-educator-level/' + educator_level_id).subscribe(res => {

      this.sourceEducatorPayRates.localdata = res;
      this.gridEducatorPayRates.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  registerOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createEducatorPayRate();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  cleanOnClick() {
    this.clearForm();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  userProfileOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDataSchoolGrid(item);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createEducatorPayRate() {
    if(!this.level_id || this.level_id == 0) {
      let error = {
        message: "Select a level to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'educator_pay_rate', 
      { 
        level_id: this.level_id, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          this.loadDataGrid();
        } else {
          this.gridEducatorPayRates.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editEducatorPayRates(Rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'educator_pay_rate/' + Rowdata.id, 
      {
        level_id: Rowdata.level_id, 
        cc_ph: Rowdata.cc_ph,
        cc_phlt: Rowdata.cc_phlt,
        gs_hd: Rowdata.gs_hd,
        gs_fd: Rowdata.gs_fd,
        gs_fdlt: Rowdata.gs_fdlt,
        asp_ph: Rowdata.asp_ph,
        asp_phlt: Rowdata.asp_phlt,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          this.loadDataGrid();
        } else {
          this.gridEducatorPayRates.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteEducatorPayRate(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'educator_pay_rate/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "orange") {
          this.loadDataGrid();
        } else {
          this.gridEducatorPayRates.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.level_id = 0;

    this.sourceEducatorPayRates.localdata = this.educator_pay_rates;
    this.gridEducatorPayRates.updatebounddata('cells');
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridEducatorPayRates() {
    this.sourceEducatorPayRates =
    {
      localdata: this.educator_pay_rates,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'level_id', type: 'int' },
        { name: 'educator_level', type: 'string' },
        { name: 'level', type: 'string' },
        { name: 'experience', type: 'string' },
        { name: 'cc_ph', type: 'integer' },
        { name: 'cc_phlt', type: 'integer' },
        { name: 'gs_hd', type: 'integer' },
        { name: 'gs_fd', type: 'integer' },
        { name: 'gs_fdlt', type: 'integer' },
        { name: 'asp_ph', type: 'integer' },
        { name: 'asp_phlt', type: 'integer' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editEducatorPayRates(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterEducatorPayRates = new jqx.dataAdapter(this.sourceEducatorPayRates, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          fila.educator_level = fila.educator_level + " (" + fila.level + " " + fila.experience + ")";

          data.push(fila);
        }
      },
    });

    this.columnsEducatorPayRates =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        hidden: true,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Educator Level',  
        datafield: 'level_id',
        displayfield: 'educator_level',
        editable: false,
        width: '37%'
      },
      { 
        text: 'CC-PH',
        datafield: 'cc_ph',
        displayfield: 'cc_ph',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'CC-PHLT',
        datafield: 'cc_phlt',
        displayfield: 'cc_phlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-HD',  
        datafield: 'gs_hd',
        displayfield: 'gs_hd',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-FD',  
        datafield: 'gs_fd',
        displayfield: 'gs_fd',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-FDLT',  
        datafield: 'gs_fdlt',
        displayfield: 'gs_fdlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'ASP-PH',  
        datafield: 'asp_ph',
        displayfield: 'asp_ph',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'ASP-PHLT',  
        datafield: 'asp_phlt',
        displayfield: 'asp_phlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClick(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteEducatorPayRate(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
