import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent {
  
  mailMe(){
    window.open("https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=support@info@ensolutions.app&tf=cm");
  }
}
