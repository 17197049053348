<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
          orange: colors.orange,
          rose: colors.rose,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/line-clamp'),
    ],
  }
  ```
-->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-100">
  <body class="h-full">
  ```
-->
<div class="bg-green-100 hidden"></div><div class="bg-red-100 hidden"></div><div class="bg-orange-100 hidden"></div>

<div class="min-h-full">
  <div class="bg-gray-100" *ngIf="route == 'principal'">
    <header class="bg-gradient-to-r from-orange-600 to-orange-600 pb-24" x-data="{open_menu: false}">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-full lg:px-8">
        <div class="relative flex flex-wrap items-center justify-center lg:justify-between">
          <!-- Logo -->
          <div class="absolute left-0 flex-shrink-0 py-5 lg:static">
            <a href="/home">
              <span class="sr-only">Educators NOW!</span>
              <img *ngIf="logo" class="h-8 w-auto" src="{{ logo }}" alt="logo_company">
            </a>
          </div>

          <!-- Right section on desktop -->
          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
            <!-- <button type="button" class="flex-shrink-0 rounded-full p-1 text-orange-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
              <span class="sr-only">View notifications</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
            </button> -->

            <!-- Profile dropdown -->
            <div class="relative ml-4 flex-shrink-0" x-data="{open: false}">
              <div>
                <button type="button" class="flex rounded-full bg-gray-200 text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100" id="user-menu-button" aria-expanded="false" aria-haspopup="true" x-on:click="open = ! open">
                  <span class="sr-only">Open user menu</span>
                  <svg *ngIf="!photo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 h-8 w-8 rounded-full">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                  <img *ngIf="photo" class="h-8 w-8 rounded-full" src="{{ photo }}" alt="">
                </button>
              </div>

              <!--
                Dropdown menu, show/hide based on menu state.

                Entering: ""
                  From: ""
                  To: ""
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div class="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1" x-show="open" x-on:click.away="open = false">
                <!-- Active: "bg-gray-100", Not Active: "" -->
                <a href="/submenus/my_profile/user-profile" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">My Profile</a>
                <a href="/submenus/my_profile/tasks" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Tasks</a>
                <a (click)="logout()" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign Out</a>
              </div>
            </div>
          </div>

          <div class="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
            <div class="lg:grid lg:grid-cols-3 lg:items-center lg:gap-8">
              <!-- Left nav -->
              <div class="hidden lg:col-span-2 lg:block">
                <nav class="flex space-x-4">
                  <a href="/home" *ngIf="isLoggedIn && menus" class="{{ route_active == 'home' ? 'text-white' : 'text-orange-100' }}  text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10" aria-current="page">Home</a>
                 <!--  <a href="/landing" *ngIf="isLoggedIn && menus" class="{{ route_active == 'home' ? 'text-white' : 'text-orange-100' }}  text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10" aria-current="page">Landing</a> -->

                  <a href="/login" *ngIf="!isLoggedIn" class="text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10" aria-current="page">Sign in</a>

                  <a *ngFor="let menu of menus;" href="{{ menu.submenus.length == 0 ? '/' + menu.link : '/submenus/' + menu.link + '/' + menu.submenus[0].link }}" class="{{ menu.link == route_active ? 'text-white' : 'text-orange-100' }}  text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10">
                    {{ menu.alias }}
                  </a>
                </nav>
              </div>
              <div class="px-12 lg:px-0">
                <!-- Search -->
                <!-- <div class="mx-auto w-full max-w-xs lg:max-w-md">
                  <label for="search" class="sr-only">Search</label>
                  <div class="relative text-white focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input id="search" class="block w-full rounded-md border border-transparent bg-white bg-opacity-20 py-2 pl-10 pr-3 leading-5 text-white placeholder-white focus:border-transparent focus:bg-opacity-100 focus:text-gray-900 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Search" type="search" name="search">
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <!-- Menu button -->
          <div class="absolute right-0 flex-shrink-0 lg:hidden">
            <!-- Mobile menu button -->
            <button type="button" class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-orange-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" aria-expanded="false" x-on:click="open_menu = ! open_menu">
              <span class="sr-only">Open main menu</span>
              <!--
                Heroicon name: outline/bars-3

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <!--
                Heroicon name: outline/x-mark

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on mobile menu state. -->
      <div class="lg:hidden"  x-show="open_menu" x-on:click.away="open_menu = false">
        <!--
          Mobile menu overlay, show/hide based on mobile menu state.

          Entering: "duration-150 ease-out"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "duration-150 ease-in"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 z-20 bg-black bg-opacity-25" aria-hidden="true"></div>

        <!--
          Mobile menu, show/hide based on mobile menu state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-150 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        -->
        <div class="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition">
          <div class="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="pt-3 pb-2">
              <div class="flex items-center justify-between px-4">
                <div>
                  <img *ngIf="logo" class="h-8 w-auto" src="{{ logo }}" alt="logo_company">
                </div>
                <div class="-mr-2">
                  <button type="button" class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500" x-on:click="open_menu = ! open_menu">
                    <span class="sr-only">Close menu</span>
                    <!-- Heroicon name: outline/x-mark -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="mt-3 space-y-1 px-2">
                <a href="/home" *ngIf="isLoggedIn && menus" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800" aria-current="page" x-on:click="open_menu = ! open_menu">Home</a>
                <!-- <a href="/landing" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800" aria-current="page" x-on:click="open_menu = ! open_menu">Landing</a> -->

                <a href="/login" *ngIf="!isLoggedIn" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800" aria-current="page" x-on:click="open_menu = ! open_menu">Sign in</a>

                <a *ngFor="let menu of menus;" href="{{ menu.submenus.length == 0 ? '/' + menu.link : '/submenus/' + menu.link + '/' + menu.submenus[0].link }}" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800" x-on:click="open_menu = ! open_menu">
                  {{ menu.alias }}
                </a>
              </div>
            </div>
            <div class="pt-4 pb-2">
              <div class="flex items-center px-5">
                <div class="flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                </div>
                <div class="ml-3 min-w-0 flex-1" *ngIf="isLoggedIn && user">
                  <div class="truncate text-base font-medium text-gray-800">{{ user.name }}</div>
                  <div class="truncate text-sm font-medium text-gray-500">{{ user.email }}</div>
                </div>
                <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                  <span class="sr-only">View notifications</span>
                  <!-- Heroicon name: outline/bell -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                  </svg>
                </button>
              </div>
              <div class="mt-3 space-y-1 px-2">
                <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Your Profile</a>

                <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Settings</a>

                <a (click)="logout()" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Sign out</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="-mt-24 pb-8">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-full lg:px-8">
        <h1 class="sr-only">Profile</h1>
        <!-- Main 3 column grid -->
        <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-4">
            <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-100">
              <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
                <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
                  <div class="p-4">
                    <div class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check-circle -->
                        <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                          <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
                        <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
                        <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
                      </div>
                      <div class="ml-4 flex flex-shrink-0">
                        <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                          <span class="sr-only">CLOSE</span>
                          <!-- Heroicon name: mini/x-mark -->
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <app-loader></app-loader>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </main>
  </div>

  <div class="bg-orange-600 py-10" *ngIf="route == 'login'">
    <app-login></app-login>
  </div>

  <div class="bg-orange-600 py-10" *ngIf="route == 'recover-password'">
    <app-recover-password></app-recover-password>
  </div>

  <div class="bg-orange-600 py-10" *ngIf="route == 'change-password'">
    <app-change-password></app-change-password>
  </div>

  <div class="bg-orange-600" *ngIf="route == 'landing'">
    <app-landing></app-landing>
  </div>

  <footer>
    <div class="sm:flex">
      <div class="sm:flex-1">
        <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-full lg:px-8">
          <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
            <span class="block sm:inline">&copy; {{ year }} Educators Now LLC, </span> <span class="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </div>

      <div class="sm:flex-1">
        <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-full lg:px-8">
          <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500">
            <span class="block sm:inline">version {{ VERSION }}</span>
          </div>
        </div>
      </div>

      <div class="sm:flex-1">
        <div class="mx-auto lg:px-8 sm:flex">
          <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-right sm:flex-1 sm:pr-8">
            <span class="block sm:inline"><a href="{{ URL_ADM }}/landing/terms-of-use" class="text-gray-500 hover:text-gray-900">Terms of Use</a></span>
          </div>
          <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-right">
            <span class="block sm:inline"><a href="{{ URL_ADM }}/landing/privacy-policy" class="text-gray-500 hover:text-gray-900">Privacy Policy</a></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>