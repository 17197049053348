import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-general-chats',
  templateUrl: './general-chats.component.html',
  styleUrls: ['./general-chats.component.css']
})
export class GeneralChatsComponent {

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;

  public chats: any;
  public chat_active: any;
  public conversations: any;
  public last_chat_id: number = 0;
  public last_message_id: number = 0;

  public message: string = "";

  // SEARCH CHAT
  public search_chats: any = new Array();
  public search_chat: string = "";

  // SEARCH MESSAGE
  public showSearchMessage: boolean = false;
  public search_messages: any = new Array();
  public search_message: string = "";
  public search_index_message: number = 0;
  public search_messages_change: any = new Array();

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('general-chats');
    this.user_id = this.tokenStorage.getUser();
    this.user_name = this.tokenStorage.getUserName();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();

      this.chats = new Array();
      this.conversations = new Array();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataForm() {
    this.http.get(this.URL_BASE + 'chats/filter-type/3-4').subscribe(res => {
      this.chats = new Array();
      let chats: any = res;

      var news_chat_id = 0;
      var news_chat_mess = "Without any message";
      var news_chat_date = "No date";
      var news_chat_crea_date = "No date";
      var news_chat_user_id = this.user_id;
      var news_chat_conv = [];

      if(chats && chats.length > 0) {
        let news_chat = chats.find((element: any) => element.chat_type === 4);

        if(news_chat) {
          news_chat_id = news_chat.id;
          news_chat_mess = news_chat.conversation[news_chat.conversation.length-1].message;
          news_chat_date = moment(news_chat.conversation[news_chat.conversation.length-1].date_time).format("yyyy-MM-DD");
          news_chat_crea_date = moment(news_chat.date_time).format("yyyy-MM-DD");
          news_chat_user_id = news_chat.user_id;
          news_chat_conv = news_chat.conversation;
        }
      }

      this.chats.push({
        "id": news_chat_id,
        "assignment_posting_id": null,
        "first_name": "Chat",
        "last_name": "News",
        "nombre": "Chat News",
        "icon": "Chat".charAt(0),
        "last_message": news_chat_mess,
        "start_date": news_chat_date,
        "creation_date": news_chat_crea_date,
        "user_id": news_chat_user_id,
        "chat_type": "4",
        "conversation": news_chat_conv
      });

      for (let i = 0; i < chats.length; i++) {
        if(chats[i].chat_type != 4) {
          if(chats[i].assignment_posting_id) {
            chats[i].nombre = chats[i].school_business_name;
          } else {
            chats[i].nombre = chats[i].first_name + " " + chats[i].last_name;
          }
          chats[i].icon = chats[i].nombre.charAt(0);
          this.chats.push(chats[i]);
        }
      }
      this.search_chats = this.chats;

      // console.log(this.search_chats);
    });
  }

  chatOnClick(chat_id: number, initial: number) {  
    this.http.get(this.URL_BASE + 'chat/' + chat_id).subscribe(res => {
      if(res) {
        this.chat_active = res;
      } else {
        this.chat_active = this.chats.find((element: any) => element.id === chat_id);
      }
      
      if(this.chat_active) {
        this.conversations = this.chat_active.conversation;

        this.conversations.forEach((element: any) => {
          if(element.user_name) {
            element.icon = element.user_name.charAt(0);
          } else {
            element.icon = "";
          }

          element.date = moment(element.date_time).format("MM/DD/yyyy");
        });

        setTimeout(() => {
          var new_message_id = this.conversations[(this.conversations.length - 1)].id;

          if(this.last_message_id == 0 || this.last_message_id != new_message_id) {
            this.last_message_id = new_message_id;

            const message = document.getElementById("chatMessageId" + this.last_message_id);
            if(message) {
              message.scrollIntoView({behavior: 'smooth'});
            }
          }
        }, 500);

        if(initial == 1) {
          if(this.last_chat_id > 0) {
            clearInterval(this.last_chat_id);
          }
          setTimeout(() => {
            this.last_chat_id = setInterval(() => {
              this.chatOnClick(chat_id, 0);
            }, 30000);
          }, 500);
        }
      }        
    });
  }

  searchMessageOnClick() {
    this.clearSearchBgOnClick();

    if(this.conversations && this.conversations.length > 0 && this.search_message != "" && this.search_message != " "){
      this.search_messages = this.conversations.filter((element: any) => {
        return (element.message.toLowerCase().indexOf(this.search_message.toLowerCase()) > -1);
      });

      this.searchUpMessageOnClick(1);
    }
  }

  searchUpMessageOnClick(initial: number = 0) {
    if(this.search_messages && this.search_messages.length > 0) {
      if(initial == 1) {
        this.search_index_message = 0;
      } else if((this.search_index_message - 1) >= 0) {
        this.search_index_message = (this.search_index_message - 1);
      }

      const message = document.getElementById("chatMessageId" + this.search_messages[this.search_index_message].id);
      if(message) {
        let exist_bg = this.search_messages_change.find((search: any) => search.id == "chatMessageId" + this.search_messages[this.search_index_message].id);
        if(!exist_bg) {
          var message_new = this.search_messages[this.search_index_message].message.toLowerCase().replace(this.search_message, "<span class='bg-indigo-500'>" + this.search_message + "</span>");
          message.innerHTML = message_new;
          this.search_messages_change.push(message);
        }

        message.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

  searchDownMessageOnClick() {
    if(this.search_messages && this.search_messages.length > 0) {
      if((this.search_index_message + 1) <= (this.search_messages.length - 1)) {
        this.search_index_message = (this.search_index_message + 1);

        const message = document.getElementById("chatMessageId" + this.search_messages[this.search_index_message].id);
        if(message) {
          let exist_bg = this.search_messages_change.find((search: any) => search.id == "chatMessageId" + this.search_messages[this.search_index_message].id);
          if(!exist_bg) {
            var message_new = this.search_messages[this.search_index_message].message.toLowerCase().replace(this.search_message, "<span class='bg-indigo-500'>" + this.search_message + "</span>");
            message.innerHTML = message_new;
            this.search_messages_change.push(message);
          }

          message.scrollIntoView({behavior: 'smooth'});
        }
      }
    }
  }

  clearSearchOnClick() {
    this.clearSearchBgOnClick();

    this.search_messages = new Array();
    this.search_message = "";
    this.search_index_message = 0;
  }

  clearSearchBgOnClick() {
    if(this.search_messages_change && this.search_messages_change.length > 0) {
      this.search_messages_change.forEach((element: any, index: number) => {
        var message_new = element.innerHTML.replace('<span class="bg-indigo-500">', "");
        message_new = message_new.replace('</span>', "");
        element.innerHTML = message_new;
      })
    }

    setTimeout(() => {
      this.search_messages_change = new Array();
    })
  }

  searchChatOnClick() {
    if(this.chats && this.chats.length > 0){
      this.search_chats = this.chats.filter((element: any) => {
        return (
          (element.assignment_posting_id && element.assignment_posting_id.toString().toLowerCase().indexOf(this.search_chat.toLowerCase()) > -1) ||
          (element.nombre.toLowerCase().indexOf(this.search_chat.toLowerCase()) > -1) ||
          (moment(element.creation_date).format("MM/DD/yyy").toLowerCase().indexOf(this.search_chat.toLowerCase()) > -1)
        );
      });
      // console.log(this.search_chats);
    }
  }

  clearSearchChatOnClick() {    
    this.search_chats = this.chats;
    this.search_chat = "";
  }
  

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  sendMessage() {
    if(!this.permissions || this.permissions.store == 0) {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(!this.chat_active || this.chat_active == null) {
      let error = {
        message: "Please select a chat to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    let conversation: any = new Array();
    var id = 1;

    if(this.message == "" || this.message == " ") {
      let error = {
        message: "Your message has no text.",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
    let validate_arr: any = this.message.split(" ");
    if(validate_arr[0] == "" || validate_arr[0] == " ") {
      let error = {
        message: "The first character cannot be a space.",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.conversations != null && this.conversations.length > 0) {
      id = (this.conversations[this.conversations.length-1].id + 1);
      conversation = this.conversations;
    } else {
      conversation = new Array();
    }

    new_array = {
      id: id,
      user_id: this.user_id,
      user_name: this.user_name,
      person: 1,
      message: this.message,
      date_time: moment(new Date()).format("yyyy-MM-DD hh:mm:ss"),
      date: moment(new Date()).format("yyyy-MM-DD"),
      icon: this.user_name.charAt(0),
      type: "text",
      link: ""
    };

    conversation.push(new_array);

    this.http
    .post(
      this.URL_BASE + 'chat/update-chat/user', 
      {
        user_id: this.chat_active.user_id,
        assignment_posting_id: this.chat_active.assignment_posting_id,
        chat_type: this.chat_active.chat_type,
        creation_date: moment(new Date()).format("yyyy-MM-DD"),
        conversation: conversation,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        // this.alerts.push(res);

        if(res.type == "green") {
          this.clearFormChat();
          this.loadDataForm();

          this.conversations = conversation;

          const message = document.getElementById("chatMessageId" + this.conversations[(this.conversations.length - 1)].id);
          if(message) {
            message.scrollIntoView({behavior: 'smooth'});
            this.last_message_id = this.conversations[(this.conversations.length - 1)].id;
          }
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearFormChat() {
    this.message = "";
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
