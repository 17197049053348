import { Component, ViewChild } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "./services/configuration.service";
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private URL_BASE: string;
  public VERSION: string;
  public URL_ADM: string;
  public alerts: any;
  private user_id: number = 0;
  public isLoggedIn = false;
  public user: any;
  public menus: any = new Array();
  public company_profile: any;
  public logo: string = "";

  public route: string = "";
  public route_active: any;

  public color: string = "orange";
  public photo: string = "";
  public year: string = "";

  constructor(
    private router: Router, 
    private tokenStorageService: TokenStorageService,
    private configuration: ConfigurationService,
    private http: HttpClient
  ) {  
    this.URL_BASE = this.configuration.getUrlBase();
    this.VERSION = this.configuration.getVersion();
    this.alerts = new Array();
  }

  ngAfterContentInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        var url = event.url.split("/")[1];
        if(event.url.split("/")[2]) {
          this.route_active = event.url.split("/")[2];
        } else {
          this.route_active = event.url.split("/")[1];
        }
        switch(url) { 
          case "login": { 
            this.route = "login";
            break; 
          } 
          case "recover-password": { 
            this.route = "recover-password";
            break; 
          } 
          case "change-password": { 
            this.route = "change-password";
            break; 
          } 
          case "landing": { 
            this.route = "landing";
            break; 
          }
          case "": { 
            this.route = "landing";
            break; 
          }
          default: { 
            url = url.split("?")[0];
            if(url == "login") {
              this.route = "login";
            } else{
              this.route = "principal";
            }
            
            break; 
          } 
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    var screen_height = document.getElementById("screen_height") as HTMLImageElement;
    if(screen_height && screen_height.style) {
      screen_height.style.height = String(screen.height - 100 + "px");
    }
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      this.user = this.tokenStorageService.getUserData();
      this.user_id = this.tokenStorageService.getUser();
      this.menus = this.tokenStorageService.getUserMenu(); 
      // console.log(this.menus); 
      this.uploadDataProfileCompany(); 
      // if(this.user_id == 1) {
      //   this.runDailyFunction(); 
      // }

      if(this.user && this.user.user_profile) {
        this.photo = this.user.user_profile.photo;
      }
    }

    let url_1 = window.location.href.split("//");
    let url_2 = url_1[1].split("/");
    this.URL_ADM = url_1[0] + "//" + url_2[0];
    this.year = moment(new Date()).format('yyyy');
  }

  uploadDataProfileCompany() {
    this.http.get(this.URL_BASE + 'company-profile/' + 1001).subscribe(res => {
      if(res) {
        this.company_profile = res;
        this.logo = this.company_profile.logo;
      }
    });
  }

  runDailyFunction() {
    this.http.get(this.URL_BASE + 'tasks/run-function/daily').subscribe((res: any) => {
      if(res && res.type == "green") {
        // this.alerts.push(res);
        // this.closeAlertTime();
      }
    });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigateByUrl("/login");
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }
}
