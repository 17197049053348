import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { SubmenusComponent } from './submenus/submenus.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  { path: 'landing/:ruta', component: LandingComponent },
  { path: 'landing/:ruta/:token', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: 'change-password/:user_id', component: ChangePasswordComponent },
  { path: 'home', canActivate:[AuthGuard], component: HomeComponent },
  { path: 'submenus/:menu/:submenu', canActivate:[AuthGuard], component: SubmenusComponent },
  { path: 'submenus/:menu/:submenu/:tab', canActivate:[AuthGuard], component: SubmenusComponent },
  { path: 'submenus/:menu/:submenu/:tab/:id', canActivate:[AuthGuard], component: SubmenusComponent },
  { path: '**', component: ErrorNotFoundComponent } //Website Error 404 NOT FOUND
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
