import { Component, ElementRef, ViewChild, OnInit, Renderer2 } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { TokenStorageService } from '../_services/token-storage.service';
import { ConfigurationService } from "../services/configuration.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {  
  private URL_BASE: string;
  public alerts: any;
  public isLoggedIn = false;
  public route: any;
  public route_envio: any;
  public company_profile: any;

  public logo: string = "";
  public dark_logo: string = "";
  public name_registration: string = "";
  public email_registration: string = "";
  public captcha_registration: string = "";
  public date: any = "";

  constructor(
    private tokenStorageService: TokenStorageService,
    private configuration: ConfigurationService,
    private http: HttpClient,
    private router: Router,
    private renderer: Renderer2
  ) {  
    this.URL_BASE = this.configuration.getUrlBase();

    this.alerts = new Array();
  }

  ngAfterContentInit(): void {
    this.route = this.router.url.split("/")[2];
    this.route_envio = window.location.href.split("landing")[0];
    // console.log(this.route_envio);
    // console.log(this.route);
  }

  async ngOnInit() {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    await this.uploadDataProfileCompany(); 
    this.date = new Date();
  }

  resolved(captchaResponse: any) {
    this.captcha_registration = captchaResponse;
    // console.log(this.captcha_registration);
  }

  async uploadDataProfileCompany() {
    await this.http.get(this.URL_BASE + 'company-profile/data/' + 1001).subscribe(res => {
      if(res) {
        this.company_profile = res;
        this.logo = this.company_profile.logo;
        this.dark_logo = this.company_profile.dark_logo;
      }
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  preRegistrationOnClick() {
    this.createPreRegistration();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createPreRegistration() {
    if(this.name_registration == "") {
      let error = {
        message: "The name is required for pre-registration",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.email_registration == "") {
      let error = {
        message: "Email is required for pre-registration",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.captcha_registration == "") {
      let error = {
        message: "The captcha is required for pre-registration",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'user/pre-register/teacher', 
      { 
        full_name: this.name_registration, 
        email: this.email_registration,
        url: this.route_envio,
        captcha: this.captcha_registration
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          setTimeout(() => {
            location.reload();
          }, 5000);
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.name_registration = "";
    this.email_registration = "";
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }
}
