<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_form">
      <h2 class="text-lg font-medium leading-6 text-gray-900">School Charge Rates Legacy</h2>
      <p class="mt-1 text-sm text-gray-500">Legacy Charge Rates by Assignment Type - Search by School Name</p>
    </div>

    <div class="mt-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxGrid #gridSchoolChargeRates
          [width]="'100%'"
          [source]="dataAdapterSchoolChargeRates"
          [columns]="columnsSchoolChargeRates"
          [pageable]="true"
          [pagesize]="20"
          [autoheight]="true"
          [showfilterrow]="true"
          [columnsresize]="true"
          [filterable]="true"
          [sortable]="true"
          [editable]="true"
          [editmode]="'dblclick'"
          [selectionmode]="'singlerow'"
          (onCellclick)="onCellClick($event)"
          [localization]="localization"
          [theme]="'energyblue'"
        ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>
