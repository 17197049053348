<div class="mx-auto max-w-screen-xl lg:max-w-full">
  <div class="overflow-hidden rounded-lg bg-white shadow">
    <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x" style="min-height: 500px; max-height: 100%;">
      <aside class="py-6 lg:col-span-3 sm:block hidden">
        <nav class="space-y-1">
          <a *ngFor="let submenu of submenus;" href="/submenus/{{ menu_path }}/{{ submenu.link }}" routerLink="/submenus/{{ menu_path }}/{{ submenu.link }}" class="{{ submenu.link == submenu_path ? 'bg-orange-600 border-orange-700 text-white hover:bg-orange-600 hover:text-white' : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900' }} group border-l-4 px-3 py-2 flex items-center text-sm font-medium">
            <svg class="{{ submenu.link == submenu_path ? 'text-white group-hover:text-white' : 'text-gray-400 group-hover:text-gray-500' }} flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" id="{{ submenu.link }}"></svg>
            <a class="truncate">{{ submenu.alias }}</a>
          </a>
        </nav>
      </aside>

      <div class="divide-y divide-gray-200 lg:col-span-9" [ngSwitch]="submenu_path">
        <!-- Profile section -->
        <div *ngSwitchCase="'agency-profile'">
          <app-agency-profile></app-agency-profile>
        </div>
        <div *ngSwitchCase="'users'">
          <app-users></app-users>
        </div>
        <div *ngSwitchCase="'user-profiles'">
          <app-user-profiles></app-user-profiles>
        </div>
        <div *ngSwitchCase="'user-profile'">
          <app-user-profile></app-user-profile>
        </div>
        <div *ngSwitchCase="'tasks'">
          <app-tasks></app-tasks>
        </div>
        <div *ngSwitchCase="'general-chats'">
          <app-general-chats></app-general-chats>
        </div>
        <div *ngSwitchCase="'agency-chats'">
          <app-agency-chats></app-agency-chats>
        </div>
        <div *ngSwitchCase="'assignment-chats'">
          <app-assignment-chats></app-assignment-chats>
        </div>
        <div *ngSwitchCase="'parameters'">
          <app-parameters></app-parameters>
        </div>
        <div *ngSwitchCase="'zones'">
          <app-zones></app-zones>
        </div>
        <div *ngSwitchCase="'roles'">
          <app-roles></app-roles>
        </div>
        <div *ngSwitchCase="'assignment-postings'">
          <app-assignment-postings></app-assignment-postings>
        </div>
        <div *ngSwitchCase="'assignment-lists'">
          <app-assignment-lists></app-assignment-lists>
        </div>
        <div *ngSwitchCase="'reconciliate'">
          <app-reconciliate></app-reconciliate>
        </div>
        <div *ngSwitchCase="'assignment-map'">
          <app-assignment-map></app-assignment-map>
        </div>
        <div *ngSwitchCase="'school-charge-rates'">
          <app-school-charge-rates></app-school-charge-rates>
        </div>
        <!-- <div *ngSwitchCase="'school-charge-rates-legacy'">
          <app-school-charge-rates-legacy></app-school-charge-rates-legacy>
        </div> -->
        <div *ngSwitchCase="'educator-pay-rates'">
          <app-educator-pay-rates></app-educator-pay-rates>
        </div>
        <div *ngSwitchCase="'educator-pay-rates-legacy'">
          <app-educator-pay-rates-legacy></app-educator-pay-rates-legacy>
        </div>
        <div *ngSwitchCase="'invoice'">
          <app-invoice></app-invoice>
        </div>
        <div *ngSwitchCase="'educator-invoice'">
          <app-educator-invoice></app-educator-invoice>
        </div>
        <div *ngSwitchCase="'school-invoice'">
          <app-school-invoice></app-school-invoice>
        </div>
        <div *ngSwitchCase="'late-arrival-report'">
          <app-late-arrival-report></app-late-arrival-report>
        </div>
        <div *ngSwitchCase="'late-check-out-report'">
          <app-late-check-out-report></app-late-check-out-report>
        </div>
        <div *ngSwitchCase="'daily-hours-report'">
          <app-daily-hours-report></app-daily-hours-report>
        </div>
        <div *ngSwitchCase="'educator-assignment-report'">
          <app-educator-assignment-report></app-educator-assignment-report>
        </div>
        <div *ngSwitchCase="'apply-cancel-report'">
          <app-apply-cancel-report></app-apply-cancel-report>
        </div>
        <div *ngSwitchCase="'same-day-cancellation-report'">
          <app-same-day-cancellation-report></app-same-day-cancellation-report>
        </div>
        <div *ngSwitchCase="'inactive-educators-report'">
          <app-inactive-educators-report></app-inactive-educators-report>
        </div>
        <div *ngSwitchCase="'inactive-schools-report'">
          <app-inactive-schools-report></app-inactive-schools-report>
        </div>
        <div *ngSwitchCase="'educator-invoice-report'">
          <app-educator-invoice-report></app-educator-invoice-report>
        </div>
        <div *ngSwitchCase="'school-invoice-report'">
          <app-school-invoice-report></app-school-invoice-report>
        </div>
        <div *ngSwitchCase="'educator-availability-report'">
          <app-educator-availability-report></app-educator-availability-report>
        </div>
        <div *ngSwitchCase="'educator-blocked-dates-report'">
          <app-educator-blocked-dates-report></app-educator-blocked-dates-report>
        </div>
        <div *ngSwitchCase="'ranking-report'">
          <app-ranking-report></app-ranking-report>
        </div>
        <div *ngSwitchDefault>
          <app-error-not-found></app-error-not-found>
        </div>
      </div>
    </div>
  </div>
</div>
