import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';

import { TokenStorageService } from '../_services/token-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private token: TokenStorageService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();

    if (token != null) {
      authReq = req.clone({
        setHeaders: {
          authorization: 'Bearer ' + token
        }
      });
    }
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // console.log("ERROR 401");
          this.token.signOut();
          this.router.navigateByUrl("/login");
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
