<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-100">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: outline/check-circle -->
            <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
            <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
              <span class="sr-only">CLOSE</span>
              <!-- Heroicon name: mini/x-mark -->
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>   

<div class="flex flex-col items-center justify-center p-4 space-y-4 antialiased text-gray-900">
  <div class="w-full px-8 max-w-lg space-y-6 bg-white rounded-md py-16">
    <h1 class=" mb-6 text-3xl font-bold text-center">
      Don't worry
    </h1>
    <p class="text-center mx-12">
      We are here to help you to recover your password. Enter the email address you used
      when you joined and we'll send you instructions to reset your password.
    </p>
    <div class="space-y-6 w-ful">
      <input class="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-primary-100"
        id="email" name="email" type="email" autocomplete="email" placeholder="Email address" [(ngModel)]="email" required>
      <div>
        <button type="submit" (click)="onSubmit()"
          class="w-full px-4 py-2 font-medium text-center text-white bg-orange-600 transition-colors duration-200 rounded-md bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1">
          Send
        </button>
      </div>
    </div>
    <div class="text-sm text-gray-600 items-center flex justify-between">
      <a href="login" class="text-gray-800 cursor-pointer hover:text-blue-500 inline-flex items-center ml-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd" />
        </svg>
        Back
      </a>
      <p class="hover:text-blue-500 cursor-pointer" (click)="mailMe()">Need help?</p>
    </div>
  </div>
</div>