<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div>
      <h2 class="text-lg font-medium leading-6 text-gray-900">Agency Profile</h2>
      <p class="mt-1 text-sm text-gray-500">Edit, Update and Save</p>
    </div>

    <div class="mt-6 flex flex-col lg:flex-row">
      <div class="flex-grow space-y-6">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">Agency Name</label>
            <input type="text" name="name" id="name" autocomplete="name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="name">
          </div>
          <div class="col-span-12 sm:col-span-6">
            <label for="agency_id" class="block text-sm font-medium text-gray-700">Agency ID</label>
            <input type="text" name="name" id="name" autocomplete="name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="agency_id" disabled>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Agency Description</label>
            <input type="text" name="description" id="description" autocomplete="description" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="description">
          </div>
          <div class="col-span-12 sm:col-span-6">
            <label for="agency_category" class="mb-1 block text-sm font-medium text-gray-700">Agency Category</label>
            <jqxComboBox 
              [width]="'100%'"  
              [height]="38" 
              [displayMember]="'name'" 
              [valueMember]="'id'" 
              [source]="assignment_codes" 
              [theme]="'energyblue'"
              [(ngModel)]="assignment_code_id"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>
        </div>
      </div>

      <div class="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
        <p class="text-sm font-medium text-gray-700" aria-hidden="true">Logo</p>
        <div class="mt-1 lg:hidden">
          <div class="flex items-center">
            <div class="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full bg-orange-600" aria-hidden="true">
              <img class="h-full w-full rounded-full" src="{{ logo }}" alt="">
            </div>
            <div class="ml-5 rounded-md shadow-sm">
              <div class="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-orange-500 focus-within:ring-offset-2 hover:bg-gray-50">
                <label for="mobile-user-photo" class="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
                  <span>Change</span>
                  <span class="sr-only">Logo</span>
                </label>
                <input id="mobile-user-photo" name="user-photo" type="file" class="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" (change)="uploadLogoOnChange($event)">
              </div>
            </div>
          </div>
        </div>

        <div class="relative hidden overflow-hidden rounded-full lg:block">
          <img class="relative h-30 w-30 rounded-full bg-orange-600" src="{{ logo }}">
          <label for="desktop-user-photo" class="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100">
            <span>Change</span>
            <span class="sr-only">Logo</span>
            <input type="file" accept=".jpg, .png, .jpeg" id="desktop-user-photo" name="user-photo" class="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" (change)="uploadLogoOnChange($event)">
          </label>
        </div>
      </div>
    </div>

    <div class="mt-3 flex flex-col lg:flex-row">
      <div class="flex-grow space-y-6">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <label for="identification" class="block text-sm font-medium text-gray-700">Identification Number</label>
            <input type="text" name="identification" id="identification" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="identification">
          </div>

          <div class="col-span-12 sm:col-span-6 hidden">
            <label for="country_id" class="block text-sm font-medium text-gray-700 mb-1">Country</label>
            <jqxComboBox #comboboxCountry
              [width]="'100%'"  
              [height]="38" 
              [displayMember]="'common_name'" 
              [valueMember]="'id'" 
              [source]="countries" 
              [theme]="'energyblue'"
              [(ngModel)]="country_id"
              (onSelect)="countriesOnSelect($event)"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-6">
            <label for="state_id" class="block text-sm font-medium text-gray-700 mb-1">State</label>
            <jqxComboBox #comboboxState
              [width]="'100%'" 
              [height]="38" 
              [displayMember]="'name'" 
              [valueMember]="'id'" 
              [source]="states" 
              [disabled]="true" 
              [theme]="'energyblue'"
              [(ngModel)]="state_id"
              (onSelect)="statesOnSelect($event)"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-6">
            <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
            <input type="text" name="address" id="address" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="address">
          </div>

          <div class="col-span-12 sm:col-span-6 hidden">
            <label for="county_id" class="block text-sm font-medium text-gray-700 mb-1">County</label>
            <jqxComboBox #comboboxCounty
              [width]="'100%'" 
              [height]="38" 
              [displayMember]="'name'" 
              [valueMember]="'id'" 
              [source]="counties" 
              [theme]="'energyblue'"
              [(ngModel)]="county_id"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="city_id" class="block text-sm font-medium text-gray-700 mb-1">City</label>
            <jqxComboBox #comboboxCity
              [width]="'100%'"  
              [height]="38" 
              [displayMember]="'name'" 
              [valueMember]="'id'" 
              [source]="cities" 
              [theme]="'energyblue'"
              (onSelect)="cityOnSelect($event)"
              (focusout)="cityFocusout($event)"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="zip_code" class="block text-sm font-medium text-gray-700 mb-1">ZIP Code</label>
            <jqxComboBox #comboboxZipCode
              [width]="'100%'"  
              [height]="38" 
              [displayMember]="'code'" 
              [valueMember]="'code'" 
              [source]="zip_codes" 
              [theme]="'energyblue'"
              (focusout)="zipCodeFocusout($event)"
              [autoComplete]="true" 
              [searchMode]="'containsignorecase'"
            ></jqxComboBox>
          </div>

          <div class="col-span-12 sm:col-span-3">
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input type="number" min="0" (input)="numberInput($event)" name="phone" id="phone" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>
          <div class="col-span-12 sm:col-span-3">
            <label for="cell_phone" class="block text-sm font-medium text-gray-700">Mobile Phone</label>
            <input type="number" min="0" (input)="numberInput($event)" name="cell_phone" id="cell_phone" autocomplete="cell_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
          </div>
          <div class="col-span-12 sm:col-span-6">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input type="text" name="email" id="email" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="email">
          </div>
        </div>
      </div>

      <div class="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
        <p class="text-sm font-medium text-gray-700" aria-hidden="true">Dark Logo</p>
        <div class="mt-1 lg:hidden">
          <div class="flex items-center">
            <div class="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full" aria-hidden="true">
              <img class="h-full w-full rounded-full" src="{{ dark_logo }}" alt="">
            </div>
            <div class="ml-5 rounded-md shadow-sm">
              <div class="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-orange-500 focus-within:ring-offset-2 hover:bg-gray-50">
                <label for="mobile-user-photo" class="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
                  <span>Change</span>
                  <span class="sr-only">Dark Logo</span>
                </label>
                <input id="mobile-user-photo" name="user-photo" type="file" class="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" (change)="uploadDarkLogoOnChange($event)">
              </div>
            </div>
          </div>
        </div>

        <div class="relative hidden overflow-hidden rounded-full lg:block">
          <img class="relative h-30 w-30 rounded-full bg-orange-600" src="{{ dark_logo }}">
          <label for="desktop-user-photo" class="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100">
            <span>Change</span>
            <span class="sr-only">Dark Logo</span>
            <input type="file" accept=".jpg, .png, .jpeg" id="desktop-user-photo" name="user-photo" class="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" (change)="uploadDarkLogoOnChange($event)">
          </label>
        </div>
      </div>
    </div>

    <div class="mt-10 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-9">
        &nbsp;
      </div>
      <div class="col-span-12 sm:col-span-3">
        <button type="submit" (click)="editProfileOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Save Agency</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div *ngIf="modalConfirmAdd" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true" style="Overflow: hidden">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" (click)="modalConfirmAdd = false">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="mx-auto mb-4 text-orange-600 w-12 h-12 dark:text-orange-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
          </svg>
          <p class="mb-4 text-gray-600 dark:text-gray-300">Please Confirm to Update & Save</p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button" class="py-2 px-8 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" (click)="modalConfirmAdd = false;">
              No, cancel
            </button>
            <button type="submit" class="py-2 px-8 text-sm font-medium text-center text-white bg-orange-600 rounded-lg hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-900" (click)="editCompanyProfile(); modalConfirmAdd = false;">
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>