import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_DATA = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user_data: any): void {
    window.sessionStorage.removeItem(USER_DATA);
    window.sessionStorage.setItem(USER_DATA, JSON.stringify(user_data));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.id;
    }

    return {};
  }

  public getUserProfile(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.user_profile_id;
    }

    return {};
  }

  public getUserName(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.name;
    }

    return {};
  }

  public getUserType(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.type_user_profile_id;
    }

    return {};
  }

  public getUserRol(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.rol_id;
    }

    return {};
  }

  public getUserParent(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user.parent_id;
    }

    return {};
  }

  public getUserData(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).user;
    }

    return {};
  }

  public getDataAll(): any {
    const data = window.sessionStorage.getItem(USER_DATA);
    if (data) {
      return JSON.parse(data);
    }

    return {};
  }

  public getUserMenu(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).menus;
    }

    return {};
  }

  public getUserPermissions(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      return JSON.parse(user).permissions;
    }

    return {};
  }

  public getUserPermission(permission: string): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    if (user) {
      let permissions = JSON.parse(user).permissions;
      permissions = permissions.find((item: any) => {
        return (item.functionality.toLowerCase() == permission.toLowerCase());
      });
      return permissions;
    }

    return {};
  }
}
