import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-school-charge-rates',
  templateUrl: './school-charge-rates.component.html',
  styleUrls: ['./school-charge-rates.component.css']
})
export class SchoolChargeRatesComponent {
  //GRIDS
  @ViewChild('gridSchoolChargeRates', { static: false }) gridSchoolChargeRates: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxUserProfile', { static: false }) comboboxUserProfile: jqxComboBoxComponent;
  @ViewChild('comboboxAssignamentCode', { static: false }) comboboxAssignamentCode: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public school_charge_rates: any;
  public sourceSchoolChargeRates: any;
  public dataAdapterSchoolChargeRates: any = [];
  public columnsSchoolChargeRates: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('users');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataGrid();
      this.createGridSchoolChargeRates();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  loadDataGrid() {
    this.http.get(this.URL_BASE + 'school_charge_rates').subscribe(res => {
      this.school_charge_rates = res;

      this.sourceSchoolChargeRates.localdata = this.school_charge_rates;
      this.gridSchoolChargeRates.updatebounddata('cells');
    });
  }

  loadDataSchoolGrid(school_id: number) {
    this.http.get(this.URL_BASE + 'school_charge_rates/filter-school/' + school_id).subscribe(res => {

      this.sourceSchoolChargeRates.localdata = res;
      this.gridSchoolChargeRates.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  userProfileOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDataSchoolGrid(item);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  editSchoolChargeRate(Rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'school_charge_rate/' + Rowdata.id, 
      {
        school_id: Rowdata.school_id, 
        cc_ph: Rowdata.cc_ph,
        cc_phlt: Rowdata.cc_phlt,
        gs_hd: Rowdata.gs_hd,
        gs_fd: Rowdata.gs_fd,
        gs_fdlt: Rowdata.gs_fdlt,
        asp_ph: Rowdata.asp_ph,
        asp_phlt: Rowdata.asp_phlt,
        cancel: Rowdata.cancel,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataGrid();
        } else {
          this.gridSchoolChargeRates.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  deleteSchoolChargeRate(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'school_charge_rate/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "orange") {
          this.loadDataGrid();
        } else {
          this.gridSchoolChargeRates.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridSchoolChargeRates() {
    this.sourceSchoolChargeRates =
    {
      localdata: this.school_charge_rates,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'school_id', type: 'int' },
        { name: 'user_profile', type: 'string' },
        { name: 'cc_ph', type: 'integer' },
        { name: 'cc_phlt', type: 'integer' },
        { name: 'gs_hd', type: 'integer' },
        { name: 'gs_fd', type: 'integer' },
        { name: 'gs_fdlt', type: 'integer' },
        { name: 'asp_ph', type: 'integer' },
        { name: 'asp_phlt', type: 'integer' },
        { name: 'cancel', type: 'integer' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editSchoolChargeRate(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterSchoolChargeRates = new jqx.dataAdapter(this.sourceSchoolChargeRates, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          data.push(fila);
        }
      },
    });

    this.columnsSchoolChargeRates =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        hidden: true,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'School',  
        datafield: 'school_id',
        displayfield: 'user_profile',
        editable: false,
        width: '28%'
      },
      { 
        text: 'CC-PH',
        datafield: 'cc_ph',
        displayfield: 'cc_ph',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'CC-PHLT',
        datafield: 'cc_phlt',
        displayfield: 'cc_phlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-HD',  
        datafield: 'gs_hd',
        displayfield: 'gs_hd',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-FD',  
        datafield: 'gs_fd',
        displayfield: 'gs_fd',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'GS-FDLT',  
        datafield: 'gs_fdlt',
        displayfield: 'gs_fdlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'ASP-PH',  
        datafield: 'asp_ph',
        displayfield: 'asp_ph',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'ASP-PHLT',  
        datafield: 'asp_phlt',
        displayfield: 'asp_phlt',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      },
      { 
        text: 'CANCEL',  
        datafield: 'cancel',
        displayfield: 'cancel',
        cellsalign: 'right',
        cellsformat: 'c2',
        editable: true,
        width: '9%',
        columntype: 'numberinput',
        initeditor: function (row: any, cellvalue: any, editor: any) {
          editor.jqxNumberInput({ digits: 7, decimalDigits: 2 });
        }
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClick(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    if (args.datafield == "Del") {
      if (selectedrowindex >= 0) {
        if(this.permissions && this.permissions.delete == 1) {
          this.deleteSchoolChargeRate(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
