<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_form">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Educator for Invoice</h2>
      <p class="mt-1 text-sm text-gray-500">Generate Weekly Invoicing By Educator, View Daily Hours and Payroll Payment Amount. Filter by Zone Manager and Educator</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-3">
        <label for="week_id" class="block text-sm font-medium text-gray-700">Week</label>
        <jqxComboBox #comboboxWeek
          [width]="'100%'"
          [height]="38"
          [displayMember]="'name'"
          [valueMember]="'id'"
          [source]="dataAdapterWeeks"
          [theme]="'energyblue'"
          [(ngModel)]="week_id"
          (onChange)="weekOnChange($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="date_from" class="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
        <jqxDateTimeInput #dateFromDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_from" 
          [theme]="'energyblue'"
          (onChange)="dateFromOnChange($event)"
          [disabled]="true"
        ></jqxDateTimeInput>
      </div>
      
      <div class="col-span-12 sm:col-span-3">
        <label for="date_to" class="block text-sm font-medium text-gray-700 mb-1">End Date</label>
        <jqxDateTimeInput #dateToDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_to" 
          [theme]="'energyblue'"
          (onChange)="dateToOnChange($event)"
          [disabled]="true"
        ></jqxDateTimeInput>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="zone_manager_id" class="block text-sm font-medium text-gray-700">Zone Manager</label>
        <jqxComboBox #comboboxZoneManager
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zone_managers" 
          [theme]="'energyblue'"
          [(ngModel)]="zone_manager_id"
          (onSelect)="zoneManagerOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
        <jqxComboBox #comboboxEducator
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="educators" 
          [theme]="'energyblue'"
          [(ngModel)]="educator_id"
          (onSelect)="userOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-5">
      </div>

      <div class="col-span-12 sm:col-span-2">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="liquidateOnClick()" class="w-full bg-sky-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus-visible:ring-sky-500">Generate</button>
      </div>

      <div class="col-span-12 sm:col-span-1">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="reportOnClick()" class="w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus-visible:ring-orange-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
          </svg>
        </button>
      </div>

      <div class="col-span-12 sm:col-span-1">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="downloadOnClick()" class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus-visible:ring-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </button>
      </div>
    </div>
    <hr>
    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxGrid #gridInvoices
          [width]="'100%'" 
          [source]="dataAdapterInvoices" 
          [columns]="columnsInvoices"
          [pageable]="true" 
          [autoheight]="true" 
          [showfilterrow]="true"
          [columnsresize]="true"
          [filterable]="true"
          [sortable]="true"  
          [editable]="false" 
          [editmode]="'dblclick'"
          [selectionmode]="'singlerow'"
          [localization]="localization"
          [theme]="'energyblue'"
        ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>