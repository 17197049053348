import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../_services/auth.service';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent {
  private URL_BASE: string;
  public alerts: any;
  public email: string = "";

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';

  constructor(
    private configuration: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, 
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/home");
    }
  }

  onSubmit(): void {
    this.authService.forgotPassword(this.email).subscribe({
      next: res => {
        this.alerts.push(res);
        this.closeAlertTime();
      },
      error: err => {
        console.log(err);
        if(err.error) {
          this.errorMessage = err.error;
        } else {
          this.errorMessage = err;
        }
        this.alerts.push(this.errorMessage);
        return this.closeAlertTime();
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }

  mailMe(){
    window.open("https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=support@educatorsnowllc.com&tf=cm");
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }
}
