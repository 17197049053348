import { Component, OnInit, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';           
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { LoadingService } from '../shared/service/loading.service';

@Component({
  selector: 'app-assignment-lists',
  templateUrl: './assignment-lists.component.html',
  styleUrls: ['./assignment-lists.component.css']
})
export class AssignmentListsComponent {
  //GRIDS
  @ViewChild('gridAssignmentPostings', { static: false }) gridAssignmentPostings: jqxGridComponent;
  @ViewChild('gridActualAssignmentPost', { static: false }) gridActualAssignmentPost: jqxGridComponent;
  @ViewChild('gridAssignmentHistories', { static: false }) gridAssignmentHistories: jqxGridComponent;

  //COMBOBOX
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxZoneManager', { static: false }) comboboxZoneManager: jqxComboBoxComponent;
  @ViewChild('comboboxZone', { static: false }) comboboxZone: jqxComboBoxComponent;
  @ViewChild('comboboxSchool', { static: false }) comboboxSchool: jqxComboBoxComponent;

  @ViewChild('comboboxUserProfile', { static: false }) comboboxUserProfile: jqxComboBoxComponent;
  @ViewChild('comboboxAssignmentUser', { static: false }) comboboxAssignmentUser: jqxComboBoxComponent;
  @ViewChild('comboboxAssignmentType', { static: false }) comboboxAssignmentType: jqxComboBoxComponent;
  @ViewChild('comboboxSkill', { static: false }) comboboxSkill: jqxComboBoxComponent;

  //CHECKBOX
  @ViewChild('checkboxForceLongTerm', { static: false }) checkboxForceLongTerm: jqxCheckBoxComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  //TOOLTIP
  @ViewChild('tooltipReference') myTooltip: jqxTooltipComponent;

  //GOOGLE MAPS
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow

  /* //see all elements
  @ViewChildren(jqxComboBoxComponent) elements: any; */

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;
  private school_profile_id: any;
  public user_type: any;
  private user_rol_id: any;
  private school_parent_id: any;

  public organizations: any;
  public zone_managers: any;
  public zones: any;
  public schools: any;
  public combobox_schools: any;
  public combobox_educators: any;
  public ranking_educators: any;
  public preferred_educators: any;
  public assignament_types: any;
  public skills: any;
  public assignment_posting_statuses: any;
  public assignment_closed_cancel_statuses: any;
  public document_teacher: any;
  public assignment_ratings: any;

  public date_from: string = "";
  public date_to: string = "";
  public organization_id: number = 0;
  public zone_manager_id: number = 0;
  public zone_id: number = 0;
  public school_id: number = 0;

  public assignment_posting_id: number = 0;
  public user_profile_id: number = 0;
  public user_ass_id: number = 0;
  public start_date: string = "";
  public min_start_date: any = "";
  public end_date: string = "";
  public min_end_date: any = "";
  public assignament_type_id: number = 0;
  public start_time: string = "";
  public end_time: string = "";
  public lunch_hours: number = 0;
  public break_hours: number = 0;
  public check_in_location: string = "";
  public check_in_contact: string = "";
  public skill_id: number = 0;
  public skill_other: string = "";
  public note_section: string = "";
  public substitute_teacher_plan: any;
  public document_teacher_name: any = "";
  public pdfSource: any = "";
  public status_id: number = 0;
  public image: any = "";
  public send_request_pre: boolean = false;
  public send_request_top: boolean = false;
  public delete_assignment_id: number = 0;


  //CHAT
  public chats: any = [];
  public conversation_educator: any = [];
  public conversation_school: any = [];
  public chat_assignment_posting_id: number = 0;
  public chat_name: string = "";
  public message_educator: string = "";
  public message_school: string = "";


  //CANCEL ASSIGNMENT
  public cancel_assignment_posting_id: number = 0;
  public closed_cancel_status_id: number = 0;
  public cancel_comment: string = "";

  //ACTUAL ASSIGNMENT HOURS 
  public actual_assignment_posting_id: number = 0;
  public actual_assignment_stat_id: number = 0;
  public school_hours: number = 0;
  public school_overtime: number = 0;
  public school_comments: string = "";
  public approval_hours: number = 0;
  public approval_overtime: number = 0;

  //RATE
  public rate_questions: any = new Array();
  public answers: any = new Array();

  //GPS LIVE
  public interval: any = [];
  public assignment_gps_live: any = new Array();

  //ASSIGNMENTS
  public assignment_postings: any;
  public sourceAssignmentPostings: any;
  public dataAdapterAssignmentPostings: any = [];
  public cellHoverAssignmentPostings: any = [];
  public columnsAssignmentPostings: any;

  // ACTUAL ASSIGNMENTS
  public actual_assignment_posts: any;
  public sourceActualAssignmentPost: any;
  public dataAdapterActualAssignmentPost: any = [];
  public columnsActualAssignmentPost: any;

  // ASSIGNMENT HISTORIES
  public assignment_histories: any = new Array();
  public sourceAssignmentHistories: any;
  public dataAdapterAssignmentHistories: any = [];
  public columnsAssignmentHistories: any;

  public localization: any;

  //MODAL
  public slideAssignment: boolean = false;
  public modalChat: boolean = false;
  public modalOpenAssignment: boolean = false;
  public modalCancelAssignment: boolean = false;
  public modalSchoolHours: boolean = false;
  public modalApprovalHours: boolean = false;
  public modalRatesAssignment: boolean = false;
  public modalMapGps: boolean = false;
  public modalPDF: boolean = false;
  public modalHistory: boolean = false;
  public modalConfirmDel: boolean = false;
  public modalConfirmEdi: boolean = false;

  // Configuración de Google Maps 
  public center = {lat: 32.770625, lng: -83.315218};
  public mapZoom = 7;
  public display?: google.maps.LatLngLiteral;
  public mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 6,
  };
  public markerPositions: any = [];
  public markerInfo: any = [];

  //DISABLED
  public disComboboxUserProfile: boolean = false;
  disableAll = false;
  
  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private loadingService: LoadingService
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('assignment-lists');
    this.user_id = this.tokenStorage.getUser();
    this.user_name = this.tokenStorage.getUserName();
    this.school_profile_id = this.tokenStorage.getUserProfile();
    this.user_type = this.tokenStorage.getUserType();
    this.user_rol_id = this.tokenStorage.getUserRol();
    this.school_parent_id = this.tokenStorage.getUserParent();

    this.alerts = new Array();
  }

  ngOnInit(): void {
    if(this.permissions && this.permissions.visualize == 1) {
      this.loadDataForm();
      this.loadDataOrganizations();
      this.loadDataSchools();
      this.loadDataEducators();
      this.loadDataZoneManagers();
      this.loadDataAssignmentTypes();
      this.loadDataSkills();
      this.loadDataAssignmentStatuses();
      this.loadOrganizationSchools(0);
      this.loadZoneManagerZonesSchools(0);
      this.createGridAssignmentPostings();
      this.createGridActualAssignmentPosts();
      this.createGridAssignmentHistories();
      setTimeout(() => {
        const data = history.state.data;
        if (data){
          this.onCellClick(data);
          this.permissions = {
            update: 1,
            visualize: 1,
          };
          /* console.log(this.elements); */
          this.disableAll = true;
        }
        history.replaceState({}, '', window.location.href);
      }, 1000);
    }
  }

  ngOnDestroy(){
    history.state.data = null;
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.date_from = moment(new Date()).format("MM-DD-yyyy");
    this.date_to = moment(new Date()).format("MM-DD-yyyy");
    
    this.min_start_date = moment(new Date()).format("MM-DD-yyyy");
    this.min_end_date = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadDataOrganizations() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter/organizations-schools').subscribe(res => {
      var sourceUserProfiles =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    }); 
  }

  async loadDataSchools() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-type-active/2/1').subscribe((res: any) => {
      var sourceUserProfiles =
      {
        localdata: res.sort(function(a: any, b: any) {
          if(a.business_name.toLowerCase() < b.business_name.toLowerCase()) { return -1; }
          if(a.business_name.toLowerCase() > b.business_name.toLowerCase()) { return 1; }
          return 0;
        }),
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.combobox_schools = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.business_name;

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataSchools();
      }
    });
  }

  async loadDataEducators() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-type-active/4/1').subscribe((res: any) => {
      var sourceUserProfiles =
      {
        localdata: res.sort(function(a: any, b: any) {
          if(a.first_name && a.second_name && a.last_name) {
            a.name = a.first_name + " " + a.second_name + " " + a.last_name;
          } else if(a.first_name && a.last_name) {
            a.name = a.first_name + " " + a.last_name;
          }
          if(b.first_name && b.second_name && b.last_name) {
            b.name = b.first_name + " " + b.second_name + " " + b.last_name;
          } else if(b.first_name && b.last_name) {
            b.name = b.first_name + " " + b.last_name;
          }

          if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
          if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
          return 0;
        }),
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'user_id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.combobox_educators = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataEducators();
      }
    });
  }

  async loadDataPreferredEducators(user_profile_id: number = 0) {
    await this.http.get(this.URL_BASE + 'allow_lists/filter-user-profile/' + user_profile_id + '/Educator/0').subscribe((res: any) => {
      if(res && res.length > 0) {
        if(res.length > 5) {
          this.preferred_educators = res.slice(0, 5);
        } else {
          this.preferred_educators = res;
        }
      }
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataPreferredEducators(user_profile_id);
      }
    });
  }

  async loadDataRankingEducators(assignment_posting_id: number = 0) {
    await this.http.get(this.URL_BASE + 'user-profile-ranking-quantity/' + assignment_posting_id + '/0/5').subscribe(res => {
      this.ranking_educators = res;
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataRankingEducators(assignment_posting_id);
      }
    });
  }

  async loadDataZoneManagers() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-zone-managers').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.zone_managers = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZoneManagers();
      }
    });
  }

  async loadDataAssignmentTypes() {
    this.http.get(this.URL_BASE + 'assignament_types').subscribe(res => {
      this.assignament_types = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataAssignmentTypes();
      }
    });
  }

  async loadDataSkills() {
    this.http.get(this.URL_BASE + 'skills').subscribe(res => {
      this.skills = res; 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataSkills();
      }
    });
  }
    
  async loadDataAssignmentStatuses() {
    this.assignment_closed_cancel_statuses = new Array(
      {
        "id": 4,
        "name": "Cancel"
      }, {
        "id": 5,
        "name": "Close"
      }
    );

    // this.http.get(this.URL_BASE + 'assignment_posting_statuses').subscribe(res => {
    //   this.assignment_posting_statuses = res;  
    //   if(this.assignment_posting_statuses && this.assignment_posting_statuses.length > 0) {
    //     this.assignment_closed_cancel_statuses = this.assignment_posting_statuses.filter((element: any) => element.id == 4 || element.id == 5); 
    //   } 
    // }, (error: any) => {
    //   if(error && error.statusText && error.statusText == "Unknown Error") {
    //     this.loadDataAssignmentStatuses();
    //   }
    // });
  }

  async loadOrganizationSchools(organization_id = 0) {
    if(organization_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 

        setTimeout(() => {
          if(this.user_type == 2) {
            this.comboboxSchool.val(this.school_profile_id);
          } else if(this.user_type == 5) {
            this.comboboxSchool.val(this.school_parent_id);
          }

          if(this.permissions && this.permissions.supervise == 1) {
            this.disComboboxUserProfile = false;
          } else {
            this.disComboboxUserProfile = true;
          }
        }, 500);
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-organization/' + organization_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadOrganizationSchools(organization_id);
        }
      });
    }
  }

  async loadZoneManagerZonesSchools(zone_manager_id = 0) {
    if(zone_manager_id == 0) {
      await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
        var sourceZones =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'description', type: 'string' }
          ]
        };

        this.zones = new jqx.dataAdapter(sourceZones, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              fila.name = fila.name + " - " + fila.description;

              data.push(fila);
            }
          },
        });
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerZonesSchools(zone_manager_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'zones/filter-zone-manager/' + zone_manager_id).subscribe(res => {
        var sourceZones =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'description', type: 'string' }
          ]
        };

        this.zones = new jqx.dataAdapter(sourceZones, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              fila.name = fila.name + " - " + fila.description;

              data.push(fila);
            }
          },
        });
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerZonesSchools(zone_manager_id);
        }
      });
    }
  }

  async loadZoneSchools(zone_id = 0) {
    if(zone_id == 0) {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-type/2').subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'user_profiles/filter-zone/' + zone_id).subscribe(res => {
        var sourceUserProfile =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'business_name', type: 'string' }
          ]
        };

        this.schools = new jqx.dataAdapter(sourceUserProfile, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              } else {
                fila.name = fila.business_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneSchools(zone_id);
        }
      });
    }
  }

  async loadEditAssignmentPosting(assignment_posting: any) {
    await this.loadDataPreferredEducators(assignment_posting.user_profile_id);
    await this.loadDataRankingEducators(assignment_posting.id);
    
    await this.http.get(this.URL_BASE + 'assignment_posting/' + assignment_posting.id).subscribe((data: any) => {
      this.assignment_posting_id = data.id;
      this.user_profile_id = data.user_profile_id;
      this.comboboxUserProfile.val(data.user_profile_id);

      if(data.user_id) {
        this.user_ass_id = data.user_id;
        this.comboboxAssignmentUser.val(data.user_id);
      }

      if(data.start_date) {
        setTimeout(() => {
          this.start_date = moment(data.start_date).format("MM-DD-yyyy");
        }, 300);
      }
      if(data.end_date) {
        setTimeout(() => {
          this.end_date = moment(data.end_date).format("MM-DD-yyyy");
        }, 300);
      }
      this.assignament_type_id = data.assignament_type_id;
      if(data.start_time) {
        this.start_time = moment(data.start_time, "HH:mm:ss").format("LT")
      }
      if(data.end_time) {
        this.end_time = moment(data.end_time, "hh:mm:ss").format("LT");
      }
      this.lunch_hours = data.lunch_hours;
      this.break_hours = data.break_hours;
      this.check_in_location = data.check_in_location;
      this.check_in_contact = data.check_in_contact;
      this.skill_id = data.skill_id;
      this.skill_other = data.skill_other;
      this.substitute_teacher_plan = "";
      this.document_teacher_name = data.substitute_teacher_name;
      this.pdfSource = data.substitute_teacher_plan;
      this.checkboxForceLongTerm.val(data.force_long_term);
      this.note_section = data.note_section;
      this.status_id = data.status_id;
      this.image = data.image;
      this.send_request_pre = data.send_request_pre;
      this.send_request_top = data.send_request_top;

      this.slideAssignment = true;
    });
  }

  loadChat(assignment_posting_id: number, user_name: number, start_date: string) {
    this.chat_assignment_posting_id = assignment_posting_id; 
    this.chat_name = "ID " + this.chat_assignment_posting_id + " - " + user_name + " - " + start_date; 

    this.http.post(this.URL_BASE + 'chat/filter-assignment/' + this.chat_assignment_posting_id, 
    {})
    .subscribe((res)=>{
      let datafromserver = (JSON.parse(JSON.stringify(res)));
      this.chats = res; 
      
      if(this.chats && this.chats.length > 0) {
        this.conversation_educator = this.chats.find((element: any) => element.chat_type == 1);
        if(this.conversation_educator) {
          this.conversation_educator = this.conversation_educator.conversation;
        }
        this.conversation_school = this.chats.find((element: any) => element.chat_type == 2);
        if(this.conversation_school) {
          this.conversation_school = this.conversation_school.conversation;
        }
      } else {
        this.conversation_educator = [];
        this.conversation_school = [];
      }

      this.modalChat = true;
    });
  }

  openAssignmentPost(assignment_posting_id: number) {
    this.http.get(this.URL_BASE + 'actual_assignment_post_stats/filter-assignment/' + assignment_posting_id).subscribe(res => {
      this.actual_assignment_posts = res;

      this.sourceActualAssignmentPost.localdata = this.actual_assignment_posts;
      this.gridActualAssignmentPost.updatebounddata('cells');

      if(this.actual_assignment_posts && this.actual_assignment_posts.length > 0) {
        this.modalOpenAssignment = true;
      } else {
        let error = {
          message: "Assignment is missing Clock-in information",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    });
  }

  loadRateAssignment(assignment_posting_id: number) {
    this.http.get(this.URL_BASE + 'assignment_postings/filter-user-applied/' + assignment_posting_id).subscribe(res => {
    // this.http.get(this.URL_BASE + 'assignment_postings/filter-user-applied/' + assignment_posting_id + '/school').subscribe(res => {
      this.assignment_ratings = res; 

      if(this.assignment_ratings && this.assignment_ratings.length > 0) {
        this.assignment_ratings.forEach((rating: any) => {
          this.rate_questions = new Array(
            {
              "id": 1,
              "question": "Was the Educator engaged today?",
              "answer": ""
            }, {
              "id": 2,
              "question": "Did the Educators display a postive attitude?",
              "answer": ""
            }, {
              "id": 3,
              "question": "Did the Educator display Classroom Management skills?",
              "answer": ""
            }, {
              "id": 4,
              "question": "Was the Educator appropiately dress for the assignment?",
              "answer": ""
            }
          );

          this.answers = new Array(
            {
              "id": 1,
              "option": "YES"
            }, {
              "id": 2,
              "option": "NO"
            }
          );

          if(!rating.questions || rating.questions.length == 0) {
            rating.questions = this.rate_questions;
            rating.disabled = false;
          } else {
            rating.disabled = true;
          }
        });
      }

      if(this.assignment_ratings && this.assignment_ratings.length > 0) {
        this.modalRatesAssignment = true;
      } else {
        let error = {
          message: "Assignments cannot be Rated until they are Closed.",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }      
    });
  }

  loadCancelAssignment(assignment_posting_id: number) {
    this.cancel_assignment_posting_id = assignment_posting_id; 

    this.modalCancelAssignment = true;
  }

  convertFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  filterAssignmentData(date_from: string = "", date_to: string = "", organization_id: number = 0, zone_manager_id: number = 0, zone_id: number = 0, school_id: number = 0) {
    date_from = moment(date_from).format("yyyy-MM-DD");
    date_to = moment(date_to).format("yyyy-MM-DD");

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }
    
    if(!zone_manager_id || zone_manager_id == 0) {
      zone_manager_id = 0;
    }
    
    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }
    
    if(!school_id || school_id == 0) {
      school_id = 0;
    }
    
    this.http.get(this.URL_BASE + 'assignment_postings/filter-data/' + date_from + '/' + date_to + '/' + organization_id + '/' + zone_manager_id + '/' + zone_id + '/' + school_id).subscribe(res => {
      this.assignment_postings = res;

      this.sourceAssignmentPostings.localdata = this.assignment_postings;
      this.gridAssignmentPostings.updatebounddata('cells');
    });
  }

  loadGPSLive(assignment_posting_id: number) {
    this.http.get(this.URL_BASE + 'assignment_posting/get-gps-live/' + assignment_posting_id).subscribe(res => {
      this.assignment_gps_live = res;

      if(this.assignment_gps_live && this.assignment_gps_live.locate) {
        var lat_educator = this.assignment_gps_live.locate.latitude;
        var lng_educator = this.assignment_gps_live.locate.longitude;
        this.center = {lat: lat_educator, lng: lng_educator};
        this.addMarker(lat_educator, lng_educator);
      }

      if(this.assignment_gps_live && this.assignment_gps_live.maps) {
        var lat_school = this.assignment_gps_live.maps.position.lat;
        var lng_school = this.assignment_gps_live.maps.position.lng;
        this.addMarker(lat_school, lng_school);
      }
    });

    this.interval = setInterval(() => {
      this.http.get(this.URL_BASE + 'assignment_posting/get-gps-live/' + assignment_posting_id).subscribe(res => {
        this.assignment_gps_live = res;

        if(this.assignment_gps_live && this.assignment_gps_live.locate) {
          var lat_educator = this.assignment_gps_live.locate.latitude;
          var lng_educator = this.assignment_gps_live.locate.longitude;
          this.center = {lat: lat_educator, lng: lng_educator};
          this.addMarker(lat_educator, lng_educator);
        }

        if(this.assignment_gps_live && this.assignment_gps_live.maps) {
          var lat_school = this.assignment_gps_live.maps.position.lat;
          var lng_school = this.assignment_gps_live.maps.position.lng;
          this.addMarker(lat_school, lng_school);
        }
      });
    }, 120000); 

    this.modalMapGps = true;
  }

  loadHistoryAssignment(assignment_posting_id: number) {
    this.http.get(this.URL_BASE + 'assignment_posting/history/' + assignment_posting_id).subscribe((res: any) => {
      this.assignment_histories = res;

      this.sourceAssignmentHistories.localdata = this.assignment_histories;
      this.gridAssignmentHistories.updatebounddata('cells');

      if(this.assignment_histories && this.assignment_histories.length > 0) {
        this.modalHistory = true;
      } else {
        let error = {
          message: "Assignment is missing history information",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadHistoryAssignment(assignment_posting_id);
      }
    });
  }

  clearIntervalMap() {
    if(this.interval) {
      clearInterval(this.interval);
    }

    this.modalMapGps = false;
  }

  // MAPS
  addMarker(lat: any, lng: any) {
    if(lat && lng) {
      this.markerPositions = [];
      setTimeout(() => {
        let markerMap = {
          position: {
            lat: lat,
            lng: lng,
          },
          label: {
            color: 'red',
            text: 'Marker School',
          },
          title: 'Marker School',
          info: {
            title: 'Marker School',
          },
          options: { 
            draggable: false,
            animation: google.maps.Animation.DROP,
          },
        }

        this.markerPositions.push(markerMap);
      }, 200);
    }
  }

  openInfoWindow(marker: MapMarker, info: any) {
    this.markerInfo = info;
    this.infoWindow.open(marker);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  updateOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      if(this.assignment_posting_id > 0) {
        this.modalConfirmEdi = true;
        // this.editAssignmentPostings();
      } else {
        let error = {
          message: "To continue, select a assignment postings",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  assignedOnClick(educator: number = 0) {
    if(this.permissions && this.permissions.update == 1) {
      if(this.assignment_posting_id > 0) {
        this.assignedAssignmentPostings(educator);
      } else {
        let error = {
          message: "To continue, select a assignment postings",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  requestEducatorsOnClick(assignment_id: number = 0, user_profiles: any, type: string) {
    if(this.permissions && this.permissions.update == 1) {
      if(this.assignment_posting_id > 0) {
        this.requestNotificationAssignment(assignment_id, user_profiles, type);
      } else {
        let error = {
          message: "To continue, select a assignment postings",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  requestEducatorOnClick() {
    if(this.permissions && this.permissions.update == 1) {
      if(this.assignment_posting_id > 0) {
        let user_profiles = new Array();

        let user_profile = this.combobox_educators.originaldata.find((element: any) => element.user_id == this.user_ass_id);
        user_profiles.push(user_profile);

        setTimeout(() => {
          if(user_profiles && user_profiles.length > 0) {
            this.requestNotificationAssignment(this.assignment_posting_id, user_profiles, "");
          } else {
              let error = {
              message: "To continue, select a educator",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();
          }
        }, 500);
      } else {
        let error = {
          message: "To continue, select a assignment postings",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  cleanOnClick() {
    this.clearForm();
  }

  rateEducatorOnClick(educator_id: number, rate: number) {
    let assignment_rating = this.assignment_ratings.find((element: any) => element.user_id == educator_id);
    
    if(assignment_rating) {
      assignment_rating.rating = rate;
    }
  }

  registerRateOnClick(assignment_rating: any) {
    if(this.permissions && this.permissions.store == 1) {
      this.registerOrEditUserRating(assignment_rating);
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  viewPDFOnClick() {
    if(this.pdfSource != "") {
      this.modalPDF = true;
    } else {
      let error = {
        message: "No PDF to view",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  organizationOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.zone_manager_id = 0;
        this.comboboxZoneManager.clearSelection();
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.filterAssignmentData(this.date_from, this.date_to, item, this.zone_manager_id, this.zone_id, this.school_id);
        this.loadZoneManagerZonesSchools(0);
        this.loadOrganizationSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadOrganizationSchools(0);
    }
  }

  zoneManagerOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, item, this.zone_id, this.school_id);
        this.loadZoneManagerZonesSchools(item);
        this.loadZoneSchools(this.school_id);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_manager_id = 0;
      this.comboboxZoneManager.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadZoneManagerZonesSchools(0);
      this.loadZoneSchools(0);
    }
  }

  zoneOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.school_id = 0;
        this.comboboxSchool.clearSelection();
        this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, item, this.school_id);
        this.loadZoneSchools(item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      this.loadZoneSchools(0);
    }
  }

  userOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, item);
      }
    } else {
      this.school_id = 0;
      this.comboboxSchool.clearSelection();
      this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS FOCUS                                   */
  /* ------------------------------------------------------------------------------------- */

  rateEducatorOnFocus(event: any, educator_id: number, rate_question_id: number) {
    if(event.target) {
      let assignment_rating = this.assignment_ratings.find((element: any) => element.user_id == educator_id);
      var answer = event.target.value;
      
      if(assignment_rating) {
        let rate_question = assignment_rating.questions.find((question: any) => question.id == rate_question_id);
        rate_question.answer = answer;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.filterAssignmentData(date, this.date_to, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.filterAssignmentData(this.date_from, date, this.organization_id, this.zone_manager_id, this.zone_id, this.school_id);
      }
    }
  }

  uploadDocumentoOnChange(event: any): void {
    if(event.target) {
      var document_file = event.target.files[0];
      this.convertFile(document_file).then(data => {
        this.substitute_teacher_plan = data;
        this.pdfSource = data;
        this.document_teacher_name = document_file.name;
      }); 
    }
  }

  uploadImageOnChange(event: any): void {
    if(event.target) {
      var document_file = event.target.files[0];
      this.convertFile(document_file).then(data => {
        this.image = data;
      }); 
    }
  }

  startDateOnChange(event: any): void {
    if(event.target) {
      var end_date = event.target.value;

      if(end_date) {
        this.min_end_date = end_date;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  editAssignmentPostings() {
    this.http
    .put(
      this.URL_BASE + 'assignment_posting/' + this.assignment_posting_id, 
      {
        user_profile_id: this.user_profile_id,  
        start_date: moment(this.startDateDateTime.val()).format("yyyy-MM-D"),
        end_date: moment(this.endDateDateTime.val()).format("yyyy-MM-D"), 
        assignament_type_id: this.assignament_type_id, 
        start_time: moment(this.start_time, "LT").format("HH:mm:ss"), 
        end_time: moment(this.end_time, "LT").format("HH:mm:ss"), 
        lunch_hours: this.lunch_hours, 
        break_hours: this.break_hours, 
        check_in_location: this.check_in_location, 
        check_in_contact: this.check_in_contact, 
        skill_id: this.skill_id, 
        skill_other: this.skill_other,
        note_section: this.note_section,
        substitute_teacher_plan: this.substitute_teacher_plan,
        force_long_term: this.checkboxForceLongTerm.val(),
        image: this.image,
        status_id: this.status_id,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearForm();
          this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
        } else {
          this.gridAssignmentPostings.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  assignedAssignmentPostings(educator: number = 0) {
    let user_id = this.user_ass_id;

    if(educator > 0) {
      user_id = educator;
    }

    setTimeout(() => {
      if(user_id == 0 || user_id == null) {
        let error = {
          message: "You must select an educator to continue",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }

      this.http
      .put(
        this.URL_BASE + 'assignment_posting/assigned-educator/' + this.assignment_posting_id, 
        {
          user_id: user_id,
          update_id: this.user_id
        }
      )
      .subscribe(
        (res:any) => {
          this.alerts.push(res);
          this.closeAlertTime();

          if(res.type == "green") {
            this.clearForm();
            this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
          } else {
            this.gridAssignmentPostings.updatebounddata('cells');
          }
        },
        (err:any) => {
          if(typeof err.error !== 'undefined') {
            if(err.error.errors)  {
              let message = "";
              Object.keys(err.error.errors).map((key) => {
                message += "- " + err.error.errors[key][0] + " <br>";
              });
                
              let error = {
                message: message,
                type: "red"
              };
              this.alerts.push(error);
            } else if(err.error.message)  {
              let error = {
                tittle: "Error",
                message: err.error.message,
                type: "red"
              };

              this.alerts.push(error);
            }
          } else {
            let error = {
              tittle: "Error",
              message: err,
              type: "red"
            };

            this.alerts.push(error);
          }
          this.closeAlertTime();
        }
      );
    }, 500);
  }

  deleteAssignmentPostings(id: number) {
    this.http
    .delete(
      this.URL_BASE + 'assignment_posting/' + id
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "orange") {
          this.delete_assignment_id = 0;
          this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
        } else {
          this.gridAssignmentPostings.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  sendMessage(chat_type: number) {
    if(!this.permissions || this.permissions.store == 0) {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let new_array: any = new Array();
    let conversation: any = new Array();
    var id = 1;

    if(chat_type == 1) {
      if(this.message_educator == "" || this.message_educator == " ") {
        let error = {
          message: "To send a message you must enter the message",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
      let validate_arr: any = this.message_educator.split(" ");
      if(validate_arr[0] == "" || validate_arr[0] == " ") {
        let error = {
          message: "The first character cannot be a space.",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }

      if(this.conversation_educator != null && this.conversation_educator.length > 0) {
        id = (this.conversation_educator[this.conversation_educator.length-1].id + 1);
        conversation = this.conversation_educator;
      } else {
        conversation = new Array();
      }

      new_array = {
        id: id,
        user_id: this.user_id,
        user_name: this.user_name,
        person: 1,
        message: this.message_educator,
        date_time: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        date: moment(new Date()).format("yyyy-MM-DD"),
        type: "text",
        link: ""
      };
    }

    if(chat_type == 2) {
      if(this.message_school == "" || this.message_school == " ") {
        let error = {
          message: "To send a message you must enter the message",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
      let validate_arr: any = this.message_school.split(" ");
      if(validate_arr[0] == "" || validate_arr[0] == " ") {
        let error = {
          message: "The first character cannot be a space.",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }

      if(this.conversation_school != null && this.conversation_school.length > 0) {
        id = (this.conversation_school[this.conversation_school.length-1].id + 1);
        conversation = this.conversation_school;
      } else {
        conversation = new Array();
      }

      new_array = {
        id: id,
        user_id: this.user_id,
        user_name: this.user_name,
        person: 1,
        message: this.message_school,
        date_time: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        date: moment(new Date()).format("yyyy-MM-DD"),
        type: "text",
        link: ""
      };
    }

    conversation.push(new_array);

    this.http
    .post(
      this.URL_BASE + 'chat/update-chat/user', 
      {
        user_id: this.user_id,
        assignment_posting_id: this.chat_assignment_posting_id,
        chat_type: chat_type,
        creation_date: moment(new Date()).format("yyyy-MM-DD"),
        conversation: conversation,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormChat();

          if(chat_type == 1) {
            this.conversation_educator = conversation;
          }
          
          if(chat_type == 2) {
            this.conversation_school = conversation;
          }
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  cancelAssignment() {
    if(!this.permissions || this.permissions.update == 0) {
      let error = {
        message: "You do not have permissions to update in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.closed_cancel_status_id == null || this.closed_cancel_status_id == 0) {
      let error = {
        message: "To cancel or closed the assignment you must register the status",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.cancel_comment == null || this.cancel_comment == "") {
      let error = {
        message: "To cancel or closed the assignment you must register the comment",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'assignment_posting/cancel-closed/' + this.cancel_assignment_posting_id, 
      {
        user_id: this.user_id,
        user_profile_id: this.school_profile_id,
        status_id: this.closed_cancel_status_id,
        cancel_comment: this.cancel_comment,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.modalCancelAssignment = false;
          this.clearFormCancelAssignment();
          this.filterAssignmentData(this.date_from, this.date_to, this.organization_id, this.zone_id, this.school_id);
        } else {
          this.gridAssignmentPostings.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editActualAssignmentPosts(rowdata: any) {
    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + rowdata.id, 
      {
        update_type: "approval", 
        approval: rowdata.approval, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.openAssignmentPost(rowdata.assignment_posting_id);

          setTimeout(() => {
            this.createGridActualAssignmentPosts();
          }, 700);
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editSchoolHours() {
    if(this.school_hours == 0 || this.school_hours == null) {
      let error = {
        message: "To save you must enter the hours",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(this.school_comments == "" || this.school_comments == null) {
      let error = {
        message: "To save you must enter the comments",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + this.actual_assignment_stat_id, 
      {
        update_type: "school_hours",
        comments: this.school_comments, 
        school_hours: this.school_hours,
        school_overtime: this.school_overtime,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.openAssignmentPost(this.actual_assignment_posting_id);
          
          setTimeout(() => {
            this.createGridActualAssignmentPosts();
          }, 700);

          this.clearFormSchoolHours();
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editApprovalHours() {
    if(this.approval_hours == 0 || this.approval_hours == null) {
      let error = {
        message: "To save you must enter the hours",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'actual_assignment_post_stat/' + this.actual_assignment_stat_id, 
      {
        update_type: "approval_hours",
        approval_hours: this.approval_hours,
        approval_overtime: this.approval_overtime,
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.openAssignmentPost(this.actual_assignment_posting_id);
          
          setTimeout(() => {
            this.createGridActualAssignmentPosts();
          }, 700);
          
          this.clearFormApprovalHours();
        } else {
          this.gridActualAssignmentPost.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  registerOrEditUserRating(assignment_rating: any) {
    if(!assignment_rating) {
      let error = {
        message: "Error when editing or registering the user rating",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(assignment_rating.rating == 0) {
      let error = {
        message: "To continue please select the rating",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    let validate_questions = assignment_rating.questions.find((element: any) => element.answer === "");
    if(validate_questions) {
      let error = {
        message: "To continue please answer each question",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .put(
      this.URL_BASE + 'assignment_posting/register/rating', 
      {
        user_id: assignment_rating.user_id,
        assignment_id: assignment_rating.assignment_id,
        rated_by: assignment_rating.rated_by,
        rating: assignment_rating.rating,
        questions: assignment_rating.questions,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        this.loadRateAssignment(assignment_rating.assignment_id);
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  requestNotificationAssignment(assignment_id: number = 0, user_profiles: any, type: string) {
    if(!assignment_id || assignment_id == 0) {
      let error = {
        message: "There is no assignment to send the notifications.",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(!user_profiles || user_profiles.length == 0) {
      let error = {
        message: "There are no educators to send the notifications.",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    this.http
    .post(
      this.URL_BASE + 'assignment_posting/notification/user_profiles', 
      {
        assignment_id: assignment_id,
        user_profiles: user_profiles,
        type: type,
        create_id: this.user_id
      }
    )
    .subscribe(
      (res: any) => {
        if(res.type == "green") {
          if(type == "preferred") {
            this.send_request_pre = true;
          }
          if(type == "top") {
            this.send_request_top = true;
          }
        }

        this.alerts.push(res);
        this.closeAlertTime();
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearForm() {
    this.assignment_posting_id = 0;
    this.user_profile_id = 0;
    this.comboboxUserProfile.clearSelection();
    this.user_ass_id = 0;
    this.comboboxAssignmentUser.clearSelection();
    this.start_date = "";
    this.end_date = "";
    this.comboboxAssignmentType.selectIndex(0);
    this.start_time = "08:00 AM";
    this.end_time = "04:00 PM";
    this.lunch_hours = 0;
    this.break_hours = 0;
    this.check_in_location = "";
    this.check_in_contact = "";
    this.comboboxSkill.selectIndex(0);
    this.skill_other = "";
    this.document_teacher = "";
    this.substitute_teacher_plan = "";
    this.document_teacher_name = "";
    this.pdfSource = "";
    this.checkboxForceLongTerm.val(false);
    this.note_section = "";
    // this.comboboxAssignmentStatuses.selectIndex(0);
    this.image = "";
    this.send_request_pre = false;
    this.send_request_top = false;

    this.slideAssignment = false;
  }

  clearFormChat() {
    this.message_educator = "";
    this.message_school = "";
  }

  clearFormCancelAssignment() {
    this.cancel_assignment_posting_id = 0;
    this.closed_cancel_status_id = 0;
    this.cancel_comment = "";
  }

  clearFormSchoolHours() {
    this.school_hours = 0;
    this.school_comments = "";

    this.modalSchoolHours = false;
  }

  clearFormApprovalHours() {
    this.approval_hours = 0;

    this.modalApprovalHours = false;
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridAssignmentPostings() {
    this.sourceAssignmentPostings =
    {
      localdata: this.assignment_postings,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_profile_id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' },
        { name: 'assignament_type_id', type: 'int' },
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'lunch_hours', type: 'string' },
        { name: 'break_hours', type: 'string' },
        { name: 'check_in_location', type: 'string' },
        { name: 'check_in_contact', type: 'string' },
        { name: 'skill_id', type: 'int' },
        { name: 'skill_other', type: 'string' },
        { name: 'substitute_teacher_plan', type: 'string' },
        { name: 'force_long_term', type: 'boolean' },
        { name: 'note_section', type: 'string' },
        { name: 'status_id', type: 'int' },
        { name: 'image', type: 'string' },
        { name: 'school', type: 'string' },
        { name: 'school_first_name', type: 'string' },
        { name: 'school_second_name', type: 'string' },
        { name: 'school_last_name', type: 'string' },
        { name: 'school_business_name', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' },
        { name: 'skill', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zone_manager', type: 'string' },
        { name: 'zone_manager_school_first_name', type: 'string' },
        { name: 'zone_manager_school_second_name', type: 'string' },
        { name: 'zone_manager_school_last_name', type: 'string' }
      ]
    };

    this.dataAdapterAssignmentPostings = new jqx.dataAdapter(this.sourceAssignmentPostings, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.school_first_name && fila.school_second_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_second_name + " " + fila.school_last_name;
          } else if(fila.school_first_name && fila.school_last_name) {
            fila.school = fila.school_first_name + " " + fila.school_last_name;
          } else if(fila.school_first_name) {
            fila.school = fila.school_first_name;
          } else {
            fila.school = fila.school_business_name;
          }

          if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
          } else if(fila.educator_first_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_last_name;
          } else if(fila.educator_first_name) {
            fila.educator = fila.educator_first_name;
          }

          if(fila.zone_manager_school_first_name && fila.zone_manager_school_second_name && fila.zone_manager_school_last_name) {
            fila.zone_manager = fila.zone_manager_school_first_name + " " + fila.zone_manager_school_second_name + " " + fila.zone_manager_school_last_name;
          } else if(fila.zone_manager_school_first_name && fila.zone_manager_school_last_name) {
            fila.zone_manager = fila.zone_manager_school_first_name + " " + fila.zone_manager_school_last_name;
          } else if(fila.zone_manager_school_first_name) {
            fila.zone_manager = fila.zone_manager_school_first_name;
          }

          if(fila.start_date) {
            fila.start_date = moment(fila.start_date).format("MM-DD-yyyy");
          }

          if(fila.end_date) {
            fila.end_date = moment(fila.end_date).format("MM-DD-yyyy");
          }

          if(fila.start_time) {
            fila.start_time = moment(fila.start_time, "HH:mm:ss").format("LT");
          }

          if(fila.end_time) {
            fila.end_time = moment(fila.end_time, "HH:mm:ss").format("LT");
          }

          data.push(fila);
        }
      },
    });

    this.cellHoverAssignmentPostings = (element?: any, pageX?: any, pageY?: any): any => {
      var cell = this.gridAssignmentPostings.getcellatposition(pageX, pageY);
      var newpageX = pageX + 15;
      var newpageY = pageY + 15;
      var oldpageY = this.myTooltip.absolutePositionX();
      var oldpageY = this.myTooltip.absolutePositionY();

      if(cell && cell.column) {
        var row = Number(cell.row)!;
        var data = this.gridAssignmentPostings.getrowdata(row);
        var datafield = cell.column.toString();

        if (datafield == "Del" || datafield == "Edit" || datafield == "Mess" || datafield == "Getin" || datafield == "Rate" || datafield == "Canc" || datafield == "Map" || datafield == "His") {
          var cellValue = cell.value;
          var tooltipContent = "";

          if(datafield == "Del") {
            tooltipContent = '<b>Delete Assignment</b>';
          }
          
          if(datafield == "Edit") {
            tooltipContent = '<b>Edit Assignment</b>';
          }
          
          if(datafield == "Mess") {
            tooltipContent = '<b>Open Chat Assignment</b>';
          }
          
          if(datafield == "Getin") {
            tooltipContent = '<b>Open Actual Assignment</b>';
          }
          
          if(datafield == "Rate") {
            tooltipContent = '<b>Open Assignment Rates</b>';
          }
          
          if(datafield == "Canc") {
            tooltipContent = '<b>Cancel or Closed Assignment</b>';
          }
          
          if(datafield == "Map") {
            tooltipContent = '<b>Open GPS Live Assignment</b>';
          }
          
          if(datafield == "His") {
            tooltipContent = '<b>Open History Assignment</b>';
          }

          if(tooltipContent != "" && tooltipContent != " ") {
            this.myTooltip.content(tooltipContent);
            this.myTooltip.open(newpageX, newpageY);
            setTimeout(() => {
              this.myTooltip.refresh();
            }, 100);
          }
        }
      }
    }

    this.columnsAssignmentPostings =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      {
        text: '',
        datafield: 'Edit',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        tooltip: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/></svg>'
        },
      },
      {
        text: '',
        datafield: 'Mess',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'Getin',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'Rate',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'Canc',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'Map',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /></svg>'
        },
      },
      {
        text: '',
        datafield: 'His',
        width: '4%',
        columntype: 'button',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" /></svg>'
        },
      },
      { 
        text: 'ID',  
        datafield: 'id',
        displayfield: 'id',
        editable: true,
        width: '5%' 
      },
      { 
        text: 'Status',  
        datafield: 'status_id',
        displayfield: 'status',
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Start Date',  
        datafield: 'start_date',
        displayfield: 'start_date',
        editable: true,
        width: '12%' 
      },
      { 
        text: 'End Date',  
        datafield: 'end_date',
        displayfield: 'end_date',
        editable: true,
        width: '12%' 
      },
      { 
        text: 'School',  
        datafield: 'user_profile_id',
        displayfield: 'school',
        editable: true,
        width: '17%' 
      },
      { 
        text: 'Educator',  
        datafield: 'user_id',
        displayfield: 'educator',
        editable: true,
        width: '17%' 
      },
      { 
        text: 'Skill',  
        datafield: 'skill_id',
        displayfield: 'skill',
        editable: true,
        width: '17%' 
      },
      { 
        text: 'Start Time',  
        datafield: 'start_time',
        displayfield: 'start_time',
        editable: true,
        width: '12%' 
      },
      { 
        text: 'End Time',  
        datafield: 'end_time',
        displayfield: 'end_time',
        editable: true,
        width: '12%' 
      },
      { 
        text: 'Zone',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '20%' 
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClick(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;

    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          this.delete_assignment_id = rowdata.id;
          this.modalConfirmDel = true;
          // this.deleteAssignmentPostings(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Edit") {
        if(this.permissions && this.permissions.update == 1) {
          if(rowdata.status_id == 1 || rowdata.status_id == 4 || rowdata.status_id == 6) {
            this.loadEditAssignmentPosting(rowdata);
            this.loadingService.hide();
          } else {
            let error = {
              message: "The assignment cannot be edited since the Status is Filled, In Progress, or Closed",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();
          }
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Mess") {
        if(this.permissions && this.permissions.visualize == 1) {
          this.loadChat(rowdata.id, rowdata.school, rowdata.start_date);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Getin") {
        if(this.permissions && this.permissions.visualize == 1) {
          this.openAssignmentPost(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Rate") {
        if(this.permissions && this.permissions.visualize == 1) {
          if(rowdata.status_id == 5) {
            this.loadRateAssignment(rowdata.id);
          } else {
            let error = {
              message: "Assignments cannot be Rated until they are Closed.",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();
          }
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Canc") {
        if(this.permissions && this.permissions.visualize == 1) {
          if(rowdata.status_id == 2 || rowdata.status_id == 3) {
            this.loadCancelAssignment(rowdata.id);
          } else {
            let error = {
              message: "An Educator must take action to Cancel an Assignment",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();
          }
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "Map") {
        if(this.permissions && this.permissions.visualize == 1) {
          if(rowdata.status_id == 2 || rowdata.status_id == 3) {
            this.loadGPSLive(rowdata.id);
          } else {
            let error = {
              message: "To log GPS live data, an assignment must first be accepted by an Educator via their mobile APP",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();
          }
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      } else if (args.datafield == "His") {
        if(this.permissions && this.permissions.visualize == 1) {
          this.loadHistoryAssignment(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }

  createGridActualAssignmentPosts() {
    this.sourceActualAssignmentPost = {
      localdata: this.actual_assignment_posts,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'date', type: 'string' },
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'end_time_real', type: 'string' },
        { name: 'long_term', type: 'boolean' },
        { name: 'overtime_hours', type: 'number' },
        { name: 'educator_hours', type: 'number' },
        { name: 'lunch_hours', type: 'number' },
        { name: 'break_hours', type: 'number' },
        { name: 'school_hours', type: 'number' },
        { name: 'school_overtime', type: 'number' },
        { name: 'approval_hours', type: 'number' },
        { name: 'approval_overtime', type: 'number' },
        { name: 'comments', type: 'string' },
        { name: 'status_id', type: 'int' },
        { name: 'user', type: 'string' },
        { name: 'assignment_post', type: 'string' },
        { name: 'school', type: 'string' },
        { name: 'school_first_name', type: 'string' },
        { name: 'school_second_name', type: 'string' },
        { name: 'school_last_name', type: 'string' },
        { name: 'school_nickname', type: 'string' },
        { name: 'approval', type: 'boolean' },
        { name: 'status', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editActualAssignmentPosts(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterActualAssignmentPost = new jqx.dataAdapter(this.sourceActualAssignmentPost, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.status_id == 2 || fila.status_id == 4) {
            fila.approval = true;
          } else {
            fila.approval = false;
          }

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }

          if(fila.start_time) {
            fila.start_time = moment(fila.start_time, "HH:mm:ss").format("LT");
          }

          if(fila.end_time) {
            fila.end_time = moment(fila.end_time, "HH:mm:ss").format("LT");
          }

          data.push(fila);
        }
      },
    });

    this.columnsActualAssignmentPost =
    [
      { 
        text: 'Approval',  
        datafield: 'approval',
        displayfield: 'approval',
        editable: true,
        filtertype: 'boolean',
        columntype: 'checkbox',
        width: '5%' 
      },
      { 
        text: 'Status',  
        datafield: 'status_id',
        displayfield: 'status',
        filtertype: 'checkedlist',
        editable: false,
        width: '8%' 
      },
      { 
        text: 'Educator',  
        datafield: 'user',
        displayfield: 'user',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '8%' 
      },
      { 
        text: 'Start Time',  
        datafield: 'start_time',
        displayfield: 'start_time',
        editable: false,
        width: '8%' 
      },
      { 
        text: 'End Time',  
        datafield: 'end_time',
        displayfield: 'end_time',
        editable: false,
        width: '8%' 
      },
      { 
        text: 'End Time Real',  
        datafield: 'end_time_real',
        displayfield: 'end_time_real',
        editable: false,
        hidden: true,
        width: '8%'
      },
      { 
        text: 'APP Worked Hrs.',
        datafield: 'educator_hours',
        displayfield: 'educator_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Lunch Hrs.',  
        datafield: 'lunch_hours',
        displayfield: 'lunch_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Break Hrs.',  
        datafield: 'break_hours',
        displayfield: 'break_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'Overtime Hrs.',
        datafield: 'overtime_hours',
        displayfield: 'overtime_hours',
        editable: false,
        width: '7%',
        filterable: false,
        cellsalign: 'center',
        filtertype: 'number',
        cellsformat: 'd2'
      },
      { 
        text: 'School',
        datafield: 'school_hours',
        displayfield: 'school_hours',
        editable: false,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          if(value == 0 || value == null) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            return value;
          }
        }
      },
      { 
        text: 'Approved',
        datafield: 'approval_hours',
        displayfield: 'approval_hours',
        editable: true,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          if(value == 0 || value == null) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            return value;
          }
        }
      },
      { 
        text: 'Ap. Overtime',  
        datafield: 'approval_overtime',
        displayfield: 'approval_overtime',
        editable: true,
        width: '7%',
        filterable: false,
        filtertype: 'number',
        columntype: 'button',
        cellsformat: 'd2',
        cellsrenderer: (row: any, datafield: any, value: any, value2: any, value3: any, data: any): any => {
          if((value == null || value == 0) && (data.status_id == 1 || data.status_id == 3)) {
            return '<div style="justify-content: center; display: flex; width: 50px;"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
          } else {
            if(value == 0) {
              return 0;
            } else {
              return value;
            }
          }
        }
      }
    ];
  }

  onCellClickActualAssignmentPost(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if(this.permissions && this.permissions.update == 1) {
        if (args.datafield == "school_hours") {
          if(rowdata.educator_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.school_hours = rowdata.educator_hours;
            this.school_overtime = rowdata.overtime_hours;

            this.modalSchoolHours = true;
          }
        } else if (args.datafield == "approval_hours") {
          if(rowdata.school_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.approval_hours = rowdata.school_hours;
            this.approval_overtime = rowdata.school_overtime;

            this.modalApprovalHours = true;
          }
        } else if (args.datafield == "approval_overtime") {
          if(rowdata.school_hours > 0 && (rowdata.approval_hours == 0 || rowdata.approval_hours == null)) {
            this.actual_assignment_posting_id = rowdata.assignment_posting_id;
            this.actual_assignment_stat_id = rowdata.id;

            this.approval_hours = rowdata.school_hours;
            this.approval_overtime = rowdata.school_overtime;

            this.modalApprovalHours = true;
          }
        }
      } else {
        let error = {
          message: "You do not have permissions to update in this module",
          type: "red"
        };

        this.alerts.push(error);
        return this.closeAlertTime();
      }
    }
  }

  createGridAssignmentHistories() {
    this.sourceAssignmentHistories = {
      localdata: this.assignment_histories,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'user_id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'date', type: 'string' },
        { name: 'report_type', type: 'string' },
        { name: 'business_name', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'educator_first_name', type: 'string' },
        { name: 'educator_second_name', type: 'string' },
        { name: 'educator_last_name', type: 'string' }
      ]
    };

    this.dataAdapterAssignmentHistories = new jqx.dataAdapter(this.sourceAssignmentHistories, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.date) {
            fila.date = moment(fila.date).format("MM-DD-yyyy");
          }

          if(fila.report_type) {
            fila.report_type = fila.report_type.charAt(0).toUpperCase() + fila.report_type.slice(1);
          }

          if(fila.educator_first_name && fila.educator_second_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_second_name + " " + fila.educator_last_name;
          } else if(fila.educator_first_name && fila.educator_last_name) {
            fila.educator = fila.educator_first_name + " " + fila.educator_last_name;
          } else if(fila.educator_first_name) {
            fila.educator = fila.educator_first_name;
          }

          data.push(fila);
        }
      },
    });

    this.columnsAssignmentHistories =
    [
      { 
        text: 'ID',  
        datafield: 'assignment_posting_id',
        displayfield: 'assignment_posting_id',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Type',  
        datafield: 'report_type',
        displayfield: 'report_type',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'School',  
        datafield: 'business_name',
        displayfield: 'business_name',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '30%' 
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
