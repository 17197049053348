<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div>
      <h2 class="text-lg font-medium leading-6 text-gray-900">User Lists</h2>
      <p class="mt-1 text-sm text-gray-500">Edit Permissions and Update Password</p>
    </div>

    <div class="mt-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxTooltip #tooltipReference [position]="'mouse'" [name]="'name'"></jqxTooltip>
        <jqxGrid #gridUsers
          [width]="'100%'" 
          [cellhover]='cellHoverUsers'
          [source]="dataAdapterUsers" 
          [columns]="columnsUsers"
          [pageable]="true" 
          [autoheight]="true" 
          [showfilterrow]="true"
          [columnsresize]="true"
          [filterable]="true"
          [sortable]="true"  
          [editable]="true" 
          [editmode]="'dblclick'"
          [selectionmode]="'singlerow'"
          (onCellclick)="onCellClick($event)"
          [localization]="localization"
          [theme]="'energyblue'"
        ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div id="modalPermissions" x-data="{modal_open: false}" x-show="modal_open" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto" *ngIf="user">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-10/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-1 text-center sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase" id="modal-title">{{ user.name }} user permissions</h3>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">      
              <div class="mb-2">
                <h2 class="text-lg font-medium leading-6 text-gray-900">Level 1 Permissions</h2>
              </div>
              <jqxGrid #gridFatherPermissions
                [width]="'100%'" 
                [source]="dataAdapterFatherPermissions" 
                [columns]="columnsFatherPermissions"
                [pageable]="true" 
                [autoheight]="true" 
                [showfilterrow]="true"
                [columnsresize]="true"
                [filterable]="true"
                [sortable]="true"  
                [editable]="true" 
                [editmode]="'dblclick'"
                [selectionmode]="'singlerow'"
                (onRowselect)="fatherPermissionsGridOnRowSelect($event)"
                [localization]="localization"
                [theme]="'energyblue'"
              ></jqxGrid>
            </div>
            <div class="col-span-12 sm:col-span-6">    
              <div class="mb-2">
                <h2 class="text-lg font-medium leading-6 text-gray-900">Level 2 Permissions</h2>
              </div>
              <jqxGrid #gridChildPermissions
                [width]="'100%'" 
                [source]="dataAdapterChildPermissions" 
                [columns]="columnsChildPermissions"
                [pageable]="true" 
                [autoheight]="true" 
                [showfilterrow]="true"
                [columnsresize]="true"
                [filterable]="true"
                [sortable]="true"  
                [editable]="true" 
                [editmode]="'dblclick'"
                [selectionmode]="'singlerow'"
                [localization]="localization"
                [theme]="'energyblue'"
              ></jqxGrid>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modal_permissions.attributes[8].value = 'display: none;'" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalPassword && user" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-1 text-center sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase" id="modal-title">PASSWORD CHANGE FOR {{ user.name }}</h3>
              <input type="hidden" class="input-energyblue" [(ngModel)]="user_id_new_password"> 
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">  
              <label for="new_password" class="block text-sm font-medium text-gray-700">New Password</label>
              <input type="text" name="new_password" id="new_password" autocomplete="new_password" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="new_password">
            </div>
            <div class="col-span-12 sm:col-span-6">    
              <label for="new_password_confirm" class="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input type="text" name="new_password_confirm" id="new_password_confirm" autocomplete="new_password_confirm" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="new_password_confirm">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="modalPassword = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
          <button type="button" (click)="changePasswordUserOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>