import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";

@Component({
  selector: 'app-submenus',
  templateUrl: './submenus.component.html',
  styleUrls: ['./submenus.component.css']
})
export class SubmenusComponent implements OnInit {
  private URL_BASE: string = "";
  private user_id: number = 0;
  public isLoggedIn = false;
  public user: any;
  public menus: any = new Array();
  public submenus: any = new Array();

  public menu_path: string = "";
  public submenu_path: string = "";

  constructor(
    private activeRoute: ActivatedRoute,
    private tokenStorageService: TokenStorageService,
    private http: HttpClient
  ) {  

  }

  ngAfterContentChecked(): void {
    if(this.activeRoute.snapshot.params) {
      this.menu_path = this.activeRoute.snapshot.params.menu;
      this.submenu_path = this.activeRoute.snapshot.params.submenu;
    }
  }

  ngOnInit(): void {
    this.user_id = this.tokenStorageService.getUser();
    if(this.activeRoute.snapshot.params) {
      this.menu_path = this.activeRoute.snapshot.params.menu;
      this.submenu_path = this.activeRoute.snapshot.params.submenu;
    }
    this.menus = this.tokenStorageService.getUserMenu(); 
    this.submenus = this.menus.find((item:any) => item.link == this.menu_path).submenus;
    
    
    if(this.submenus && this.submenus.length > 0) {
      setTimeout(() => {
        this.submenus.sort(function (a: any, b: any) {
          if (a.order < b.order)
            return -1;
          else if (a.order > b.order)
            return 1;
          else 
            return 0;
        });
      }, 200);

      setTimeout(() => {
        for (var submenu of this.submenus) {
          const element = document.getElementById(submenu.link) as HTMLElement;
          element.innerHTML = '<path stroke-linecap="round" stroke-linejoin="round" d="' + submenu.icon + '"/>';
        }
      }, 500);
    }
  }
}
