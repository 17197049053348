<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Parameters</h2>
    </div> 

    <div class="mt-2" x-data="{general: true, assignment: false, agency_profile: false}">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
            <option>My Account</option>
            <option>Company</option>
            <option selected>Team Members</option>
            <option>Billing</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a x-on:click="general = true, assignment = false, agency_profile = false" (click)="tabOnClick(1)" x-bind:class="general ? 'border-indigo-500 text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'">
                <svg x-bind:class="general ? 'text-indigo-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                </svg>
                <span>General</span>
            </a>
            <a x-on:click="general = false, assignment = true, agency_profile = false" (click)="tabOnClick(2)" x-bind:class="assignment ? 'border-indigo-500 text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'">
              <svg xmlns="http://www.w3.org/2000/svg" x-bind:class="assignment ? 'text-indigo-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'"  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <span>Assignment</span>
            </a>
            <a x-on:click="general = false, assignment = false, agency_profile = true" (click)="tabOnClick(3)" x-bind:class="agency_profile ? 'border-indigo-500 text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'">
              <svg xmlns="http://www.w3.org/2000/svg" x-bind:class="agency_profile ? 'text-indigo-500 -ml-0.5 mr-2 h-5 w-5' : 'text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'"  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <span>Agency Settings</span>
            </a>
          </nav>
        </div>
      </div>

      <div x-show="general">
        <div class="grid divide-y divide-neutral-200 mx-auto">
          <div class="py-5">
            <details class="group" #accordionGeneral>
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Identification Types</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="id_type_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="id_type_name" id="id_type_name" autocomplete="id_type_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="id_type_name">
                  </div>

                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createIdTypeOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxTooltip #tooltipReference [position]="'mouse'" [name]="'name'"></jqxTooltip>
                    <jqxGrid #gridIdTypes
                      [width]="'100%'" 
                      [cellhover]='cellHoverIdTypes'
                      [source]="dataAdapterIdTypes" 
                      [columns]="columnsIdTypes"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickIdTypes($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>

          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Genders</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="gender_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="gender_name" id="gender_name" autocomplete="gender_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="gender_name">
                  </div>
                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createGenderOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridGenders
                      [width]="'100%'" 
                      [cellhover]='cellHoverGenders'
                      [source]="dataAdapterGenders" 
                      [columns]="columnsGenders"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickGenders($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
          
          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Contact Types</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="contact_type_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="contact_type_name" id="contact_type_name" autocomplete="contact_type_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_type_name">
                  </div>
                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createContactTypeOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridContactTypes
                      [width]="'100%'" 
                      [cellhover]='cellHoverContactTypes'
                      [source]="dataAdapterContactTypes" 
                      [columns]="columnsContactTypes"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickContactTypes($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
          
          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Document Types</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="document_type_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="document_type_name" id="document_type_name" autocomplete="document_type_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="document_type_name">
                  </div>
                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createDocumentTypeOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridDocumentTypes
                      [width]="'100%'" 
                      [cellhover]='cellHoverDocumentTypes'
                      [source]="dataAdapterDocumentTypes" 
                      [columns]="columnsDocumentTypes"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickDocumentTypes($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>

      <div x-show="assignment">
        <div class="grid divide-y divide-neutral-200 mx-auto">
          <div class="py-5">
            <details class="group" #accordionAssignmentTypes>
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Assignment Types</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="assignment_type_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="assignment_type_name" id="assignment_type_name" autocomplete="assignment_type_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="assignment_type_name">
                  </div>
                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createAssignmentTypeOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridAssignmentTypes
                      [width]="'100%'" 
                      [cellhover]='cellHoverAssignmentTypes'
                      [source]="dataAdapterAssignmentTypes" 
                      [columns]="columnsAssignmentTypes"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickAssignmentTypes($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
          
          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Skills</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-10">
                    <label for="skill_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="skill_name" id="skill_name" autocomplete="skill_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="skill_name">
                  </div>

                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createSkillOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridSkills
                      [width]="'100%'" 
                      [cellhover]='cellHoverSkills'
                      [source]="dataAdapterSkills" 
                      [columns]="columnsSkills"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickSkills($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
          
          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Holidays</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create or Edit</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-6">
                    <label for="holiday_name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="holiday_name" id="holiday_name" autocomplete="holiday_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="holiday_name">
                  </div>

                  <div class="col-span-12 sm:col-span-4">
                    <label for="holiday_date" class="block mt-1 text-sm font-medium text-gray-700">Date</label>
                    <jqxDateTimeInput 
                      [width]="'100%'" 
                      [height]="38"
                      [formatString]="'MM-dd-yyyy'"
                      [(ngModel)]="holiday_date" 
                      [theme]="'energyblue'"
                    ></jqxDateTimeInput>
                  </div>

                  <div class="col-span-12 sm:col-span-2">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createHolidayOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
                  </div>

                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridHolidays
                      [width]="'100%'" 
                      [cellhover]='cellHoverHolidays'
                      [source]="dataAdapterHolidays" 
                      [columns]="columnsHolidays"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickHolidays($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>

      <div x-show="agency_profile">
        <div class="grid divide-y divide-neutral-200 mx-auto">
          <div class="py-5">
            <details class="group" #accordionAgencyProfile>
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>General</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Edit Educators Assignments Coverage Range (miles), Edit Clock-in time Tolerance (before start time allowance) , Educators and Schools Cancellation Time Tolerances, Edit Country Code</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-3">
                    <label for="gps_range" class="block text-sm font-medium text-gray-700">GPS Range (miles)</label>
                    <input type="text" min="0" (input)="numberInput($event)" name="gps_range" id="gps_range" autocomplete="gps_range" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="gps_range">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="time_zone" class="block text-sm font-medium text-gray-700">Time Zone</label>
                    <input type="text" name="time_zone" id="time_zone" autocomplete="time_zone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="time_zone">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="platform_color" class="block text-sm font-medium text-gray-700">Platform Color</label>
                    <input type="text" name="platform_color" id="platform_color" autocomplete="platform_color" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="platform_color">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="entry_time_tolerance" class="block text-sm font-medium text-gray-700">Entry Time Tolerance (minutes)</label>
                    <input type="text" min="0" (input)="numberInput($event)" autocomplete="entry_time_tolerance" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="entry_time_tolerance">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="cancel_educator_tolerance" class="block text-sm font-medium text-gray-700">Cancellation Tolerance Educator (minutes)</label>
                    <input type="text" min="0" (input)="numberInput($event)" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cancel_educator_tolerance">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="cancel_school_tolerance" class="block text-sm font-medium text-gray-700">Cancellation Tolerance School (minutes)</label>
                    <input type="text" min="0" (input)="numberInput($event)" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cancel_school_tolerance">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="country_code" class="block text-sm font-medium text-gray-700">Country Code (SMS)</label>
                    <input type="text" name="country_code" id="country_code" autocomplete="country_code" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="country_code">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="editProfileOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">UPDATE</button>
                  </div>
                </div>
              </div>
            </details>
          </div>

          <!-- <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span>Contacts</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Add Agency Contact List</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-3">
                    <label for="contact_name" class="block text-sm font-medium text-gray-700">Contact Name</label>
                    <input type="text" name="contact_name" id="contact_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_name">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="contact_email" class="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="contact_email" id="contact_email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_email">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="contact_mobile_phone" class="block text-sm font-medium text-gray-700">Mobile Phone</label>
                    <input type="text" min="0" (input)="numberInput($event)" name="contact_mobile_phone" id="contact_mobile_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="contact_mobile_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createContactOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Contact</button>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridContacts
                      [width]="'100%'" 
                      [cellhover]='cellHoverContacts'
                      [source]="dataAdapterContacts" 
                      [columns]="columnsContacts"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickContacts($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div> -->
          
          <div class="py-5">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span class="sm:col-span-7">Assignment Chat Communication</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Create Custom Links and Messages for Automated Assignment Chat Messages</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-2">
                    <label for="appl_mess_type" class="block text-sm font-medium text-gray-700 mb-1">Type</label>
                    <jqxComboBox 
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'alias'" 
                      [source]="message_types" 
                      [theme]="'energyblue'"
                      [(ngModel)]="appl_mess_type"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>
                  <div class="col-span-12 sm:col-span-{{ appl_mess_type == 'link' ? 4:7 }}">
                    <label for="appl_mess_text" class="block text-sm font-medium text-gray-700">Text</label>
                    <input type="email" name="appl_mess_text" id="appl_mess_text" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="appl_mess_text">
                  </div>
                  <div class="col-span-12 sm:col-span-3 {{ appl_mess_type == 'link' ? 'block' : 'hidden' }}">
                    <label for="appl_mess_link" class="block text-sm font-medium text-gray-700">Link</label>
                    <input type="text" name="appl_mess_link" id="appl_mess_link" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="appl_mess_link">
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="createApplicationMessageOnClick()" class="mt-1 w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Custom Message</button>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridApplicationMessages
                      [width]="'100%'" 
                      [cellhover]='cellHoverApplicationMessages'
                      [source]="dataAdapterApplicationMessages" 
                      [columns]="columnsApplicationMessages"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickApplicationMessages($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
          
          <div class="py-5 hidden">
            <details class="group">
              <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                <span class="sm:col-span-7">Agency Preferential and Restricted List of Educators</span>
                <span class="transition group-open:rotate-180">
                  <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div class="mt-3 group-open:animate-fadeIn">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <p class="text-sm text-gray-500">Add Educator Name to List Type</p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-6">
                    <label for="wb_educator_id" class="block text-sm font-medium text-gray-700">Educator</label>
                    <jqxComboBox
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'id'" 
                      [source]="educators" 
                      [theme]="'energyblue'"
                      [(ngModel)]="wb_educator_id"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label for="wb_type_id" class="block text-sm font-medium text-gray-700">Type</label>
                    <jqxComboBox
                      [width]="'100%'"  
                      [height]="38" 
                      [displayMember]="'name'" 
                      [valueMember]="'id'" 
                      [source]="wb_types" 
                      [theme]="'energyblue'"
                      [(ngModel)]="wb_type_id"
                      [autoComplete]="true" 
                      [searchMode]="'containsignorecase'"
                    ></jqxComboBox>
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
                    <button type="submit" (click)="addWBListOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Add Lists</button>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-12 gap-4">
                  <div class="col-span-12 sm:col-span-12">
                    <jqxGrid #gridWBLists
                      [width]="'100%'" 
                      [cellhover]='cellHoverWBLists'
                      [source]="dataAdapterWBLists" 
                      [columns]="columnsWBLists"
                      [pageable]="true" 
                      [autoheight]="true" 
                      [showfilterrow]="true"
                      [columnsresize]="true"
                      [filterable]="true"
                      [sortable]="true"  
                      [editable]="true" 
                      [editmode]="'dblclick'"
                      [selectionmode]="'singlerow'"
                      (onCellclick)="onCellClickWBLists($event)"
                      [localization]="localization"
                      [theme]="'energyblue'"
                    ></jqxGrid>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>       
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>