import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import { Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent {
  //COMBOBOX
  @ViewChild('comboboxTypeIdentification', { static: false }) comboboxTypeIdentification: jqxComboBoxComponent;
  @ViewChild('comboboxGender', { static: false }) comboboxGender: jqxComboBoxComponent;
  @ViewChild('comboboxCity', { static: false }) comboboxCity: jqxComboBoxComponent;
  @ViewChild('comboboxZipCode', { static: false }) comboboxZipCode: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;

  public token: string;
  public parameters: any;

  public type_identifications: any;
  public genders: any;
  public cities: any;
  public zip_codes: any;

  public name: string = "";
  public email: string = "";
  public first_name: string = "";
  public second_name: string = "";
  public last_name: string = "";
  public nickname: string = "";
  public type_identification_id: number = 0;
  public identification: any = 0;
  public mask_identification: any = {mask:'00-0000000', type:'num', len:10, userCaracters: true};
  public gender_id: number = 0;
  public city_id: number = 0;
  public zip_code: string = "";
  public address: string = "";
  public birth_date: string = "";
  public phone: string = "";
  public cell_phone: string = "";

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    
    this.alerts = new Array();

    this.token = this.router.url.split("/")[3];
    if(this.token) {
      this.token = this.token.split("?")[0];
    }
    // console.log(this.token);

    this.rutaActiva.queryParamMap
      .subscribe((params) => {
        this.parameters = { ...params };
        this.parameters = this.parameters.params;
        // console.log(this.parameters);
      }
    );
  }

  async ngOnInit() {
    await this.loadDataForm();
    await this.loadTypeIdentifications();
    await this.loadDataGenders();
    await this.loadCities();
    await this.validateToken();
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.birth_date = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadTypeIdentifications() {
    await this.http.get(this.URL_BASE + 'type_identifications/data/unauthenticated').subscribe((res: any) => {
      this.type_identifications = res;

      setTimeout(() => {
        this.comboboxTypeIdentification.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadTypeIdentifications();
      }
    });
  }

  async loadDataGenders() {
    await this.http.get(this.URL_BASE + 'genders/data/unauthenticated').subscribe(res => {
      this.genders = res;

      setTimeout(() => {
        this.comboboxGender.selectIndex(0);
      }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataGenders();
      }
    });
  }

  async loadCities() {
    await this.http.get(this.URL_BASE + 'cities/data/unauthenticated').subscribe((res: any) => {
      this.cities = res;

      // setTimeout(() => {
      //   this.comboboxCity.selectIndex(0);
      // }, 200);  
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadCities();
      }
    });
  }

  async loadZipCodes(city_id: number = 0) {
    await this.http.get(this.URL_BASE + 'zip_codes/filter-city/data/unauthenticated/' + city_id).subscribe(res => {
      this.zip_codes = res;

      // setTimeout(() => {
      //   this.comboboxZipCode.selectIndex(0);
      // }, 500); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadZipCodes(city_id);
      }
    });
  }

  async validateToken() {
    await this.http
    .post(
      this.URL_BASE + 'user/validate-token/pre-registration/' + this.parameters.id,  
      {
        token: this.token
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "red") {
          this.goLanding();
        } else if(res.type == "green") {
          this.uploadDataRegistration(res.user);
        } 
      },
      (err:any) => {
        this.goLanding();
      }
    );
  }

  uploadDataRegistration(user: any) {
    this.name = user.full_name;
    this.email = user.email;

    var array_name = this.name.split(" ");
    if(array_name.length == 4) {
      this.first_name = array_name[0];
      this.second_name = array_name[1]; 
      this.last_name = array_name[2] + " " + array_name[3];
    } else if(array_name.length == 3) {
      this.first_name = array_name[0]; 
      this.last_name = array_name[1] + " " + array_name[2];
    } else {
      this.first_name = array_name[0]; 
      this.last_name = array_name[1];
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  registerOnClick() {
    this.createPerson();
  }

  limpiarOnClick() {
    this.limpiarForm();
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  cityOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadZipCodes(item);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                     EVENTOS FOCUSOUT                                  */
  /* ------------------------------------------------------------------------------------- */

  cityFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let city = this.cities.find((element: any) => element.name == item);

          if(city) {
            this.city_id = city.id;
          } else {
            this.city_id = 0;
            this.comboboxCity.val("");
            this.comboboxCity.close();
            this.zip_codes = new Array();
            this.zip_code = "";

            let error = {
              message: "You must select a valid city",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }

  zipCodeFocusout(event: any): void {
    setTimeout(() => {
      if (event.target) {
        let item = event.target.value;
        if (item) {
          let zip_code = [];

          if(this.zip_codes) {
            zip_code = this.zip_codes.find((element: any) => element.code == item);
          }

          if(zip_code) {
            this.zip_code = zip_code.code;
          } else {
            this.zip_code = "";
            this.comboboxZipCode.val("");
            this.comboboxZipCode.close();

            let error = {
              message: "You must select a valid zip code",
              type: "red"
            };

            this.alerts.push(error);
            return this.closeAlertTime();;
          }
        }
      } 
    }, 500);
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        let arr_val = value.split("-");
        var value_return = "";

        if(arr_val.length > 0) {
          arr_val.forEach((element: any) => {
            if(element != "-") {
              value_return = value_return + "" + element;
            }
          })
        }

        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  onChangeFormatIdentification(event: any): void {
    var type_identification_id = 0;
    if(event && event.args) {
      type_identification_id = event.args.item.value;
    } else {
      type_identification_id = this.type_identification_id;
    }

    if(type_identification_id > 0) {
      if(type_identification_id == 1) {
        // SSN 123-45-6789
        this.mask_identification = {mask:'000-00-0000', type:'num', len:11, userCaracters: true};
      }
      if(type_identification_id == 2) {
        // EIN 12-3456789
        this.mask_identification = {mask:'00-0000000', type:'num', len:10, userCaracters: true};
      }

      setTimeout(()  => {
        this.identification = this.identification;
      }, 300);
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createPerson() {
    var birth_date = "";
    if(this.birth_date) {
      birth_date = moment(this.birth_date).format("yyyy-MM-DD");
    }

    var identification = "";
    let arr_identification = Array.from(this.identification);

    if(arr_identification && arr_identification.length > 0) {
      arr_identification.forEach((element: any) => {
        if(!isNaN(element)) {
          identification = identification + "" + element;
        }
      });
    }

    this.http
    .post(
      this.URL_BASE + 'user/register/teacher', 
      { 
        pre_register_id: this.parameters.id, 
        type_user_profile_id: 4, 
        first_name: this.first_name, 
        second_name: this.second_name, 
        last_name: this.last_name, 
        nickname: this.nickname, 
        type_identification_id: this.type_identification_id, 
        identification: identification, 
        email: this.email,  
        gender_id: this.gender_id, 
        city_id: this.city_id, 
        zip_code: this.zip_code, 
        address: this.address, 
        birth_date: birth_date, 
        phone: this.phone, 
        cell_phone: this.cell_phone, 
        password: this.identification,
        create_id: 1
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          setTimeout(() => {
            this.goLanding();
          }, 1200);
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  goLanding() {
    this.router.navigateByUrl("/landing");
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  limpiarForm() {
    // this.cotizacion_id = 0;
    // this.token = "";
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                               */
  /* ------------------------------------------------------------------------------------- */

  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}