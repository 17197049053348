import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent {
  //GRIDS
  @ViewChild('gridZones', { static: false }) gridZones: jqxGridComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;

  public zone_managers: any;
  public name: string = "";
  public description: string = "";
  public user_profile_id: number = 0;

  public zones: any;
  public sourceZones: any;
  public dataAdapterZones: any = [];
  public columnsZones: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('parameters');
    this.user_id = this.tokenStorage.getUser();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataZones();

      this.createGridZones();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataZones() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-zone-managers').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.zone_managers = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      }); 
    });

    await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
      this.zones = res;

      this.sourceZones.localdata = this.zones;
      this.gridZones.updatebounddata('cells');
    });
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  createZoneOnClick() {
    if(this.permissions && this.permissions.store == 1) {
      this.createZone();
    } else {
      let error = {
        message: "You do not have permissions to register in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES CRUD                               */
  /* ------------------------------------------------------------------------------------- */

  createZone() {
    this.http
    .post(
      this.URL_BASE + 'zone', 
      { 
        name: this.name, 
        description: this.description, 
        user_profile_id: this.user_profile_id, 
        create_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.clearFormZone();
          this.loadDataZones();
        } else {
          this.gridZones.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }

  editZone(RowEdit: any) {
    this.http
    .put(
      this.URL_BASE + 'zone/' + RowEdit.id, 
      {
        name: RowEdit.name, 
        description: RowEdit.description, 
        user_profile_id: RowEdit.user_profile_id, 
        update_id: this.user_id
      }
    )
    .subscribe(
      (res:any) => {
        this.alerts.push(res);
        this.closeAlertTime();

        if(res.type == "green") {
          this.loadDataZones();
        } else {
          this.gridZones.updatebounddata('cells');
        }
      },
      (err:any) => {
        if(typeof err.error !== 'undefined') {
          if(err.error.errors)  {
            let message = "";
            Object.keys(err.error.errors).map((key) => {
              message += "- " + err.error.errors[key][0] + " <br>";
            });
              
            let error = {
              message: message,
              type: "red"
            };
            this.alerts.push(error);
          } else if(err.error.message)  {
            let error = {
              tittle: "Error",
              message: err.error.message,
              type: "red"
            };

            this.alerts.push(error);
          }
        } else {
          let error = {
            tittle: "Error",
            message: err,
            type: "red"
          };

          this.alerts.push(error);
        }
        this.closeAlertTime();
      }
    );
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */

  clearFormZone() {
    this.name = "";
    this.description = "";
    this.user_profile_id = 0;
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridZones() {
    this.sourceZones =
    {
      localdata: this.zones,
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'name', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'user_profile_id', type: 'integer' },
        { name: 'user_profile', type: 'string' },
        { name: 'first_name', type: 'string' },
        { name: 'second_name', type: 'string' },
        { name: 'last_name', type: 'string' },
        { name: 'business_name', type: 'string' }
      ],
      updaterow: (rowid: any, rowdata: any, commit: any): any => {
        commit(true);
        if(this.permissions && this.permissions.update == 1) {
          if (rowdata != "") {
            this.editZone(rowdata);
          } else {
            commit(false);
          }
        } else {
          let error = {
            message: "You do not have permissions to edit in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    };

    this.dataAdapterZones = new jqx.dataAdapter(this.sourceZones, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.first_name && fila.second_name && fila.last_name) {
            fila.user_profile = fila.first_name + " " + fila.second_name + " " + fila.last_name;
          } else if(fila.first_name && fila.last_name) {
            fila.user_profile = fila.first_name + " " + fila.last_name;
          } else if(fila.first_name) {
            fila.user_profile = fila.first_name;
          } else {
            fila.user_profile = fila.business_name;
          }

          data.push(fila);
        }
      },
    });

    this.columnsZones =
    [
      {
        text: '',
        datafield: 'Del',
        width: '4%',
        columntype: 'button',
        hidden: 'true',
        filterable: false,
        editable: false,
        cellsrenderer: (row: any, datafield: any, value: any): any => {
          return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 2 23 27" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/></svg>'
        },
      },
      { 
        text: 'Name',  
        datafield: 'name',
        displayfield: 'name',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Description',  
        datafield: 'description',
        displayfield: 'description',
        editable: false,
        width: '30%' 
      },
      { 
        text: 'Select Zone Manager Name',  
        datafield: 'user_profile_id',
        displayfield: 'user_profile',
        editable: true,
        width: '50%',
        columntype: 'dropdownlist',
        createeditor: (row: number, column: any, editor: any): void => {
          editor.jqxDropDownList({
            autoDropDownHeight: true,
            source: this.zone_managers,
            displayMember: "name",
            valueMember: "id"
          });
        },
        cellvaluechanging: (row: number, column: any, columntype: any, oldvalue: any, newvalue: any): any => {
          // return the old value, if the new value is empty.
          if (newvalue == '') return oldvalue;
        }
      }
    ];
    // this.localization = getLocalization('es');
  }

  onCellClickZones(event:any) {
    var args = event.args;
    var selectedrowindex = args.rowindex;
    var rowdata = args.row.bounddata;
    
    if (selectedrowindex >= 0) {
      if (args.datafield == "Del") {
        if(this.permissions && this.permissions.delete == 1) {
          // this.delete(rowdata.id);
        } else {
          let error = {
            message: "You do not have permissions to delete in this module",
            type: "red"
          };

          this.alerts.push(error);
          return this.closeAlertTime();
        }
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}