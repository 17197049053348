import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-inactive-schools-report',
  templateUrl: './inactive-schools-report.component.html',
  styleUrls: ['./inactive-schools-report.component.css']
})
export class InactiveSchoolsReportComponent {
  //GRIDS
  @ViewChild('gridReports', { static: false }) gridReports: jqxGridComponent;

  //DATETIME
  @ViewChild('dateTimeInput', { static: false }) dateTimeInput: jqxDateTimeInputComponent;

  //COMBOBOX
  @ViewChild('comboboxOrganization', { static: false }) comboboxOrganization: jqxComboBoxComponent;
  @ViewChild('comboboxZone', { static: false }) comboboxZone: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;

  // DATA
  public organizations: any;
  public zones: any;

  // FORM
  public filter_date: any = "";
  public organization_id: number = 0;
  public zone_id: number = 0;
  public days: number = 0;

  // GRIDS
  public reports: any;
  public sourceReports: any;
  public dataAdapterReports: any = [];
  public columnsReports: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('apply-cancel-report');
    this.user_id = this.tokenStorage.getUser();
    this.user_name = this.tokenStorage.getUserName();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataOrganizations();
      await this.loadDataZones();
      await this.createGridReports();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.filter_date = moment(new Date()).format("MM-D-yyyy");
  }

  async loadDataOrganizations() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter/organizations-schools').subscribe(res => {
      var sourceUserProfiles =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' },
          { name: 'name', type: 'string' }
        ]
      };

      this.organizations = new jqx.dataAdapter(sourceUserProfiles, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataOrganizations();
      }
    });
  }

  async loadDataZones() {
    await this.http.get(this.URL_BASE + 'zones').subscribe(res => {
      var sourceZones =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'description', type: 'string' }
        ]
      };

      this.zones = new jqx.dataAdapter(sourceZones, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            fila.name = fila.name + " - " + fila.description;

            data.push(fila);
          }
        },
      });
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZones();
      }
    });
  }

  loadDataReport(date: any = "", organization_id: number = 0, zone_id: number = 0) {
    if(date == "") {
      date = 0;
    } else {
      date = moment(date).format("yyyy-MM-DD");
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }

    this.http.get(this.URL_BASE + 'report/filter-inactive-schools/' + date + '/' + organization_id + '/' + zone_id).subscribe(res => {
      this.reports = res;

      this.sourceReports.localdata = this.reports;
      this.gridReports.updatebounddata('cells');
    });
  }

  reportGenerate(date: string = "", organization_id: number = 0, zone_id: number = 0) {
    if(date == "") {
      let error = {
        message: "Select a date to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    } else {
      date = moment(date).format("yyyy-MM-DD");
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }

    window.open(this.URL_BASE + 'inactive-schools/report-pdf/' + date + '/' + organization_id + '/' + zone_id + '/' + this.user_id, "_blank");
  }

  reportExcelData(date: string = "", organization_id: number = 0, zone_id: number = 0) {
    if(date == "") {
      let error = {
        message: "Select a date to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    } else {
      date = moment(date).format("yyyy-MM-DD");
    }

    if(!organization_id || organization_id == 0) {
      organization_id = 0;
    }

    if(!zone_id || zone_id == 0) {
      zone_id = 0;
    }

    window.open(this.URL_BASE + 'inactive-schools/report-excel/' + date + '/' + organization_id + '/' + zone_id + '/' + this.user_id, "_blank");
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  reportOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportGenerate(this.filter_date, this.organization_id, this.zone_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  downloadOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportExcelData(this.filter_date, this.organization_id, this.zone_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS FOCUS                                   */
  /* ------------------------------------------------------------------------------------- */

  daysOnFocus(event: any) {
    if(event.target) {
      var days = event.target.value;
      
      if(days >= 0) {
        this.filter_date = moment(new Date()).subtract(days, 'days').format("MM-D-yyyy");
      }
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  organizationOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.zone_id = 0;
        this.comboboxZone.clearSelection();
        this.loadDataReport(this.filter_date, item, this.zone_id);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.loadDataReport(this.filter_date, this.organization_id, this.zone_id);
    }
  }

  zoneOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.organization_id = 0;
        this.comboboxOrganization.clearSelection();
        this.loadDataReport(this.filter_date, this.organization_id, item);
      }
    } else {
      this.organization_id = 0;
      this.comboboxOrganization.clearSelection();
      this.zone_id = 0;
      this.comboboxZone.clearSelection();
      this.loadDataReport(this.filter_date, this.organization_id, this.zone_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                        EVENTOS INPUT                                  */
  /* ------------------------------------------------------------------------------------- */
  
  numberInput(event: any): void {
    if(event && event.target) {
      var value = event.target.value;

      if(value) {
        var value_return = event.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, '');
        
        event.target.value = value_return;
      } else {
        event.target.value = null;
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        var start_date = moment(new Date());
        var end_date = moment(date);
        var days = (-end_date.diff(start_date, 'days'));
        if(days < 0) {
          days = 0;
        }
        this.days = days;

        this.loadDataReport(date, this.organization_id);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridReports() {
    this.sourceReports =
    {
      localdata: this.reports, 
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'last_assignment_date', type: 'string' },
        { name: 'inactive_days', type: 'int' },
        { name: 'user', type: 'string' },
        { name: 'first_name', type: 'string' },
        { name: 'second_name', type: 'string' },
        { name: 'last_name', type: 'string' },
        { name: 'business_name', type: 'string' },
        { name: 'organization_name', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zip_code', type: 'string' },
        { name: 'zone_manager', type: 'string' }
      ]
    };

    this.dataAdapterReports = new jqx.dataAdapter(this.sourceReports, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          fila.last_assignment_date = moment(fila.last_assignment_date).format("MM-DD-yyyy");

          data.push(fila);
        }
      },
    });

    this.columnsReports =
    [
      { 
        text: 'Last Date',  
        datafield: 'last_assignment_date',
        displayfield: 'last_assignment_date',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Inactive Days',  
        datafield: 'inactive_days',
        displayfield: 'inactive_days',
        editable: false,
        width: '12%' 
      },
      { 
        text: 'Organization',  
        datafield: 'organization_name',
        displayfield: 'organization_name',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'School',  
        datafield: 'business_name',
        displayfield: 'business_name',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'Zone Number',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zip Code',  
        datafield: 'zip_code',
        displayfield: 'zip_code',
        editable: false,
        width: '15%' 
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}