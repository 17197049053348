<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: outline/check-circle -->
            <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
            <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
            <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: mini/x-mark -->
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>   

<div class="py-6 px-4 sm:p-6 lg:pb-8">
  <div>
    <h2 class="text-lg font-medium leading-6 text-gray-900">Educator Interest Form</h2>
    <p class="mt-1 text-sm text-gray-500">Please fill in all the information to finish the registration process</p>
  </div>

  <div class="mt-6 flex flex-col lg:flex-row">
    <div class="flex-grow space-y-6">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-3">
          <label for="first_name" class="block text-sm font-medium text-gray-700">
            First Name <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" name="first_name" id="first_name" autocomplete="first_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="first_name">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="second_name" class="block text-sm font-medium text-gray-700">Middle Name</label>
          <input type="text" name="second_name" id="second_name" autocomplete="second_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="second_name">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="last_name" class="block text-sm font-medium text-gray-700">
            Last Name <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" name="last_name" id="last_name" autocomplete="last_name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="last_name">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="nickname" class="block text-sm font-medium text-gray-700">Nickname</label>
          <input type="text" autocomplete="nickname" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="nickname">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="type_identification_id" class="block text-sm font-medium text-gray-700 mb-1">
            Identification Type <span class="text-xss text-red-500">(Required)</span>
          </label>
          <jqxComboBox #comboboxTypeIdentification
            [width]="'100%'"  
            [height]="38" 
            [displayMember]="'name'" 
            [valueMember]="'id'" 
            [source]="type_identifications" 
            [theme]="'energyblue'"
            [(ngModel)]="type_identification_id"
            (onSelect)="onChangeFormatIdentification($event)"
            [autoComplete]="true" 
            [searchMode]="'containsignorecase'"
          ></jqxComboBox>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="identification" class="block text-sm font-medium text-gray-700">
            Identification Number <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" autocomplete="identification" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="identification" [brmasker]="mask_identification">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" name="email" id="email" autocomplete="email" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="email" readonly>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="birth_date" class="block text-sm font-medium text-gray-700 mb-1">
            Birth Date <span class="text-xss text-red-500">(Required)</span>
          </label>
          <jqxDateTimeInput 
            [width]="'100%'" 
            [height]="38"
            [formatString]="'MM-dd-yyyy'"
            [(ngModel)]="birth_date" 
            [theme]="'energyblue'"
          ></jqxDateTimeInput>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="gender_id" class="block text-sm font-medium text-gray-700">
            Gender
          </label>
          <jqxComboBox #comboboxGender
            [width]="'100%'"  
            [height]="38" 
            [displayMember]="'name'" 
            [valueMember]="'id'" 
            [source]="genders" 
            [theme]="'energyblue'"
            [(ngModel)]="gender_id"
            [autoComplete]="true" 
            [searchMode]="'containsignorecase'"
          ></jqxComboBox>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="state_id" class="block text-sm font-medium text-gray-700 mb-1">
            Current State <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" name="state_id" id="state_id" autocomplete="state_id" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" value="GEORGIA" disabled>
          <!-- <jqxComboBox #comboboxState
            [width]="'100%'" 
            [height]="38" 
            [displayMember]="'name'" 
            [valueMember]="'id'"
            [disabled]="true"
            [source]="states" 
            [theme]="'energyblue'"
            [(ngModel)]="state_id"
            (onSelect)="statesOnSelect($event)"
            [autoComplete]="true" 
            [searchMode]="'containsignorecase'"
          ></jqxComboBox> -->
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="city_id" class="block text-sm font-medium text-gray-700 mb-1">
            Current City <span class="text-xss text-red-500">(Required)</span>
          </label>
          <jqxComboBox #comboboxCity
            [width]="'100%'"  
            [height]="38" 
            [displayMember]="'name'" 
            [valueMember]="'id'" 
            [source]="cities" 
            [theme]="'energyblue'"
            (onSelect)="cityOnSelect($event)"
            (focusout)="cityFocusout($event)"
            [autoComplete]="true" 
            [searchMode]="'containsignorecase'"
          ></jqxComboBox>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="zip_code" class="block text-sm font-medium text-gray-700 mb-1">
            Current ZIP Code <span class="text-xss text-red-500">(Required)</span>
          </label>
          <jqxComboBox #comboboxZipCode
            [width]="'100%'"  
            [height]="38" 
            [displayMember]="'code'" 
            [valueMember]="'code'" 
            [source]="zip_codes" 
            [theme]="'energyblue'"
            (focusout)="zipCodeFocusout($event)"
            [autoComplete]="true" 
            [searchMode]="'containsignorecase'"
          ></jqxComboBox>
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="address" class="block text-sm font-medium text-gray-700">
            Current Address <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="text" name="address" id="address" autocomplete="address" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="address">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
          <input type="number" min="0" (input)="numberInput($event)" name="phone" id="phone" autocomplete="phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
        </div>

        <div class="col-span-12 sm:col-span-3">
          <label for="cell_phone" class="block text-sm font-medium text-gray-700">
            Mobile Phone <span class="text-xss text-red-500">(Required)</span>
          </label>
          <input type="number" min="0" (input)="numberInput($event)" name="cell_phone" id="cell_phone" autocomplete="cell_phone" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="cell_phone" onkeypress="return this.value.length < 10;" oninput="if(this.value.length >= 10) { this.value = this.value.slice(0, 10); }">
        </div>
        <div class="col-span-12 sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
          <button type="submit" (click)="registerOnClick()" class="mt-1 block w-full bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Register</button>
        </div>
      </div>
    </div>
  </div>
</div>