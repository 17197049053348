<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div>
      <h2 class="text-lg font-medium leading-6 text-gray-900">Zones</h2>
      <p class="mt-1 text-sm text-gray-500">Edit and Upade Schools Zone Numbers, School Zone Names and Select Zone Manager Name (double click cell to select name from drop down menul)</p>
    </div> 

    <!-- <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-3">
        <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
        <input type="text" name="name" id="name" autocomplete="name" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="name">
      </div>

      <div class="col-span-12 sm:col-span-4">
        <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
        <input type="text" name="description" id="description" autocomplete="description" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="description">
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="user_profile_id" class="block text-sm font-medium text-gray-700 mb-1">Zone Manager</label>
        <jqxComboBox
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zone_managers" 
          [theme]="'energyblue'"
          [(ngModel)]="user_profile_id"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-2">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="createZoneOnClick()" class="w-full inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">CREATE</button>
      </div>
    </div> -->
    
    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxGrid #gridZones
          [width]="'100%'" 
          [source]="dataAdapterZones" 
          [columns]="columnsZones"
          [pageable]="true" 
          [autoheight]="true" 
          [showfilterrow]="true"
          [columnsresize]="true"
          [filterable]="true"
          [sortable]="true"  
          [editable]="true" 
          [editmode]="'dblclick'"
          [selectionmode]="'singlerow'"
          (onCellclick)="onCellClickZones($event)"
          [localization]="localization"
          [theme]="'energyblue'"
        ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>