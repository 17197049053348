import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpInterceptor } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrMaskerModule } from 'br-mask';
import { RecaptchaModule } from 'ng-recaptcha';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxComboBoxModule } from 'jqwidgets-ng/jqxcombobox';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxRadioButtonModule } from 'jqwidgets-ng/jqxradiobutton';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxEditorModule } from 'jqwidgets-ng/jqxeditor';
import { jqxRadioButtonGroupModule } from 'jqwidgets-ng/jqxradiobuttongroup';
import { jqxTooltipModule } from 'jqwidgets-ng/jqxtooltip';

import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SubmenusComponent } from './submenus/submenus.component';
import { AgencyProfileComponent } from './agency-profile/agency-profile.component';
import { UsersComponent } from './users/users.component';
import { UserProfilesComponent } from './user-profiles/user-profiles.component';
import { ParametersComponent } from './parameters/parameters.component';
import { LandingComponent } from './landing/landing.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { AssignmentPostingsComponent } from './assignment-postings/assignment-postings.component';
import { PostingsComponent } from './postings/postings.component';
import { SchoolChargeRatesComponent } from './school-charge-rates/school-charge-rates.component';
import { EducatorPayRatesComponent } from './educator-pay-rates/educator-pay-rates.component';
import { TasksComponent } from './tasks/tasks.component';
import { AssignmentListsComponent } from './assignment-lists/assignment-lists.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AssignmentChatsComponent } from './assignment-chats/assignment-chats.component';
import { GeneralChatsComponent } from './general-chats/general-chats.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { EducatorInvoiceComponent } from './educator-invoice/educator-invoice.component';
import { SchoolInvoiceComponent } from './school-invoice/school-invoice.component';
import { AgencyChatsComponent } from './agency-chats/agency-chats.component';
import { LateArrivalReportComponent } from './late-arrival-report/late-arrival-report.component';
import { ReconciliateComponent } from './reconciliate/reconciliate.component';
import { DailyHoursReportComponent } from './daily-hours-report/daily-hours-report.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EducatorAssignmentReportComponent } from './educator-assignment-report/educator-assignment-report.component';
import { ApplyCancelReportComponent } from './apply-cancel-report/apply-cancel-report.component';
import { LateCheckOutReportComponent } from './late-check-out-report/late-check-out-report.component';
import { InactiveSchoolsReportComponent } from './inactive-schools-report/inactive-schools-report.component';
import { InactiveEducatorsReportComponent } from './inactive-educators-report/inactive-educators-report.component';
import { RolesComponent } from './roles/roles.component';
import { EducatorInvoiceReportComponent } from './educator-invoice-report/educator-invoice-report.component';
import { SchoolInvoiceReportComponent } from './school-invoice-report/school-invoice-report.component';
import { ZonesComponent } from './zones/zones.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AssignmentMapComponent } from './assignment-map/assignment-map.component';
import { EducatorAvailabilityReportComponent } from './educator-availability-report/educator-availability-report.component';
import { EducatorBlockedDatesReportComponent } from './educator-blocked-dates-report/educator-blocked-dates-report.component';
import { RankingReportComponent } from './ranking-report/ranking-report.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { SameDayCancellationReportComponent } from './same-day-cancellation-report/same-day-cancellation-report.component';
import { SchoolChargeRatesLegacyComponent } from './school-charge-rates-legacy/school-charge-rates-legacy.component';
import { EducatorPayRatesLegacyComponent } from './educator-pay-rates-legacy/educator-pay-rates-legacy.component';
import { jqxSwitchButtonModule } from 'jqwidgets-ng/jqxswitchbutton';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';

@NgModule({
  declarations: [
    AppComponent,
    ErrorNotFoundComponent,
    LoginComponent,
    HomeComponent,
    SubmenusComponent,
    AgencyProfileComponent,
    UsersComponent,
    UserProfilesComponent,
    ParametersComponent,
    LandingComponent,
    RegisterUserComponent,
    AssignmentPostingsComponent,
    PostingsComponent,
    SchoolChargeRatesComponent,
    SchoolChargeRatesLegacyComponent,
    EducatorPayRatesComponent,
    EducatorPayRatesLegacyComponent,
    TasksComponent,
    AssignmentListsComponent,
    UserProfileComponent,
    AssignmentChatsComponent,
    GeneralChatsComponent,
    InvoiceComponent,
    EducatorInvoiceComponent,
    SchoolInvoiceComponent,
    AgencyChatsComponent,
    LateArrivalReportComponent,
    ReconciliateComponent,
    DailyHoursReportComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent,
    EducatorAssignmentReportComponent,
    ApplyCancelReportComponent,
    LateCheckOutReportComponent,
    InactiveSchoolsReportComponent,
    InactiveEducatorsReportComponent,
    RolesComponent,
    EducatorInvoiceReportComponent,
    SchoolInvoiceReportComponent,
    ZonesComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    AssignmentMapComponent,
    EducatorAvailabilityReportComponent,
    EducatorBlockedDatesReportComponent,
    RankingReportComponent,
    DeleteAccountComponent,
    SameDayCancellationReportComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    PdfViewerModule,
    GoogleMapsModule,
    BrMaskerModule,
    RecaptchaModule,
    jqxGridModule,
    jqxInputModule,
    jqxComboBoxModule,
    jqxTextAreaModule,
    jqxNumberInputModule,
    jqxDateTimeInputModule,
    jqxCheckBoxModule,
    jqxButtonModule,
    jqxRadioButtonModule,
    jqxChartModule,
    jqxEditorModule,
    jqxRadioButtonGroupModule,
    jqxTooltipModule,
    jqxSwitchButtonModule,
    jqxLoaderModule,
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
