<div class="py-6 lg:pb-8 px-8 lg:px-24 h-[400px]">
  <div id="start_form">
    <h2 class="text-lg font-medium leading-6 text-gray-900"><b>Delete Account</b></h2>
  </div>

  <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
    <div class="col-span-12 sm:col-span-12 text-justify">
      <span>
        To request the deletion of your account and data, please send an email to <a class="underline text-cyan-600 decoration-cyan-600" (click)="mailMe()" href="mailto:info@ensolutions.app">info@ensolutions.app</a>. Your request will be processed within 48 hours.
      </span>
    </div>
  </div>
</div>