<div *ngIf="permissions && permissions.visualize == 1">
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-10 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div *ngFor="let alert of alerts" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-{{ alert.type }}-100 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-{{ alert.type }}-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path *ngIf="alert.type == 'green'" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path *ngIf="alert.type == 'red'" stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'green'">Successful</p>
              <p class="text-sm font-medium text-gray-900" *ngIf="alert.type == 'red'">Something went wrong</p>
              <p class="mt-1 text-sm text-gray-500"><span [innerHTML]="alert.message"></span></p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button (click)="closeAlert()" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: mini/x-mark -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div id="start_assignment_posting">
      <h2 class="text-lg font-medium leading-6 text-gray-900">Hours Reconciliation</h2>
      <p class="mt-1 text-sm text-gray-500">Filter, Select and Approve Educators Daily Hours</p>
    </div>

    <div class="mt-6 mb-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-3">
        <label for="date_from" class="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
        <jqxDateTimeInput #dateFromDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_from" 
          [theme]="'energyblue'"
          (onChange)="dateFromOnChange($event)"
        ></jqxDateTimeInput>
      </div>
      
      <div class="col-span-12 sm:col-span-3">
        <label for="date_to" class="block text-sm font-medium text-gray-700 mb-1">End Date</label>
        <jqxDateTimeInput #dateToDateTime
          [width]="'100%'" 
          [height]="38" 
          [formatString]="'MM-dd-yyyy'"
          [(ngModel)]="date_to" 
          [theme]="'energyblue'"
          (onChange)="dateToOnChange($event)"
        ></jqxDateTimeInput>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="organization_id" class="block text-sm font-medium text-gray-700">Organization</label>
        <jqxComboBox #comboboxOrganization
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="organizations" 
          [theme]="'energyblue'"
          [(ngModel)]="organization_id"
          (onSelect)="organizationOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="zone_manager_id" class="block text-sm font-medium text-gray-700">Zone Manager</label>
        <jqxComboBox #comboboxZoneManager
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zone_managers" 
          [theme]="'energyblue'"
          [(ngModel)]="zone_manager_id"
          (onSelect)="zoneManagerOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="zone_id" class="block text-sm font-medium text-gray-700">Zone</label>
        <jqxComboBox #comboboxZone
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="zones" 
          [theme]="'energyblue'"
          [(ngModel)]="zone_id"
          (onSelect)="zoneOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-3">
        <label for="school_id" class="block text-sm font-medium text-gray-700">School</label>
        <jqxComboBox #comboboxSchool
          [width]="'100%'"  
          [height]="38" 
          [displayMember]="'name'" 
          [valueMember]="'id'" 
          [source]="schools" 
          [theme]="'energyblue'"
          [(ngModel)]="school_id"
          (onSelect)="userOnSelect($event)"
          [autoComplete]="true" 
          [searchMode]="'containsignorecase'"
          [disabled]="disComboboxUserProfile"
        ></jqxComboBox>
      </div>

      <div class="col-span-12 sm:col-span-5"></div>

      <div class="col-span-12 sm:col-span-1">
        <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
        <button type="submit" (click)="downloadOnClick()" class="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus-visible:ring-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </button>
      </div>
    </div>

    <hr>

    <div class="mt-6 grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-12">
        <jqxGrid #gridActualAssignmentPost
            [width]="'100%'" 
            [source]="dataAdapterActualAssignmentPost" 
            [columns]="columnsActualAssignmentPost"
            [pageable]="true" 
            [autoheight]="true" 
            [showfilterrow]="true"
            [columnsresize]="true"
            [filterable]="true"
            [sortable]="true"  
            [editable]="true" 
            [editmode]="'dblclick'"
            [selectionmode]="'singlerow'"
            (onCellclick)="onCellClickActualAssignmentPost($event)"
            [localization]="localization"
            [theme]="'energyblue'"
          ></jqxGrid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!permissions || permissions.visualize == 0">
  <h1>You do not have permissions to view this module</h1>
</div>

<div *ngIf="modalSchoolHours" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">School Hours</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalSchoolHours = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-2">  
              <label for="school_hours" class="block text-sm font-medium text-gray-700">Hours</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="school_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_hours">
            </div>
            <div class="col-span-12 sm:col-span-2">  
              <label for="school_overtime" class="block text-sm font-medium text-gray-700">Overtime</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="school_overtime" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_overtime">
            </div>
            <div class="col-span-12 sm:col-span-8">  
              <label for="school_comments" class="block text-sm font-medium text-gray-700">Comments</label>
              <jqxComboBox #comboboxComment
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'description'" 
                [valueMember]="'id'" 
                [source]="comments" 
                [theme]="'energyblue'"
                [(ngModel)]="comment_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
              <textarea rows="2" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm {{ comment_id == 7 ? 'block' : 'hidden' }}" [(ngModel)]="school_comments"></textarea>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editSchoolHours()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalTip" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>              
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Tip</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalTip = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6 ml-3">  
              <label for="amount" class="block text-sm font-medium text-gray-700">Amount</label>
              <span class="text-gray-500 sm:text-sm" style="position: absolute; bottom: 88px; left: 48px;">$</span>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="amount" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" style="padding-left: 30px;" [(ngModel)]="amountTip">
            </div>
            <!-- <div class="col-span-12 sm:col-span-2">  
              <label for="school_overtime" class="block text-sm font-medium text-gray-700">Overtime</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="school_overtime" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="school_overtime">
            </div> -->
           <!--  <div class="col-span-12 sm:col-span-8">  
              <label for="school_comments" class="block text-sm font-medium text-gray-700">Comments</label>
              <jqxComboBox #comboboxComment
                [width]="'100%'"  
                [height]="38" 
                [displayMember]="'description'" 
                [valueMember]="'id'" 
                [source]="comments" 
                [theme]="'energyblue'"
                [(ngModel)]="comment_id"
                [autoComplete]="true" 
                [searchMode]="'containsignorecase'"
              ></jqxComboBox>
              <textarea rows="2" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm {{ comment_id == 7 ? 'block' : 'hidden' }}" [(ngModel)]="school_comments"></textarea>
            </div> -->
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editAmount()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalApprovalHours" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-black bg-opacity-60"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-6/12">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start justify-between">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-auto rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-auto px-5">
              <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="text-lg ml-5 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Approval Hours</h3>
            </div>
            <div class="mt-1 sm:ml-4">
              <div class="ml-3 flex h-7 items-center sm:text-left">
                <button (click)="modalApprovalHours = false" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">  
              <label for="approval_hours" class="block text-sm font-medium text-gray-700">Hours</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="approval_hours" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="approval_hours">
            </div>
            <div class="col-span-12 sm:col-span-6">  
              <label for="approval_overtime" class="block text-sm font-medium text-gray-700">Overtime</label>
              <input type="text" min="0" (input)="numberInput($event)" autocomplete="approval_overtime" class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm" [(ngModel)]="approval_overtime">
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editApprovalHours()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>