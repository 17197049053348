import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";
import { TokenStorageService } from '../_services/token-storage.service';
import * as moment from 'moment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';

@Component({
  selector: 'app-same-day-cancellation-report',
  templateUrl: './same-day-cancellation-report.component.html',
  styleUrls: ['./same-day-cancellation-report.component.css']
})
export class SameDayCancellationReportComponent {
  //GRIDS
  @ViewChild('gridReports', { static: false }) gridReports: jqxGridComponent;

  //DATETIME
  @ViewChild('startDateDateTime', { static: false }) startDateDateTime: jqxDateTimeInputComponent;
  @ViewChild('endDateDateTime', { static: false }) endDateDateTime: jqxDateTimeInputComponent;

  //DATETIME
  @ViewChild('comboboxZoneManager', { static: false }) comboboxZoneManager: jqxComboBoxComponent;
  @ViewChild('comboboxEducator', { static: false }) comboboxEducator: jqxComboBoxComponent;

  private URL_BASE: string;
  public alerts: any;
  public permissions: any;
  public user_id: any;
  public user_name: any;

  // DATA
  public zone_managers: any;
  public educators: any;

  // FORM
  public date_from: string = "";
  public date_to: string = "";
  public zone_manager_id: number = 0;
  public educator_id: number = 0;
  public educator: any;

  // GRIDS
  public reports: any;
  public sourceReports: any;
  public dataAdapterReports: any = [];
  public columnsReports: any;

  public localization: any;

  constructor(
    private configuration: ConfigurationService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient
  ) { 
    this.URL_BASE = this.configuration.getUrlBase();
    this.permissions = this.tokenStorage.getUserPermission('same-day-cancellation-report');
    this.user_id = this.tokenStorage.getUser();
    this.user_name = this.tokenStorage.getUserName();

    this.alerts = new Array();
  }

  async ngOnInit() {
    if(this.permissions && this.permissions.visualize == 1) {
      await this.loadDataForm();
      await this.loadDataZoneManagers();
      await this.loadZoneManagerEducators(0);

      this.createGridReports();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          CREACIÓN DE SOURCE                           */
  /* ------------------------------------------------------------------------------------- */

  async loadDataForm() {
    this.date_from = moment(new Date()).format("MM-DD-yyyy");
    this.date_to = moment(new Date()).format("MM-DD-yyyy");
  }

  async loadDataZoneManagers() {
    await this.http.get(this.URL_BASE + 'user_profiles/filter-zone-managers').subscribe(res => {
      var sourceUserProfile =
      {
        localdata: res,
        datatype: 'array',
        datafields:
        [
          { name: 'id', type: 'int' },
          { name: 'name', type: 'string' },
          { name: 'first_name', type: 'string' },
          { name: 'second_name', type: 'string' },
          { name: 'last_name', type: 'string' },
          { name: 'business_name', type: 'string' }
        ]
      };

      this.zone_managers = new jqx.dataAdapter(sourceUserProfile, {
        beforeLoadComplete: function(records: any) {
          var data = new Array();
          for (var i = 0; i < records.length; i++) {
            var fila = records[i];

            if(fila.first_name && fila.second_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
            } else if(fila.first_name && fila.last_name) {
              fila.name = fila.first_name + " " + fila.last_name;
            } else if(fila.first_name) {
              fila.name = fila.first_name;
            } else {
              fila.name = fila.business_name;
            }

            data.push(fila);
          }
        },
      }); 
    }, (error: any) => {
      if(error && error.statusText && error.statusText == "Unknown Error") {
        this.loadDataZoneManagers();
      }
    });
  }

  async loadZoneManagerEducators(zone_manager_id = 0) {
    if(zone_manager_id == 0) {
      await this.http.get(this.URL_BASE + 'users/filter-type/4').subscribe(res => {
        var sourceUser =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' }
          ]
        };

        this.educators = new jqx.dataAdapter(sourceUser, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerEducators(zone_manager_id);
        }
      });
    } else {
      await this.http.get(this.URL_BASE + 'users/filter-zone-manager/' + zone_manager_id).subscribe(res => {
        var sourceUser =
        {
          localdata: res,
          datatype: 'array',
          datafields:
          [
            { name: 'id', type: 'int' },
            { name: 'name', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'second_name', type: 'string' },
            { name: 'last_name', type: 'string' }
          ]
        };

        this.educators = new jqx.dataAdapter(sourceUser, {
          beforeLoadComplete: function(records: any) {
            var data = new Array();
            for (var i = 0; i < records.length; i++) {
              var fila = records[i];

              if(fila.first_name && fila.second_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.second_name + " " + fila.last_name;
              } else if(fila.first_name && fila.last_name) {
                fila.name = fila.first_name + " " + fila.last_name;
              } else if(fila.first_name) {
                fila.name = fila.first_name;
              }

              data.push(fila);
            }
          },
        }); 
      }, (error: any) => {
        if(error && error.statusText && error.statusText == "Unknown Error") {
          this.loadZoneManagerEducators(zone_manager_id);
        }
      });
    }
  }

  loadDataReport(date_from: string = "", date_to: string = "", zone_manager_id: number = 0, user_id: number = 0) {
    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zone_manager_id) {
      zone_manager_id = 0;
    }

    if(!user_id) {
      user_id = 0;
    }

    this.http.get(this.URL_BASE + 'report/filter-same-day-cancellation/' + date_from + '/' + date_to + '/' + zone_manager_id + '/' + user_id).subscribe(res => {
      this.reports = res;

      this.sourceReports.localdata = this.reports;
      this.gridReports.updatebounddata('cells');
    });
  }

  reportGenerate(date_from: any = "", date_to: any = "", zone_manager_id: number = 0, user_id: number = 0) {
    if(date_from == "" && date_to == "") {
      let error = {
        message: "Select a date range to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zone_manager_id) {
      zone_manager_id = 0;
    }
    
    if(!user_id) {
      user_id = 0;
    }

    window.open(this.URL_BASE + 'same-day-cancellation/report-pdf/' + date_from + '/' + date_to + '/' + zone_manager_id + '/' + user_id + '/' + this.user_id, "_blank");
  }

  reportExcelData(date_from: any = "", date_to: any = "", zone_manager_id: number = 0, user_id: number = 0) {
    if(date_from == "" && date_to == "") {
      let error = {
        message: "Select a date range to continue",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }

    if(date_from) {
      date_from = moment(date_from).format("yyyy-MM-DD");
    } else {
      date_from = "0";
    }
    
    if(date_to) {
      date_to = moment(date_to).format("yyyy-MM-DD");
    } else {
      date_to = "0";
    }

    if(!zone_manager_id) {
      zone_manager_id = 0;
    }
    
    if(!user_id) {
      user_id = 0;
    }

    window.open(this.URL_BASE + 'same-day-cancellation/report-excel/' + date_from + '/' + date_to + '/' + zone_manager_id + '/' + user_id + '/' + this.user_id, "_blank");
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CLICK                                   */
  /* ------------------------------------------------------------------------------------- */

  reportOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportGenerate(this.date_from, this.date_to, this.zone_manager_id, this.educator_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  downloadOnClick() {
    if(this.permissions && this.permissions.visualize == 1) {
      this.reportExcelData(this.date_from, this.date_to, this.zone_manager_id, this.educator_id);
    } else {
      let error = {
        message: "You do not have permissions to visualize in this module",
        type: "red"
      };

      this.alerts.push(error);
      return this.closeAlertTime();
    }
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS SELECT                                  */
  /* ------------------------------------------------------------------------------------- */

  zoneManagerOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.educator_id = 0;
        this.comboboxEducator.clearSelection();
        this.loadDataReport(this.date_from, this.date_to, item, this.educator_id);
        this.loadZoneManagerEducators(item);
      }
    } else {
      this.zone_manager_id = 0;
      this.comboboxZoneManager.clearSelection();
      this.educator_id = 0;
      this.comboboxEducator.clearSelection();
      this.loadDataReport(this.date_from, this.date_to, this.zone_manager_id, this.educator_id);
      this.loadZoneManagerEducators(0);
    }
  }

  userOnSelect(event: any): void {
    if (event.args) {
      let item = event.args.item.value;
      if (item) {
        this.loadDataReport(this.date_from, this.date_to, this.zone_manager_id, item);
      }
    } else {
      this.educator_id = 0;
      this.comboboxEducator.clearSelection();
      this.loadDataReport(this.date_from, this.date_to, this.zone_manager_id, this.educator_id);
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                       EVENTOS CHANGE                                  */
  /* ------------------------------------------------------------------------------------- */

  dateFromOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadDataReport(date, this.date_to, this.zone_manager_id, this.educator_id);
      }
    }
  }

  dateToOnChange(event: any): void {
    if(event.target) {
      var date = event.target.value;

      if(date) {
        this.loadDataReport(this.date_from, date, this.zone_manager_id, this.educator_id);
      }
    }
  }


  /* ------------------------------------------------------------------------------------- */
  /*                                      FUNCIONES LIMPIEZA                               */
  /* ------------------------------------------------------------------------------------- */


  /* ------------------------------------------------------------------------------------- */
  /*                                      CREACIÓN DE GRIDS                                */
  /* ------------------------------------------------------------------------------------- */

  createGridReports() {
    this.sourceReports =
    {
      localdata: this.reports, 
      datatype: 'array',
      datafields:
      [
        { name: 'id', type: 'int' },
        { name: 'report_type', type: 'string' },
        { name: 'assignament_type_id', type: 'int' },
        { name: 'assignment_posting_id', type: 'int' },
        { name: 'assignment', type: 'string' },
        { name: 'date', type: 'string' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' },
        { name: 'assignament_type', type: 'string' },
        { name: 'school_business_name', type: 'string' },
        { name: 'organization_name', type: 'string' },
        { name: 'zone_school', type: 'string' },
        { name: 'zip_code', type: 'string' },
        { name: 'educator', type: 'string' },
        { name: 'zone_manager', type: 'string' },
        { name: 'status', type: 'string' }
      ]
    };

    this.dataAdapterReports = new jqx.dataAdapter(this.sourceReports, {
      beforeLoadComplete: function(records: any) {
        var data = new Array();
        for (var i = 0; i < records.length; i++) {
          var fila = records[i];

          if(fila.report_type) {
            fila.report_type = `${fila.report_type[0].toUpperCase()}${fila.report_type.slice(1).toLowerCase()}`;
          }

          fila.assignment = fila.assignment_posting_id + " / " + moment(fila.start_date).format("MM-DD-yyyy") + " - " + moment(fila.end_date).format("MM-DD-yyyy");

          fila.date = moment(fila.date).format("MM-DD-yyyy");

          data.push(fila);
        }
      },
    });

    this.columnsReports =
    [
      { 
        text: 'Educator',  
        datafield: 'educator',
        displayfield: 'educator',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Type',  
        datafield: 'report_type',
        displayfield: 'report_type',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Status',  
        datafield: 'status_id',
        displayfield: 'status',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Assignment',  
        datafield: 'assignment',
        displayfield: 'assignment',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'Date',  
        datafield: 'date',
        displayfield: 'date',
        editable: false,
        width: '10%' 
      },
      { 
        text: 'Organization',  
        datafield: 'organization_name',
        displayfield: 'organization_name',
        editable: false,
        width: '25%' 
      },
      { 
        text: 'School',  
        datafield: 'school_business_name',
        displayfield: 'school_business_name',
        editable: false,
        width: '20%' 
      },
      { 
        text: 'Zone Number',  
        datafield: 'zone_school',
        displayfield: 'zone_school',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zone Manager',  
        datafield: 'zone_manager',
        displayfield: 'zone_manager',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Zip Code',  
        datafield: 'zip_code',
        displayfield: 'zip_code',
        editable: false,
        width: '15%' 
      },
      { 
        text: 'Assignment Type',  
        datafield: 'assignament_type_id',
        displayfield: 'assignament_type',
        editable: false,
        width: '20%' 
      }
    ];
  }

  /* ------------------------------------------------------------------------------------- */
  /*                                          FUNCIONES MENSAJES                           */
  /* ------------------------------------------------------------------------------------- */
  closeAlert() {
    this.alerts = new Array();
  }

  closeAlertTime() {
    setTimeout(() => {
      this.alerts = this.alerts.filter((element: any, index: number) => index != 0);
    }, 20000);
  }

}
 